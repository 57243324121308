import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { Strong } from '../../../components/Text';
import Icon from '../../../components/Icon';
import { colors } from '../../../constants/theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 40,
    borderRadius: 10,
    backgroundColor: 'rgb(249, 249, 249)',
    paddingHorizontal: 15,
    alignSelf: 'flex-start',
  },
  icon: {
    marginBottom: 1,
    marginLeft: -5,
  },
  text: {
    color: colors.ui.ok,
    textTransform: 'uppercase',
  },
});

function CompletedBadge({ text }) {
  return (
    <View style={styles.container}>
      <Icon name="checkmark" size={25} style={styles.icon} color={colors.ui.ok} />
      <Strong style={styles.text}>{text}</Strong>
    </View>
  );
}

CompletedBadge.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

export default CompletedBadge;
