import { StyleSheet } from 'react-native';
import { colors } from '../../../constants/theme';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.10,
    shadowRadius: 3,
    marginBottom: 15,
  },
  titleWrapper: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  providerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  providerName: {
    fontSize: 18,
  },
  providerLogo: {
    width: 35,
    height: 35,
  },
  toggleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  toggleLabel: {
    textTransform: 'uppercase',
    color: colors.label.tertiary,
    fontSize: 11,
  },
  toggleLabelGreen: {
    color: colors.ui.ok,
  },
  toggleLabelAttention: {
    color: colors.ui.attention,
  },
  toggleButton: {
    marginLeft: 10,
  },
  seperator: {
    height: 1,
    backgroundColor: '#F0F0F0',
  },
  pluginInstalledContainer: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  pluginInstalledText: {
    marginLeft: 10,
    lineHeight: 20,
    color: colors.label.secondary,
  },
  downloadPluginContainer: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexShrink: 1,
  },
  downloadPluginTextWrapper: {
    flexShrink: 1,
    marginLeft: 10,
    marginTop: 3,
  },
  downloadPluginText: {
    lineHeight: 20,
    color: colors.label.primary,
    marginBottom: 10,
  },
  openLinkButton: {
    alignSelf: 'flex-start',
    marginTop: 10,
  },
  rightIcon: {
    marginRight: -2,
    marginLeft: 7,
  },
  openLinkIcon: {
    marginRight: 7,
    marginLeft: -2,
  },
});

export default styles;
