import { StyleSheet } from 'react-native';
import { shadow } from '../../constants/theme';

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    bottom: 95,
    right: 25,
  },
  openHelpMenuButton: {
    backgroundColor: '#ffffff',
    borderRadius: 50,
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...shadow.normal,
  },
  menuContainer: {
    position: 'absolute',
    transform: [{ translateX: '-100%' }, { translateY: '-100%' }],
    backgroundColor: '#fff',
    borderRadius: 10,
    ...shadow.normal,
    padding: 10,
    paddingBottom: 5,
  },
  heading: {
    fontSize: 16,
    padding: 10,
  },
  devider: {
    height: 1,
    backgroundColor: '#ececec',
    marginVertical: 10,
    marginHorizontal: -10,
  },
  copyright: {
    padding: 10,
    marginBottom: 5,
  },
});

export default styles;
