import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { P, Strong } from '../../../components/Text';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import AttentionSeeker from '../../../components/AttentionSeeker';
import CompletedBadge from './CompletedBadge';
import { colors } from '../../../constants/theme';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  attention: {
    marginRight: 5,
  },
  stepIndicator: {
    width: 30,
    height: 30,
    backgroundColor: '#fff',
    borderWidth: 3,
    borderColor: '#f2f2f2',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepIndicatorActive: {
    borderColor: '#d5d5d5',
  },
  titleWrapper: {
    maxWidth: 450,
    marginLeft: 15,
  },
  title: {
    fontSize: 16,
  },
  description: {
    marginTop: 5,
    fontSize: 14,
    color: colors.text.small,
  },
});

function TodoItem({ item }) {
  const inProgress = item.state === 'IN_PROGRESS';
  const isIdle = item.state === 'IDLE';
  return (
    <View style={styles.container}>
      <View style={styles.stepGuideLine} />
      <View style={[styles.stepIndicator, inProgress && styles.stepIndicatorActive]}>
        {(inProgress || isIdle) ? (<Strong>{item.step}</Strong>)
          : (<Icon name="checkmark-sharp" size={20} style={styles.icon} color={colors.ui.ok} />)}
      </View>
      <View style={styles.titleWrapper}>
        <Strong style={styles.title}>
          {inProgress && <AttentionSeeker style={styles.attention} />}
          {item.title}
        </Strong>
        <P style={styles.description}>{item.description}</P>
      </View>
      <View style={{ flex: 1 }} />
      {item.state !== 'COMPLETED' ? (
        <Button
          type={item.state !== 'IN_PROGRESS' ? 'secondary' : 'primary'}
          label={item.buttonLabel}
          onPress={item.onPress && item.onPress}
          disabled={item.state === 'IDLE'}
        />
      ) : (
        <CompletedBadge text={item.completedDescription} />
      )}
    </View>
  );
}

export const TodoItemType = PropTypes.exact({
  id: PropTypes.string,
  step: PropTypes.number,
  state: PropTypes.string,
  title: PropTypes.element,
  description: PropTypes.element,
  completedDescription: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  onPress: PropTypes.func,
  buttonLabel: PropTypes.element,
});

TodoItem.propTypes = {
  item: TodoItemType.isRequired,
};

export default TodoItem;
