import React, { useState } from 'react';
import { View, Modal, Linking } from 'react-native';
import Backdrop from '../../components/Backdrop';
import CloseModalButton from '../../components/CloseModalButton';
import { P, H3, A } from '../../components/Text';
import styles from './SupportModal.styles';
import { useLocale } from '../../services/localization';
import { links } from '../../constants';

// eslint-disable-next-line react/prop-types
function Solution({ children }) {
  return (
    <View style={styles.line}>
      <P style={styles.handIcon}>👉</P>
      <P style={styles.text}>
        {children}
      </P>
    </View>
  );
}

function link(str, action) {
  return <A onPress={action} style={styles.text}>{str}</A>;
}

const openSupport = () => {
  Linking.openURL(links.createTicket);
};

const SupportModal = React.forwardRef((_, ref) => {
  const $t = useLocale();
  const [isVisible, setIsVisible] = useState(false);

  const onRequestClose = () => {
    setIsVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setIsVisible(true);
    },
  }));

  return (
    <Modal onRequestClose={onRequestClose} animationType="fade" visible={isVisible} transparent>
      <Backdrop />
      <View style={styles.contentWrapper}>
        <View style={styles.content}>
          <H3>{$t({ id: 'SupportModal.Title', defaultMessage: 'Unable to connect your Woo webshop with FastPicker?' })}</H3>
          <H3>{$t({ id: 'SupportModal.PleaseCheck', defaultMessage: 'Please check the following:' })}</H3>
          <View style={styles.solutions}>
            <Solution>{$t({ id: 'SupportModal.UrlSecretKeyCorrect', defaultMessage: 'Make sure your webshop url, secret and key are entered correctly' })}</Solution>
            <Solution>{$t({ id: 'SupportModal.ReadWritePermissions', defaultMessage: 'Make sure your Woo secret and key have READ/WRITE permissions' })}</Solution>
            <Solution>{$t({ id: 'SupportModal.DisablePlugins', defaultMessage: 'Try disabling every plugin except WooCommerce and FastPicker plugin' })}</Solution>
          </View>
          <P style={styles.text}>
            {$t({ id: 'SupportModal.StillNotAbelToConnect', defaultMessage: 'Still not able to connect? <link>Please contact our support</link>' }, {
              link: (str) => link(str, openSupport),
            })}
          </P>
        </View>
        <CloseModalButton onPress={onRequestClose} />
      </View>
    </Modal>
  );
});

export default SupportModal;
