import { useMemo } from 'react';
import useShippingProviders from '../../hooks/shop/useShippingProviders';
import useWordpressPlugin from '../../hooks/shop/useWordpressPlugin';
import useShopStatus from '../../hooks/shop/useShopStatus';
import useShopInfo from '../../hooks/shop/useShopInfo';
import { ShopStatus } from '../../constants';

export const useHasEnabledShippingProviders = (shopId) => {
  const { data } = useShippingProviders(shopId);
  return useMemo(() => {
    if (!data) {
      return false;
    }
    const found = data?.shop?.shipping_fields.find((carrier) => carrier.active);
    return !!found;
  }, [data]);
};

export const useHasEnabledWordpressPlugin = (shopId) => {
  const { data } = useWordpressPlugin(shopId);
  return useMemo(() => {
    if (!data) {
      return false;
    }
    return !!data.shop.installed_plugin_version;
  }, [data]);
};

export const useIsActiveShop = (shopId) => {
  const { data } = useShopStatus(shopId);
  return useMemo(() => {
    if (!data) {
      return false;
    }
    return data.shop.status === ShopStatus.Active;
  }, [data]);
};

export const useIsShopInfoComplete = (shopId) => {
  const { data } = useShopInfo(shopId);
  return useMemo(() => {
    if (!data) {
      return false;
    }
    const fields = ['address', 'city', 'country', 'logo', 'postcode'];
    const incompleteField = fields.find((field) => data.shop[field] === null);
    return {
      incompleteField: !incompleteField,
      shopStatus: data.shop.status,
    };
  }, [data]);
};
