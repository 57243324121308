import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query GetOrders($first: Int!, $page: Int!, $status: [String]) {
    orders(first: $first, page: $page, status: $status) {
      paginatorInfo {
        currentPage
        count
        total
      }
      data {
        id
        woocommerce_order_id
        shipping_first_name
        shipping_last_name
        shipping_address_1
        shipping_address_2
        shipping_city
        status
        shipping_label_status
        order_lines {
          id
          picked
          quantity
        }
        shipping_label
      }
    }
  }
`;

export const UPDATE_ORDER_FIELDS = gql`
  query RefreshOrderShippingFields {
    orders(first: 30, page: 1, status: ["new", "being_picked"]) {
      paginatorInfo {
        currentPage
        count
      }
      data {
        id
        shipping_fields {
          plugin
          fields {
            name
          }
        }
      }
    }
  }
`;

export const GET_ORDER = gql`
  query GetOrder($orderId: ID!) {
    order(id: $orderId) {
      id
      status
      woocommerce_order_id
      order_lines {
        id
        sku
        title
        picked
        quantity
        image
      }
      shop_id
      shipping_label
      shipping_label_status
      shipping_provider
      shipping_failed_reason
      shipping_country
      shipping_country_code
      shipping_state
      shipping_fields {
        plugin
        fields {
          name
          value
          options
          type
          subFields {
            name
            value
            options
            type
          }
        }
      }
    }
  }
`;

export const PICK_ITEM = gql`
  mutation PickItem ($orderLineId: ID!) {
    picked(id: $orderLineId) {
      id
      picked
      quantity
    }
  }
`;

export const UNPICK_ITEM = gql`
  mutation UnpickItem($orderLineId: ID!, $quantity: Int) {
    picked(id: $orderLineId, quantity: $quantity) {
      id
      picked
      quantity
    }
  }
`;

export const COMPLETE_ORDER = gql`
  mutation CompleteOrder($orderId: ID!) {
    orderCompleted(id: $orderId) {
      id
      status
    }
  }
`;
