import { useMutation } from '@apollo/client';
import { UPDATE_SHOP, HAS_SHOP } from '../graphql/ShopQuery';
import { GET_ORDERS } from '../graphql/OrderQuery';

const useUpdateShop = (shopFields, onCompleted, onError) => {
  const [runMutation, { loading }] = useMutation(UPDATE_SHOP, {
    variables: shopFields,
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted(response) {
      if (typeof onCompleted === 'function') onCompleted(response);
    },
    refetchQueries: [{ query: HAS_SHOP }, { query: GET_ORDERS }],
  });

  const updateShop = () => {
    if (loading) return;
    runMutation();
  };

  return { updateShop, loading };
};

export default useUpdateShop;
