import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet } from 'react-native';
import { P } from './Text';
import { fonts, colors } from '../constants/theme';
import { useLocale } from '../services/localization';

const styles = StyleSheet.create({
  shopStatus: {
    color: '#000',
    textAlign: 'left',
    lineHeight: 21,
  },
  shopStatusProgress: {
    color: '#000',
  },
  shopStatusActive: {
    color: '#70C630',
    fontFamily: fonts.family.bold,
    textTransform: 'uppercase',
  },
  shopStatusWarning: {
    color: colors.ui.attention,
  },
});

const color = {
  active: styles.shopStatusActive,

  waiting_for_test: styles.shopStatusProgress,
  testing: styles.shopStatusProgress,
  fetching_order_statuses: styles.shopStatusProgress,
  fetched_order_statuses: styles.shopStatusProgress,
  new: styles.shopStatusProgress,

  cant_reach_shop: styles.shopStatusWarning,
  failed_retry: styles.shopStatusWarning,
  invalid_credentials: styles.shopStatusWarning,
  connection_error: styles.shopStatusWarning,
  no_read_permissions: styles.shopStatusWarning,
  no_write_permissions: styles.shopStatusWarning,
  cant_read_order: styles.shopStatusWarning,
  cant_delete_order: styles.shopStatusWarning,
  cant_create_order: styles.shopStatusWarning,
  cant_fetch_products: styles.shopStatusWarning,
  cant_update_order: styles.shopStatusWarning,
  // ready_to_test: styles.shopStatusProgress,
};

export function getShopStatusLabels($t) {
  const linebreak = { br: '\n' };
  return {
    active: $t({ id: 'ShopStatusDescription.Active', defaultMessage: 'Shop is active' }),
    waiting_for_test: $t({ id: 'ShopStatusDescription.WaitingForTest', defaultMessage: 'Starting shop validation...' }),
    ready_to_test: $t({ id: 'ShopStatusDescription.Testing', defaultMessage: 'Validating setup...' }),
    testing: $t({ id: 'ShopStatusDescription.Testing', defaultMessage: 'Validating setup...' }),
    fetching_order_statuses: $t({ id: 'ShopStatusDescription.FetchingOrderStatuses', defaultMessage: 'Retrieving order statuses...' }),
    failed_retry: $t({ id: 'ShopStatusDescription.FailedRetry', defaultMessage: 'Failed to connect to your shop{br}Please check your credentials and make sure they have READ/WRITE permissions.' }, linebreak),
    cant_reach_shop: $t({ id: 'ShopStatusDescription.CantReachShop', defaultMessage: 'Unable to talk to your shop{br}Please check if you entered the correct shop url and credentials.' }, linebreak),
    cant_read_order: $t({ id: 'ShopStatusDescription.CantReadOrder', defaultMessage: 'Unable to get order information{br}Please check if your webshops order API is setup correctly.' }, linebreak),
    cant_delete_order: $t({ id: 'ShopStatusDescription.CantDeleteOrder', defaultMessage: 'Unable to remove orders{br}Please check with your hosting provider if the DELETE HTTP request is enabled.' }, linebreak),
    cant_create_order: $t({ id: 'ShopStatusDescription.CantCreateOrder', defaultMessage: 'Unable to create orders{br}Please check if your webshops order API is setup correctly.' }, linebreak),
    cant_update_order: $t({ id: 'ShopStatusDescription.CantUpdateOrder', defaultMessage: 'Unable to update an order{br}Please check with your hosting provider if the PUT HTTP request is enabled.' }, linebreak),
    cant_fetch_products: $t({ id: 'ShopStatusDescription.CantFetchProducts', defaultMessage: 'Unable to fetch products{br}Please check if your webshops product API is setup correctly.' }, linebreak),
    invalid_credentials: $t({ id: 'ShopStatusDescription.InvalidCredentials', defaultMessage: 'Invalid credentials{br}Please check your credentials and make sure they have READ/WRITE permissions.' }, linebreak),
    connection_error: $t({ id: 'ShopStatusDescription.ConnectionError', defaultMessage: 'Failed to connect with your shop{br}Please check if you entered the correct shop url and credentials.' }, linebreak),
    no_read_permissions: $t({ id: 'ShopStatusDescription.NoReadPermissions', defaultMessage: 'Incorrect permissions{br}FastPicker requires READ and WRITE permissions. Please make sure your credentials have READ/WRITE permissions and try again.' }, linebreak),
    no_write_permissions: $t({ id: 'ShopStatusDescription.NoWritePermissions', defaultMessage: 'Incorrect permissions{br}FastPicker requires READ and WRITE permissions. Please make sure your credentials have READ/WRITE permissions and try again.' }, linebreak),
    // fetched_order_statuses: '',
    // new: '',
  };
}

function ShopStatusLabel({ style, status }) {
  const $t = useLocale();

  const labels = getShopStatusLabels($t);

  if (!labels[status]) return null;

  return (
    <P style={[styles.shopStatus, color[status], style]}>
      {labels[status] || status}
    </P>
  );
}

ShopStatusLabel.propTypes = {
  status: PropTypes.string,
};

ShopStatusLabel.defaultProps = {
  status: '',
};

export default ShopStatusLabel;
