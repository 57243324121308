import React from 'react';
import PropTypes from 'prop-types';
import AsyncStorage from '@react-native-async-storage/async-storage';

const AuthStorageKey = '@new-auth';

const InitialState = { user: null, auth: null };

const AuthContext = React.createContext();

function authReducer(state, action) {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.payload.user,
        auth: action.payload.auth,
      };
    case 'RESTORE':
      return {
        ...state,
        user: action.payload.user,
        auth: action.payload.auth,
      };
    case 'LOGOUT':
      return {
        ...state,
        user: null,
        auth: null,
      };
    default:
      return state;
  }
}

function useAuthContext() {
  return React.useContext(AuthContext);
}

function AuthProvider({ onReady, ...props }) {
  const [state, dispatch] = React.useReducer(authReducer, InitialState);

  const signIn = React.useCallback(async (user, auth) => {
    await AsyncStorage.setItem(AuthStorageKey, JSON.stringify({ user, auth }));
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        auth,
      },
    });
  }, []);

  const signOut = React.useCallback(async () => {
    await AsyncStorage.removeItem(AuthStorageKey);
    dispatch({ type: 'LOGOUT' });
  }, []);

  const values = React.useMemo(() => ({
    ...state,
    signIn,
    signOut,
  }), [state, signIn, signOut]);

  React.useEffect(() => {
    async function restore2() {
      let oldState;

      try {
        oldState = await AsyncStorage.getItem(AuthStorageKey);
      } catch (e) {
        // test
      }

      if (!oldState) {
        await AsyncStorage.removeItem(AuthStorageKey);
        dispatch({ type: 'LOGOUT' });
        onReady();
        return;
      }

      dispatch({
        type: 'RESTORE',
        payload: JSON.parse(oldState),
      });
      onReady();
    }
    restore2();
  }, [onReady]);

  return <AuthContext.Provider value={values} {...props} />;
}

AuthProvider.propTypes = {
  onReady: PropTypes.func.isRequired,
};

export { AuthContext, useAuthContext, AuthStorageKey };
export default AuthProvider;
