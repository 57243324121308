import React, { useState, useEffect, useRef } from 'react';
import { Dimensions, View, StyleSheet } from 'react-native';
import { gql, useSubscription } from '@apollo/client';
import Screen from '../../components/Layout';
import { FadeIn } from '../../components/Animation';
import {
  H1, P, Small, A,
} from '../../components/Text';
import { TextField, PasswordField, SelectField } from '../../components/Form';
import Button from '../../components/Button';
import ProgressBar from '../../components/ProgressBar';
import { ErrorNotification } from '../../components/ErrorNotification';
import LoadingIndicator from '../../components/LoadingIndicator';
import SupportModal from '../../features/SupportModal';
import Alert from '../../components/Alert';
import InstructionVideo from '../../components/InstructionVideo';
import { getShopStatusLabels } from '../../components/ShopStatusLabel';
import { mutationError } from '../../services/Error';
import useShop from '../../hooks/useShop';
import useCreateShop from '../../hooks/useCreateShop';
import useUpdateShop from '../../hooks/useUpdateShop';
import useTestShop from '../../hooks/useTestShop';
import useDeleteShop from '../../hooks/useDeleteShop';
import { useLocale } from '../../services/localization';
import { colors } from '../../constants/theme';
import Icon from '../../components/Icon';

const smallDevice = Dimensions.get('window').width < 875;

const styles = StyleSheet.create({
  heading: {
    marginBottom: 30,
    flexDirection: smallDevice ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: smallDevice ? 'flex-start' : 'center',
  },
  bar: {
    maxWidth: smallDevice ? null : 300,
    marginTop: smallDevice ? 10 : 0,
  },
  input: {
    marginBottom: 15,
  },
  note: {
    marginTop: 3,
    marginLeft: 3,
  },
  highlight: {
    color: '#565656',
  },
  instructionVideo: {
    marginBottom: 15,
    marginTop: 5,
  },
  text: {
    fontSize: 16,
    marginBottom: 30,
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  cancelButton: {
    color: '#3258C5',
    textDecorationLine: 'underline',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  finalButton: {
    marginHorizontal: 15,
  },
  testing: {
    fontFamily: 'Roboto bold',
    fontSize: 16,
    marginHorizontal: 15,
  },
  gdrpReminder: {
    borderColor: '#e1e1e1',
    borderWidth: 1,
    borderRadius: 5,
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    marginVertical: 5,
  },
  infoIcon: {
    marginRight: 10,
  },
  gdprText: {
    lineHeight: 20,
    color: colors.label.primary,
  },
  unableToConnectLink: {
    marginTop: 10,
  },
});

function HighlightText([str]) {
  return <Small style={styles.highlight}>{str}</Small>;
}

// determine stage based on shop status
const getStageFromStatus = (status) => {
  const steps = [
    [],
    [
      'new',
      'cant_reach_shop',
      'invalid_credentials',
      'connection_error',
      'fetching_order_statuses',
      'fetching_order_statuses_error',
      'cant_read_order',
      'cant_delete_order',
      'cant_create_order',
      'cant_fetch_products',
      'cant_update_order',
      'no_read_permissions',
      'no_write_permissions',
    ],
    ['fetched_order_statuses', 'ready_to_test'],
    ['waiting_for_test', 'testing'],
    ['active'],
  ];
  const stageIndex = steps.findIndex((step) => step.includes(status));
  return stageIndex;
};

const initialFields = {
  id: '',
  name: '',
  url: '',
  key: '',
  secret: '',
  import_order_status: '',
  completed_order_status: '',
  barcode_postmeta_key: '_sku',
};

function goToPrivacyPage(str) {
  return <A to="/settings/privacy">{str}</A>;
}

const GET_SHOP_UPDATE = gql`
  subscription OnShopChanged($id: ID!) {
    shopUpdated(id: $id) {
      id
      name
      url
      key
      secret
      status
      import_order_status
      completed_order_status
      wizard_status
      woocommerce_order_statuses {
        slug
        name
      }
      shipping_fields {
        plugin
        active
      }
    }
  }
`;

function AddShopScreen({ route, navigation }) {
  const supportModalRef = useRef();
  const $t = useLocale();
  const stages = {
    0: {
      step: 0,
    },
    1: {
      step: 1,
      percentage: 5,
      title: $t(
        {
          id: 'AddShopScreen.CurrentStep.Title',
          defaultMessage: 'Add a shop {currentStep}/{totalSteps}',
        },
        { currentStep: 1, totalSteps: 4 },
      ),
    },
    2: {
      step: 2,
      percentage: 40,
      title: $t(
        {
          id: 'AddShopScreen.CurrentStep.Title',
          defaultMessage: 'Add a shop {currentStep}/{totalSteps}',
        },
        { currentStep: 2, totalSteps: 4 },
      ),
    },
    3: {
      step: 3,
      percentage: 80,
      title: $t(
        {
          id: 'AddShopScreen.CurrentStep.Title',
          defaultMessage: 'Add a shop {currentStep}/{totalSteps}',
        },
        { currentStep: 3, totalSteps: 4 },
      ),
    },
    4: {
      step: 4,
      percentage: 100,
      title: $t(
        {
          id: 'AddShopScreen.CurrentStep.Title',
          defaultMessage: 'Add a shop {currentStep}/{totalSteps}',
        },
        { currentStep: 4, totalSteps: 4 },
      ),
    },
  };
  const errorMessages = getShopStatusLabels($t);
  const { shopId } = route.params || false;
  const [stage, setStage] = useState(stages[0]);
  const [fields, setFields] = useState(initialFields);
  const [errors, setErrors] = useState(false);
  const [polling, setPolling] = useState(false);
  const [progressing, setProgressing] = useState(false);
  const { data, loading } = useShop(shopId);

  const [orderStatusOptions, setOrderStatusOptions] = useState([]);

  const { deleteShop } = useDeleteShop(fields?.id, () => {
    navigation.navigate('Shops');
  });

  const { data: subscription } = useSubscription(GET_SHOP_UPDATE, { variables: { id: fields?.id } });

  // change stage helper
  const changeStage = (newStage) => {
    setStage(stages[newStage]);
  };

  const enablePolling = () => {
    setPolling(true);
  };

  const disablePolling = () => {
    setPolling(false);
    setProgressing(false);
  };

  const handleErrorEvent = (errorMessage) => {
    setErrors(errorMessage);
    disablePolling();
  };

  const { testShop } = useTestShop(
    fields?.id,
    () => enablePolling(),
    (error) => handleErrorEvent(mutationError(error)),
  );

  // Init stage
  useEffect(() => {
    // Only init when loading is finished, prevent state ui flicker
    if (loading) {
      return;
    }

    // Start add process from zero
    if (!shopId && stage.step === 0) {
      changeStage(1);
      return;
    }

    // Load fields with init shop data
    if (data && data.shop) {
      const newStage = getStageFromStatus(data.shop.status);
      let initFields = {
        ...fields,
        ...data?.shop,
      };
      if (newStage === 2) {
        initFields = {
          ...initFields,
          import_order_status: 'processing',
          completed_order_status: 'completed',
        };
      }
      setFields(initFields);
      changeStage(newStage);
    } else {
      changeStage(1);
    }

    // Init order statuses
    const orderStatuses = [];
    data?.shop.woocommerce_order_statuses.forEach((status) => {
      orderStatuses.push({
        value: status.slug,
        label: `${status.name} (${status.slug})`,
      });
    });
    setOrderStatusOptions(orderStatuses);
  }, [data, loading]);

  const {
    createShop,
    data: createdShop,
  } = useCreateShop(
    fields,
    () => enablePolling(),
    (error) => handleErrorEvent(mutationError(error)),
  );

  useEffect(() => {
    if (!createdShop) return;
    const updatedFields = {
      ...fields,
      ...createdShop.createShop,
      import_order_status: 'processing',
      completed_order_status: 'completed',
    };
    setFields(updatedFields);
  }, [createdShop]);

  const { updateShop } = useUpdateShop(
    {
      shopId: fields?.id,
      ...fields,
    },
    () => {
      enablePolling();
      if (stage.step === 2) {
        changeStage(3);
        testShop();
      }
    },
    (error) => handleErrorEvent(mutationError(error)),
  );

  const cancelAddShop = () => {
    if (!fields.id) {
      navigation.navigate('Shops');
      return;
    }
    deleteShop();
  };

  // Subscription handler
  useEffect(() => {
    if (!subscription) return;
    const shop = subscription?.shopUpdated;
    const { status } = shop;

    if (errorMessages[status]) {
      handleErrorEvent(errorMessages[status]);
    }

    if (
      !orderStatusOptions.length
      && shop.woocommerce_order_statuses?.length
    ) {
      const orderStatuses = [];
      shop.woocommerce_order_statuses.forEach((orderStatus) => {
        orderStatuses.push({
          value: orderStatus.slug,
          label: `${orderStatus.name} (${orderStatus.slug})`,
        });
      });
      setOrderStatusOptions(orderStatuses);
    }

    if (status === 'active') {
      disablePolling();
    }

    const nextStep = getStageFromStatus(status);
    if (nextStep !== stage.step) {
      changeStage(nextStep);

      if (stage.step === 1 && nextStep === 2) {
        setErrors(false);
        disablePolling();
      }
    }
  }, [subscription]);

  // Handle form field changes
  const handleInputChange = (e, fieldName) => {
    setErrors(false);
    setFields({
      ...fields,
      [fieldName]: e.target.value,
    });
  };

  const nextStep = () => {
    setProgressing(true);
    if (stage.step === 1) {
      if (fields.id) {
        updateShop();
      } else {
        createShop();
      }
    }
    if (stage.step === 2) {
      updateShop();
    }
  };

  if (stage.step < 1) {
    return (
      <Screen>
        <FadeIn>
          <LoadingIndicator />
        </FadeIn>
      </Screen>
    );
  }
  return (
    <Screen>
      <FadeIn>
        <View style={styles.heading}>
          <H1>{stage.title}</H1>
          <ProgressBar style={styles.bar} percentage={stage.percentage} />
        </View>
        {stage.step === 1 && (
        <FadeIn>
          <TextField
            style={styles.input}
            label={$t({ id: 'AddShopForm.WebshopNameField.Label', defaultMessage: 'Webshop name' })}
            placeholder={$t({ id: 'AddShopForm.WebshopNameField.Placeholder', defaultMessage: 'My Shop' })}
            value={fields.name}
            onChange={(e) => handleInputChange(e, 'name')}
            disabled={polling || progressing}
            autoFocus
          />
          <TextField
            style={styles.input}
            label={$t({ id: 'AddShopForm.WebshopDomainField.Label', defaultMessage: 'Webshop domain' })}
            placeholder={$t({ id: 'AddShopForm.WebshopDomainField.Placeholder', defaultMessage: 'https://www.yourwebshop.com' })}
            value={fields.url}
            onChange={(e) => handleInputChange(e, 'url')}
            disabled={polling || progressing}
          />
          <TextField
            style={styles.input}
            label={$t({ id: 'AddShopForm.WooConsumerKeyField.Label', defaultMessage: 'Woocommerce Consumer key' })}
            placeholder={$t({ id: 'AddShopForm.WooConsumerKeyField.Placeholder', defaultMessage: 'ck_326c14ee636dd...' })}
            value={fields.key}
            onChange={(e) => handleInputChange(e, 'key')}
            textContentType="none"
            autoCorrect={false}
            autoCompleteType="off"
            description={(
              <Small style={styles.note}>
                {$t({
                  id: 'AddShopForm.WooConsumerKeyField.Description',
                  defaultMessage: 'Make sure your key has <highlight>Read/Write</highlight> permissions.',
                }, {
                  highlight: HighlightText,
                })}
              </Small>
            )}
            disabled={polling || progressing}
          />
          <PasswordField
            style={styles.input}
            label={$t({ id: 'AddShopForm.WooConsumerSecretField.Label', defaultMessage: 'Woocommerce Consumer secret' })}
            placeholder={$t({ id: 'AddShopForm.WooConsumerSecretField.Placeholder', defaultMessage: 'cs_123a64b8009c14c2b...' })}
            value={fields.secret}
            onChange={(e) => handleInputChange(e, 'secret')}
            textContentType="none"
            autoCorrect={false}
            autoCompleteType="off"
            disabled={polling || progressing}
          />
          <InstructionVideo style={styles.instructionVideo} />
          <View style={styles.gdrpReminder}>
            <Icon name="information-circle" size={22} color="#3258C5" style={styles.infoIcon} />
            <P style={styles.gdprText}>
              {$t({ id: 'AddShopForm.PrivacyDataAgreementReminder.Text', defaultMessage: 'We take privacy very seriously. Please check your <link>privacy page</link> for more info on our Data Processing Agreement policy.' }, { link: goToPrivacyPage })}
            </P>
          </View>
        </FadeIn>
        )}
        {stage.step === 2 && (
        <FadeIn>
          <SelectField
            style={styles.input}
            label={$t({ id: 'AddShopForm.OrderImportStatusField.Label', defaultMessage: 'Choose which status we use as an indicator to import a new order' })}
            items={orderStatusOptions}
            selectedValue={fields.import_order_status || 'processing'}
            onValueChange={(itemValue) => {
              setFields({
                ...fields,
                import_order_status: itemValue.target.value,
              });
            }}
            disabled={polling || progressing}
          />
          <SelectField
            style={styles.input}
            label={$t({ id: 'AddShopForm.OrderCompletedStatusField.Label', defaultMessage: 'Set the status to which we change the order after picking is completed' })}
            items={orderStatusOptions}
            selectedValue={fields.completed_order_status || 'completed'}
            onValueChange={(itemValue) => {
              setFields({
                ...fields,
                completed_order_status: itemValue.target.value,
              });
            }}
            disabled={polling || progressing}
          />
        </FadeIn>
        )}
        {stage.step === 3 && (
        <FadeIn>
          <ErrorNotification
            errorTitle={$t({ id: 'AddShopScreen.SettingUpPicker.Title', defaultMessage: 'Setting up picker...' })}
            icon="loader"
            errorMessage={$t({ id: 'AddShopScreen.SettingUpPicker.Description', defaultMessage: 'One moment please, we are creating a test order and will pick it. We do this to ensure everything works as expected.' })}
          />
        </FadeIn>
        )}
        {stage.step === 4 && (
        <FadeIn>
          <ErrorNotification
            errorTitle={$t({ id: 'AddShopScreen.ShopAdded.Title', defaultMessage: 'Shop added' })}
            icon="checkmark"
            errorMessage={$t({ id: 'AddShopScreen.ShopAdded.Description', defaultMessage: 'Your shop is added and ready to use. You can start picking yourself or add your first picker.' })}
          />
          <View style={styles.buttons}>
            <Button
              label={$t({ id: 'AddShopScreen.StartPickingButton.Label', defaultMessage: 'Start picking' })}
              size="small"
              style={styles.finalButton}
              onPress={() => navigation.navigate('Dashboard')}
            />
            <P>{$t({ id: 'AddShopScreen.ShopAdded.ButtonDivider.Text', defaultMessage: 'or' })}</P>
            <Button
              label={$t({ id: 'AddShopScreen.AddAPickerButton.Label', defaultMessage: 'Add a picker' })}
              size="small"
              style={styles.finalButton}
              onPress={() => navigation.navigate('AddPicker')}
            />
          </View>
        </FadeIn>
        )}
        {[1, 2].includes(stage.step) && (
        <View style={styles.actions}>
          <P style={styles.cancelButton} onPress={cancelAddShop}>
            {$t({ id: 'AddShopScreen.CancelAndGoBackButton.Label', defaultMessage: 'Cancel and go back' })}
          </P>
          <Button
            label={$t({ id: 'AddShopScreen.NextStepButton.Label', defaultMessage: 'Continue' })}
            loadingLabel={(stage.step === 1 && (polling || progressing)) ? 'Checking connection...' : undefined}
            size="small"
            onPress={nextStep}
            loading={polling || progressing}
            disabled={polling || progressing || (stage.step === 1 && (fields.name === '' || fields.url === '' || fields.key === '' || fields.secret === ''))} // @TODO improve logic
          />
        </View>
        )}
        {errors && (
          <FadeIn>
            <Alert text={`${errors}`} />
            <A style={styles.unableToConnectLink} onPress={() => supportModalRef.current.open()}>
              {$t({ id: 'AddShopScreen.UnableToConnect.Label', defaultMessage: 'Unable to connect?' })}
            </A>
          </FadeIn>
        )}
      </FadeIn>
      <View style={{ height: 50 }} />
      <SupportModal ref={supportModalRef} />
    </Screen>
  );
}

export default AddShopScreen;
