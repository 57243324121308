import React from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import LargeActionButton, { ButtonType } from '../../../components/LargeActionButton';
import { useLocale } from '../../../services/localization';
import { pickProgress } from '../../../services/Helpers';

function ContinueOrderButton({ order, highlight }) {
  const $t = useLocale();
  const navigation = useNavigation();
  const type = highlight ? ButtonType.Primary : ButtonType.Secondary;

  const progress = React.useMemo(() => pickProgress(order.order_lines), [order]);

  return (
    <LargeActionButton
      label={
        progress.progressPercentage < 100
          ? $t({ id: 'OrdersList.ContinuePickingButton.ContinueLabel', defaultMessage: 'Continue picking' })
          : $t({ id: 'OrdersList.ContinuePickingButton.FinishLabel', defaultMessage: 'Finish order' })
        }
      description={$t({ id: 'OrdersList.ContinuePickingButton.Description', defaultMessage: 'Order #{orderId}' }, { orderId: order.woocommerce_order_id })}
      subText={$t({ id: 'OrdersList.ContinuePickingButton.PercentagePickedText', defaultMessage: '{percentage}% picked' }, { percentage: progress.progressPercentage })}
      onPress={() => navigation.navigate('PickOrder', { orderId: order.id, wooId: order.woocommerce_order_id })}
      type={type}
    />
  );
}

const Id = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

const OrderLine = PropTypes.shape({
  id: Id,
  picked: PropTypes.number,
  quantity: PropTypes.number,
});

const Order = PropTypes.shape({
  id: Id.isRequired,
  woocommerce_order_id: PropTypes.string,
  order_lines: PropTypes.arrayOf(OrderLine).isRequired,
});

ContinueOrderButton.propTypes = {
  order: Order.isRequired,
  highlight: PropTypes.bool,
};

ContinueOrderButton.defaultProps = {
  highlight: false,
};

export default ContinueOrderButton;
