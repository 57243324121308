import { useMemo } from 'react';
import { useAuthContext } from '../auth';
import { Actions, Roles } from './types';

export const Permissions = {
  [Roles.Admin]: [
    Actions.ManageShop,
  ],
  [Roles.StoreOwner]: [
    Actions.ManageShop,
  ],
};

export function can(role, action) {
  return Permissions[role] !== undefined && Permissions[role].includes(action);
}

export function useCanManageShop() {
  const { user } = useAuthContext();
  return useMemo(() => can(user?.role, Actions.ManageShop), [user]);
}
