import React from 'react';
import { Image } from 'react-native';
import {
  ShippingPlugin, ShippingPluginName, assets, SupportedShippingPlugins,
} from '../../../constants';
import Icon from '../../../components/Icon';
import styles from './DeclareForm.styles';

export const FieldType = {
  String: 'string',
  Enum: 'enum',
  Bool: 'boolean',
  Int: 'integer',
  Float: 'float',
  Repeater: 'repeater',
};

export const ShippingProviderOptions = [
  {
    value: ShippingPlugin.PostNL,
    label: ShippingPluginName[ShippingPlugin.PostNL], // @TODO: this is dump way of selecting a string, improve constant
    iconComponent: <Image style={styles.postnlLogo} source={assets.images.postnl} />,
  },
  {
    value: ShippingPlugin.MyParcel,
    label: ShippingPluginName[ShippingPlugin.MyParcel],
    iconComponent: <Image style={styles.myparcelLogo} source={assets.images.myparcel} />,
  },
  {
    value: ShippingPlugin.KDZ,
    label: ShippingPluginName[ShippingPlugin.KDZ],
    iconComponent: <Image style={styles.kdzLogo} source={assets.images.kdz} />,
  },
  {
    value: ShippingPlugin.Sendcloud,
    label: ShippingPluginName[ShippingPlugin.Sendcloud],
    iconComponent: <Image style={styles.kdzLogo} source={assets.images.sendcloud} />,
  },
];

export const ColloAmountTypes = Array.from({ length: 4 }, (_, i) => {
  const val = (i + 1).toString();
  return { value: val, label: val };
});

export function getAvailableShippingProviderOptions(shippingFields) {
  return shippingFields.reduce((agg, provider) => {
    const providerOption = ShippingProviderOptions.find((option) => provider.plugin === option.value);
    if (providerOption) {
      agg.push(providerOption);
    }
    return agg;
  }, []);
}

export function getTranslations($t, shippingProvider) {
  const providerName = ShippingPluginName[shippingProvider] || '';
  return {
    package_type: $t({ id: 'DeclareForShippingModal.PackageTypeField.Label', defaultMessage: 'Package Type' }),
    collo_amount: $t({ id: 'DeclareForShippingModal.ColloAmountField.Label', defaultMessage: 'Collo Amount' }),
    colli_amount: $t({ id: 'DeclareForShippingModal.ColloAmountField.Label', defaultMessage: 'Collo Amount' }), // note collo and colli, they are the same
    label_description: $t({ id: 'DeclareForShippingModal.LabelDescriptionField.Label', defaultMessage: 'Label Description' }),
    only_recipient: $t({ id: 'DeclareForShippingModal.OnlyRecipientField.Label', defaultMessage: 'Only Recipient' }),
    signature_required: $t({ id: 'DeclareForShippingModal.SignatureRequiredField.Label', defaultMessage: 'Signature Required' }),
    age_check: $t({ id: 'DeclareForShippingModal.AgeCheckField.Label', defaultMessage: 'Age Check' }),
    return_shipments: $t({ id: 'DeclareForShippingModal.ReturnShipmentsField.Label', defaultMessage: 'Return Shipments' }),
    insured: $t({ id: 'DeclareForShippingModal.InsuredField.Label', defaultMessage: 'Insured' }),
    insured_amount: $t({ id: 'DeclareForShippingModal.InsuredAmountField.Label', defaultMessage: 'Insured Amount' }),
    labelDescriptionPlaceholder: $t({ id: 'DeclareForShippingModal.LabelDescriptionField.Placeholder', defaultMessage: 'Label Description' }),
    closeButton: $t({ id: 'DeclareForShippingModal.CloseModalButton.Label', defaultMessage: 'Close' }),
    declareButton: $t({ id: 'DeclareForShippingModal.DeclareAndPrintButton.Label', defaultMessage: 'Declare and print shipping label' }),
    declareLoadingText: $t({ id: 'DeclareForShippingModal.DeclareAndPrintButton.LoadingLabel', defaultMessage: 'Declaring order, one moment please...' }),
    options: $t({ id: 'DeclareForShippingModal.Options.Label', defaultMessage: 'Options' }),

    // kdz
    delivery_method: $t({ id: 'DeclareForShippingModal.DeliveryMethod.Label', defaultMessage: 'Delivery method' }),
    remark: $t({ id: 'DeclareForShippingModal.Remark.Label', defaultMessage: 'Remark' }),
    colli: $t({ id: 'DeclareForShippingModal.Colli.Label', defaultMessage: 'Colli' }),

    // sendcloud
    sender_address: $t({ id: 'DeclareForShippingModal.SenderAddress.Label', defaultMessage: 'Sender Address' }),
    product_id: $t({ id: 'DeclareForShippingModal.ProductId.Label', defaultMessage: 'Product' }),
    insurance_amount: $t({ id: 'DeclareForShippingModal.InsuranceAmount.Label', defaultMessage: 'Insurance Amount' }),
    customs_invoice_number: $t({ id: 'DeclareForShippingModal.CustomsInvoiceNumber.Label', defaultMessage: 'Customs Invoice Number' }),
    customs_shipment_type: $t({ id: 'DeclareForShippingModal.CustomsInvoiceType.Label', defaultMessage: 'Customs Invoice Type' }),
    country_state: $t({ id: 'DeclareForShippingModal.CountryState.Label', defaultMessage: 'Country State' }),
    weight: $t({ id: 'DeclareForShippingModal.Weight.Label', defaultMessage: 'Weight' }),

    sendcloudOutsideEuTitle: $t({ id: 'DeclareForShippingModal.sendcloudOutsideEu.Title', defaultMessage: 'We do not support sending orders with Sendcloud outside Europa' }),
    sendcloudOutsideEuDescription: $t({ id: 'DeclareForShippingModal.sendcloudOutsideEu.Description', defaultMessage: 'We are still working on support for sending orders through Sendcloud outside Europe. Please use one of our other supported carriers or contact our support for any info and questions.' }),

    // Errors
    missingFields: $t({ id: 'DeclareForShippingModal.MissingFields.Error', defaultMessage: 'Please enter all required fields' }),
    missingColliValues: $t({ id: 'DeclareForShippingModal.MissingColliValues.Error', defaultMessage: 'Please enter colli values' }),
    missingWeight: $t({ id: 'DeclareForShippingModal.MissingWeigth.Error', defaultMessage: 'Please enter the weight of the package and select a send product' }),
    insuranceToLow: $t({ id: 'DeclareForShippingModal.InsuranceToLow.Error', defaultMessage: 'Insurance can not be lower then €100' }),
    insuranceToHigh: $t({ id: 'DeclareForShippingModal.InsuranceToHigh.Error', defaultMessage: 'Insurance can not be hight then €5000' }),
    missingProduct: $t({ id: 'DeclareForShippingModal.MissingProduct.Error', defaultMessage: 'Please select one of the available send products' }),

    noFieldsFoundTitle: $t({ id: 'DeclareForShippingModal.NoFieldsFoundTitle.Error', defaultMessage: 'No shipping fields found for {providerName}' }, { providerName }),
    noFieldsFoundDescription: $t({ id: 'DeclareForShippingModal.noFieldsFoundDescription.Error', defaultMessage: 'Make sure you have setup {providerName} correctly and enabled it as a shipping provider from your shop settings.' }, { providerName }),
  };
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Icons = {
  package: <Icon name="cube-outline" size={28} />,
  mailbox: <Icon name="file-tray-outline" size={26} />,
  letter: <Icon name="mail-outline" size={25} />,
  default: <Icon name="mail-outline" size={25} />,
};

export function mapToOptions(options) {
  return options.map((option) => ({
    value: option,
    label: capitalizeFirstLetter(option),
    ...(Icons[option]) ? { iconComponent: Icons[option] } : {},
  }));
}

// @TODO: Refactor to form component? <FloatInput />?
export function onlyNumberOrFloat(value) {
  return value.replace(/[^0-9.,]/g, '').replace(',', '.');
}

/**
 * Order shipping fields based on hard coded order defined in PrefferedShippingFieldOrder
 */
export function orderShippingFields(provider, fields = [], prefferedOrderArray = {}) {
  if (!provider || !Array.isArray(fields) || !prefferedOrderArray[provider]) {
    return fields;
  }
  return fields.slice().sort((a, b) => prefferedOrderArray[provider].indexOf(a.name) - prefferedOrderArray[provider].indexOf(b.name));
}

/**
 * Get default shipping provider based on supported shipping plugins array
 */
export function getDefaultShippingProvider(shippingFields) {
  if (shippingFields && shippingFields.length > 0) {
    return shippingFields.find((provider) => SupportedShippingPlugins.includes(provider.plugin)).plugin;
  }
  return undefined;
}
