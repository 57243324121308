import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { P } from '../Text';
import Icon from '../Icon';
import styles from './PulldownMenuItem.styles';

function PulldownMenuItem({
  style, label, icon, rightIcon, onPress,
}) {
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <Icon style={styles.icon} name={icon} size={24} />
      <P numberOfLines={1} style={styles.label}>{label}</P>
      {rightIcon && <Icon name={rightIcon} size={24} style={styles.rightIcon} />}
    </TouchableOpacity>
  );
}

PulldownMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  rightIcon: PropTypes.string,
  onPress: PropTypes.func,
};

PulldownMenuItem.defaultProps = {
  onPress: () => null,
  rightIcon: undefined,
};

export default PulldownMenuItem;
