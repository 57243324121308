import React, { useState, useMemo, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { FadeIn } from '../../../components/Animation';
import { EmailField, PasswordField } from '../../../components/Form';
import { validateEmail } from '../../../services/Validation';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import { A } from '../../../components/Text';
import { useLocale } from '../../../services/localization';
import { ErrorType, getErrors } from '../../../services/Error';
import { useToastNotification } from '../../../services/toast';
import useLogin from './useLogin';
import styles from './LoginForm.styles';
import { RESEND_VERIFY_EMAIL } from '../../../graphql/AuthQuery';

function link(str, action) {
  return <A onPress={action}>{str}</A>;
}

function LoginForm() {
  const $t = useLocale();
  const passwordRef = React.useRef();
  const navigation = useNavigation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const { toastInfo, toastWarning } = useToastNotification();
  const [resendVerifyEmail] = useMutation(RESEND_VERIFY_EMAIL);

  const resendVerifyEmailLink = useCallback(async () => {
    setError(false);
    try {
      await resendVerifyEmail({ variables: { email: username } });
      const info = $t({ id: 'LoginForm.Toast.ResendVerifyEmailSend', defaultMessage: 'Verify email link send. If you have not received the email after a few minutes, please check your spam folder.' });
      toastInfo(info);
    } catch {
      const warning = $t({ id: 'LoginForm.Toast.ResendVerifyEmailSend.Error', defaultMessage: 'Unable to send new verify email link. Please try again later or contact our support.' });
      toastWarning(warning);
    }
  }, [$t, resendVerifyEmail, toastInfo, toastWarning, username]);

  const translations = useMemo(() => ({
    missingFields: $t({ id: 'LoginForm.Error.NoValidEmailPassword', defaultMessage: 'Please enter a valid email and password.' }),
    loginFailed: $t({ id: 'LoginForm.Error.InvalidCombinationEmailPassword', defaultMessage: 'This combination of email and password is incorrect. Please try again.' }),
    verifyEmail: $t({ id: 'LoginForm.Error.VerifyEmailFirst', defaultMessage: 'You need to verify your email first before you can log in. No email received? <link>Resend verification email</link>' }, { link: (str) => link(str, resendVerifyEmailLink) }),
    emailLabel: $t({ id: 'LoginForm.Field.EmailLabel', defaultMessage: 'Email' }),
    emailPlaceholder: $t({ id: 'LoginForm.Field.EmailPlaceholder', defaultMessage: 'your@email.com' }),
    passwordLabel: $t({ id: 'LoginForm.Field.PasswordLabel', defaultMessage: 'Password' }),
    passwordPlaceholder: $t({ id: 'LoginForm.Field.PasswordPlaceholder', defaultMessage: 'Your password' }),
    forgotPassword: $t({ id: 'LoginForm.Link.ForgotPasswordLabel', defaultMessage: 'Forgot password?' }),
    signInNow: $t({ id: 'LoginForm.Button.SignInLabel', defaultMessage: 'Sign in now' }),
  }), [$t, resendVerifyEmailLink]);

  const { login, loading } = useLogin(
    { username, password },
    null,
    (errorResponse) => {
      const types = getErrors(errorResponse);
      if (types.includes(ErrorType.EmailNotVerified)) {
        setError(translations.verifyEmail);
        return;
      }
      setError(translations.loginFailed);
    },
  );

  const submit = React.useCallback(() => {
    if (loading) return;
    if (!validateEmail(username) || password === '') {
      setError(translations.missingFields);
      return;
    }
    login();
  }, [loading, login, password, translations.missingFields, username]);

  return (
    <>
      <EmailField
        style={styles.input}
        label={translations.emailLabel}
        placeholder={translations.emailPlaceholder}
        value={username}
        onChangeText={setUsername}
        onFocus={() => setError(false)}
        autoFocus
        returnKeyType="next"
        onSubmitEditing={submit}
        onKeyPress={(e) => {
          if (e.code === 'Tab') {
            e.preventDefault();
            passwordRef.current.focus();
          }
        }}
      />
      <PasswordField
        ref={passwordRef}
        style={styles.input}
        label={translations.passwordLabel}
        placeholder={translations.passwordPlaceholder}
        value={password}
        onChangeText={setPassword}
        onFocus={() => setError(false)}
        returnKeyType="send"
        onSubmitEditing={submit}
        moreButtonOnPress={() => navigation.navigate('ForgotPassword')}
        moreButtonLabel={translations.forgotPassword}
      />
      <Button
        style={styles.button}
        onPress={submit}
        loading={loading}
        size="large"
        label={translations.signInNow}
      />
      {error && (
        <FadeIn>
          <Alert text={error} />
        </FadeIn>
      )}
    </>
  );
}

export default LoginForm;
