import React, { useState, useRef } from 'react';
import { TouchableOpacity, View, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Icon from '../../../components/Icon';
import { P } from '../../../components/Text';
import PulldownMenuItem from '../../../components/PulldownMenuItem';
import RoleBadge from '../../../components/RoleBadge';
import { useAuthContext } from '../../../services/auth';
import { Roles, can, Actions } from '../../../services/permissions';
import { links } from '../../../constants';
import { useOutsideAlerter } from '../../../hooks/interface';
import { useLocale } from '../../../services/localization';
import styles from './UserMenu.styles';
import PlanStatus from './PlanStatus';

const openSupport = () => {
  Linking.openURL(links.getSupport);
};

function UserMenu({ style }) {
  const navigation = useNavigation();
  const menuContainerRef = useRef(null);
  const [showMenu, SetShowMenu] = useState(false);
  const { user, signOut } = useAuthContext();
  const firstName = React.useMemo(() => user.name.split(' ')[0] || '', [user]);
  const canManageShop = React.useMemo(() => can(user.role, Actions.ManageShop), [user]);
  const $t = useLocale();

  const go = (screen, params) => {
    SetShowMenu(false);
    navigation.navigate(screen, params);
  };

  const toggle = () => {
    SetShowMenu(!showMenu);
  };

  const close = () => {
    SetShowMenu(false);
  };

  const translations = React.useMemo(() => ({
    help: $t({ id: 'UserMenu.Label.Help', defaultMessage: 'Help' }),
    settings: $t({ id: 'UserMenu.Label.Settings', defaultMessage: 'Settings' }),
    logout: $t({ id: 'UserMenu.Label.LogOut', defaultMessage: 'Log out' }),
  }), [$t]);

  useOutsideAlerter(menuContainerRef, close);

  return (
    <View style={[styles.userMenuContainer, style]} ref={menuContainerRef}>
      <View style={styles.toggleButtonWrapper}>
        {user.role === Roles.Admin && <RoleBadge type={user.role} style={styles.badge} />}
        <TouchableOpacity
          activeOpacity={0.7}
          style={styles.toggleMenuButton}
          onPress={toggle}
        >
          <Icon name={showMenu ? 'chevron-up-outline' : 'chevron-down-outline'} size={20} />
          <P style={styles.toggleMenuButtonLabel}>{firstName}</P>
        </TouchableOpacity>
      </View>
      {showMenu && (
        <View style={styles.menuContainer}>
          {canManageShop && (
            <>
              <PlanStatus />
              <View style={styles.devider} />
            </>
          )}
          <PulldownMenuItem
            label={translations.settings}
            icon="settings"
            onPress={() => go('Settings', { tab: 'details' })}
          />
          <PulldownMenuItem
            label={translations.help}
            icon="help-buoy-sharp"
            rightIcon="log-out-outline"
            onPress={openSupport}
          />
          <View style={styles.devider} />
          <PulldownMenuItem label={translations.logout} icon="exit" onPress={signOut} />
        </View>
      )}
    </View>
  );
}

export default UserMenu;
