import { StyleSheet } from 'react-native';
import { colors } from '../../../../constants';

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
  labelWrapper: {
    marginBottom: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  balanceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F1D53E',
    borderRadius: 100,
    padding: 3,
    paddingRight: 8,
  },
  euroIconWrapper: {
    backgroundColor: '#FCEC98',
    height: '100%',
    justifyContent: 'center',
    borderRadius: 100,
    width: 18,
    marginRight: 5,
    alignItems: 'center',
  },
  planLabel: {
    textTransform: 'uppercase',
    fontSize: 12,
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: colors.brand.primary,
    borderRadius: 100,
    color: colors.background.primary,
  },
  tryoutExplainer: {
    color: '#717171',
    lineHeight: 18,
  },
  priceLink: {
    marginTop: 10,
  },
});

export default styles;
