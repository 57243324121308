import React from 'react';
import { mutationError } from '../../services/Error';
import { useLocale } from '../../services/localization';
import ScreenWithSidebars from '../../components/ScreenWithSidebars';
import { FadeIn } from '../../components/Animation';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ErrorNotification } from '../../components/ErrorNotification';
import { H4, Strong } from '../../components/Text';
import ShopSubMenu from '../../components/ShopSubMenu';
import ActivityList from './ActivityList';
import useShop from '../../hooks/useShop';
import styles from './ShopActivityScreen.styles';

function ShopActivityScreen({ route, navigation }) {
  const { shopId } = route.params;
  const { loading, error, data: response } = useShop(shopId);
  const $t = useLocale();

  if (loading) return <LoadingIndicator />;
  if (!response) {
    return (
      <ErrorNotification
        errorTitle={$t({
          id: 'EditShopScreen.ShopNotFound.Title',
          defaultMessage: 'Shop not found',
        })}
        errorMessage={$t({
          id: 'EditShopScreen.ShopNotFound.Description',
          defaultMessage:
            "Sorry, seems that you're looking for a shop that does not exists.",
        })}
        buttonLabel={$t({
          id: 'EditShopScreen.BackToShopsButton.Label',
          defaultMessage: 'Back to shops',
        })}
        buttonAction={() => navigation.navigate('Shops')}
      />
    );
  }
  if (error) return <ErrorNotification errorMessage={mutationError(error)} />;
  return (
    <ScreenWithSidebars
      LeftSidebarComponent={(
        <>
          <Strong style={styles.shopName}>{response.shop.name}</Strong>
          <ShopSubMenu />
        </>
      )}
      contentWidth="wide"
      MainContentComponent={(
        <FadeIn>
          <H4 style={styles.heading}>
            {$t({ id: 'ShopLogList.Heading', defaultMessage: 'Activity log' })}
          </H4>
          <ActivityList shopId={shopId} />
        </FadeIn>
  )}
    />
  );
}

export default ShopActivityScreen;
