import { StyleSheet, Platform } from 'react-native';
import { colors } from '../../../constants';

const styles = StyleSheet.create({
  userMenuContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    marginRight: 15,
  },
  toggleButtonWrapper: {
    flexDirection: 'row',
    height: '100%',
  },
  toggleMenuButton: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  badge: {
    marginRight: 7,
  },
  toggleMenuButtonLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    padding: 3,
    fontFamily: 'Roboto bold',
    ...Platform.select({
      native: {
        display: 'none',
      },
    }),
  },
  menuContainer: {
    position: 'absolute',
    minWidth: 210,
    right: 0,
    top: 60,
    backgroundColor: colors.background.primary,
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    padding: 10,
    paddingBottom: 5,
    flexDirection: 'column',
  },
  devider: {
    height: 1,
    backgroundColor: colors.background.secondary,
    marginVertical: 10,
    marginHorizontal: -10,
  },
});

export default styles;
