import React from 'react';
import { TouchableOpacity } from 'react-native';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import Icon from '../../components/Icon';
import styles from './BackButton.styles';

function BackButton() {
  const navigation = useNavigation();
  const state = useNavigationState((newState) => newState);

  const handleBackPress = React.useCallback(() => {
    if (state.index === 0) {
      navigation.navigate({ name: 'Dashboard' });
      return;
    }
    navigation.goBack();
  }, [navigation, state]);

  return (
    <TouchableOpacity style={styles.container} onPress={handleBackPress}>
      <Icon name="arrow-back" />
    </TouchableOpacity>
  );
}

export default BackButton;
