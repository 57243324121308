import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import RadioButton from './RadioButton';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: 1,
  },
  item: {
    marginRight: 10,
    marginVertical: 5,
  },
});

function RadioGroup({
  name,
  options,
  selectedValue,
  compact,
  disabled,
  onChange,
}) {
  return (
    <View style={styles.container}>
      {options.map((item, index) => (
        <RadioButton
          onPress={() => onChange(item.value)}
          label={item.label}
          iconComponent={item.iconComponent}
          checked={selectedValue === item.value}
          compact={compact}
          style={styles.item}
          key={`radiogroup-${name}-${index}`}
          disabled={disabled}
        />
      ))}
    </View>
  );
}

const SelectOption = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  label: PropTypes.string,
});

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(SelectOption),
  // eslint-disable-next-line react/forbid-prop-types
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
};

RadioGroup.defaultProps = {
  options: [],
  compact: false,
  disabled: false,
  selectedValue: undefined,
  onChange: () => null,
};

export default RadioGroup;
