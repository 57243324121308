import React from 'react';
import { View, Linking } from 'react-native';
import { Strong, P, A } from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import styles from './PlanStatus.styles';
import { colors, links } from '../../../../constants';
import { useLocale } from '../../../../services/localization';

const openPricingPage = () => {
  Linking.openURL(links.Pricing);
};

const trailExpireDate = 'Thu Jul 1 2023 00:00:01 GMT+0000';

function monthDiff(dateFrom, dateTo) {
  return dateTo.getMonth() - dateFrom.getMonth()
    + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
}

export function getTimeLeftOnTrail() {
  return monthDiff(new Date(), new Date(trailExpireDate));
}

function Bold(str) {
  return <Strong style={styles.tryoutExplainer}>{str}</Strong>;
}

function PlanStatus() {
  const $t = useLocale();

  const months = getTimeLeftOnTrail();

  const locales = {
    tryoutLabel: $t({ id: 'PlanStatus.TryOutPlan.Label', defaultMessage: 'Try-out Plan' }),
    trailEnds: $t({ id: 'PlanStatus.TrailEndsIn.Text', defaultMessage: 'There are <bold>{months} months left</bold> on your unlimited trail plan.' }, { months, bold: Bold }),
    seePricingLink: $t({ id: 'PlanStatus.SeePricing.Link.Label', defaultMessage: 'See pricing after try-out' }),
  };

  return (
    <View style={styles.container}>
      <View style={styles.labelWrapper}>
        <View style={styles.balanceContainer}>
          <View style={styles.euroIconWrapper}><Icon name="logo-euro" size={10} /></View>
          <Icon name="infinite" size={16} color="#82760F" />
        </View>
        <Strong style={styles.planLabel}>{locales.tryoutLabel}</Strong>
      </View>
      <P style={styles.tryoutExplainer}>
        {locales.trailEnds}
      </P>
      <A onPress={openPricingPage} style={styles.priceLink}>
        {locales.seePricingLink}
        {' '}
        <Icon name="exit-outline" size={13} color={colors.brand.primary} />
      </A>
    </View>
  );
}

export default PlanStatus;
