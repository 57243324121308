import { StyleSheet } from 'react-native';
import { colors } from '../../../../constants';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  placeholder: {
    minHeight: 106,
    borderStyle: 'solid',
    borderColor: colors.border.primary,
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 15,
    alignContent: 'center',
    paddingTop: 16,
  },
  carrierLogo: {
    width: 15,
    height: 15,
  },
});

export default styles;
