import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser ($userId: ID!) {
    user(id: $userId) {
      id
      name
      email
      language
      role
      newsletter
      data_processing_agreement
    }
  }
`;

export const UPDATE_USER = gql`
  mutation ($userId: ID!, $name: String!, $language: Language) {
    updateUser(
      input: {
          id: $userId
          name: $name
          language: $language
        }
      ) {
        id
        name
        email
        language
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation ($old_password: String!, $password: String!, $password_confirmation: String!) {
    updatePassword(
      input: {
          old_password: $old_password
          password: $password
          password_confirmation: $password_confirmation
        }
      ) {
        status
        message
    }
  }
`;
