import { useState } from 'react';
import printJS from 'print-js';

const defaultPrintingState = {
  printing: false,
  ref: undefined,
};

const usePrint = () => {
  const [state, setState] = useState(defaultPrintingState);

  const print = (url, options, onCompleted) => {
    if (!url) return;

    const request = {
      printable: url,
      onLoadingStart: () => setState({
        printing: true,
        ...options.ref && { ref: options.ref },
      }),
      onPrintDialogClose: () => {
        setState(defaultPrintingState);
        if (onCompleted) onCompleted();
      },
      ...options,
    };

    printJS(request);
  };

  return { print, state };
};

export default usePrint;
