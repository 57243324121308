import React, { useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { mutationError } from '../../../services/Error';
import { ValidationRule } from '../../../services/Validation';
import { FadeIn } from '../../../components/Animation';
import {
  H1, P, A, Small,
} from '../../../components/Text';
import { EmailField } from '../../../components/Form';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import Icon from '../../../components/Icon';
import { useLocale } from '../../../services/localization';
import useForgotPassword from '../../../hooks/useForgotPassword';
import styles from './ForgotPasswordForm.styles';
import useForm from '../../../hooks/form';

const InitialFields = {
  email: '',
};

const Validation = {
  email: [ValidationRule.validEmail],
};

function styledValitationError(errors) {
  // eslint-disable-next-line react/no-array-index-key
  return errors && errors.map((message, index) => <Small style={styles.noteText} key={`error-${index}`}>{message}</Small>);
}

function ForgotPasswordForm() {
  const navigation = useNavigation();
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const $t = useLocale();

  const {
    values, handleOnChange, validationErrors, checkValidation, hasValidationErrors,
  } = useForm(InitialFields, Validation);

  const { forgotPassword, loading } = useForgotPassword(
    values.email.value,
    () => {
      handleOnChange('email', '');
      setCompleted(true);
    },
    (err) => setError(mutationError(err)),
  );

  const submit = React.useCallback(() => {
    if (loading || hasValidationErrors()) {
      return;
    }
    forgotPassword();
  }, [forgotPassword, hasValidationErrors, loading]);

  if (completed) {
    return (
      <FadeIn>
        <Icon
          name="checkmark"
          size={100}
          color="#6FC62E"
          style={styles.checkmark}
        />
        <H1 style={styles.heading}>{$t({ id: 'ForgotPasswordForm.EmailSend.Title', defaultMessage: 'Email has been send' })}</H1>
        <P style={styles.explanationText}>
          {$t({ id: 'ForgotPasswordForm.PleaseCheckInbox.Text', defaultMessage: 'Please check your inbox and click on the received link to reset your password.' })}
        </P>
        <Button
          style={styles.button}
          onPress={() => navigation.navigate('Login')}
          size="large"
          label={$t({ id: 'ForgotPasswordForm.LoginButton.Label', defaultMessage: 'Login' })}
        />
        <P style={styles.registerText}>
          {$t({ id: 'ForgotPasswordForm.CheckSpamFilter.Text', defaultMessage: 'Did not receive the email? Check your spam filter.' })}
        </P>
      </FadeIn>
    );
  }

  return (
    <>
      <H1 style={styles.heading}>{$t({ id: 'ForgotPasswordForm.Heading', defaultMessage: 'Reset your password' })}</H1>
      <P style={styles.explanationText}>
        {$t({ id: 'ForgotPasswordForm.EnterYourRegisteredEmail.Description', defaultMessage: 'Enter your registered email address to receive password reset instructions.' })}
      </P>
      <EmailField
        style={styles.input}
        label={$t({ id: 'ForgotPasswordForm.EmailField.Label', defaultMessage: 'Enter your email address' })}
        placeholder={$t({ id: 'ForgotPasswordForm.EmailField.Placeholder', defaultMessage: 'your@email.com' })}
        value={values.email.value}
        onChangeText={(val) => handleOnChange('email', val)}
        onFocus={() => setError(false)}
        onBlur={() => checkValidation('email')}
        description={styledValitationError(validationErrors.email)}
        required
        returnKeyType="send"
        onSubmitEditing={submit}
      />
      <Button
        style={styles.button}
        onPress={submit}
        loading={loading}
        size="large"
        label={$t({ id: 'ForgotPasswordForm.SendPasswordResetButton.Label', defaultMessage: 'Send password reset email' })}
      />
      {error && (
        <FadeIn>
          <Alert text={error} />
        </FadeIn>
      )}
      <P style={styles.registerText}>
        {`${$t({ id: 'ForgotPasswordForm.AlreadyHaveAccount.Text', defaultMessage: 'Already have an account?' })} `}
        <A style={styles.link} to="/login">
          {$t({ id: 'ForgotPasswordForm.LoginHereButton.Label', defaultMessage: 'Login here' })}
        </A>
      </P>
    </>
  );
}

export default ForgotPasswordForm;
