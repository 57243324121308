import React, { useRef, useEffect } from 'react';
import { Animated, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#DEDEDE',
    height: 12,
    width: '100%',
    maxWidth: 300,
    borderRadius: 5,
    padding: 3,
  },
  progress: {
    borderRadius: 5,
    height: '100%',
    backgroundColor: '#6FC62E',
  },
});

function ProgressBar({ style, percentage }) {
  const widthAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(widthAnim, {
      toValue: percentage,
      useNativeDriver: true,
      duration: 500,
    }).start();
  }, [percentage, widthAnim]);

  return (
    <View style={[styles.container, style]}>
      <Animated.View
        style={[
          styles.progress,
          {
            width: widthAnim.interpolate({
              inputRange: [0, 100],
              outputRange: ['1%', '100%'],
            }),
          },
        ]}
      />
    </View>
  );
}

ProgressBar.propTypes = {
  percentage: PropTypes.number,
};

ProgressBar.defaultProps = {
  percentage: 0,
};

export default ProgressBar;
