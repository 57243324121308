import { Countries, EuMemberStates } from './countries';

function getCountryCodeFromName(countryName) {
  if (!countryName) {
    return undefined;
  }
  if (countryName.length === 2) {
    return countryName;
  }
  const match = Object.entries(Countries).find(([, name]) => name.toLowerCase().search(countryName.toLowerCase()) !== -1);
  return match[0];
}

export function isEuMember({ code, name }) {
  const countryCode = code || getCountryCodeFromName(name);
  return EuMemberStates.includes(countryCode);
}
