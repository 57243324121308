import { useState, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

export const SHOP_ACTIVITY_LOGS = gql`
query ($shopId: ID!, $first: Int!, $page: Int!) {
  activityLogs(shop_id: $shopId, first: $first, page: $page, orderBy: [ { field: "created_at", order: DESC } ]) {
    paginatorInfo {
      currentPage
      count
      total
    }
    data {
      id
      message
      created_at
      shop_id
      order_id
    }
  }
}
`;

const useGetActivityLogs = (shopId, itemsPerPage) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const query = useQuery(SHOP_ACTIVITY_LOGS, {
    variables: {
      shopId,
      first: itemsPerPage,
      page: 1,
    },
    fetchPolicy: 'network-only',
    pollInterval: 10000,
  });

  function fetchMore() {
    setIsFetchingMore(true);
    const nextPage = query.data.activityLogs.paginatorInfo.currentPage + 1;
    query.fetchMore({
      variables: {
        first: itemsPerPage,
        page: nextPage,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setIsFetchingMore(false);

        if (!fetchMoreResult) return previousResult; // return if there are no results

        // merge incoming with current cache
        return {
          activityLogs: {
            __typename: 'ActivityLogPaginator',
            paginatorInfo: {
              count: previousResult.activityLogs.data.length + fetchMoreResult.activityLogs.data.length,
              currentPage: fetchMoreResult.activityLogs.paginatorInfo.currentPage,
              total: fetchMoreResult.activityLogs.paginatorInfo.total,
            },
            data: [
              ...previousResult.activityLogs.data,
              ...fetchMoreResult.activityLogs.data,
            ],
          },
        };
      },
    });
  }

  const items = useMemo(() => query?.data?.activityLogs.data || [], [query]);
  const pagination = useMemo(() => query?.data?.activityLogs.paginatorInfo || [], [query]);

  return {
    loading: query.loading,
    error: query.error,
    refetch: query.refetch,
    items,
    pagination,
    isFetchingMore,
    fetchMore,
  };
};

export default useGetActivityLogs;
