import React from 'react';
import { View, Image } from 'react-native';
import { H1, P } from '../Text';
import Icon from '../Icon';
import styles from './NoInternetWarning.styles';
import { assets } from '../../constants';
import { useLocale } from '../../services/localization';

function NoInternetWarning() {
  const $t = useLocale();
  return (
    <View style={styles.container}>
      <Image style={styles.logo} source={assets.images.logo} />
      <Icon name="ios-cloud-offline" size={70} style={styles.icon} />
      <H1 style={styles.heading}>
        {$t({ id: 'NoInternetWarning.Heading', defaultMessage: 'Darn, you seem to be offline' })}
      </H1>
      <P style={styles.text}>
        {$t({ id: 'NoInternetWarning.Text', defaultMessage: 'You need internet to be able to pick orders.' })}
      </P>
    </View>
  );
}

export default NoInternetWarning;
