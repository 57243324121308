import React, { useState } from 'react';
import {
  View, ScrollView, TouchableOpacity,
} from 'react-native';
import PropTypes from 'prop-types';
import styles from './DeclareForm.styles';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import Icon from '../../../components/Icon';
import { FadeView } from '../../../components/Animation';
import { Strong, H1, Small } from '../../../components/Text';
import { TextField, SelectField } from '../../../components/Form';
import { RadioGroup, Checkbox } from '../../../components/Fields';
import useDeclareForShipping from '../../../hooks/shop/useDeclareForShipping';
import { useLocale } from '../../../services/localization';
import {
  ShippingFields, ShippingFieldDefault, ShippingPlugin, PrefferedShippingFieldOrder,
} from '../../../constants';
import InfoBar from '../../../components/InfoBar';
import {
  ColloAmountTypes,
  getAvailableShippingProviderOptions,
  getTranslations,
  FieldType,
  mapToOptions,
  onlyNumberOrFloat,
  orderShippingFields,
  getDefaultShippingProvider,
} from './helpers';
import AddColliButton from './AddColliButton';
import SendcloudSenderAddress from './SendcloudSenderAddress';
import SendcloudProviderProduct from './SendcloudProviderProduct';
import { hasMissingFields } from '../../../services/Validation';
import { isEuMember } from '../../../services/shipping';

// @TODO: implement international sendcloud form
// @TODO: able to set inital selected value for sendcloud (maybe others too)
// @TODO: remember previous selected methods (redux rtk)

// @TODO: move colli to its own component like sendcloud

function InputExplainer({ fieldName }) {
  const $t = useLocale();
  if (fieldName === ShippingFields.InsuranceAmount) {
    return <Small style={styles.inputNote}>{$t({ defaultMessage: 'Multiple of €100 (Max: €5000)', id: 'DeclareForm.InsuranceAmount.Description' })}</Small>;
  }
  return null;
}
InputExplainer.propTypes = { fieldName: PropTypes.string.isRequired };

function DeclareForm({
  orderId, shopId, shippingFields, onCompleted, onRequestCancel, shippingProvider, destinationCountry,
}) {
  const $t = useLocale();
  const scrollRef = React.useRef();
  const { declareForShipping, loading } = useDeclareForShipping();
  const [selectedShippingProvider, setSelectedShippingProvider] = useState(shippingProvider || getDefaultShippingProvider(shippingFields));
  const [errors, setErrors] = useState(false);
  const availableShippingProviders = React.useMemo(() => getAvailableShippingProviderOptions(shippingFields), [shippingFields]);

  const [formValues, setFormValues] = useState(undefined);
  const [formFields, setFormFields] = useState([]);
  const [insuredOptions, setInsuredOptions] = useState(undefined);
  // @TODO: Why is this a useEffect and not a useMemo?!?! Please refactor...
  React.useEffect(() => {
    if (!shippingFields || !selectedShippingProvider) {
      return;
    }
    setErrors(false); // reset errors when switching provider
    const provider = shippingFields.find(({ plugin }) => plugin === selectedShippingProvider);
    const values = {};
    provider?.fields?.forEach((field) => {
      values[field.name] = ShippingFieldDefault[field.name] || null;
      if (field.name === ShippingFields.InsuredAmount) {
        const options = [];
        field?.options?.forEach((option) => {
          options.push({
            label: `€${option}`,
            value: option,
          });
        });
        setInsuredOptions(options);
      }

      // format repeater fields
      if (field.type === FieldType.Repeater) {
        const composedSubField = {};
        field.subFields.forEach((subField) => {
          composedSubField[subField.name] = ShippingFieldDefault[subField.name] || null;
        });
        values[field.name] = [{ _id: Date.now(), ...composedSubField }];
      }
    });

    const sortedFields = orderShippingFields(selectedShippingProvider, provider?.fields, PrefferedShippingFieldOrder);

    setFormFields(sortedFields);
    setFormValues(values);
  }, [shippingFields, selectedShippingProvider]);

  const locale = React.useMemo(() => getTranslations($t, selectedShippingProvider), [$t, selectedShippingProvider]);

  const onError = (err) => {
    setErrors(JSON.stringify(err));
  };

  const onSubmit = () => {
    const hasIncompleteColliLine = formValues?.colli?.findIndex((line) => {
      const invalidField = Object.values(line).findIndex((field) => !field);
      return invalidField !== -1;
    }) !== -1;

    if (formValues.colli && hasIncompleteColliLine) {
      setErrors(locale.missingColliValues);
      return;
    }

    // @TODO: this needs to be dynamic and defined better. This is to hardcoded.
    if (selectedShippingProvider === 'sendcloud' && (formValues[ShippingFields.Weight] === null || formValues[ShippingFields.Weight] === '')) {
      setErrors(locale.missingWeight);
      return;
    }
    const InsuranceAmount = Number(formValues[ShippingFields.InsuranceAmount]) || 0;
    if (selectedShippingProvider === 'sendcloud' && InsuranceAmount < 100) {
      setErrors(locale.insuranceToLow);
      return;
    }
    if (selectedShippingProvider === 'sendcloud' && InsuranceAmount > 5000) {
      setErrors(locale.insuranceToHigh);
      return;
    }
    if (selectedShippingProvider === 'sendcloud' && formValues[ShippingFields.ProductId] === null) {
      setErrors(locale.missingProduct);
      return;
    }

    if (hasMissingFields(selectedShippingProvider, formValues)) {
      setErrors(locale.missingFields);
      return;
    }

    declareForShipping(orderId, selectedShippingProvider, formValues, onCompleted, onError);
  };

  // @TODO form validation using hook? Would be cool. No idea how that works with radio groups etc.
  const handleInputChange = React.useCallback((fieldName, val, type = 'string') => {
    const filteredVal = type === 'number' ? onlyNumberOrFloat(val) : val;
    setErrors(false);
    setFormValues((prev) => ({
      ...prev,
      ...{ [fieldName]: filteredVal },
    }));
  }, []);

  // @TODO: colli needs to be moved to its own component
  const addColli = () => {
    const newLine = {
      _id: Date.now(),
      weight: null,
      width: null,
      height: null,
      length: null,
    };
    const newColli = [...formValues.colli, newLine];
    handleInputChange('colli', newColli);
  };

  const removeColli = (lineId) => {
    const filtered = [...formValues.colli].filter((line) => line._id !== lineId);
    handleInputChange('colli', filtered);
  };

  const handleColliValueChange = (field, index, event) => {
    const newColliLines = [...formValues.colli];
    newColliLines[index] = { ...formValues.colli[index], ...{ [field]: event.target.value } };
    handleInputChange('colli', newColliLines);
  };

  const optionsFields = React.useMemo(() => (formFields ? formFields.filter((field) => field.type === FieldType.Bool) : []), [formFields]);
  const colliFields = React.useMemo(() => {
    if (selectedShippingProvider !== ShippingPlugin.KDZ || !formFields) {
      return [];
    }
    return formFields.filter((field) => field.type === FieldType.Repeater);
  }, [formFields, selectedShippingProvider]);

  function renderInputField(field) {
    const isColloField = [ShippingFields.ColloAmount, ShippingFields.ColliAmount].includes(field.name);
    if (isColloField) {
      return (
        <RadioGroup
          name={field.name}
          options={ColloAmountTypes}
          onChange={(val) => handleInputChange(field.name, val)}
          selectedValue={formValues[field.name]}
          compact
          disabled={loading}
        />
      );
    }

    if (field.name === ShippingFields.SenderAddress) {
      return (
        <SendcloudSenderAddress
          shopId={shopId}
          selectedValue={formValues[field.name]}
          onChange={(val) => handleInputChange(field.name, val)}
        />
      );
    }

    if (field.name === ShippingFields.ProductId) {
      return (
        <SendcloudProviderProduct
          orderId={orderId}
          weight={formValues[ShippingFields.Weight] || null}
          selectedValue={formValues[field.name]}
          onChange={(val) => handleInputChange(field.name, val)}
        />
      );
    }

    if (field.name === ShippingFields.InsuranceAmount) {
      return (
        <TextField
          placeholder={locale[field.name] || ''}
          onChangeText={(val) => handleInputChange(field.name, val, 'number')}
          onBlur={(e) => {
            const value = e.target.value || 100;
            const formattedVal = (Math.ceil(value / 100) * 100).toString();
            handleInputChange(field.name, formattedVal, 'number');
          }}
          style={styles.numberField}
          disabled={loading}
          key={field.name}
          keyboardType="decimal-pad"
          value={formValues[field.name] || ''}
          description={<InputExplainer fieldName={field.name} />}
          leftInnerLabel="€"
        />
      );
    }

    if (field.type === FieldType.Enum) {
      return (
        <RadioGroup
          name={field.name}
          options={mapToOptions(field.options)}
          onChange={(val) => handleInputChange(field.name, val)}
          selectedValue={formValues[field.name]}
          compact
          disabled={loading}
        />
      );
    }

    if ([FieldType.Int, FieldType.Float].includes(field.type)) {
      const options = {
        autoFocus: field.name === ShippingFields.Weight,
        leftInnerLabel: field.name === ShippingFields.InsuranceAmount ? '€' : undefined,
        innerLabel: field.name === ShippingFields.Weight ? 'kg' : undefined,
      };
      return (
        <TextField
          placeholder={locale[field.name] || ''}
          onChange={(e) => handleInputChange(field.name, e.target.value, 'number')}
          style={styles.numberField}
          disabled={loading}
          key={field.name}
          keyboardType="decimal-pad"
          value={formValues[field.name] || ''}
          description={<InputExplainer fieldName={field.name} />}
          {...options}
        />
      );
    }

    return (
      <TextField
        placeholder={locale[field.name] || ''}
        onChange={(e) => handleInputChange(field.name, e.target.value)}
        style={styles.textArea}
        disabled={loading}
        key={field.name}
      />
    );
  }

  const hasFormFields = !formFields || formFields.length === 0;

  const isSendcloudSelected = selectedShippingProvider === ShippingPlugin.Sendcloud;
  const isSendcloudAndOutsideEu = isSendcloudSelected && !isEuMember(destinationCountry);

  const disableDeclareButton = isSendcloudAndOutsideEu || hasFormFields;

  return (
    <>
      <View style={styles.header}>
        <H1 style={styles.heading}>
          {$t({ id: 'DeclareForShippingModal.Heading.Title', defaultMessage: 'Declare order for shipping' })}
        </H1>
        <Strong style={styles.providerLabel}>
          {$t({ id: 'DeclareForShippingModal.ShippingProviderField.Label', defaultMessage: 'Send with' })}
        </Strong>
        <RadioGroup
          name="shippingProvider"
          options={availableShippingProviders}
          selectedValue={selectedShippingProvider}
          onChange={setSelectedShippingProvider}
          disabled={loading}
        />
      </View>
      <ScrollView ref={scrollRef} style={styles.form}>
        {!isSendcloudAndOutsideEu && (
        <>
          {/* @TODO: Fix filter. This ugly, long and not readable */}
          {formFields && formFields.filter((field) => ['string', 'enum', 'integer', 'float'].includes(field.type) && !['insured_amount', ShippingFields.CustomsInvoiceNumber, ShippingFields.CountryState, ShippingFields.CustomsShipmentType].includes(field.name)).map((item) => (
          // @TODO: Fix weird zIndex workaround
            <View style={[styles.row, item.name === ShippingFields.ProductId && { zIndex: 1 }]} key={item.name}>
              <View style={styles.labelWrapper}>
                <Strong style={styles.label}>
                  {locale[item.name] || item.name}
                </Strong>
              </View>
              {renderInputField(item)}
            </View>
          ))}

          {(optionsFields && optionsFields.length > 0) && (
          <View style={styles.row}>
            <View style={styles.labelWrapper}>
              <Strong style={styles.label}>{locale.options}</Strong>
            </View>
            <View style={styles.optionsContainer}>
              {optionsFields.map((item) => (
                <Checkbox
                  label={locale[item.name] || item.name}
                  onChange={(val) => handleInputChange(item.name, val)}
                  key={item.name}
                  style={styles.checkbox}
                  compact
                />
              ))}
            </View>
          </View>
          )}

          {(colliFields && colliFields.length > 0) && (
          <View style={styles.row}>
            <View style={styles.labelWrapper}>
              <Strong style={styles.label}>{locale.colli}</Strong>
            </View>
            <View style={styles.colliContainer}>
              {formValues?.colli?.map((line, index) => (
                <View style={styles.colliRowWrapper} key={`colli-${line._id}`}>
                  <TextField
                    placeholder="Weight"
                    disabled={loading}
                    style={styles.colliField}
                    innerLabel="kg"
                    keyboardType="numeric"
                    onChange={(e) => handleColliValueChange('weight', index, e)}
                  />
                  <TextField
                    placeholder="Width"
                    disabled={loading}
                    style={styles.colliField}
                    innerLabel="cm"
                    keyboardType="numeric"
                    onChange={(e) => handleColliValueChange('width', index, e)}
                  />
                  <TextField
                    placeholder="Height"
                    disabled={loading}
                    style={styles.colliField}
                    innerLabel="cm"
                    keyboardType="numeric"
                    onChange={(e) => handleColliValueChange('height', index, e)}
                  />
                  <TextField
                    placeholder="Length"
                    disabled={loading}
                    style={[styles.colliField, styles.noRightMargin]}
                    innerLabel="cm"
                    onChange={(e) => handleColliValueChange('length', index, e)}
                  />
                  {formValues.colli.length > 1 && (
                    <TouchableOpacity activeOpacity={0.5} onPress={() => removeColli(line._id)} style={styles.removeColliRow}>
                      <Icon name="remove-circle-outline" size={20} color="#e1e1e1" />
                    </TouchableOpacity>
                  )}
                </View>
              ))}
              <AddColliButton onPress={addColli} />
            </View>
          </View>
          )}

          {formValues?.insured && (
          <View style={styles.row}>
            <View style={styles.labelWrapper}>
              <Strong style={styles.label}>{locale.insured_amount}</Strong>
            </View>
            <SelectField
              items={insuredOptions}
              selectedValue={formValues.insured_amount || insuredOptions[0].value}
              onValueChange={(e) => handleInputChange(ShippingFields.InsuredAmount, e.target?.value)}
            />
          </View>
          )}
        </>
        )}

        {isSendcloudAndOutsideEu && (
          <InfoBar
            title={locale.sendcloudOutsideEuTitle}
            description={locale.sendcloudOutsideEuDescription}
            iconName="information-circle"
            iconColor="#676767"
            style={styles.infoBar}
          />
        )}

        {hasFormFields && (
          <InfoBar
            title={locale.noFieldsFoundTitle}
            description={locale.noFieldsFoundDescription}
            iconName="information-circle"
            iconColor="#676767"
            style={styles.infoBar}
          />
        )}
      </ScrollView>
      <View style={styles.footer}>
        {errors && <Alert text={errors} />}
        <View style={styles.actionsWrapper}>
          <FadeView show={!loading}>
            <Button
              type="secondary"
              label={locale.closeButton}
              onPress={onRequestCancel}
            />
          </FadeView>
          <Button
            label={locale.declareButton}
            loadingLabel={locale.declareLoadingText}
            onPress={onSubmit}
            loading={loading}
            disabled={disableDeclareButton}
          />
        </View>
      </View>
    </>
  );
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

DeclareForm.propTypes = {
  orderId: IdType.isRequired,
  shopId: IdType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  shippingFields: PropTypes.array.isRequired, // @TODO - Improve type
  shippingProvider: PropTypes.string,
  destinationCountry: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  onCompleted: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
};

DeclareForm.defaultProps = {
  shippingProvider: undefined,
  destinationCountry: undefined,
};

export default DeclareForm;
