import React from 'react';
import { View } from 'react-native';
import { FadeIn } from '../../components/Animation';
import ForgotPasswordForm from './ForgotPasswordForm';
import Logo from '../../components/Logo';
import styles from './ForgotPasswordScreen.styles';

function ForgotPasswordScreen() {
  return (
    <View style={styles.container}>
      <FadeIn style={styles.leftContainer}>
        <View style={styles.logoContainer}>
          <Logo />
        </View>
        <View style={styles.formContainer}>
          <ForgotPasswordForm />
        </View>
      </FadeIn>
    </View>
  );
}

export default ForgotPasswordScreen;
