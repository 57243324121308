import { Dimensions, StyleSheet } from 'react-native';
import { colors } from '../../../constants/theme';

const smallDevice = Dimensions.get('window').width < 875;

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.background.primary,
    borderRadius: 10,
    minHeight: 55,
    padding: 5,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
  },
  pickedCheckbox: {
    marginLeft: smallDevice ? 10 : 15,
  },
  counter: {
    marginHorizontal: 15,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingVertical: 10,
  },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'stretch',
    borderRadius: 6,
    marginRight: 10,
    backgroundColor: colors.border.primary,
  },
  title: {
    fontFamily: 'Roboto bold',
    fontSize: 16,
    flex: 1,
    paddingRight: 10,
  },
  highlight: {
    color: colors.brand.primary,
  },
  sku: {
    paddingTop: 5,
    color: '#717171', // @TODO: add color to theme
  },
});

export default styles;
