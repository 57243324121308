import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { useRoute, useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native';
import { P } from '../../../components/Text';
import Icon from '../../../components/Icon';
import styles from './Notifications.styles';
import { colors, wizardStatus } from '../../../constants';
import { isShopConnectionError } from '../../../services/Helpers';
import { useAuthContext } from '../../../services/auth';
import { can, Actions } from '../../../services/permissions';
import { useLocale } from '../../../services/localization';

export const GET_SHOPS = gql`
  query GetShopStates {
    shops(first: 50) {
      data {
        id
        status
        wizard_status
      }
    }
  }
`;

const ShopRelatedRoutes = ['Shops', 'EditWoocommerce', 'EditShippingProviders', 'EditPlugin', 'EditShop'];

function Notifications() {
  const { loading, error, data } = useQuery(GET_SHOPS);
  const navigation = useNavigation();
  const route = useRoute();
  const { user } = useAuthContext();
  const canManageShop = React.useMemo(() => can(user.role, Actions.ManageShop), [user]);
  const $t = useLocale();

  const disconnectedShop = React.useMemo(() => {
    if (!data || !data.shops.data || !data.shops.data.lenght === 0) {
      return false;
    }
    return data.shops.data.find((shop) => isShopConnectionError(shop.status) && shop.wizard_status === wizardStatus.FINISHED);
  }, [data]);

  const isShopRelatedPage = React.useMemo(() => ShopRelatedRoutes.includes(route.name), [route.name]);

  const go = React.useCallback(() => navigation.navigate('EditWoocommerce', { shopId: disconnectedShop?.id }), [disconnectedShop?.id, navigation]);

  if (loading || error || !data || !disconnectedShop || !canManageShop || isShopRelatedPage) {
    return null;
  }

  return (
    <TouchableOpacity style={styles.container} activeOpacity={0.6} onPress={go}>
      <Icon size={18} name="cloud-offline" color={colors.ui.attention} />
      <P style={styles.text}>
        {$t({ id: 'Notifications.ShopConnectionError', defaultMessage: 'One of your shops failed to connect' })}
      </P>
    </TouchableOpacity>
  );
}

export default Notifications;
