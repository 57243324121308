import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '70%',
  },
  logo: {
    position: 'absolute',
    left: 15,
    top: 15,
    width: 127,
    height: 32,
  },
  icon: {
  },
  heading: {
    marginTop: 15,
  },
  text: {
    marginTop: 10,
    fontSize: 16,
  },
});

export default styles;
