import React from 'react';
import { ActivityIndicator, TouchableOpacity } from 'react-native';
import { colors } from '../../../constants';
import { P } from '../../../components/Text';
import styles from './FetchMoreButton.styles';

function FetchMoreButton({ onPress, loading }) {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress} disabled={loading}>
      {loading
        ? <ActivityIndicator size="small" color={colors.brand.primary} />
        : <P style={styles.label}>Show more orders</P>}
    </TouchableOpacity>
  );
}

export default FetchMoreButton;
