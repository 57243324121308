import { useMutation } from '@apollo/client';
import { UPDATE_USER } from '../../graphql/UserQuery';

const useUpdateUser = (userFields, onCompleted, onError) => {
  const [runMutation, { loading }] = useMutation(UPDATE_USER, {
    variables: userFields,
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted(response) {
      if (typeof onCompleted === 'function') onCompleted(response);
    },
  });

  const updateUser = () => {
    if (loading) return;
    runMutation();
  };

  return { updateUser, loading };
};

export default useUpdateUser;
