import { useMutation } from '@apollo/client';
import { UPDATE_FORGOTTEN_PASSWORD } from '../graphql/AuthQuery';

const useUpdateForgottenPassword = (fields, onCompleted, onError) => {
  const [updateForgottenPassword, { loading }] = useMutation(
    UPDATE_FORGOTTEN_PASSWORD,
    {
      variables: fields,
      onError(error) {
        if (typeof onError === 'function') onError(error);
      },
      onCompleted() {
        if (typeof onCompleted === 'function') onCompleted();
      },
    },
  );

  return { updateForgottenPassword, loading };
};

export default useUpdateForgottenPassword;
