import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  heading: {
    marginTop: 20,
    marginBottom: 15,
    alignItems: 'center',
    flexDirection: 'row',
  },
  shopStatus: {
    paddingLeft: 10,
  },
  input: {
    marginBottom: 15,
    flex: 1,
  },
  inputZipCode: {
    width: 100,
    marginHorizontal: 15,
  },
  logo: {
    width: 100,
    height: 100,
    marginRight: 15,
    marginBottom: 15,
    borderRadius: 10,
    backgroundColor: '#E1E1E1',
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  delete: {
    color: '#D11212',
    textDecorationLine: 'underline',
  },
  shopName: {
    textTransform: 'uppercase',
    fontSize: 12,
    marginBottom: 10,
    color: '#B6B6B6',
    marginLeft: 13,
  },
});

export default styles;
