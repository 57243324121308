import { useMutation, gql } from '@apollo/client';

const VERIFY_EMAIL_CHANGE = gql`
  mutation verifyEmailChange($token: String!) {
    verifyEmailChange(input: { token: $token }) {
      id
      email
    }
  }
`;

const useVerifyEmailChange = (onCompleted, onError) => {
  const [runMutation, { loading, error }] = useMutation(VERIFY_EMAIL_CHANGE, {
    onError(e) {
      if (typeof onError === 'function') onError(e);
    },
    onCompleted() {
      if (typeof onCompleted === 'function') onCompleted();
    },
  });

  const verifyEmailChange = (token) => {
    if (loading) return;
    runMutation({
      variables: {
        token,
      },
    });
  };

  return { verifyEmailChange, loading, error };
};

export default useVerifyEmailChange;
