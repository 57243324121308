import React from 'react';
import { View, Modal } from 'react-native';
import PropTypes from 'prop-types';
import { ShippingLabelStatus } from '../../constants';
import Backdrop from './components/Backdrop';
import DeclareForm from './DeclareForm';
import PrintingLabel from './components/PrintingLabel';
import Declaring from './components/Declaring';
import Error from './components/Error';
import CloseModalButton from './components/CloseModalButton';
import styles from './DeclareForShippingModal.styles';

const State = {
  Form: 'form',
  Declaring: 'declaring',
  Declared: 'declared',
  Error: 'error',
};

function getInitalModalState(shippingStatus) {
  const states = {
    [ShippingLabelStatus.NOT_DECLARED]: State.Form,
    [ShippingLabelStatus.DECLARING]: State.Declaring,
    [ShippingLabelStatus.DECLARED]: State.Declared,
    [ShippingLabelStatus.FAILED_TO_DECLARE]: State.Error,
  };
  return states[shippingStatus];
}

const DeclareForShippingModal = React.forwardRef(({
  shippingProvider, shippingStatus, shopId, orderId, shippingFields, destinationCountry,
}, ref) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [state, setState] = React.useState(getInitalModalState(shippingStatus));

  const onRequestClose = () => {
    setIsVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setIsVisible(true);
    },
  }));

  function renderContent() {
    if (state === State.Error) {
      return <Error orderId={orderId} onCompleted={() => setState(State.Form)} />;
    }

    if (state === State.Form) {
      return (
        <DeclareForm
          shopId={shopId}
          orderId={orderId}
          shippingProvider={shippingProvider}
          shippingFields={shippingFields}
          destinationCountry={destinationCountry}
          onCompleted={() => setState(State.Declaring)}
          onRequestCancel={onRequestClose}
        />
      );
    }

    if (state === State.Declaring) {
      return <Declaring orderId={orderId} onCompleted={() => setState(State.Declared)} onError={() => setState(State.Error)} />;
    }

    if (state === State.Declared) {
      return <PrintingLabel orderId={orderId} onCompleted={onRequestClose} />;
    }

    return null;
  }

  return (
    <Modal onRequestClose={onRequestClose} animationType="fade" visible={isVisible} transparent>
      <Backdrop />
      <View style={styles.modalContent}>
        <CloseModalButton onPress={onRequestClose} />
        {renderContent()}
      </View>
    </Modal>
  );
});

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

DeclareForShippingModal.propTypes = {
  shippingStatus: PropTypes.oneOf(Object.values(ShippingLabelStatus)).isRequired,
  shopId: IdType.isRequired,
  orderId: IdType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  shippingFields: PropTypes.array.isRequired, // @TODO Improve prop typing
  shippingProvider: PropTypes.string,
  destinationCountry: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
};

DeclareForShippingModal.defaultProps = {
  shippingProvider: undefined,
  destinationCountry: undefined,
};

export default DeclareForShippingModal;
