import React from 'react';
import { gql, useQuery, useSubscription } from '@apollo/client';
import { mutationError } from '../../services/Error';
import { useLocale } from '../../services/localization';
import { FadeIn } from '../../components/Animation';
import ScreenHeading from '../../components/ScreenHeading';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ErrorNotification } from '../../components/ErrorNotification';
import ScreenWithSidebars from '../../components/ScreenWithSidebars';
import { H4, Strong } from '../../components/Text';
import ShopSubMenu from '../../components/ShopSubMenu';
import InfoBar from '../../components/InfoBar';
import ShippingProvider, { ProviderStatus } from '../../components/ShippingProvider';
import { ShippingPlugin, colors } from '../../constants';
import styles from './EditShippingProvidersScreen.styles';
import PluginNotInstalledWarning from './PluginNotInstalledWarning';
import SendCloud from './SendCloud';

function checkProviders(shippingProviders) {
  return shippingProviders.find((provider) => [ProviderStatus.Enabled, ProviderStatus.ReadyToEnable].includes(provider.status));
}

const SHOP_CHANGED_SUBSCRIPTION = gql`
  subscription OnShopChanged($id: ID!) {
    shopUpdated(id: $id) {
      id
      status
      sendcloud_status
      wizard_status
      woocommerce_order_statuses {
        slug
        name
      }
      shipping_fields {
        plugin
        active
      }
    }
  }
`;

export const SHOP_PROVIDERS = gql`
  query GetShopShippingProviders($shopId: ID!) {
    shop(id: $shopId) {
      id
      name
      shipping_fields {
        plugin
        active
      }
      installed_plugin_version
      sendcloud_status
    }
  }
`;

const providerOrder = [
  ShippingPlugin.Sendcloud,
  ShippingPlugin.PostNL,
  ShippingPlugin.MyParcel,
  ShippingPlugin.KDZ,
];

function sortProviders(providers) {
  return providers.slice().sort((a, b) => providerOrder.indexOf(a.plugin) - providerOrder.indexOf(b.plugin));
}

function EditShippingProvidersScreen({ route, navigation }) {
  const { shopId } = route.params;
  const { loading, error, data: response } = useQuery(SHOP_PROVIDERS, { variables: { shopId } });
  const $t = useLocale();

  useSubscription(SHOP_CHANGED_SUBSCRIPTION, {
    variables: { id: shopId },
    skip: shopId === undefined,
  });

  const shippingProviders = React.useMemo(() => {
    if (!response) {
      return [];
    }
    const shopProviders = sortProviders(response?.shop?.shipping_fields || []);
    return Object.values(ShippingPlugin).reduce((agg, supportedProvider) => {
      const provider = shopProviders.find((prov) => prov.plugin === supportedProvider);
      if (!provider) {
        agg.push({
          plugin: supportedProvider,
          status: ProviderStatus.PluginNotInstalled,
        });
      } else {
        agg.push({
          ...provider,
          status: provider.active ? ProviderStatus.Enabled : ProviderStatus.ReadyToEnable,
        });
      }
      return agg;
    }, []);
  }, [response]);
  const sortedShippingProviders = React.useMemo(() => sortProviders(shippingProviders), [shippingProviders]);

  const hasSupportedShippingProviders = React.useMemo(() => checkProviders(shippingProviders), [shippingProviders]);
  const hasFastPickerPlugin = response?.shop?.installed_plugin_version || false;

  function renderProvider(provider) {
    if (provider.plugin === ShippingPlugin.Sendcloud) {
      return (
        <SendCloud key={provider.plugin} shopId={shopId} status={response.shop.sendcloud_status} active={provider.active} />
      );
    }
    return (
      <ShippingProvider
        provider={provider.plugin}
        status={provider.status}
        hasFastPickerPlugin={hasFastPickerPlugin}
        shopId={response.shop.id}
        key={provider.plugin}
      />
    );
  }

  if (loading) return <LoadingIndicator />;
  if (!response) {
    return (
      <ErrorNotification
        errorTitle={$t({ id: 'EditShopScreen.ShopNotFound.Title', defaultMessage: 'Shop not found' })}
        errorMessage={$t({ id: 'EditShopScreen.ShopNotFound.Description', defaultMessage: "Sorry, seems that you're looking for a shop that does not exists." })}
        buttonLabel={$t({ id: 'EditShopScreen.BackToShopsButton.Label', defaultMessage: 'Back to shops' })}
        buttonAction={() => navigation.navigate('Shops')}
      />
    );
  }
  if (error) return <ErrorNotification errorMessage={mutationError(error)} />;
  return (
    <ScreenWithSidebars
      LeftSidebarComponent={(
        <>
          <Strong style={styles.shopName}>{response.shop.name}</Strong>
          <ShopSubMenu />
        </>
      )}
      MainContentComponent={(
        <FadeIn>
          <ScreenHeading title={$t({ id: 'EditShopScreen.Page.Title', defaultMessage: 'Edit shop {name}' }, { name: response.shop.name })} />
          <H4 style={styles.heading}>
            {$t({ id: 'EditShopScreen.ShippingProviderSection.Title', defaultMessage: 'Shipping providers' })}
          </H4>
          {!hasFastPickerPlugin && <PluginNotInstalledWarning />}
          {!hasSupportedShippingProviders && (
            <InfoBar
              iconName="alert-circle"
              iconColor={colors.brand.primary}
              title="No shipping providers found"
              description="Non of the following plugins are installed on your WooCommerce webshop. Please install and setup one of the following plugins."
              style={styles.noSupportedProvidersFoundText}
            />
          )}
          {sortedShippingProviders.map((provider) => renderProvider(provider))}
        </FadeIn>
      )}
    />
  );
}

export default EditShippingProvidersScreen;
