import React, { useState } from 'react';
import { Linking, View, TouchableOpacity } from 'react-native';
import { mutationError } from '../../../services/Error';
import { FadeIn } from '../../../components/Animation';
import {
  P, H1, A, Small, Strong,
} from '../../../components/Text';
import { EmailField, TextField, PasswordField } from '../../../components/Form';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import Icon from '../../../components/Icon';
import useRegister from '../../../hooks/useRegister';
import { useLocale } from '../../../services/localization';
import { ValidationRule } from '../../../services/Validation';
import { links } from '../../../constants';
import styles from './RegisterForm.styles';
import useForm from '../../../hooks/form';
import { getTimeLeftOnTrail } from '../../../navigation/UserControlBar/UserMenu/PlanStatus';

const openTermsOfService = () => {
  Linking.openURL(links.TermsOfService);
};

const openPrivacyPolicy = () => {
  Linking.openURL(links.PrivacyPolicy);
};

const InitialFields = {
  name: '',
  email: '',
  password: '',
};

const Validation = {
  name: [ValidationRule.notEmpty, ValidationRule.minThreeChar, ValidationRule.validString],
  email: [ValidationRule.validEmail],
  password: [
    ValidationRule.notEmpty,
    ValidationRule.hasLetter,
    ValidationRule.hasCapital,
    ValidationRule.hasSpecialChar,
    ValidationRule.hasDigit,
  ],
};

function LoginLink(str) {
  return <A to="/login">{str}</A>;
}

function TermsOfServiceLink(str) {
  return <A onPress={openTermsOfService} style={styles.link}>{str}</A>;
}

function PrivacyPolicyLink(str) {
  return <A onPress={openPrivacyPolicy} style={styles.link}>{str}</A>;
}

function ResendVerifyEmailLink(str) {
  return <A to="/resend-verify-email">{str}</A>;
}

function StyledEmail(str) {
  return <Strong>{str}</Strong>;
}

function styledValitationError(errors) {
  // eslint-disable-next-line react/no-array-index-key
  return errors && errors.map((message, index) => <Small style={styles.noteText} key={`error-${index}`}>{message}</Small>);
}

function RegisterForm() {
  const passwordRef = React.useRef();
  const [error, setError] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState({ checked: false, error: false });
  const [completed, setCompleted] = useState(false);
  const $t = useLocale();

  const {
    values, handleOnChange, validationErrors, checkValidation, hasValidationErrors,
  } = useForm(InitialFields, Validation);

  const { register, loading } = useRegister(
    {
      name: values.name.value,
      email: values.email.value,
      password: values.password.value,
    },
    () => setCompleted(true),
    (e) => setError(mutationError(e)),
  );

  const submit = React.useCallback(() => {
    if (loading || hasValidationErrors() || !acceptedTerms.checked) {
      if (!acceptedTerms.checked) {
        setAcceptedTerms({ ...acceptedTerms, error: true });
      }
      return;
    }
    register();
  }, [acceptedTerms, hasValidationErrors, loading, register]);

  const toggleTerms = React.useCallback(() => {
    setAcceptedTerms({ checked: !acceptedTerms.checked, error: false });
  }, [acceptedTerms.checked]);

  if (completed) {
    return (
      <FadeIn>
        <H1 style={styles.heading}>
          {$t({ id: 'RegisterForm.VerifyEmailSend.Title', defaultMessage: 'Verify your email' })}
        </H1>
        <Strong style={styles.text}>
          {$t({ id: 'RegisterForm.VerifyYourEmail.Text', defaultMessage: 'You will need to verify your email to complete the registration.' })}
        </Strong>
        <P style={styles.text}>
          {$t({
            id: 'RegisterForm.EmailHasBeenSend.Explaination',
            defaultMessage: 'An email has been send to <strong>{email}</strong> with a link to verify your account. If you have not received the email after a few minutes, please check your spam folder.',
          }, { email: values.email.value, strong: StyledEmail })}
        </P>
        <P style={styles.text}>
          {$t({
            id: 'RegisterForm.NoEmailReceived.Text',
            defaultMessage: 'No email received? <link>Resend verification email</link>',
          }, { link: ResendVerifyEmailLink })}
        </P>
      </FadeIn>
    );
  }

  return (
    <>
      <H1 style={styles.heading}>{$t({ id: 'RegisterForm.Heading', defaultMessage: 'Create your account' })}</H1>
      <TextField
        style={styles.input}
        label={$t({ id: 'RegisterForm.NameField.Label', defaultMessage: 'Your name' })}
        placeholder={$t({ id: 'RegisterForm.NameField.Placeholder', defaultMessage: 'Bill Person' })}
        value={values.name.value}
        onChangeText={(val) => handleOnChange('name', val)}
        onFocus={() => setError(false)}
        onBlur={() => checkValidation('name')}
        description={styledValitationError(validationErrors.name)}
        required
        returnKeyType="next"
        onSubmitEditing={submit}
      />
      <EmailField
        style={styles.input}
        label={$t({ id: 'RegisterForm.EmailField.Label', defaultMessage: 'Email address' })}
        placeholder={$t({ id: 'RegisterForm.EmailField.Placeholder', defaultMessage: 'your@email.com' })}
        value={values.email.value}
        onChangeText={(val) => handleOnChange('email', val)}
        onFocus={() => setError(false)}
        onBlur={() => checkValidation('email')}
        description={styledValitationError(validationErrors.email)}
        required
        returnKeyType="next"
        onSubmitEditing={submit}
        onKeyPress={(e) => {
          if (e.code === 'Tab') {
            e.preventDefault();
            passwordRef.current.focus();
          }
        }}
      />
      <PasswordField
        ref={passwordRef}
        style={styles.input}
        label={$t({ id: 'RegisterForm.PasswordField.Label', defaultMessage: 'Password' })}
        placeholder={$t({ id: 'RegisterForm.PasswordField.Placeholder', defaultMessage: 'Your secure password' })}
        value={values.password.value}
        onChangeText={(val) => handleOnChange('password', val)}
        onFocus={() => setError(false)}
        onBlur={() => checkValidation('password')}
        description={styledValitationError(validationErrors.password)}
        returnKeyType="send"
        autoComplete="off"
        required
        onSubmitEditing={submit}
      />
      <TouchableOpacity style={styles.radioWrapper} onPress={toggleTerms} activeOpacity={0.6}>
        <View style={[styles.radio, acceptedTerms.checked && styles.radioActive]}>
          {acceptedTerms.checked && <Icon name="checkmark-sharp" color="#fff" size={17} />}
        </View>
        <P style={styles.termsOfServiceLabel}>
          {$t({
            id: 'RegisterForm.AgreeToTheTermsOfService.Text',
            defaultMessage: 'I accept the <termsLink>Terms of Service</termsLink> and <privacyLink>Privacy Policy</privacyLink>',
          }, { termsLink: TermsOfServiceLink, privacyLink: PrivacyPolicyLink })}
        </P>
      </TouchableOpacity>
      {acceptedTerms.error && (
        <Small style={styles.noteText}>
          {$t({ id: 'RegisterForm.AgreeToTheTermsOfService.Warning', defaultMessage: 'Please agree with our Terms of Service and Privacy Policy before creating an account' })}
        </Small>
      )}
      <Button
        style={styles.button}
        onPress={submit}
        loading={loading}
        size="large"
        label={$t({ id: 'RegisterForm.CreateAccountButton.Label', defaultMessage: 'Create account' })}
      />
      <P style={styles.freeTrailText}>
        {$t({ id: 'RegisterForm.GetFreeTrail.Text', defaultMessage: 'And Get Free {months} Months Trail Plan 👍' }, { months: getTimeLeftOnTrail() })}
      </P>
      {error && (
        <FadeIn>
          <Alert text={error} />
        </FadeIn>
      )}
      <P style={styles.registerText}>
        {$t({
          id: 'RegisterForm.AlreadyHaveAccount.Text',
          defaultMessage: 'Already have an account? <link>Login here</link>',
        }, { link: LoginLink })}
      </P>
    </>
  );
}

export default RegisterForm;
