import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { mutationError } from '../../../services/Error';
import { ValidationRule } from '../../../services/Validation';
import { FadeIn } from '../../../components/Animation';
import {
  H1, P, A, Small,
} from '../../../components/Text';
import { EmailField, PasswordField } from '../../../components/Form';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import Icon from '../../../components/Icon';
import useUpdateForgottenPassword from '../../../hooks/useUpdateForgottenPassword';
import { useLocale } from '../../../services/localization';
import styles from './UpdatePasswordForm.styles';
import useForm from '../../../hooks/form';

const InitialFields = {
  email: '',
  password: '',
};

const Validation = {
  email: [ValidationRule.validEmail],
  password: [
    ValidationRule.notEmpty,
    ValidationRule.hasLetter,
    ValidationRule.hasCapital,
    ValidationRule.hasSpecialChar,
    ValidationRule.hasDigit,
  ],
};

function styledValitationError(errors) {
  // eslint-disable-next-line react/no-array-index-key
  return errors && errors.map((message, index) => <Small style={styles.noteText} key={`error-${index}`}>{message}</Small>);
}

function UpdatePasswordForm({ token }) {
  const navigation = useNavigation();
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const $t = useLocale();

  const {
    values, handleOnChange, validationErrors, checkValidation, hasValidationErrors,
  } = useForm(InitialFields, Validation);

  const { updateForgottenPassword, loading } = useUpdateForgottenPassword(
    {
      token,
      email: values.email.value,
      password: values.password.value,
    },
    () => {
      handleOnChange('email', '');
      handleOnChange('password', '');
      setCompleted(true);
    },
    (err) => setError(mutationError(err)),
  );

  const submit = React.useCallback(() => {
    if (loading || hasValidationErrors()) {
      return;
    }
    updateForgottenPassword();
  }, [hasValidationErrors, loading, updateForgottenPassword]);

  if (completed) {
    return (
      <FadeIn>
        <Icon
          name="checkmark"
          size={100}
          color="#6FC62E"
          style={styles.checkmark}
        />
        <H1 style={styles.heading}>{$t({ id: 'UpdatePasswordForm.PasswordUpdatedSuccess.Title', defaultMessage: 'Password updated' })}</H1>
        <P style={styles.explanationText}>
          {$t({ id: 'UpdatePasswordForm.PasswordUpdated.Description', defaultMessage: 'We have updated your password and you should be able to login.' })}
        </P>
        <Button
          style={styles.button}
          onPress={() => navigation.navigate('Login')}
          size="large"
          label={$t({ id: 'UpdatePasswordForm.LoginButton.Label', defaultMessage: 'Login' })}
        />
      </FadeIn>
    );
  }

  return (
    <>
      <H1 style={styles.heading}>{$t({ id: 'UpdatePasswordForm.Heading', defaultMessage: 'Create new password' })}</H1>
      <P style={styles.explanationText}>
        {$t({ id: 'UpdatePasswordForm.Description', defaultMessage: 'Enter a new password for your account.' })}
      </P>
      <EmailField
        style={styles.input}
        label={$t({ id: 'UpdatePasswordForm.EmailField.Label', defaultMessage: 'Email' })}
        placeholder={$t({ id: 'UpdatePasswordForm.EmailField.Placeholder', defaultMessage: 'your@email.com' })}
        value={values.email.value}
        onChangeText={(val) => handleOnChange('email', val)}
        onFocus={() => setError(false)}
        onBlur={() => checkValidation('email')}
        description={styledValitationError(validationErrors.email)}
        returnKeyType="next"
        onSubmitEditing={submit}
      />
      <PasswordField
        style={styles.input}
        label={$t({ id: 'UpdatePasswordForm.PasswordField.Label', defaultMessage: 'Your new password' })}
        placeholder={$t({ id: 'UpdatePasswordForm.PasswordField.Placeholder', defaultMessage: 'secure password' })}
        value={values.password.value}
        onChangeText={(val) => handleOnChange('password', val)}
        onFocus={() => setError(false)}
        onBlur={() => checkValidation('password')}
        description={styledValitationError(validationErrors.password)}
        returnKeyType="send"
        onSubmitEditing={submit}
      />
      <Button
        style={styles.button}
        onPress={submit}
        loading={loading}
        size="large"
        label={$t({ id: 'UpdatePasswordForm.ChangePasswordButton.Label', defaultMessage: 'Change password' })}
      />
      {error && (
        <FadeIn>
          <Alert text={error} />
        </FadeIn>
      )}
      <P style={styles.registerText}>
        {`${$t({ id: 'UpdatePasswordForm.AlreadyHaveAccount.Text', defaultMessage: 'Already have an account?' })} `}
        <A to="/login">{$t({ id: 'UpdatePasswordForm.LoginHereButton.Label', defaultMessage: 'Login here' })}</A>
      </P>
    </>
  );
}

UpdatePasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default UpdatePasswordForm;
