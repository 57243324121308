import { StyleSheet } from 'react-native';
import { colors, fonts } from '../../../constants/theme';

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 13,
    paddingVertical: 10,
    marginBottom: 3,
  },
  activeContainer: {
    backgroundColor: colors.background.secondary,
  },
  icon: {
    marginRight: 10,
  },
  label: {
    fontSize: 16,
    padddingLeft: 1,
  },
  activeLabel: {
    fontFamily: fonts.family.bold,
    color: colors.brand.primary,
  },
});

export default styles;
