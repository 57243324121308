// @TODO: rename constant variables to be more constitant. They use all kind of different formats which is ugly!

export const ShippingPlugin = {
  // PostNL: 'postnl', // @TODO: Force disabled because plugin got rewritten and this carrier is now broken
  MyParcel: 'myparcel',
  KDZ: 'kdz',
  Sendcloud: 'sendcloud',
};

export const SupportedShippingPlugins = [
  ShippingPlugin.MyParcel,
  ShippingPlugin.KDZ,
  ShippingPlugin.Sendcloud,
];

export const ShippingPluginName = {
  [ShippingPlugin.PostNL]: 'PostNL',
  [ShippingPlugin.MyParcel]: 'MyParcel',
  [ShippingPlugin.KDZ]: 'KDZ',
  [ShippingPlugin.Sendcloud]: 'Sendcloud',
};

export const PackageTypes = {
  Package: 'package',
  Mailbox: 'mailbox',
  Letter: 'letter',
};

export const ShippingFields = {
  // postnl & myparcel
  PackageType: 'package_type',
  ColloAmount: 'collo_amount',
  LabelDescription: 'label_description',
  AgeCheck: 'age_check',
  Insured: 'insured',
  InsuredAmount: 'insured_amount',
  ReturnShipments: 'return_shipments',
  OnlyRecipient: 'only_recipient',
  SignatureRequired: 'signature_required',

  // myparcel
  ColliAmount: 'colli_amount',

  // kdz
  DeliveryMethod: 'delivery_method',
  Remark: 'remark',

  // sendcloud
  SenderAddress: 'sender_address',
  ProductId: 'product_id',
  Weight: 'weight',
  InsuranceAmount: 'insurance_amount',

  // sendcloud international
  CustomsInvoiceNumber: 'customs_invoice_number',
  CustomsShipmentType: 'customs_shipment_type',
  CountryState: 'country_state',
  Products: 'products', // @todo - wiki mentions also a parcel_items field. Is this that field or am i missing something?
};

export const ShippingFieldDefault = {
  // postnl & myparcel
  [ShippingFields.PackageType]: 'package',
  [ShippingFields.ColloAmount]: '1',
  [ShippingFields.ColliAmount]: '1',

  // kdz
  [ShippingFields.DeliveryMethod]: 'Road Express',

  // sendcloud
  [ShippingFields.InsuranceAmount]: 100,
};

export const RequiredShippingFields = {
  [ShippingPlugin.Sendcloud]: [
    ShippingFields.Weight,
    ShippingFields.InsuranceAmount,
  ],
};

export const PrefferedShippingFieldOrder = {
  [ShippingPlugin.Sendcloud]: [
    ShippingFields.Weight,
    ShippingFields.ProductId,
    ShippingFields.Products,
    ShippingFields.InsuranceAmount,
    ShippingFields.SenderAddress,
  ],
};

export const ShippingLabelStatus = {
  NOT_DECLARED: 'NOT_DECLARED',
  DECLARING: 'DECLARING',
  FAILED_TO_DECLARE: 'FAILED_TO_DECLARE',
  DECLARED: 'DECLARED',
  NOT_SUPPORTED: 'NOT_SUPPORTED',
};

/**
 * Sendcloud connection status. Returned per shop
 */
export const SendcloudStatus = {
  Active: 'active',
  AccountIncomplete: 'account_incomplete',
  InvalidCredentials: 'invalid_credentials',
  CheckingConnection: 'checking_connection',
};
