import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import { Strong } from '../Text';
import Icon from '../Icon';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#E9E9E9',
    flexDirection: 'row',
    paddingHorizontal: 15,
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 70,
    minWidth: 150,
  },
  compact: {
    paddingHorizontal: 7,
    height: 48,
    minWidth: 1,
  },
  radio: {
    width: 17,
    height: 17,
    backgroundColor: '#fff',
    borderColor: '#E1E1E1',
    borderWidth: 1,
    borderRadius: 3,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioActive: {
    backgroundColor: '#2D54C4',
    borderColor: '#2D54C4',
  },
  innerCircle: {
    backgroundColor: '#fff',
    width: 9,
    height: 9,
    borderRadius: 1,
  },
  label: {
    marginLeft: 7,
    marginRight: 4,
  },
});

function Checkbox({
  label, checked, disabled, compact, onChange, style, ...props
}) {
  const [isChecked, setIsChecked] = useState(checked);

  const onPress = () => {
    const check = !isChecked;
    setIsChecked(check);
    onChange(check);
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, compact && styles.compact, style]}
      activeOpacity={0.6}
      disabled={disabled}
      {...props}
    >
      <View style={[styles.radio, isChecked && styles.radioActive]}>
        {isChecked && <Icon name="checkmark-sharp" color="#fff" size={17} />}
      </View>
      <Strong style={styles.label} numberOfLines={1}>{label}</Strong>
    </TouchableOpacity>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  compact: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  compact: false,
  onChange: () => null,
};

export default Checkbox;
