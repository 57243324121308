import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  divider: {
    borderRightColor: '#d8d8d8',
    borderRightWidth: 1,
    height: '100%',
    marginHorizontal: 15,
  },
});

export default styles;
