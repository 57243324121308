import React, { useState } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { FadeIn } from './Animation';
import { P, Strong } from './Text';
import Icon from './Icon';
import { useLocale } from '../services/localization';

const styles = StyleSheet.create({
  container: {
    borderRadius: 7,
    textDecorationLine: 'none',
    backgroundColor: '#e6e6e6',
    textAlign: 'left',
    borderWidth: 1,
    borderColor: '#e1e1e1',
  },
  toggleViewButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  helpIcon: {
    marginRight: 10,
  },
  labelContainer: {
    flex: 1,
  },
  label: {
    paddingBottom: 3,
    fontSize: 15,
  },
  subLabel: {
    color: '#656565',
  },
  caretIcon: {
    marginRight: 6,
  },
  content: {
    backgroundColor: '#dbdbdb',
    borderRadius: 5,
    padding: 10,
    margin: 10,
    marginTop: 0,
    textAlign: 'center',
  },
});

function InstructionVideo({ style }) {
  const [showVideo, setShowVideo] = useState(false);
  const $t = useLocale();
  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.toggleViewButton}
        onPress={() => setShowVideo(!showVideo)}
      >
        <Icon
          name="help-circle"
          size={22}
          color="#3258C5"
          style={styles.helpIcon}
        />
        <View style={styles.labelContainer}>
          <Strong style={styles.label}>
            {$t({ id: 'InstructionVideo.Description.Text', defaultMessage: 'Need help finding your WooCommerce Consumer key and secret?' })}
          </Strong>
          <P style={styles.subLabel}>
            {$t({ id: 'InstructionVideo.Button.Label', defaultMessage: 'Watch our instruction video here' })}
          </P>
        </View>
        <Icon
          name={showVideo ? 'caret-up' : 'caret-down'}
          size={14}
          color="#c3c3c3"
          style={styles.caretIcon}
        />
      </TouchableOpacity>
      {showVideo && (
        <FadeIn style={styles.content}>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/D4GKCeu5opg"
            title="How to Create a WooCommerce API key and secret"
            frameBorder="0"
            allowFullScreen
          />
        </FadeIn>
      )}
    </View>
  );
}

export default InstructionVideo;
