import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { Link } from '@react-navigation/native';
import { fonts } from '../constants/theme';

const styles = StyleSheet.create({
  font: {
    fontFamily: fonts.family.body,
  },
  color: {
    color: '#0F0F0F',
  },
  p: {
    fontSize: fonts.size.body,
  },
  strong: {
    fontFamily: fonts.family.bold,
  },
  small: {
    fontSize: fonts.size.small,
    color: '#717171',
  },
  a: {
    color: '#3258C5',
    textDecorationLine: 'underline',
  },
  h1: {
    fontFamily: fonts.family.heading,
    fontSize: fonts.size.h1,
    marginLeft: -1,
  },
  h2: {
    fontFamily: fonts.family.heading,
    fontSize: fonts.size.h2,
  },
  h3: {
    fontFamily: fonts.family.heading,
    fontSize: fonts.size.h3,
  },
  h4: {
    fontFamily: fonts.family.heading,
    fontSize: fonts.size.h4,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e1e1e1',
  },
});

export function P({ style, ...props }) {
  return <Text style={[styles.font, styles.color, styles.p, style]} {...props} />;
}

export function Strong({ style, ...props }) {
  return <P style={[styles.strong, style]} {...props} />;
}

export function Small({ style, ...props }) {
  return <P style={[styles.small, style]} {...props} />;
}

export function A({ style, ...props }) {
  return props.to
    ? <Link style={[styles.font, styles.p, styles.a, style]} {...props} />
    : <P style={[styles.font, styles.p, styles.a, style]} {...props} />;
}

export function H1({ style, ...props }) {
  return <Text style={[styles.font, styles.color, styles.h1, style]} {...props} />;
}

export function H2({ style, ...props }) {
  return <Text style={[styles.font, styles.color, styles.h2, style]} {...props} />;
}

export function H3({ style, ...props }) {
  return <Text style={[styles.font, styles.color, styles.h3, style]} {...props} />;
}

export function H4({ style, ...props }) {
  return <Text style={[styles.font, styles.color, styles.h4, style]} {...props} />;
}
