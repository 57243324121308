import { useQuery, gql } from '@apollo/client';

export const GET_WORDPRESS_PLUGIN = gql`
  query ($shopId: ID!) {
    shop(id: $shopId) {
      id
      installed_plugin_version
    }
  }
`;

const useWordpressPlugin = (shopId) => {
  const { loading, error, data } = useQuery(GET_WORDPRESS_PLUGIN, {
    variables: {
      shopId,
    },
  });

  return { loading, error, data };
};

export default useWordpressPlugin;
