import React from 'react';
import { ValidationRule, ValidationRegex } from '../../services/Validation';
import { useLocale } from '../../services/localization';

function initializeFields(fields) {
  const formattedFields = {};
  Object.entries(fields).forEach(([key, value]) => {
    formattedFields[key] = {
      value,
      touched: false,
    };
  });
  return formattedFields;
}

function validateValue(value, validationRules, errorTranslations) {
  return validationRules.reduce((agg, rule) => {
    if (!ValidationRegex[rule].test(value)) {
      agg.push(errorTranslations[rule]);
    }
    return agg;
  }, []);
}

function countValidationErrors(validationErrors) {
  return Object.values(validationErrors).reduce((agg, vals) => agg + vals.length, 0);
}

function useForm(initalFields, fieldValidation) {
  const $t = useLocale();
  const [values, setValues] = React.useState(initializeFields(initalFields));
  const [validationErrors, setValidationErrors] = React.useState({});
  const [allFieldsTouched, setAllFieldsTouched] = React.useState(false);
  const [canSubmitForm, setCanSubmitForm] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);

  /**
   *
   */
  const translations = React.useMemo(() => ({
    [ValidationRule.minThreeChar]: $t({ id: 'validation.error.pleaseUseThreeCharacters', defaultMessage: 'Please use 3 or more characters' }),
    [ValidationRule.notEmpty]: $t({ id: 'validation.error.canNotBeEmpty', defaultMessage: 'This field can not be empty' }),
    [ValidationRule.hasLetter]: $t({ id: 'validation.error.mustContainLowercaseCharacter', defaultMessage: 'This field must contain a lowercase character' }),
    [ValidationRule.hasCapital]: $t({ id: 'validation.error.mustContainUppercaseCharacter', defaultMessage: 'This field must contain an uppercase character' }),
    [ValidationRule.hasSpecialChar]: $t({ id: 'validation.error.mustContainSpecialCharacter', defaultMessage: 'This field must contain a special character' }),
    [ValidationRule.hasDigit]: $t({ id: 'validation.error.mustContainNumber', defaultMessage: 'This field must contain a number' }),
    [ValidationRule.validEmail]: $t({ id: 'validation.error.enterValidEmail', defaultMessage: 'Please enter a valid email address' }),
    [ValidationRule.validString]: $t({ id: 'validation.error.enterValidString', defaultMessage: 'Please use only letters and spaces' }),
    [ValidationRule.noSpecialChar]: $t({ id: 'validation.error.noSpecialCharacters', defaultMessage: 'Please use letters and numbers only' }),
  }), [$t]);

  /**
   *
   */
  const handleOnChange = React.useCallback((fieldName, fieldValue) => {
    const newValues = {
      ...values,
      [fieldName]: {
        value: fieldValue,
        touched: true,
      },
    };
    setValues(newValues);
    setHasChanges(true);
    const allTouched = Object.values(newValues).every((val) => val.touched === true);
    setAllFieldsTouched(allTouched);
  }, [values]);

  /**
   *
   */
  const checkValidation = React.useCallback((fieldName) => {
    const { value, touched } = values[fieldName];
    if (!touched) {
      return;
    }
    const { [fieldName]: rules } = fieldValidation;
    const errors = validateValue(value, rules, translations);
    setValidationErrors({
      ...validationErrors,
      [fieldName]: errors,
    });
  }, [fieldValidation, translations, validationErrors, values]);

  /**
   *
   */
  const hasValidationErrors = React.useCallback(() => {
    const formErrors = {};
    Object.entries(values).forEach(([fieldName]) => {
      const { value } = values[fieldName];
      const { [fieldName]: rules } = fieldValidation;
      const errors = validateValue(value, rules, translations);
      formErrors[fieldName] = errors;
    });
    setValidationErrors(formErrors);
    const totalErrors = countValidationErrors(formErrors);
    return totalErrors > 0;
  }, [fieldValidation, translations, values]);

  /**
   *
   */
  const resetFormState = React.useCallback(() => {
    setValidationErrors({});
    setAllFieldsTouched(false);
    setCanSubmitForm(false);
    setHasChanges(false);
  }, []);

  /**
   *
   */
  React.useEffect(() => {
    const totalErrors = countValidationErrors(validationErrors);
    setCanSubmitForm(totalErrors === 0 && allFieldsTouched);
  }, [allFieldsTouched, validationErrors]);

  /**
   *
   */
  return {
    values,
    handleOnChange,
    validationErrors,
    checkValidation,
    hasValidationErrors,
    canSubmitForm,
    resetFormState,
    hasChanges,
  };
}

export default useForm;
