import React from 'react';
import { StyleSheet } from 'react-native';
import { FadeIn } from '../../../components/Animation';
import { H1, P } from '../../../components/Text';
import OrderListItem from '../OrderListItem';
import { useLocale } from '../../../services/localization';

const styles = StyleSheet.create({
  subtitle: {
    fontSize: 16,
    marginBottom: 20,
    marginTop: 5,
  },
});

const fakeOrders = [
  {
    id: 1,
    customerName: 'Karl T Hulett',
    address: '4460 Eagle Drive',
    city: 'Monroe',
    totalItems: 1,
    opacity: 0.35,
  },
  {
    id: 2,
    customerName: 'Stacy L Carter',
    address: '612 Crummit Lane',
    city: 'Union',
    totalItems: 4,
    opacity: 0.2,
  },
  {
    id: 3,
    customerName: 'Mary E Minter',
    address: '4145 Dawson Drive',
    city: 'Little Rock',
    totalItems: 2,
    opacity: 0.1,
  },
];

function FakeOrderList({ style }) {
  const $t = useLocale();

  return (
    <FadeIn style={style}>
      <H1>{$t({ id: 'FakeOrderList.Orders.Heading', defaultMessage: 'Orders' })}</H1>
      <P style={styles.subtitle}>
        {$t({ id: 'FakeOrderList.Orders.Description', defaultMessage: 'Your orders will show up here after you connected your shop.' })}
      </P>
      {fakeOrders.map(
        ({
          id, customerName, address, city, totalItems, opacity,
        }) => (
          <OrderListItem
            key={`fake-order-${id}`}
            id={id}
            wooId={id}
            customerName={customerName}
            address={address}
            city={city}
            totalItems={totalItems}
            style={{ opacity }}
            disabled
          />
        ),
      )}
    </FadeIn>
  );
}

export default FakeOrderList;
