import React from 'react';
import { ActivityIndicator, StyleSheet } from 'react-native';
import Screen from './Layout';

const styles = StyleSheet.create({
  container: {
    margin: 15,
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
  },
});

function LoadingIndicator({ style }) {
  return (
    <Screen style={[styles.container, style]}>
      <ActivityIndicator size="large" />
    </Screen>
  );
}

export default LoadingIndicator;
