import React from 'react';
import PropTypes from 'prop-types';
import { View, Linking } from 'react-native';
import { P, Strong, A } from '../Text';
import Button from '../Button';
import Icon from '../Icon';
import { colors, links } from '../../constants';
import { useLocale } from '../../services/localization';
import styles from './FastPickerPlugin.styles';

const latestPluginVersion = '1.0.2';

function PluginIsInstalledText({ version }) {
  const $t = useLocale();
  return (
    <View style={styles.pluginInstalledContainer}>
      <View style={styles.pluginInstalledTextWrapper}>
        <Icon size={22} name="checkmark-circle-outline" color={colors.ui.ok} />
        <P style={styles.pluginInstalledText}>
          {$t({ id: 'FastPickerPlugin.PluginVersionInstalled.Text', defaultMessage: 'This shop has the FastPicker Wordpress plugin installed (v{version}).' }, { version })}
        </P>
      </View>
      <View style={styles.pluginInstalledTextWrapper}>
        <Icon size={22} name="rocket-outline" color={colors.ui.ok} />
        <P style={styles.pluginInstalledText}>
          {$t({ id: 'FastPickerPlugin.OrdersAreSyncedInRealtime.Text', defaultMessage: 'The orders from this shop will be synced to FastPicker in real-time.' })}
        </P>
      </View>
      <View style={[styles.pluginInstalledTextWrapper, styles.noBottomMargin]}>
        <Icon size={22} name="boat-outline" color={colors.ui.ok} />
        <P style={styles.pluginInstalledText}>
          {$t({ id: 'FastPickerPlugin.OrdersCanBeDeclared.Text', defaultMessage: 'Orders from this shop can be declared using one of the supported carriers.' })}
        </P>
      </View>
    </View>
  );
}

PluginIsInstalledText.propTypes = {
  version: PropTypes.string.isRequired,
};

function PluginNotInstalledText() {
  const $t = useLocale();
  return (
    <View style={styles.downloadPluginContainer}>
      <Icon size={22} name="information-circle" color={colors.brand.primary} />
      <View style={styles.downloadPluginTextWrapper}>
        <Strong style={styles.downloadPluginTitle}>
          {$t({ id: 'FastPickerPlugin.PluginNotInstalled.Title', defaultMessage: 'Looks like the FastPicker plugin is not installed on you WooCommerce webshop.' })}
        </Strong>
        <P style={styles.downloadPluginText}>
          {$t({ id: 'FastPickerPlugin.PluginNotInstalled.Description', defaultMessage: 'Please install the plugin if you want to sync orders in realtime (without plugin it takes about 5 minutes). Or if you would like to use one of our supported shipping providers.' })}
        </P>
        <Button
          onPress={() => Linking.openURL(links.FastPickerPluginStore)}
          style={styles.openLinkButton}
          label={$t({ id: 'FastPickerPlugin.DownloadPluginHere.Button.Label', defaultMessage: 'Download Plugin here' })}
          type="secondary"
          rightIcon={(
            <Icon
              size={22}
              name="exit-outline"
              color={colors.brand.primary}
              style={styles.openLinkIcon}
            />
          )}
        />
      </View>
    </View>
  );
}

function DownloadNewVersionText() {
  const $t = useLocale();
  return (
    <View style={styles.downloadPluginContainer}>
      <Icon size={22} name="information-circle" color={colors.brand.primary} />
      <View style={styles.downloadPluginTextWrapper}>
        <Strong style={styles.downloadPluginTitle}>
          {$t({ id: 'FastPickerPlugin.UpdateAvailable.Title', defaultMessage: 'Update available ({latestPluginVersion})' }, { latestPluginVersion })}
        </Strong>
        <P style={styles.downloadPluginText}>
          {$t({ id: 'FastPickerPlugin.UpdateAvailable.Description', defaultMessage: 'Please update your FastPicker plugin to be able to use the latest and greates new features.' })}
        </P>
        <Button
          onPress={() => Linking.openURL(links.FastPickerPluginStore)}
          style={styles.openLinkButton}
          label={$t({ id: 'FastPickerPlugin.DownloadNewVersionHere.Button.Label', defaultMessage: 'Download new version here' })}
          type="secondary"
          rightIcon={(
            <Icon
              size={22}
              name="exit-outline"
              color={colors.brand.primary}
              style={styles.openLinkIcon}
            />
          )}
        />
      </View>
    </View>
  );
}

function VisitPluginPage() {
  const $t = useLocale();
  return (
    <View style={styles.visitStoreWrapper}>
      <A onPress={() => Linking.openURL(links.FastPickerPluginStore)}>
        {$t({ id: 'FastPickerPlugin.VisitPluginStorePage.Link.Label', defaultMessage: 'Visit plugin store page' })}
      </A>
      <Icon
        size={19}
        name="exit-outline"
        color={colors.brand.primary}
        style={styles.visitStoreIcon}
      />
    </View>
  );
}

function FastPickerPlugin({ version }) {
  const $t = useLocale();

  function renderToggleLabel() {
    if (!version) {
      return <Strong style={styles.toggleLabel}>{$t({ id: 'FastPickerPlugin.Status.PluginNotInstalled', defaultMessage: 'Plugin not installed' })}</Strong>;
    }
    if (version !== latestPluginVersion) {
      return <Strong style={[styles.toggleLabel, styles.toggleLabelBlue]}>{$t({ id: 'FastPickerPlugin.Status.UpdateAvailable', defaultMessage: 'Update available' })}</Strong>;
    }
    return <Strong style={[styles.toggleLabel, styles.toggleLabelGreen]}>{$t({ id: 'FastPickerPlugin.Status.Installed', defaultMessage: 'Installed' })}</Strong>;
  }

  function renderText() {
    if (!version) {
      return <PluginNotInstalledText />;
    }
    return <PluginIsInstalledText version={version} />;
  }

  function renderUpdateAvailable() {
    if (version !== latestPluginVersion) {
      return <DownloadNewVersionText />;
    }
    return <VisitPluginPage />;
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleWrapper}>
        <Strong style={styles.providerName}>{$t({ id: 'FastPickerPlugin.Title', defaultMessage: 'FastPicker Wordpress Plugin' })}</Strong>
        <View style={styles.toggleWrapper}>
          {renderToggleLabel()}
        </View>
      </View>
      <View style={styles.seperator} />
      {renderText()}
      {version && (
        <>
          <View style={styles.seperator} />
          {renderUpdateAvailable()}
        </>
      )}
    </View>
  );
}

FastPickerPlugin.propTypes = {
  version: PropTypes.string.isRequired,
};

export default FastPickerPlugin;
