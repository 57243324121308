import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, View, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { mutationError } from '../../services/Error';
import { FadeIn } from '../Animation';
import {
  H1, P, A, Strong,
} from '../Text';
import Button from '../Button';
import useVerifyEmail from '../../hooks/useVerifyEmail';
import { useToastNotification } from '../../services/toast';
import { useLocale } from '../../services/localization';
import { colors } from '../../constants/theme';

const styles = StyleSheet.create({
  heading: {
    marginBottom: 30,
  },
  button: {
    marginVertical: 30,
  },
  text: {
    textAlign: 'center',
  },
  contentWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  spinner: {
    marginRight: 7,
  },
});

function link(str) {
  <A to="/resend-verify-email">{str}</A>;
}

function VerifyEmailForm({ token }) {
  const navigation = useNavigation();
  const [error, setError] = useState(false);
  const { toastSucces } = useToastNotification();
  const $t = useLocale();

  const { verifyEmail } = useVerifyEmail(
    token,
    () => {
      const msg = $t({
        id: 'VerifyEmailForm.EmailSuccessfullyVerified.Text',
        defaultMessage: 'Email successfully verified! You can now start using the order picker.',
      });
      toastSucces(msg);
    },
    (e) => setError(mutationError(e)),
  );

  useEffect(() => {
    setTimeout(() => verifyEmail(), 1500);
  }, [verifyEmail]);

  if (error) {
    return (
      <FadeIn>
        <H1 style={styles.heading}>
          {$t({ id: 'VerifyEmailForm.EmailAlreadyVerified.Title', defaultMessage: 'Email already verified or token is invalid' })}
        </H1>
        <P>
          {$t({ id: 'VerifyEmailForm.SeemsAlreadyVerified.Description', defaultMessage: 'It seems that this email is already verified or your verifcation token is invalid. You should be able to login.' })}
        </P>
        <Button
          style={styles.button}
          size="large"
          label={$t({ id: 'VerifyEmailForm.GoToLoginButton.Label', defaultMessage: 'Go to login' })}
          onPress={() => navigation.push('Login')}
        />
        <P style={styles.text}>
          {$t(
            {
              id: 'VerifyEmailForm.OrResendVerifyEmail.Text',
              defaultMessage: 'or <link>resend verification email</link>',
            },
            { link },
          )}
        </P>
      </FadeIn>
    );
  }

  return (
    <>
      <H1 style={styles.heading}>{$t({ id: 'VerifyEmailForm.Heading', defaultMessage: 'Verifying email' })}</H1>
      <View style={styles.contentWrapper}>
        <ActivityIndicator size="small" style={styles.spinner} color={colors.brand.primary} />
        <Strong>{$t({ id: 'VerifyEmailForm.OneMomentPlease.Text', defaultMessage: 'One moment please...' })}</Strong>
      </View>
    </>
  );
}

VerifyEmailForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default VerifyEmailForm;
