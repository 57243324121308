import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    color: '#000',
    padding: 10,
    borderRadius: 7,
    textDecorationLine: 'none',
    marginBottom: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  icon: {
    paddingRight: 15,
  },
  rightIcon: {
    paddingLeft: 20,
  },
  label: {
    flex: 1,
    fontSize: 16,
    textTransform: 'uppercase',
  },
});

export default styles;
