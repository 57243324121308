import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  user: {
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: 30,
  },
  badge: {
    marginTop: 7,
  },
  heading: {
    marginTop: 20,
    marginBottom: 15,
  },
  avatar: {
    color: '#3258C5',
    marginLeft: 3,
  },
});

export default styles;
