import React from 'react';
import PropTypes from 'prop-types';
import { View, ImageBackground, Linking } from 'react-native';
import { P, Strong } from '../Text';
import Button from '../Button';
import Icon from '../Icon';
import ToggleButton from '../Fields/ToggleButton';
import { assets, colors } from '../../constants/theme';
import { ShippingPluginName, ShippingPlugin } from '../../constants/shipping';
import useToggleShippingLabelPlugin from '../../hooks/shop/useToggleShippingPlugin';
import { useToastNotification } from '../../services/toast';
import { useLocale } from '../../services/localization';
import styles from './ShippingProvider.styles';

export const ProviderStatus = {
  Enabled: 'enabled',
  ReadyToEnable: 'readyToEnable',
  PluginNotInstalled: 'pluginNotInstalled',
};

const ProviderLogo = {
  [ShippingPlugin.PostNL]: <ImageBackground style={styles.providerLogo} resizeMode="contain" source={assets.images.postnl} />,
  [ShippingPlugin.MyParcel]: <ImageBackground style={styles.providerLogo} resizeMode="contain" source={assets.images.myparcel} />,
  [ShippingPlugin.KDZ]: <ImageBackground style={styles.providerLogo} resizeMode="contain" source={assets.images.kdz} />,
};

const ProviderPluginUrl = {
  [ShippingPlugin.PostNL]: 'https://nl.wordpress.org/plugins/woo-postnl/',
  [ShippingPlugin.MyParcel]: 'https://nl.wordpress.org/plugins/woocommerce-myparcel/',
};

function PluginIsInstalledText() {
  const $t = useLocale();
  return (
    <View style={styles.pluginInstalledContainer}>
      <Icon size={22} name="checkmark-circle" color={colors.ui.ok} />
      <P style={styles.pluginInstalledText}>{$t({ id: 'ShippingProvider.WordpressPluginNotInstalled.Text', defaultMessage: 'Wordpress plugin installed.' })}</P>
    </View>
  );
}

function DownloadPluginText({ provider }) {
  const $t = useLocale();
  const providerName = ShippingPluginName[provider];
  const url = ProviderPluginUrl[provider];
  return (
    <View style={styles.downloadPluginContainer}>
      <Icon size={22} name="information-circle" color={colors.brand.primary} />
      <View style={styles.downloadPluginTextWrapper}>
        <P style={styles.downloadPluginText}>
          {$t({ id: 'ShippingProvider.InstallWordpressPluginFirst.Text', defaultMessage: 'You need to install and setup the {providerName} Wordpress plugin before you can enable {providerName} as a shipping provider.' }, { providerName })}
        </P>
        {url && (
          <Button onPress={() => Linking.openURL(url)} style={styles.openLinkButton} label={$t({ id: 'ShippingProvider.DownloadPluginHere.Button.Label', defaultMessage: 'Download Plugin here' })} type="secondary" rightIcon={<Icon size={22} style={styles.openLinkIcon} name="exit-outline" color={colors.brand.primary} />} />
        )}
      </View>
    </View>
  );
}

DownloadPluginText.propTypes = {
  provider: PropTypes.string.isRequired,
};

function ShippingProvider({ provider, status, shopId }) {
  const $t = useLocale();
  const providerName = React.useMemo(() => ShippingPluginName[provider], [provider]);
  const { toastSucces, toastInfo, toastWarning } = useToastNotification();
  const { toggleShippingPlugin, loading } = useToggleShippingLabelPlugin(
    (data) => {
      const plugin = data.updateShop.shipping_fields.find((field) => field.plugin === provider);
      if (plugin.active) {
        toastSucces($t({ id: 'ShippingProvider.ProviderEnabled.Toast.Text', defaultMessage: 'We have enabled {providerName}. Happy picking!' }, { providerName }));
      } else {
        toastInfo($t({ id: 'ShippingProvider.ProviderDisabled.Toast.Text', defaultMessage: '{providerName} is disabled' }, { providerName }));
      }
    },
    () => {
      toastWarning($t({ id: 'ShippingProvider.SomethingWentWrong.Text', defaultMessage: 'Something went wrong. Please try again later or contact our support.' }));
    },
  );

  function renderStatusDescription() {
    if (status === ProviderStatus.ReadyToEnable || status === ProviderStatus.Enabled) {
      return <PluginIsInstalledText />;
    }
    if (status === ProviderStatus.PluginNotInstalled) {
      return <DownloadPluginText provider={provider} />;
    }
    return null;
  }

  function renderToggleLabel() {
    if (loading) {
      return <Strong style={styles.toggleLabel}>{$t({ id: 'ShippingProvider.StatusLabel.Loading', defaultMessage: 'Saving...' })}</Strong>;
    }
    if (status === ProviderStatus.Enabled) {
      return <Strong style={[styles.toggleLabel, styles.toggleLabelGreen]}>{$t({ id: 'ShippingProvider.StatusLabel.Enabled', defaultMessage: 'Enabled' })}</Strong>;
    }
    if (status === ProviderStatus.ReadyToEnable) {
      return <Strong style={styles.toggleLabel}>{$t({ id: 'ShippingProvider.StatusLabel.Disabled', defaultMessage: 'Disabled' })}</Strong>;
    }
    return <Strong style={styles.toggleLabel}>{$t({ id: 'ShippingProvider.StatusLabel.MissingPlugin', defaultMessage: 'Missing plugin' })}</Strong>;
  }

  const disableButton = ![ProviderStatus.ReadyToEnable, ProviderStatus.Enabled].includes(status);

  return (
    <View style={styles.container}>
      <View style={styles.titleWrapper}>
        <View style={styles.providerWrapper}>
          {ProviderLogo[provider]}
          <Strong style={styles.providerName}>{ShippingPluginName[provider]}</Strong>
        </View>
        <View style={styles.toggleWrapper}>
          {renderToggleLabel()}
          <ToggleButton
            checked={status === ProviderStatus.Enabled}
            onChange={() => {
              toggleShippingPlugin(shopId, provider, status !== ProviderStatus.Enabled);
            }}
            style={styles.toggleButton}
            disabled={disableButton}
          />
        </View>
      </View>
      <View style={styles.seperator} />
      {renderStatusDescription()}
    </View>
  );
}

ShippingProvider.propTypes = {
  provider: PropTypes.string.isRequired,
  status: PropTypes.string,
  shopId: PropTypes.string,
};

ShippingProvider.defaultProps = {
  status: '',
  shopId: undefined,
};

export default ShippingProvider;
