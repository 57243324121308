import React, { useRef, useEffect } from 'react';
import { Animated } from 'react-native';

export function FadeIn({ style, ...props }) {
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 400,
      useNativeDriver: false,
    }).start();
  }, [fadeAnim]);

  return <Animated.View style={[style, { opacity: fadeAnim }]} {...props} />;
}

export function FadeInOutPulse({ style = {}, ...props }) {
  const fadeAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(fadeAnim, {
          toValue: 0.3,
          duration: 1000,
          useNativeDriver: false,
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false,
        }),
      ]),
    ).start();
  }, [fadeAnim]);

  return <Animated.View style={[style, { opacity: fadeAnim }]} {...props} />;
}

export function FadeView({ style = {}, show = true, ...props }) {
  const fadeAnim = useRef(new Animated.Value(show ? 1 : 0)).current;

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: show ? 1 : 0,
      duration: 250,
      useNativeDriver: false,
    }).start();
  }, [show, fadeAnim]);

  return <Animated.View style={[style, { opacity: fadeAnim }]} {...props} />;
}
