import { useQuery } from '@apollo/client';
import { GET_USER } from '../graphql/UserQuery';

const useUser = (userId) => {
  const { loading, error, data } = useQuery(GET_USER, {
    variables: {
      userId,
    },
  });

  return { loading, error, data };
};

export default useUser;
