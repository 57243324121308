import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  downloadPluginAttention: {
    position: 'absolute',
    left: 0,
    top: 28,
    transform: [{ translateX: '-50%' }],
  },
  downloadPluginContainer: {
    padding: 20,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexShrink: 1,
    backgroundColor: '#E6EFFA',
    borderWidth: 1,
    borderColor: '#C3D7EE',
    marginBottom: 15,
  },
  downloadPluginTextWrapper: {
    flexShrink: 1,
    marginLeft: 10,
    marginTop: 3,
  },
  downloadPluginTitle: {
    lineHeight: 20,
    color: '#616E94',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  downloadPluginText: {
    marginTop: 10,
    lineHeight: 20,
    color: '#616E94',
  },
  openLinkButton: {
    alignSelf: 'flex-start',
    marginTop: 10,
  },
  openLinkIcon: {
    marginLeft: 7,
  },
});

export default styles;
