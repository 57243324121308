import { useMutation } from '@apollo/client';
import { GET_SHOPS, CREATE_SHOP, HAS_SHOP } from '../graphql/ShopQuery';
import { DefaultLanguage } from '../services/localization';

const useCreateShop = (shopFields, onCompleted, onError) => {
  const [runMutation, { loading, data }] = useMutation(CREATE_SHOP, {
    variables: { ...shopFields, language: DefaultLanguage },
    refetchQueries: [{ query: GET_SHOPS }, { query: HAS_SHOP }],
    onError(error) {
      if (onError) onError(error);
    },
    onCompleted(response) {
      if (onCompleted) onCompleted(response);
    },
  });

  const createShop = () => {
    if (loading) return;
    runMutation();
  };

  return { createShop, loading, data };
};

export default useCreateShop;
