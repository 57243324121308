import { StyleSheet } from 'react-native';
import { colors, fonts } from '../../constants/theme';

const styles = StyleSheet.create({
  button: {
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 13,
    paddingVertical: 10,
    marginBottom: 3,
  },
  activeButton: {
    backgroundColor: '#EAE9E9',
  },
  icon: {
    marginRight: 10,
  },
  label: {
    fontSize: 16,
    padddingLeft: 1,
  },
  activeLabel: {
    fontFamily: fonts.family.bold,
    color: colors.brand.primary,
  },
  subLabel: {
    fontSize: 11,
    textTransform: 'uppercase',
    marginTop: 3,
    color: '#B6B6B6',
  },
  activeSubLabel: {
    color: colors.ui.ok,
  },
  alertSubLabel: {
    color: colors.ui.attention,
  },
});

export default styles;
