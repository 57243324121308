import React from 'react';
import { View } from 'react-native';
import { FadeIn } from '../../components/Animation';
import Logo from '../../components/Logo';
import RegisterForm from './RegisterForm';
import styles from './RegisterScreen.styles';

function RegisterScreen() {
  return (
    <View style={styles.container}>
      <FadeIn style={styles.content}>
        <View style={styles.logoContainer}>
          <Logo />
        </View>
        <View style={styles.formContainer}>
          <RegisterForm />
        </View>
      </FadeIn>
    </View>
  );
}

export default RegisterScreen;
