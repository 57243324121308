import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  input: {
    marginBottom: 15,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 15,
  },
  note: {
    marginTop: 3,
    marginLeft: 3,
  },
});

export default styles;
