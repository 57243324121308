import 'react-native-gesture-handler';
import React from 'react';
import { View } from 'react-native';
import { useNetInfo } from '@react-native-community/netinfo';
import { StatusBar } from 'expo-status-bar';
import { LocalizationProvider } from './services/localization';
import AuthProvider from './services/auth';
import NotificationsProvider from './state/NotificationsProvider';
import ApolloProvider from './services/apollo';
import Router from './navigation/Router';
import LoadingIndicator from './components/LoadingIndicator';
import NoInternetWarning from './components/NoInternetWarning';
import useLoadResources from './hooks/useLoadResources';
import GetSupportMenu from './features/GetSupportMenu';
import SubscribeToNewsletterPopup from './features/SubscribeToNewsletterPopup';
import ToastProvider from './services/toast';

function AppLoader() {
  return <View style={{ flex: 1, justifyContent: 'center', alignContent: 'center' }}><LoadingIndicator /></View>;
}

export default function App() {
  const netInfo = useNetInfo();
  const [ready, setReady] = React.useState({ auth: false, resources: false, notifications: false });

  const onReadyAuthProvider = React.useCallback(() => setReady((s) => ({ ...s, auth: true })), []);
  const onReadyNotificationsProvider = React.useCallback(() => setReady((s) => ({ ...s, notifications: true })), []);
  const onReadyResources = React.useCallback(() => setReady((s) => ({ ...s, resources: true })), []);

  useLoadResources(onReadyResources);

  return (
    <AuthProvider onReady={onReadyAuthProvider}>
      <NotificationsProvider onReady={onReadyNotificationsProvider}>
        {(!ready.resources || !ready.auth) ? <AppLoader /> : (
          <ApolloProvider>
            <LocalizationProvider>
              {!netInfo.isConnected ? <NoInternetWarning /> : (
                <>
                  <Router />
                  <GetSupportMenu />
                  <ToastProvider />
                  <SubscribeToNewsletterPopup />
                </>
              )}
            </LocalizationProvider>
            <StatusBar style="dark" />
          </ApolloProvider>
        )}
      </NotificationsProvider>
    </AuthProvider>
  );
}
