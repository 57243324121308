import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import Icon from '../Icon';
import { Strong } from '../Text';
import { useLocale } from '../../services/localization';
import styles from './RoleBadge.styles';
import { Roles } from '../../services/permissions';

function GodBadge({ style }) {
  const $t = useLocale();
  return (
    <View style={[styles.badge, styles.god, style]}>
      <Icon style={styles.icon} name="star" size={12} />
      <Strong style={[styles.label, styles.god]}>
        {$t({ id: 'RoleBadge.Admin', defaultMessage: 'God' })}
      </Strong>
    </View>
  );
}

function StoreOwnerBadge({ style }) {
  const $t = useLocale();
  return (
    <View style={[styles.badge, styles.owner, style]}>
      <Icon style={styles.icon} name="cart" size={12} color="#e5ecff" />
      <Strong style={[styles.label, styles.ownerLabel]}>
        {$t({ id: 'RoleBadge.ShopOwner', defaultMessage: 'Shop Owner' })}
      </Strong>
    </View>
  );
}

function PickerBadge({ style }) {
  const $t = useLocale();
  return (
    <View style={[styles.badge, styles.picker, style]}>
      <Strong style={[styles.label, styles.pickerLabel]} color="#6a6a6a">
        {$t({ id: 'RoleBadge.Picker', defaultMessage: 'Picker' })}
      </Strong>
    </View>
  );
}

function RoleBadge({ style, type }) {
  const badges = {
    [Roles.Admin]: <GodBadge style={style} />,
    [Roles.OrderPicker]: <PickerBadge style={style} />,
    [Roles.StoreOwner]: <StoreOwnerBadge style={style} />,
  };
  return badges[type] || null;
}

RoleBadge.propTypes = {
  type: PropTypes.oneOf(Object.values(Roles)).isRequired,
};

export default RoleBadge;
