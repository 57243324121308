import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { H1 } from './Text';
import Button from './Button';

const styles = StyleSheet.create({
  heading: {
    flexDirection: 'row',
    alignContent: 'space-between',
    alignItems: 'center',
    marginBottom: 25,
    borderBottomColor: '#dfdfdf',
    borderBottomWidth: 1,
    paddingBottom: 25,
  },
  title: {
    flex: 1,
  },
});

function ScreenHeading({ style, ...props }) {
  return (
    <View style={[styles.heading, style]}>
      <H1 style={styles.title}>{props.title}</H1>
      {props.buttonLabel && (
      <Button
        label={props.buttonLabel}
        size="small"
        onPress={props.buttonAction}
      />
      )}
    </View>
  );
}

ScreenHeading.propTypes = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonAction: PropTypes.func,
};

ScreenHeading.defaultProps = {
  title: '',
  buttonLabel: undefined,
  buttonAction: undefined,
};

export default ScreenHeading;
