import React from 'react';
import { View } from 'react-native';
import styles from './ModalFooter.styles';

function Footer({ children }) { // @TODO: fix missing prop type
  return (
    <View style={styles.footer}>
      {children}
    </View>
  );
}

export default Footer;
