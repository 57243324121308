import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { mutationError } from '../../services/Error';
import Screen from '../../components/Layout';
import { FadeIn } from '../../components/Animation';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ErrorNotification } from '../../components/ErrorNotification';
import ScreenHeading from '../../components/ScreenHeading';
import { P } from '../../components/Text';
import { TextField, EmailField } from '../../components/Form';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import usePicker from '../../hooks/usePicker';
import useUpdatePicker from '../../hooks/useUpdatePicker';
import useDeletePicker from '../../hooks/useDeletePicker';
import { useToastNotification } from '../../services/toast';
import { useLocale } from '../../services/localization';
import styles from './EditPickerScreen.styles';

const initialFields = {
  name: '',
  email: '',
};

function EditPickerScreen({ route, navigation }) {
  const { pickerId } = route.params;
  const { loading, error, data: response } = usePicker(pickerId);
  const [fields, setFields] = useState(initialFields);
  const [variables, setVariables] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState(false);
  const { toastSucces, toastInfo } = useToastNotification();
  const $t = useLocale();

  const handleInputChange = (e, fieldName) => {
    if (!hasChanges && fields[fieldName] !== e.target.value) setHasChanges(true);
    setFields({
      ...fields,
      [fieldName]: e.target.value,
    });
  };

  // Only set variables for query that have content
  // to prevent sending things like empty password field
  useEffect(() => {
    const vars = {};
    Object.keys(initialFields).forEach((key) => {
      if (!fields[key]) return;
      vars[key] = fields[key];
    });
    setVariables(vars);
  }, [fields]);

  useEffect(() => {
    if (!response) return;

    setFields({
      ...initialFields,
      ...response.picker,
    });
  }, [response]);

  const { updatePicker, loading: isSaving } = useUpdatePicker(
    {
      pickerId,
      ...variables,
    },
    () => {
      navigation.navigate('Pickers');
      const msg = $t(
        {
          id: 'EditPickerScreen.PickerUpdated.Text',
          defaultMessage: 'Picker {name} updated',
        },
        { name: fields.name },
      );
      toastSucces(msg);
    },
    (e) => setErrors(mutationError(e)),
  );

  const { deletePicker } = useDeletePicker(pickerId, () => {
    navigation.navigate('Pickers');
    const msg = $t(
      {
        id: 'EditPickerScreen.PickerRemoved.Text',
        defaultMessage: 'Picker {name} removed',
      },
      { name: fields.name },
    );
    toastInfo(msg);
  });

  if (loading) return <LoadingIndicator />;
  if (!response) {
    return (
      <ErrorNotification
        errorTitle={$t({ id: 'EditPickerScreen.PickerNotFound.Title', defaultMessage: 'Picker not found' })}
        errorMessage={$t({ id: 'EditPickerScreen.PickerNotFound.Description', defaultMessage: 'Sorry, it seems that you\'re looking for a picker that does not exists.' })}
        buttonLabel={$t({ id: 'EditPickerScreen.BackToPickersButton.Label', defaultMessage: 'Back to pickers' })}
        buttonAction={() => navigation.navigate('Pickers')}
      />
    );
  }
  if (error) {
    return (
      <ErrorNotification
        errorMessage={mutationError(error)}
        buttonLabel={$t({ id: 'EditPickerScreen.BackToPickersButton.Label', defaultMessage: 'Back to pickers' })}
        buttonAction={() => navigation.navigate('Pickers')}
      />
    );
  }
  return (
    <Screen size="medium">
      <FadeIn>
        <ScreenHeading title={
          $t({ id: 'EditPickerScreen.Heading', defaultMessage: 'Edit picker {name}' }, { name: response.picker.name })
        }
        />
        <TextField
          style={styles.input}
          label={$t({ id: 'EditPickerScreen.NameField.Label', defaultMessage: 'Picker name' })}
          placeholder={$t({ id: 'EditPickerScreen.NameField.Placeholder', defaultMessage: 'John Picker' })}
          value={fields.name}
          onChange={(e) => handleInputChange(e, 'name')}
        />
        <EmailField
          style={styles.input}
          label={$t({ id: 'EditPickerScreen.EmailField.Label', defaultMessage: 'Email address' })}
          placeholder={$t({ id: 'EditPickerScreen.EmailField.Placeholder', defaultMessage: 'name@youremail.com' })}
          value={fields.email}
          disabled
        />
        <View style={styles.actionsContainer}>
          <View style={styles.actionsSecondaryActions}>
            <P style={styles.deleteButton} onPress={() => deletePicker()}>
              {$t({ id: 'EditPickerScreen.RemovePickerButton.Label', defaultMessage: 'Remove picker' })}
            </P>
          </View>
          <Button
            label={
              hasChanges
                ? $t({ id: 'EditPickerScreen.SavePickerButton.Label', defaultMessage: 'Save' })
                : $t({ id: 'EditPickerScreen.NothingToSave.Text', defaultMessage: 'Nothing to save' })
              }
            size="small"
            onPress={updatePicker}
            loading={isSaving}
            disabled={!hasChanges || isSaving}
          />
        </View>
        {errors && <Alert text={errors} />}
      </FadeIn>
    </Screen>
  );
}

export default EditPickerScreen;
