import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useMutation, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { SelectField } from '../Form';
import { useLocale } from '../../services/localization';
import Button from '../Button';
import { ValidationRule } from '../../services/Validation';
import { isShopConnectionError } from '../../services/Helpers';
import { useToastNotification } from '../../services/toast';
import useForm from '../../hooks/form';

const styles = StyleSheet.create({
  input: {
    marginBottom: 15,
    flex: 1,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 15,
  },
});

const Validation = {
  import_order_status: [ValidationRule.validString],
  completed_order_status: [ValidationRule.validString],
};

const UPDATE_IMPORT_STATUS_MUTATION = gql`
  mutation (
    $shopId: ID!
    $import_order_status: String
    $completed_order_status: String
  ) {
    updateShop(
      input: {
        id: $shopId
        import_order_status: $import_order_status
        completed_order_status: $completed_order_status
      }
    ) {
      id
      import_order_status
      completed_order_status
      woocommerce_order_statuses {
        slug
        name
      }
    }
  }
`;

function ImportStatusForm({
  shopId, shopStatus, statusOptions, importOrderStatus, completedOrderStatus,
}) {
  const $t = useLocale();
  const { toastSucces, toastWarning } = useToastNotification();

  const InitialFields = {
    import_order_status: importOrderStatus,
    completed_order_status: completedOrderStatus,
  };

  const {
    values, handleOnChange, hasChanges, resetFormState,
  } = useForm(InitialFields, Validation);

  const [save, { loading }] = useMutation(UPDATE_IMPORT_STATUS_MUTATION, {
    variables: {
      shopId,
      import_order_status: values.import_order_status.value,
      completed_order_status: values.completed_order_status.value,
    },
    onError() {
      toastWarning('Something went wrong. Please try again later.');
    },
    onCompleted() {
      resetFormState();
      toastSucces('Order status settings saved!');
    },
  });

  const options = React.useMemo(() => {
    if (!statusOptions) {
      return [];
    }
    const orderStatuses = [];
    statusOptions.forEach((status) => {
      orderStatuses.push({
        value: status.slug,
        label: `${status.name} (${status.slug})`,
      });
    });
    return orderStatuses;
  }, [statusOptions]);

  const canEdit = isShopConnectionError(shopStatus);

  return (
    <>
      <View>
        <SelectField
          style={styles.input}
          label="Choose which status is used to import a new order from Woo to FastPicker"
          items={options}
          selectedValue={values.import_order_status.value || ''}
          onValueChange={(val) => handleOnChange('import_order_status', val.target.value)}
          disabled={!canEdit}
        />
        <SelectField
          style={styles.input}
          label="Set the status to which we change the order after picking is completed"
          items={options}
          selectedValue={values.completed_order_status.value || ''}
          onValueChange={(val) => handleOnChange('completed_order_status', val.target.value)}
          disabled={!canEdit}
        />
      </View>
      <View style={styles.actions}>
        <Button
          label={hasChanges
            ? $t({ id: 'EditShopScreen.SaveButton.Label', defaultMessage: 'Save' })
            : $t({ id: 'EditShopScreen.NothingToSave.Text', defaultMessage: 'Nothing to save' })}
          size="small"
          onPress={save}
          loading={loading}
          disabled={!hasChanges || loading || !canEdit}
        />
      </View>
    </>
  );
}

ImportStatusForm.propTypes = {
  shopId: PropTypes.string.isRequired,
  shopStatus: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  statusOptions: PropTypes.array, // @TODO - Improve typing
  importOrderStatus: PropTypes.string,
  completedOrderStatus: PropTypes.string,
};

ImportStatusForm.defaultProps = {
  statusOptions: [],
  importOrderStatus: '',
  completedOrderStatus: '',
};

export default ImportStatusForm;
