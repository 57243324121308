import { useQuery, gql } from '@apollo/client';

export const GET_SHOP_INFO = gql`
  query ($shopId: ID!) {
    shop(id: $shopId) {
      id
      name
      url
      logo
      barcode_postmeta_key
      address
      address_2
      city
      postcode
      country
      pdf_footer_text
      language
      status
    }
  }
`;

const useShopInfo = (shopId) => {
  const { loading, error, data } = useQuery(GET_SHOP_INFO, {
    variables: {
      shopId,
    },
  });

  return { loading, error, data };
};

export default useShopInfo;
