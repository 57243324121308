import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, gql } from '@apollo/client';
import styles from './PrintPackingSlipButton.styles';
import { useLocale } from '../../../services/localization';
import { useToastNotification } from '../../../services/toast';
import QuickActionButton from '../../../components/QuickActionButton';
import usePrint from '../../../hooks/usePrint';

export const GET_PACKING_SLIP_URL = gql`
  query GetPackageSlipUrl($orderId: ID!) {
    order(id: $orderId) {
      id
      packing_slip
    }
  }
`;

function PrintPackingSlipButton({ orderId, highlight }) {
  const $t = useLocale();
  const { toastWarning } = useToastNotification();
  const { print, state } = usePrint();

  const [getPackageSlip, { loading }] = useLazyQuery(GET_PACKING_SLIP_URL, {
    variables: {
      orderId,
    },
    onCompleted(data) {
      const options = {
        ref: `packing_slip_${orderId}`,
        documentTitle: $t({
          id: 'PickOrderScreen.PackingSlipDocument.Title',
          defaultMessage: 'Package slip - Order #{orderId}',
        }, {
          orderId,
        }),
      };
      print(data.order.packing_slip, options);
    },
    onError() {
      toastWarning($t({ id: 'PrintPackingSlipButton.FailedToGetPackingSlip.Text', defaultMessage: 'Failed to get packing slip.' }));
    },
  });

  return (
    <QuickActionButton
      style={styles.printPackingSlip}
      label={$t({ id: 'PrintPackingSlipButton.Label', defaultMessage: 'Print packing slip' })}
      leftIcon={loading || state.printing ? '' : 'print'}
      onPress={getPackageSlip}
      loading={loading || state.printing}
      highlight={highlight}
    />
  );
}

PrintPackingSlipButton.propTypes = {
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  highlight: PropTypes.bool,
};

PrintPackingSlipButton.defaultProps = {
  highlight: false,
};

export default PrintPackingSlipButton;
