import React from 'react';
import Toast from 'react-native-toast-message';

export const ToastType = {
  Success: 'success',
  Info: 'info',
  Warning: 'warning',
};

const options = {
  position: 'top',
  visibilityTime: 5000,
  topOffset: 40,
};

export const useToastNotification = () => {
  const toastSucces = React.useCallback((text1) => {
    Toast.show({
      ...options,
      text1,
      type: ToastType.Success,
    });
  }, []);

  const toastInfo = React.useCallback((text1) => {
    Toast.show({
      ...options,
      text1,
      type: ToastType.Info,
    });
  }, []);

  const toastWarning = React.useCallback((text1) => {
    Toast.show({
      ...options,
      text1,
      type: ToastType.Warning,
    });
  }, []);

  return { toastSucces, toastInfo, toastWarning };
};
