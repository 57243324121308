import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { ShippingLabelStatus } from '../../../constants';
import { ErrorNotification } from '../../../components/ErrorNotification';
import { useLocale } from '../../../services/localization';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
});

export const GET_SHIPPING_STATUS = gql`
  query order($orderId: ID!) {
    order(id: $orderId) {
      id
      shipping_label_status
    }
  }
`;

function Declaring({ orderId, onCompleted, onError }) {
  const $t = useLocale();
  const { data } = useQuery(
    GET_SHIPPING_STATUS,
    {
      variables: {
        orderId,
      },
    },
  );

  const translations = React.useMemo(() => ({
    heading: $t({ id: 'ShippingModal.Declaring.DeclaringOrder', defaultMessage: 'Declaring order' }),
    description: $t({ id: 'ShippingModal.Declaring.OneMoment', defaultMessage: 'One moment, we are declaring the order for you.' }),
  }), [$t]);

  React.useEffect(() => {
    if (!data) {
      return;
    }
    if (data?.order?.shipping_label_status === ShippingLabelStatus.DECLARED) {
      onCompleted();
      return;
    }
    const errorStates = [
      ShippingLabelStatus.FAILED_TO_DECLARE,
      ShippingLabelStatus.NOT_DECLARED,
      ShippingLabelStatus.NOT_SUPPORTED,
    ];
    if (errorStates.includes(data?.order?.shipping_label_status)) {
      onError();
    }
  }, [data, onCompleted, onError]);

  return (
    <ErrorNotification
      style={styles.container}
      icon="loader"
      errorTitle={translations.heading}
      errorMessage={translations.description}
    />
  );
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

Declaring.propTypes = {
  orderId: IdType.isRequired,
  onCompleted: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Declaring;
