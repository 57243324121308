import { useMutation } from '@apollo/client';
import { RESEND_VERIFY_EMAIL } from '../../graphql/AuthQuery';

const useResendVerifyEmail = (email, onCompleted, onError) => {
  const [resendVerifyEmail, { loading }] = useMutation(RESEND_VERIFY_EMAIL, {
    variables: { email },
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted() {
      if (typeof onCompleted === 'function') onCompleted();
    },
  });

  return { resendVerifyEmail, loading };
};

export default useResendVerifyEmail;
