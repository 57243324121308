import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: 100,
    marginRight: 15,
    marginBottom: 15,
    borderRadius: 10,
    overflow: 'hidden',
    backgroundColor: '#E1E1E1',
  },
  state: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#E1E1E1',
    height: 100,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: 100,
    height: 100,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
});

export default styles;
