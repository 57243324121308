import { StyleSheet, Dimensions } from 'react-native';

const smallDevice = Dimensions.get('window').width < 1024;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 5,
  },
  firstCell: {
    fontFamily: 'Roboto bold',
    paddingLeft: 15,
  },
  orderInfo: {
    flex: 3,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingVertical: 5,
  },
  cell: {
    flex: 1,
    paddingHorizontal: smallDevice ? 10 : 30,
  },
  name: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto bold',
    textAlign: 'left',
    paddingBottom: 2,
  },
  address: {
    color: '#717171', // @TODO: add color to theme
  },
  items: {
    color: '#717171',
    textTransform: 'uppercase',
    textAlign: 'right',
  },
});

export default styles;
