import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { pickProgress } from '../../services/Helpers';
import { OrderStatus } from '../../constants/order';

const GET_ORDERS = gql`
  query GetFirstTenOrders {
    orders(first: 10, page: 1, status: ["new", "being_picked"]) {
      data {
        id
        woocommerce_order_id
        shipping_label_status
        status
        order_lines {
          id
          picked
          quantity
        }
      }
    }
  }
`;

const ORDER_SUBSCRIPTION = gql`
  subscription OnOrder {
    order {
      id
      woocommerce_order_id
      shipping_first_name
      shipping_last_name
      shipping_address_1
      shipping_address_2
      shipping_city
      status
      shipping_label_status
      order_lines {
        id
        picked
        quantity
      }
      shipping_label
      shipping_provider
      shipping_failed_reason
    }
  }
`;

function isCompletedStatus(status) {
  return [OrderStatus.Completing, OrderStatus.Completed].includes(status);
}

export function useOrderShortcutButtons() {
  const { loading, error, data } = useQuery(GET_ORDERS);

  const shortcutButtons = React.useMemo(() => {
    if (!data || !data?.orders?.data || !data?.orders?.data.length === 0) {
      return {};
    }

    const nextOrder = data?.orders?.data?.find((item) => {
      const progress = pickProgress(item.order_lines);
      return progress.progressPercentage === 0;
    });

    const inProgressOrder = data?.orders?.data?.find((item) => {
      const progress = pickProgress(item.order_lines);
      return progress.progressPercentage > 0 && !isCompletedStatus(item.status);
    });

    return {
      nextOrder,
      inProgressOrder,
    };
  }, [data]);

  return {
    loadingShortcuts: loading,
    shortcuts: shortcutButtons,
    hasShortcuts: shortcutButtons?.nextOrder || shortcutButtons?.inProgressOrder,
    shortcutButtonsError: error,
  };
}

export function useLoadMore(fetchMore) {
  const [isFetchingMore, setIsFetchingMore] = React.useState(false);

  function fetchMoreOrders(variables) {
    setIsFetchingMore(true);
    fetchMore({
      variables,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        setIsFetchingMore(false);

        // return if there are no results
        if (!fetchMoreResult) return previousResult;

        // merge incoming with current cache
        return {
          orders: {
            __typename: 'OrderPaginator',
            paginatorInfo: {
              count: previousResult.orders.data.length + fetchMoreResult.orders.data.length,
              currentPage: fetchMoreResult.orders.paginatorInfo.currentPage,
              total: fetchMoreResult.orders.paginatorInfo.total,
            },
            data: [
              ...previousResult.orders.data,
              ...fetchMoreResult.orders.data,
            ],
          },
        };
      },
    });
  }

  return { isFetchingMore, fetchMoreOrders };
}

export function useNewOrdersSubscription(subscribeToMore, itemsPerPage) {
  React.useEffect(() => {
    subscribeToMore({
      document: ORDER_SUBSCRIPTION,
      updateQuery: (cache, { subscriptionData }) => {
        // return cache if incoming data is empty
        if (!subscriptionData.data) return cache;

        // return is there is no space left in the list based on items per page
        const isThereRoomInList = cache.orders.paginatorInfo.count === 0 || cache.orders.paginatorInfo.count % itemsPerPage > 0;
        if (!isThereRoomInList) {
          return {
            ...cache,
            orders: {
              ...cache.orders,
              paginatorInfo: {
                ...cache.orders.paginatorInfo,
                total: cache.orders.paginatorInfo.total + 1, // update total as list is full but there are more items available
              },
            },
          };
        }

        const { order } = subscriptionData.data;

        // check if order is not already in the list
        const alreadyInList = cache.orders.data.some((item) => item.id === order.id);
        const isCompleted = [OrderStatus.Completed, OrderStatus.Completing].includes(order.status);
        if (alreadyInList || isCompleted) {
          return cache;
        }

        // update cache and return new array
        return {
          ...cache,
          orders: {
            ...cache.orders,
            paginatorInfo: {
              ...cache.orders.paginatorInfo,
              count: cache.orders.paginatorInfo.count + 1,
              total: cache.orders.paginatorInfo.total + 1,
            },
            data: [...cache.orders.data, order],
          },
        };
      },
    });
  }, [itemsPerPage, subscribeToMore]);
}
