import { StyleSheet } from 'react-native';
import { fonts } from '../../../constants';

export default StyleSheet.create({
  container: {
    padding: 40,
    minHeight: 322, // height of connect form state
  },
  textHeader: {
    fontSize: 18,
    marginBottom: 20,
    marginTop: 10,
  },
  text: {
    fontSize: fonts.size.label,
    lineHeight: 26,
    marginBottom: 20,
  },
  carriersContainer: {
    marginBottom: 20,
  },
  filler: {
    flex: 1,
  },
});
