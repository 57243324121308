import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  wizard: {
    marginBottom: 30,
  },
  quickActionsContainer: {
    flexDirection: 'row',
    marginBottom: 40,
  },
  actionSpacer: {
    width: 20,
    height: 20,
  },
  loadMoreButton: {
    borderWidth: 1,
    borderColor: '#D8D8D8',
    alignItems: 'center',
    height: 53,
    borderRadius: 10,
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 20,
  },
  listHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listFilters: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggleCompletedLabel: {
    marginRight: 10,
    textTransform: 'uppercase',
    fontSize: 12,
  },
  subtitle: {
    fontSize: 16,
    marginTop: 5,
    marginBottom: 20,
  },
  bold: {
    fontFamily: 'Roboto bold',
    fontSize: 16,
  },
  refetchSpinner: {
    margin: 50,
  },
  note: {
    marginTop: 40,
  },
});

export default styles;
