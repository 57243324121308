import React from 'react';
import { StyleSheet, View } from 'react-native';

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    backgroundColor: '#000',
    opacity: 0.2,
    ...StyleSheet.absoluteFillObject,
  },
});

function Backdrop() {
  return <View style={styles.backdrop} />;
}

export default Backdrop;
