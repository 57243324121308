import React from 'react';
import PropTypes from 'prop-types';
import QuickActionButton from '../../../components/QuickActionButton';
import {
  ShippingPlugin, ShippingPluginName, ShippingLabelStatus, colors,
} from '../../../constants';
import { useLocale } from '../../../services/localization';
import usePrint from '../../../hooks/usePrint';
import styles from './DeclareForShippingButton.styles';
import { forceHttps } from '../../../services/Helpers';

export const ShippingButtonState = {
  NOT_SUPPORTED: 'NOT_SUPPORTED',
  DISABLED: 'DISABLED',
  READY_TO_DECLARE: 'READY_TO_DECLARE',
  DECLARING: 'DECLARING',
  DECLARED: 'DECLARED',
  DECLARED_NO_LABEL: 'DECLARED_NO_LABEL',
  PRINTING_LABEL: 'PRINTING_LABEL',
  FAILED_TO_DECLARE: 'FAILED_TO_DECLARE',
};

function DeclareForShippingButton({
  shippingStatus, labelUrl, provider, pickingCompleted, canDeclare, onPress,
}) {
  const $t = useLocale();
  const providerName = ShippingPluginName[provider] || '';
  const { print, state: printState } = usePrint();

  const translations = React.useMemo(() => ({
    noProvidersSetup: $t({ id: 'DeclareForShippingButton.NoProvidersSetup', defaultMessage: 'No providers setup' }),
    declareForShipping: $t({ id: 'DeclareForShippingButton.DeclareForShipping', defaultMessage: 'Declare for shipping' }),
    declaring: $t({ id: 'DeclareForShippingButton.Declaring', defaultMessage: 'Declaring order' }),
    declaringWith: $t({ id: 'DeclareForShippingButton.DeclaringWith', defaultMessage: 'Declaring with' }),
    declaredWith: $t({ id: 'DeclareForShippingButton.DeclaredWith', defaultMessage: 'Declared with' }),
    declaredWithProvider: $t({ id: 'DeclareForShippingButton.DeclareWithProvider', defaultMessage: 'Declared with {provider}' }, { provider: providerName }),
    printShippingLabel: $t({ id: 'DeclareForShippingButton.PrintShippingLabel', defaultMessage: 'Print shipping label' }),
    printingShippingLabel: $t({ id: 'DeclareForShippingButton.PrintingShippingLabel', defaultMessage: 'Printing shipping label' }),
    failedToDeclareWith: $t({ id: 'DeclareForShippingButton.FailedToDeclareWith', defaultMessage: 'Failed to declare with {provider}' }, { provider: providerName }),
    tryAgain: $t({ id: 'DeclareForShippingButton.TryAgain', defaultMessage: 'Try again' }),
  }), [$t, providerName]);

  const state = React.useMemo(() => {
    // Can declare but ignore this state when order is already declared
    const isDeclaredButCanNotDeclare = !canDeclare && (
      [ShippingLabelStatus.DECLARED, ShippingLabelStatus.FAILED_TO_DECLARE, ShippingLabelStatus.DECLARING].includes(shippingStatus)
    );
    if (!canDeclare && !isDeclaredButCanNotDeclare) {
      return ShippingButtonState.NOT_SUPPORTED;
    }
    // Order not fully picked yet
    if (shippingStatus === ShippingLabelStatus.NOT_DECLARED && !pickingCompleted) {
      return ShippingButtonState.DISABLED;
    }
    // Order picked and providers available
    if (shippingStatus === ShippingLabelStatus.NOT_DECLARED && pickingCompleted) {
      return ShippingButtonState.READY_TO_DECLARE;
    }
    // Is declaring
    if (shippingStatus === ShippingLabelStatus.DECLARING) {
      return ShippingButtonState.DECLARING;
    }
    // Is declared and printing label
    if (shippingStatus === ShippingLabelStatus.DECLARED && printState.printing) {
      return ShippingButtonState.PRINTING_LABEL;
    }
    // Is declared but has no label (kdz)
    if (shippingStatus === ShippingLabelStatus.DECLARED && !labelUrl) {
      return ShippingButtonState.DECLARED_NO_LABEL;
    }
    if (shippingStatus === ShippingLabelStatus.DECLARED) {
      return ShippingButtonState.DECLARED;
    }
    // Is error
    if (shippingStatus === ShippingLabelStatus.FAILED_TO_DECLARE) {
      return ShippingButtonState.FAILED_TO_DECLARE;
    }
    return null;
  }, [canDeclare, labelUrl, pickingCompleted, printState.printing, shippingStatus]);

  const printLabel = () => {
    if (printState.printing) return;
    const options = {
      ref: 'shipping_label',
    };
    const url = forceHttps(labelUrl);
    print(url, options);
  };

  if (state === ShippingButtonState.NOT_SUPPORTED) {
    return (
      <QuickActionButton
        style={styles.button}
        label={translations.noProvidersSetup}
        leftIcon="boat-outline"
        disabled
      />
    );
  }

  if (state === ShippingButtonState.DISABLED) {
    return (
      <QuickActionButton
        style={styles.button}
        label={translations.declareForShipping}
        leftIcon="boat"
        disabled
      />
    );
  }

  if (state === ShippingButtonState.READY_TO_DECLARE) {
    return (
      <QuickActionButton
        style={styles.button}
        label={translations.declareForShipping}
        leftIcon="boat"
        focus
        onPress={onPress}
      />
    );
  }

  if (state === ShippingButtonState.DECLARING) {
    return (
      <QuickActionButton
        style={styles.button}
        label={providerName ? translations.declaringWith : translations.declaring}
        subLabel={providerName}
        loading
        focus
        onPress={onPress}
      />
    );
  }

  if (state === ShippingButtonState.DECLARED_NO_LABEL) {
    return (
      <QuickActionButton
        style={styles.button}
        label={translations.declaredWith}
        subLabel={providerName}
        leftIcon="checkmark-circle-outline"
        leftIconColor={colors.ui.ok}
      />
    );
  }

  if (state === ShippingButtonState.DECLARED) {
    return (
      <QuickActionButton
        style={styles.button}
        label={translations.printShippingLabel}
        subLabel={translations.declaredWithProvider}
        leftIcon="print"
        onPress={printLabel}
      />
    );
  }

  if (state === ShippingButtonState.PRINTING_LABEL) {
    return (
      <QuickActionButton
        style={styles.button}
        label={translations.printingShippingLabel}
        loading
      />
    );
  }

  if (state === ShippingButtonState.FAILED_TO_DECLARE) {
    return (
      <QuickActionButton
        style={styles.button}
        label={translations.failedToDeclareWith}
        subLabel={translations.tryAgain}
        leftIcon="alert-circle"
        leftIconSize={35}
        leftIconColor={colors.ui.attention}
        onPress={onPress}
      />
    );
  }

  return null;
}

DeclareForShippingButton.propTypes = {
  shippingStatus: PropTypes.oneOf(Object.values(ShippingLabelStatus)).isRequired,
  labelUrl: PropTypes.string,
  provider: PropTypes.oneOf(Object.values(ShippingPlugin)),
  pickingCompleted: PropTypes.bool,
  canDeclare: PropTypes.bool,
  onPress: PropTypes.func,
};

DeclareForShippingButton.defaultProps = {
  labelUrl: null,
  provider: null,
  pickingCompleted: undefined,
  canDeclare: undefined,
  onPress: undefined,
};

export default DeclareForShippingButton;
