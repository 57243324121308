import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderRadius: 7,
    textDecorationLine: 'none',
    backgroundColor: '#f1f1f1',
    textAlign: 'left',
    borderWidth: 1,
    borderColor: '#e1e1e1',
  },
  toggleViewButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
  },
  helpIcon: {
    marginRight: 10,
  },
  textWrapper: {
    flex: 1,
  },
  title: {
    paddingBottom: 5,
    fontSize: 16,
  },
  description: {
    color: '#656565',
    lineHeight: 22,
  },
});

export default styles;
