import React from 'react';
import { View, Image } from 'react-native';
import PropTypes from 'prop-types';
import { useLocale } from '../../../services/localization';
import { P } from '../../../components/Text';
import PickedCheckbox from './PickedCheckbox';
import ItemCounter from './ItemCounter';
import usePickOrderLine from '../../../hooks/usePickOrderLine';
import styles from './OrderLine.styles';

function OrderLine({ style, ...props }) {
  const $t = useLocale();
  const { pickOrderLine, revertPick } = usePickOrderLine({
    id: props.id,
    quantity: props.quantity,
    picked: props.picked,
  });

  const locale = React.useMemo(
    () => ({
      sku: $t({
        id: 'OrderLine.Sku.Label',
        defaultMessage: 'sku',
      }),
      skuNotAvailable: $t({
        id: 'OrderLine.SkuNotAvailable.Text',
        defaultMessage: 'not available',
      }),
    }),
    [$t],
  );

  return (
    <View style={[styles.row, style]} {...props}>
      <PickedCheckbox
        style={styles.pickedCheckbox}
        checked={props.picked === props.quantity}
        onPress={pickOrderLine}
        disabled={props.disablePicking}
      />
      <ItemCounter
        style={styles.counter}
        total={props.quantity}
        counted={props.picked}
        incrementCounted={pickOrderLine}
        decrementCounted={revertPick}
        disablePickButtons={props.disablePicking}
      />
      {props.image && (
        <Image source={{ uri: props.image }} style={styles.image} />
      )}
      <View style={styles.textContainer}>
        <P
          style={[
            styles.title,
            props.picked !== props.quantity && styles.highlight,
          ]}
        >
          {props.title}
        </P>
        <P style={styles.sku}>
          {`${locale.sku}: ${props.sku || locale.skuNotAvailable}`}
        </P>
      </View>
    </View>
  );
}

OrderLine.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  picked: PropTypes.number.isRequired,
  sku: PropTypes.string,
  image: PropTypes.string,
  quantity: PropTypes.number.isRequired,
  disablePicking: PropTypes.bool,
};

OrderLine.defaultProps = {
  sku: undefined,
  image: undefined,
  disablePicking: false,
};

export default OrderLine;
