import { useQuery } from '@apollo/client';
import { GET_PICKERS } from '../graphql/PickerQuery';

const usePickers = () => {
  const { loading, error, data } = useQuery(GET_PICKERS);

  return { loading, error, data };
};

export default usePickers;
