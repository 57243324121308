import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextInput, View, StyleSheet, TouchableOpacity,
} from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { P, A } from './Text';
import Icon from './Icon';
import { colors } from '../constants';

const styles = StyleSheet.create({
  input: {
    height: 48,
    borderStyle: 'solid',
    borderColor: colors.border.primary,
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 15,
    fontFamily: 'Roboto',
    backgroundColor: colors.background.primary,
  },
  innerLabel: {
    position: 'absolute',
    top: 16,
    right: 10,
    color: '#5f5f5f',
  },
  leftInnerLabel: {
    position: 'absolute',
    top: 16,
    left: 10,
    color: '#5f5f5f',
  },
  inputWithInner: {
    paddingRight: 25,
  },
  inputWithLeftInner: {
    paddingLeft: 25,
  },
  password: {
    paddingRight: 40,
  },
  labelContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  label: {
    fontFamily: 'Roboto bold',
    fontSize: 16,
    paddingLeft: 3,
    marginBottom: 3,
  },
  link: {
    color: '#3258C5',
    textDecorationLine: 'underline',
    fontWeight: 'normal',
    fontSize: 14,
    marginRight: 3,
  },
  secureTextButton: {
    position: 'absolute',
    padding: 10,
    right: 5,
    top: 27,
    backgroundColor: colors.background.primary,
    borderRadius: 7,
  },
});

const TextField = React.forwardRef(({ style, ...props }, ref) => (
  <View style={style}>
    <View style={styles.labelContainer}>
      {props.label && <P style={styles.label}>{`${props.label}${props.required ? '*' : ''}`}</P>}
      {props.moreButtonLabel && <A onPress={props.moreButtonOnPress} style={styles.link}>{props.moreButtonLabel}</A>}
    </View>
    <TextInput
      ref={ref}
      style={StyleSheet.flatten([styles.input, props.innerLabel && styles.inputWithInner, props.leftInnerLabel && styles.inputWithLeftInner])}
      placeholderTextColor={colors.label.tertiary}
      {...props}
    />
    {props.description && props.description}
    {props.leftInnerLabel && <P style={styles.leftInnerLabel}>{props.leftInnerLabel}</P>}
    {props.innerLabel && <P style={styles.innerLabel}>{props.innerLabel}</P>}
  </View>
));

TextField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  leftInnerLabel: PropTypes.string,
  innerLabel: PropTypes.string,
  moreButtonOnPress: PropTypes.func,
  moreButtonLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.any,
};

TextField.defaultProps = {
  label: undefined,
  required: false,
  leftInnerLabel: undefined,
  innerLabel: undefined,
  description: undefined,
  moreButtonOnPress: undefined,
  moreButtonLabel: undefined,
};

const EmailField = React.forwardRef((props, ref) => (
  <TextField
    ref={ref}
    keyboardType="email-address"
    textContentType="emailAddress"
    autoCompleteType="email"
    autoCorrect={false}
    maxLength={50}
    {...props}
  />
));

const PasswordField = React.forwardRef(({ style, ...props }, ref) => {
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  return (
    <View style={style}>
      <View style={styles.labelContainer}>
        {props.label && <P style={styles.label}>{`${props.label}${props.required ? '*' : ''}`}</P>}
        {props.moreButtonLabel && <A onPress={props.moreButtonOnPress} style={styles.link}>{props.moreButtonLabel}</A>}
        <TouchableOpacity
          style={styles.secureTextButton}
          onPress={() => setSecureTextEntry(!secureTextEntry)}
          activeOpacity={1}
        >
          <Icon
            name={secureTextEntry ? 'eye' : 'eye-off'}
            color="#3258C5"
            size={16}
          />
        </TouchableOpacity>
      </View>
      <TextInput
        ref={ref}
        style={StyleSheet.flatten([styles.input, styles.password])}
        placeholderTextColor={colors.label.tertiary}
        secureTextEntry={secureTextEntry}
        autoCorrect={false}
        autoCompleteType="password"
        maxLength={150}
        {...props}
      />
      {props.description && props.description}
    </View>
  );
});

PasswordField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  moreButtonOnPress: PropTypes.func,
  moreButtonLabel: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.any,
};

PasswordField.defaultProps = {
  label: undefined,
  required: false,
  moreButtonOnPress: undefined,
  moreButtonLabel: undefined,
  description: undefined,
};

function SelectField({ onValueChange, style, ...props }) {
  const items = [];
  props.items?.forEach((item) => {
    items.push(
      <Picker.Item label={item.label} value={item.value} key={item.value} />,
    );
  });
  const handleValueChange = (e) => {
    // mimic other form field event objects
    if (!onValueChange) return;
    onValueChange({ target: { value: e } });
  };
  return (
    <View style={style}>
      {props.label && <P style={styles.label}>{props.label}</P>}
      <Picker style={styles.input} onValueChange={handleValueChange} {...props}>
        {items}
      </Picker>
      {props.description}
    </View>
  );
}

const SelectFieldItem = PropTypes.exact({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

SelectField.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(SelectFieldItem),
  onValueChange: PropTypes.func,
  description: PropTypes.element,
};

SelectField.defaultProps = {
  label: undefined,
  items: undefined,
  onValueChange: undefined,
  description: null,
};

export {
  TextField,
  EmailField,
  PasswordField,
  SelectField,
};
