import { useMutation } from '@apollo/client';
import { CREATE_PICKER, GET_PICKERS } from '../graphql/PickerQuery';

const useCreatePicker = (pickerFields, onCompleted, onError) => {
  const [runMutation, { loading }] = useMutation(CREATE_PICKER, {
    variables: pickerFields,
    // @todo: update pickers with writeCache instead of refetching over network
    refetchQueries: [{ query: GET_PICKERS }],
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted() {
      if (typeof onCompleted === 'function') onCompleted();
    },
  });

  const createPicker = () => {
    if (loading) return;
    runMutation();
  };

  return { createPicker, loading };
};

export default useCreatePicker;
