import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { P } from '../../../components/Text';
import { useLocale } from '../../../services/localization';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import Button from '../../../components/Button';
import SendcloudCarriers from '../../SendcloudCarriers';
import styles from './ValidateCarriers.styles';

function ValidateCarriers({ shopId, onCompleted }) {
  const $t = useLocale();
  return (
    <>
      <ModalHeader title={$t({ defaultMessage: 'Check Sendcloud carriers', id: 'ConnectSendcloudModal.CheckCarriers.Heading' })} />
      <View style={styles.container}>
        <P style={styles.text}>
          {$t({ defaultMessage: 'The following carriers are enabled for your Sendcloud account. Please check if these are the ones you have setup. ⤵️', id: 'ConnectSendcloudModal.CheckEnabledCarriers.Explainer' })}
        </P>
        <View style={styles.carriersContainer}>
          <SendcloudCarriers shopId={shopId} />
        </View>
        <P style={styles.text}>
          {$t({ defaultMessage: 'Carrier missing? Please check your Sendcloud carrier settings and refresh.', id: 'ConnectSendcloudModal.MissingCarriers.Explainer' })}
        </P>
      </View>
      <ModalFooter>
        <View style={styles.filler} />
        <Button label={$t({ defaultMessage: 'Finish setup', id: 'ConnectSendcloudModal.FinishSetup.Button.Label' })} onPress={onCompleted} />
      </ModalFooter>
    </>
  );
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

ValidateCarriers.propTypes = {
  shopId: IdType.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

export default ValidateCarriers;
