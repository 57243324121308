import React from 'react';
import { Animated, View, Easing } from 'react-native';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { Strong, P } from '../../components/Text';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { colors } from '../../constants';
import styles from './SubscribeToNewsletterPopup.styles';
import { useNotificationsContext } from '../../state/NotificationsProvider';
import { useAuthContext } from '../../services/auth';
import { mutationError } from '../../services/Error';
import { can, Actions } from '../../services/permissions';
import { useToastNotification } from '../../services/toast';
import { useLocale } from '../../services/localization';

export const TOGGLE_NEWSLETTER = gql`
  mutation ToggleNewsletter ($userId: ID!) {
    updateUser(
      input: {
          id: $userId
          newsletter: true
        }
      ) {
        id
        newsletter
    }
  }
`;

export const GET_NEWSLETTER = gql`
  query GetNewsletter ($userId: ID!) {
    user(id: $userId) {
      id
      newsletter
    }
  }
`;

function SubscribeToNewsletterPopup() {
  const $t = useLocale();
  const [show, toggleShow] = React.useState(false);
  const { dontAskToSubscribe, dispatch } = useNotificationsContext();
  const { user } = useAuthContext();
  const { toastWarning, toastInfo } = useToastNotification();
  const bottomAnim = React.useRef(new Animated.Value(-100)).current;

  const translations = React.useMemo(() => ({
    ifChangeMind: $t({ id: 'SubscribeToNewsletterPopup.IfChangeMind.Toast', defaultMessage: 'If you change your mind, you can always subscribe from your settings page.' }),
    thanksForSubscribing: $t({ id: 'SubscribeToNewsletterPopup.ThanksForSubscribing.Text', defaultMessage: 'Thanks for subscribing! We\'ll keep you up-to-date on the latest news and features.' }),
    subscribeHeading: $t({ id: 'SubscribeToNewsletterPopup.Subscribe.Heading', defaultMessage: 'Want to stay up-to-date about the latest FastPicker features?' }),
    subscribeDescription: $t({ id: 'SubscribeToNewsletterPopup.Subscribe.Description', defaultMessage: 'Subscribe to our newsletter and we will keep you up-to-date.' }),
    subscribe: $t({ id: 'SubscribeToNewsletterPopup.SubscribeButton.Label', defaultMessage: 'Subscribe' }),
    close: $t({ id: 'SubscribeToNewsletterPopup.closeButton.Label', defaultMessage: 'Close' }),
    dontAskAgain: $t({ id: 'SubscribeToNewsletterPopup.DontAskAgainButton.Label', defaultMessage: 'Don\'t ask again' }),
  }), [$t]);

  const [fetchSubscription, { loading: fetching, data }] = useLazyQuery(GET_NEWSLETTER);

  const hidePopup = React.useCallback(() => {
    toggleShow(false);
    dispatch({
      type: 'DONT_ASK_AGAIN',
      payload: {
        dontAskToSubscribe: true,
      },
    });
  }, [dispatch]);

  const [subscribeToNewsletter, { loading: subscribing }] = useMutation(TOGGLE_NEWSLETTER, {
    onError(error) {
      toastWarning(mutationError(error));
    },
    onCompleted() {
      setTimeout(() => {
        hidePopup();
      }, 3000);
    },
  });

  // fetch current subscription on load
  React.useEffect(() => {
    fetchSubscription({
      variables: {
        userId: user?.id,
      },
    });
  }, [fetchSubscription, user?.id]);

  React.useEffect(() => {
    // check if user is logged in and can manage a shop
    if (!user || !can(user.role, Actions.ManageShop)) {
      return;
    }
    // wait for current newsletter subscription state
    if (fetching || !data) {
      return;
    }
    // already asked and user subscribed or hid message
    if (dontAskToSubscribe) {
      return;
    }
    // don't show message when already subscribed
    if (data.user.newsletter) {
      return;
    }
    toggleShow(true);
  }, [user, fetching, data, dontAskToSubscribe]);

  const handleSubscribePress = React.useCallback(() => {
    subscribeToNewsletter({
      variables: {
        userId: user.id,
      },
    });
  }, [subscribeToNewsletter, user?.id]);

  const handleDontShowAgainPress = React.useCallback(() => {
    hidePopup();
    toastInfo(translations.ifChangeMind);
  }, [hidePopup, toastInfo, translations.ifChangeMind]);

  // fade in animation
  React.useEffect(() => {
    // @OTODO - refactor states!
    Animated.timing(
      bottomAnim,
      {
        delay: show ? 2000 : 0,
        toValue: show ? 70 : -100,
        easing: show ? Easing.elastic(-1) : Easing.back(),
        duration: show ? 500 : 300,
        useNativeDriver: false,
      },
    ).start();
  }, [show, bottomAnim]);

  function renderContent() {
    if (data?.user?.newsletter) {
      return (
        <>
          <Icon size={40} name="checkmark-circle-outline" color={colors.ui.ok} />
          <View style={styles.textWrapper}>
            <Strong style={styles.heading}>{translations.thanksForSubscribing}</Strong>
          </View>
          <Button onPress={hidePopup} label={translations.close} type="secondary" style={styles.button} />
        </>
      );
    }

    return (
      <>
        <Icon size={22} name="mail-outline" color={colors.brand.primary} />
        <View style={styles.textWrapper}>
          <Strong style={styles.heading}>{translations.subscribeHeading}</Strong>
          <P style={styles.text}>{translations.subscribeDescription}</P>
        </View>
        <Button label={translations.subscribe} style={styles.button} onPress={handleSubscribePress} loading={subscribing} />
        <Button onPress={handleDontShowAgainPress} label={translations.dontAskAgain} type="secondary" style={styles.button} />
      </>
    );
  }

  if (bottomAnim < 1 || !user) { // @TODO - bottomAnim does not work... Its a ref.
    return null;
  }

  return (
    <Animated.View style={[styles.container, { bottom: bottomAnim }]}>
      {renderContent()}
    </Animated.View>
  );
}

export default SubscribeToNewsletterPopup;
