import { useMutation } from '@apollo/client';
import { GET_PICKERS, DELETE_PICKER } from '../graphql/PickerQuery';
import { useLocale } from '../services/localization';

const useDeletePicker = (pickerId, onCompleted, onError) => {
  const $t = useLocale();

  const [runMutation, { loading }] = useMutation(DELETE_PICKER, {
    variables: { pickerId },
    update(cache) {
      const existingPickers = cache.readQuery({ query: GET_PICKERS });
      if (!existingPickers) {
        return;
      }
      const updatedPickers = existingPickers.pickers.data.filter(
        (p) => p.id !== pickerId,
      );
      cache.writeQuery({
        query: GET_PICKERS,
        data: {
          pickers: { __typename: 'PickerPaginator', data: updatedPickers },
        },
      });
    },
    optimisticResponse: {
      deletePicker: {
        id: pickerId,
        __typename: 'Picker',
      },
    },
    onError(error) {
      if (onError) onError(error);
    },
    onCompleted() {
      if (onCompleted) onCompleted();
    },
  });

  const deletePicker = () => {
    if (loading) return;
    const msg = $t({
      id: 'DeletePicker.ConfirmPrompt.Text',
      defaultMessage: 'Are you sure you want to remove this picker? This action can not be reversed.',
    });
    if (window.confirm(msg)) {
      runMutation();
    }
  };

  return { deletePicker, loading };
};

export default useDeletePicker;
