import React from 'react';
import IonIcon from 'react-native-vector-icons/Ionicons';
import PropTypes from 'prop-types';

function Icon({
  name, size, color, style,
}) {
  return <IonIcon name={name} size={size} color={color} style={style} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
};

Icon.defaultProps = {
  size: 32,
  color: '#000',
};

export default Icon;
