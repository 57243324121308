import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 1,
    opacity: 0.3,
  },
});

function CloseModalButton({ onPress }) {
  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <Icon name="close" size={32} />
    </TouchableOpacity>
  );
}

CloseModalButton.propTypes = {
  onPress: PropTypes.func,
};

CloseModalButton.defaultProps = {
  onPress: () => null,
};

export default CloseModalButton;
