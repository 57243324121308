import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity } from 'react-native';
import { FadeIn } from '../../../../components/Animation';
import Icon from '../../../../components/Icon';
import { colors } from '../../../../constants/theme';
import styles from './PickedCheckbox.styles';

function PickedCheckbox({ style, ...props }) {
  return (
    <TouchableOpacity style={[styles.container, style]} disabled={props.disabled} {...props}>
      {props.checked && (
      <FadeIn>
        <Icon
          name="checkmark"
          size={50}
          color={colors.ui.ok}
          style={styles.checkmark}
        />
      </FadeIn>
      )}
    </TouchableOpacity>
  );
}

PickedCheckbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
};

PickedCheckbox.defaultProps = {
  disabled: false,
  checked: false,
};

export default PickedCheckbox;
