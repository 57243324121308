import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refresh_token: String!) {
    refreshToken(input: { refresh_token: $refresh_token }) {
      access_token
      refresh_token
      expires_in
    }
  }
`;

export const LOGOUT = gql`
  mutation logout {
    logout {
      status
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(input: { email: $email }) {
      status
      message
    }
  }
`;

export const RESEND_VERIFY_EMAIL = gql`
  mutation resendVerifyEmail($email: String!) {
    resendVerifyEmail(email: $email) {
      status
    }
  }
`;

export const UPDATE_FORGOTTEN_PASSWORD = gql`
  mutation updateForgottenPassword(
    $token: String!
    $email: String!
    $password: String!
  ) {
    updateForgottenPassword(
      input: {
        token: $token
        email: $email
        password: $password
        password_confirmation: $password
      }
    ) {
      status
      message
    }
  }
`;

export const REGISTER = gql`
  mutation register($name: String!, $email: String!, $password: String!) {
    register(
      input: {
        name: $name
        email: $email
        password: $password
        password_confirmation: $password
      }
    ) {
      status
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(input: { token: $token }) {
      access_token
      refresh_token
      expires_in
      user {
        id
        email
        name
        role
      }
    }
  }
`;

export const UPDATE_PASSWORD = null;
