import { StyleSheet, Dimensions } from 'react-native';

export const smallDevice = Dimensions.get('window').width < 875;

export const styles = StyleSheet.create({
  heading: {
    marginBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actions: {
    flexDirection: 'row',
    marginBottom: 30,
  },
  printPackingSlip: {
    marginRight: smallDevice ? 0 : 5,
    marginBottom: smallDevice ? 10 : 0,
  },
  printShippingLabel: {
    marginHorizontal: smallDevice ? 0 : 5,
    marginBottom: smallDevice ? 10 : 0,
  },
  completeOrderAndNotify: {
    marginLeft: smallDevice ? 0 : 5,
  },
  flex: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  subHeading: {
    marginBottom: 15,
  },
  orderLine: {
    marginBottom: 7,
  },
});

export const mStyles = StyleSheet.create({
  heading: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  progressBar: {
    maxWidth: null,
    marginTop: 10,
  },
  actions: {
    flexDirection: 'column',
  },
});
