import React from 'react';
import { View, StyleSheet } from 'react-native';
import { FadeInOutPulse } from './Animation';
import { colors } from '../constants/theme';

const styles = StyleSheet.create({
  container: {
    width: 10,
    height: 10,
    backgroundColor: colors.ui.attention,
    borderRadius: 10,
  },
});

function AttentionSeeker({ style }) {
  return (
    <FadeInOutPulse style={style}>
      <View style={styles.container} />
    </FadeInOutPulse>
  );
}

export default AttentionSeeker;
