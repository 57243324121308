/**
 * @TODO This feature is currently disabled by design.
 */
import React from 'react';
import { ErrorNotification } from '../../components/ErrorNotification';
import useVerifyEmailChange from '../../hooks/user/useVerifyEmailChange';
import { useToastNotification } from '../../services/toast';
import { useAuthContext } from '../../services/auth';
import { useLocale } from '../../services/localization';
import { Tab } from '../SettingsScreen/shared';

function VerifyEmailChange({ navigation, route }) {
  const $t = useLocale();
  const { token } = route.params;
  const { user } = useAuthContext();
  const { toastSucces, toastWarning } = useToastNotification();
  const navigateTo = !user ? 'Login' : 'Settings';

  const onCompleted = () => {
    navigation.navigate(navigateTo, { tab: Tab.Details });
    toastSucces($t({
      id: 'VerifyEmailChange.YourEmailHasUpdated.Succes.Text',
      defaultMessage: 'Your email has been updated!',
    }));
  };

  const onError = () => {
    navigation.navigate(navigateTo, { tab: Tab.Details });
    toastWarning($t({
      id: 'VerifyEmailChange.FailedToUpdateEmail.Error.Text',
      defaultMessage: 'Sadly, we failed to update your email. Please try again.',
    }));
  };

  const { verifyEmailChange } = useVerifyEmailChange(onCompleted, onError);

  React.useEffect(() => {
    setTimeout(() => {
      verifyEmailChange(token);
    }, 500);
  }, [token, verifyEmailChange]);

  return (
    <ErrorNotification
      errorTitle={$t({
        id: 'VerifyEmailChange.VerifyingEmailChange.Title',
        defaultMessage: 'Verifying email change...',
      })}
      errorMessage={$t({
        id: 'VerifyEmailChange.OneMomentPlease.Text',
        defaultMessage: 'One moment please. You\'ll be automatically redirected when finished.',
      })}
      icon="loader"
    />
  );
}

export default VerifyEmailChange;
