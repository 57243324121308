import React, { useMemo } from 'react';
import { Image, View, Linking } from 'react-native';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation, ApolloError } from '@apollo/client';
import { A, Strong, P } from '../../components/Text';
import styles from './SendcloudCarriers.styles';
import { assets, links } from '../../constants';
import { useToastNotification } from '../../services/toast';
import Alert from '../../components/Alert';

// @TODO: [sendcloud]: translations

const GET_SENDCLOUD_PROVIDERS = gql`
query GetSendcloudProviders($shopId: ID!, $weight: Float) {
  sendcloudGetProviders(shopId: $shopId, weight: $weight) {
    providers
  }
}
`;

const REFRESH_SENDCLOUD_CARRIERS = gql`
mutation sendcloudUpdateProviders($shopId: ID!) {
  sendcloudUpdateProviders(shopId: $shopId) {
    providers
  }
}
`;

function useRefreshCarriers() {
  const { toastInfo, toastWarning } = useToastNotification();

  const [refreshCarriers, { loading }] = useMutation(REFRESH_SENDCLOUD_CARRIERS, {
    onError() {
      toastWarning('Something went wrong when retreiving your Sendcloud carriers. Please try again.');
    },
    onCompleted() {
      toastInfo('Sendcloud carriers updated.');
    },
    refetchQueries: ['GetSendcloudProviders'],
  });

  return {
    refreshing: loading,
    refreshCarriers,
  };
}

const SendcloudCarrierName = { // @TODO: move to shipping constants
  sendcloud: 'Sendcloud',
  dhl: 'DHL',
  postnl: 'PostNL',
  dpd: 'DPD',
  ups: 'UPS',
  dhl_express: 'DHL Express',
  deutsche_post: 'Deutsche Post',
  mondial_relay: 'Mondial Relay',
  budbee: 'Budbee',
  trunkrs: 'Trunkrs',
  homerr: 'Homerr',
  viatim: 'Viatim',
  storeshippers: 'Storeshippers',
  fietskoeriersnl: 'Fietskoeriers.nl',
  quicargo: 'Quicargo',
  gls: 'GLS',
  fedex: 'FedEx',
};

function SendcloudCarriers({ shopId }) {
  const { refreshing, refreshCarriers } = useRefreshCarriers();
  const productsQuery = useQuery(GET_SENDCLOUD_PROVIDERS, {
    variables: { shopId, weight: 1 },
  });

  const availableProviders = useMemo(() => {
    if (!productsQuery.data || !productsQuery.data.sendcloudGetProviders) {
      return [];
    }
    return productsQuery.data.sendcloudGetProviders.providers;
  }, [productsQuery.data]);

  function renderRefreshButton() {
    if (refreshing) {
      return <P style={[styles.link, styles.refreshing]}>Retreiving carriers. One moment please...</P>;
    }
    return (
      <A onPress={() => refreshCarriers({ variables: { shopId } })} style={styles.link}>
        Refresh carriers
      </A>
    );
  }

  function renderCarrierList() {
    if (productsQuery.loading || refreshing) {
      return null;
    }
    if (!productsQuery.error && productsQuery.error instanceof ApolloError) {
      return <Alert text="Unable to load carriers. Please check you Sendcloud account." style={styles.alert} />;
    }
    if (!availableProviders || availableProviders.length === 0) {
      return <Alert text="No carriers found or enabled. Please check your Sendcloud carrier page and enable." style={styles.alert} />;
    }
    return availableProviders.map((carrierId) => (
      <View style={styles.carrier} key={carrierId}>
        {assets.images[carrierId] && <Image source={assets.images[carrierId]} resizeMode="contain" style={styles.carrierLogo} />}
        <Strong style={styles.carrierLabel}>{SendcloudCarrierName[carrierId] || carrierId}</Strong>
      </View>
    ));
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Strong style={styles.title}>Enabled carriers</Strong>
        {renderRefreshButton()}
        <A onPress={() => Linking.openURL(links.SendcloudCarrierPage)} style={styles.link}>Edit carriers</A>
      </View>
      <View style={styles.carrierContainer}>
        {renderCarrierList()}
      </View>
    </View>
  );
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

SendcloudCarriers.propTypes = {
  shopId: IdType.isRequired,
};

export default SendcloudCarriers;
