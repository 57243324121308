import { StyleSheet } from 'react-native';
import { colors, fonts } from '../../constants';

export default StyleSheet.create({
  contentWrapper: {
    maxWidth: 800,
    width: 680,
    maxHeight: 1000,
    backgroundColor: '#fff',
    borderRadius: 10,
    alignSelf: 'center',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    overflow: 'hidden',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: 25,
  },
  header: {
    padding: 40,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    backgroundColor: '#f9f9f9',
  },
  content: {
    padding: 40,
  },
  explainer: {
    fontSize: fonts.size.label,
    lineHeight: 26,
    marginBottom: 20,
  },
  input: {
    marginTop: 15,
  },
  validationError: {
    marginTop: 3,
    marginLeft: 3,
    color: colors.ui.attention,
    fontSize: 14,
  },
  footer: {
    padding: 20,
    paddingHorizontal: 40,
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#f9f9f9', // @TODO: alternative lighter background color variant. Add to theme
  },
});
