import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    padding: 20,
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexShrink: 1,
    backgroundColor: '#E6EFFA',
    borderWidth: 1,
    borderColor: '#C3D7EE',
    marginBottom: 15,
  },
  attention: {
    position: 'absolute',
    left: 0,
    top: 28,
    transform: [{ translateX: '-50%' }],
  },
  textWrapper: {
    flexShrink: 1,
    marginLeft: 10,
    marginTop: 3,
  },
  title: {
    lineHeight: 20,
    color: '#616E94',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  text: {
    marginTop: 10,
    lineHeight: 20,
    color: '#616E94',
  },
  button: {
    alignSelf: 'flex-start',
    marginTop: 15,
  },
  buttonIcon: {
    marginRight: 5,
    marginLeft: -5,
    marginBottom: 2,
  },
});

export default styles;
