import React from 'react';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import LargeActionButton, { ButtonType } from '../../../components/LargeActionButton';
import { useLocale } from '../../../services/localization';

function NextOrderButton({
  wooId, orderId, highlight,
}) {
  const $t = useLocale();
  const navigation = useNavigation();
  const type = highlight ? ButtonType.Primary : ButtonType.Secondary;

  return (
    <LargeActionButton
      label={$t({ id: 'OrdersList.NextOrderButton.Label', defaultMessage: 'Next order' })}
      description={$t({ id: 'OrdersList.NextOrderButton.Description', defaultMessage: 'Start picking next order' })}
      subText={$t({ id: 'OrdersList.NextOrderButton.OrderIdText', defaultMessage: 'Order #{orderId}' }, { orderId: wooId })}
      type={type}
      onPress={() => navigation.navigate('PickOrder', { orderId, wooId })}
    />
  );
}

const Id = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

NextOrderButton.propTypes = {
  wooId: Id.isRequired,
  orderId: Id.isRequired,
  highlight: PropTypes.bool,
};

NextOrderButton.defaultProps = {
  highlight: false,
};

export default NextOrderButton;
