import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 10,
    minHeight: 55,
    padding: 5,
    paddingHorizontal: 15,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    marginBottom: 7,
  },
});

function ListItem({ style, ...props }) {
  return (
    <TouchableOpacity
      activeOpacity={0.5}
      style={[styles.item, style]}
      onPress={props.onPress}
      {...props}
    />
  );
}

ListItem.propTypes = {
  onPress: PropTypes.func,
};

ListItem.defaultProps = {
  onPress: () => null,
};

export default ListItem;
