import React from 'react';
import { StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import ToastNotification from './ToastNotification';

const toastConfig = {
  success: ({ text1 }) => <ToastNotification text1={text1} type="success" />,
  info: ({ text1 }) => <ToastNotification text1={text1} type="info" />,
  warning: ({ text1 }) => <ToastNotification text1={text1} type="warning" />,
};

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    left: '50%',
  },
});

function ToastProvider() {
  return (
    <View style={styles.container}>
      <Toast config={toastConfig} />
    </View>
  );
}

export default ToastProvider;
