export const links = {
  getSupport: 'https://fastpicker.freshdesk.com/',
  createTicket: 'https://fastpicker.freshdesk.com/support/tickets/new',
  TermsOfService: 'https://fastpicker.io/terms-of-service/',
  PrivacyPolicy: 'https://fastpicker.io/privacy-statement/',
  Pricing: 'https://fastpicker.io/pricing/',
  Documentation: 'https://fastpicker.freshdesk.com/a/solutions',
  FastPickerPluginStore: 'https://wordpress.org/plugins/fastpicker/',
  DataProcessingAgreement: 'https://fastpicker.io/wp-content/uploads/2022/07/data-processing-agreement-v1.pdf',
  Upvoty: 'https://fastpicker.upvoty.com/b/fastpicker/',
  SendcloudCarrierPage: 'https://app.sendcloud.com/v2/settings/carriers/list',
  SendcloudApiKeyHelp: 'https://support.sendcloud.com/hc/en-us/articles/360024967012-Sendcloud-API-documentation-and-Quick-Start-Guide-#4',
};
