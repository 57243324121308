import React from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { H3 } from '../../../components/Text';
import RoleBadge from '../../../components/RoleBadge';
import Icon from '../../../components/Icon';
import TabButton from '../../../components/TabButton';
import { Roles } from '../../../services/permissions';
import styles from './LeftSidebar.styles';
import { Tab } from '../shared';

function LeftSidebar({ username, canManageShop, role }) {
  const navigation = useNavigation();
  const route = useRoute();
  const incomingTab = route?.params?.tab || {};
  const activeTab = Object.values(Tab).includes(incomingTab) ? incomingTab : Tab.Details;

  const go = React.useCallback((tab) => {
    navigation.navigate('Settings', { tab });
  }, [navigation]);

  return (
    <>
      <View style={styles.user}>
        <Icon style={styles.avatar} name="person-circle" size={75} />
        <H3>{username}</H3>
        <RoleBadge type={role} style={styles.badge} />
      </View>
      <View>
        <TabButton iconName="settings" label="Account" onPress={() => go(Tab.Details)} isActive={activeTab === Tab.Details} />
        <TabButton iconName="lock-open" label="Security" onPress={() => go(Tab.Security)} isActive={activeTab === Tab.Security} />
        {canManageShop && <TabButton iconName="shield-checkmark" label="Privacy" onPress={() => go(Tab.Privacy)} isActive={activeTab === Tab.Privacy} />}
        <TabButton iconName="notifications" label="Notifications" onPress={() => go(Tab.Notifications)} isActive={activeTab === Tab.Notifications} />
      </View>
    </>
  );
}

LeftSidebar.propTypes = {
  username: PropTypes.string.isRequired,
  canManageShop: PropTypes.bool.isRequired,
  role: PropTypes.oneOf(Object.values(Roles)).isRequired,
};

export default LeftSidebar;
