import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FadeIn } from './Animation';
import { P } from './Text';
import ListItem from './ListItem';
import Button from './Button';
import { useLocale } from '../services/localization';

const styles = StyleSheet.create({
  item: {
    padding: 7,
  },
  textContainer: {
    paddingVertical: 5,
  },
  name: {
    fontFamily: 'Roboto bold',
    fontSize: 16,
  },
  email: {
    fontSize: 16,
    color: '#717171', // @TODO add color to theme
    paddingTop: 3,
  },
});

function PickersListItem({
  id, name, email, style,
}) {
  const $t = useLocale();
  const navigation = useNavigation();
  const go = () => {
    navigation.push('EditPicker', { pickerId: id });
  };
  return (
    <FadeIn>
      <ListItem style={[styles.item, style]} onPress={go}>
        <View style={styles.textContainer}>
          <P style={styles.name}>{name}</P>
          <P style={styles.email}>{email}</P>
        </View>
        <Button
          label={$t({ id: 'PickersListItem.EditPickerButton.Label', defaultMessage: 'Edit' })}
          type="secondary"
          onPress={go}
        />
      </ListItem>
    </FadeIn>
  );
}

PickersListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default PickersListItem;
