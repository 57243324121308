import { useMutation, gql } from '@apollo/client';
import { ShippingPlugin } from '../../constants';
import { isEmpty } from '../../services/Validation';

const DECLARE_FOR_SHIPPING = gql`
  mutation ($input: CreateShippingLabelInput!) {
    createShippingLabel(input: $input) {
      id
      shipping_label_status
    }
  }
`;

// type CarrierKey = typeof in ShippingPlugin;

function isValidShippingPlugin(pluginName) {
  return Object.values(ShippingPlugin).includes(pluginName);
}

// "input": {
//   "id": "1004",
//   "plugin": "kdz",
//   "fields": [
//     {
//       "name": "delivery_method",
//       "value": "Road Express"
//     },
//     {
//       "name": "colli",
//       "value": null,
//       "subFields": [
//          [
//            { "name": "weight", "value": 1 },
//            { "name": "height", "value": 1 },
//            { "name": "lenght", "value": 1 },
//            { "name": "width", "value": 1 },
//          ],
//       }]
//   }

const useDeclareForShipping = () => {
  const [runMutation, { loading, data, error }] = useMutation(DECLARE_FOR_SHIPPING);

  const declareForShipping = (orderId, pluginName, fields, onCompleted, onError) => {
    if (loading) return;
    if (!orderId || !isValidShippingPlugin(pluginName) || !fields || fields.length === 0) return;

    // console.log('fields:', fields);

    // const international = {
    //   customs_invoice_number: '123456',
    //   customs_shipment_type: 2,
    //   country_state: null,
    //   parcel_items: [
    //     {
    //       description: 'A test product for testing sendcloud shipping',
    //       weight: '0.5',
    //       value: '10',
    //       hs_code: '12345678',
    //       origin_country: 'NL',
    //       properties: [
    //         { colour: 'Black' },
    //       ],
    //     },
    //   ],
    // };

    // console.log(JSON.stringify({ ...fields, ...international }, null, 2));

    // fields = { ...fields, ...international };

    const input = [];
    Object.keys(fields).forEach((fieldName) => {
      const value = fields[fieldName];
      if (isEmpty(value) || fieldName === 'products') return; // @TODO: [sendcloud]: fix clasing product name for sendcloud and kdz?

      // console.log('👇');
      // console.log('fieldName', fieldName);
      // console.log('value', value);
      // console.log('type of:', typeof value);

      if (typeof value === 'object' && value.length > 0 && fieldName !== 'parcel_items') {
        // console.log('⚠️ subFields: ', JSON.stringify(value, null, false));

        // From:
        // [
        //   {
        //     _id: 1658513639895, weight: '1', width: '1', height: '1', length: '1',
        //   },
        //   {
        //     _id: 1658513643654, weight: '2', width: '2', height: '2', length: '2',
        //   }
        // ];

        // To:
        // [
        //   [
        //     { name: 'weight', value: '1' },
        //     { name: 'height', value: '1' },
        //     { name: 'width', value: '1' },
        //     { name: 'length', value: '1' },
        //   ],
        //   [
        //     { name: 'weight', value: '1' },
        //     { name: 'height', value: '1' },
        //     { name: 'width', value: '1' },
        //     { name: 'length', value: '1' },
        //   ],
        // ]
        const subFields = value.reduce((agg, subField) => {
          const row = [];
          Object.entries(subField).forEach(([key, val]) => {
            if (key === '_id') return; // ignore _id which used by UI and not an valid api field
            row.push({ name: key, value: parseFloat(val) });
          });
          agg.push(row);
          return agg;
        }, []);

        // console.log(subFields);

        input.push({
          name: fieldName,
          value: null,
          subFields,
        });
      } else {
        input.push({
          name: fieldName,
          value,
        });
      }
    });

    // const international2 = [
    //   {
    //     name: 'customs_invoice_number',
    //     value: '123456',
    //   },
    //   {
    //     name: 'customs_shipment_type',
    //     value: '2',
    //   },
    //   {
    //     name: 'country_state',
    //     value: null,
    //   },
    //   {
    //     name: 'parcel_items',
    //     value: [
    //       {
    //         name: 'description',
    //         value: 'A test product for testing sendcloud shipping',
    //       },
    //       {
    //         name: 'value',
    //         value: 10,
    //       },
    //       {
    //         name: 'hs_code',
    //         value: '12345678',
    //       },
    //       {
    //         name: 'origin_country',
    //         value: 'NL',
    //       },
    //     ],
    //   },
    // ];
    //   customs_invoice_number: '123456',
    //   customs_shipment_type: 2,
    //   country_state: null,
    //   parcel_items: [
    //     {
    //       description: 'A test product for testing sendcloud shipping',
    //       weight: '0.5',
    //       value: '10',
    //       hs_code: '12345678',
    //       origin_country: 'NL',
    //       properties: [
    //         { colour: 'Black' },
    //       ],
    //     },
    //   ],
    // };

    // const formattedFields = [
    //   {
    //     name: 'weight',
    //     value: 500,
    //   },
    //   {
    //     name: 'sender_address',
    //     value: 348740,
    //   },
    //   {
    //     name: 'product_id',
    //     value: 8,
    //   },
    // ];

    // console.log('🚀', JSON.stringify(input, null, 2));

    // CustomsInvoiceNumber: 'customs_invoice_number',
    // CustomsShipmentType: 'customs_shipment_type',
    // CountryState: 'country_state',
    // Products: 'products',

    // return;

    runMutation({
      variables: {
        input: {
          id: orderId,
          plugin: pluginName,
          fields: { ...input },
        },
      },
      onError(e) {
        if (onError) onError(e);
      },
      update() {
        if (onCompleted) onCompleted();
      },
    });
  };

  return {
    declareForShipping, loading, data, error,
  };
};

export default useDeclareForShipping;
