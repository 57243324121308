import { issueState } from '../../constants';

export function hasAConnectedShop(issues) {
  // Check if there are any issue categories
  if (Object.keys(issues).length > 0) {
    // Check if there shops at all
    if (issues.general.has(issueState.NO_SHOPS)) {
      return false;
    }

    // Check if there are any shops with issues?
    if (Object.keys(issues.shops).length === 0) {
      return true;
    }

    // Check if there is any shop without ADD_SHOP_INPROGRESS state
    const reducer = (count, shop) => (shop.has(issueState.ADD_SHOP_INPROGRESS) ? count + 1 : count);
    const shopAmountInProgress = Object.values(issues.shops).reduce(reducer, 0);

    if (shopAmountInProgress > 0) {
      return false;
    }
  }

  return true;
}
