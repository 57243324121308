import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Animated, View, TouchableOpacity } from 'react-native';
import { P } from '../../../../components/Text';
import Icon from '../../../../components/Icon';
import { colors } from '../../../../constants/theme';
import styles from './ItemCounter.styles';

function IncrementButton({ disabled, onPress }) {
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled} style={[styles.button, styles.incrementButton, disabled && styles.disabledButton]}>
      <Icon
        name="add-circle-sharp"
        size={20}
        color={disabled ? '#c6c6c6' : colors.brand.primary}
      />
    </TouchableOpacity>
  );
}

IncrementButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

function DecrementButton({ disabled, onPress }) {
  return (
    <TouchableOpacity onPress={onPress} disabled={disabled} style={[styles.button, styles.decrementButton, disabled && styles.disabledButton]}>
      <Icon
        name="remove-circle-sharp"
        size={20}
        color={disabled ? '#c6c6c6' : colors.brand.primary}
      />
    </TouchableOpacity>
  );
}

DecrementButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

function ItemCounter({
  counted, total, disablePickButtons, incrementCounted, decrementCounted, style,
}) {
  const scaleAnim = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.sequence([
      Animated.spring(scaleAnim, {
        toValue: 1.3,
        speed: 50,
        bounciness: 1,
        useNativeDriver: true,
      }),
      Animated.timing(scaleAnim, {
        toValue: 1,
        duration: 100,
        useNativeDriver: true,
      }),
    ]).start();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counted]);

  return (
    <View style={[styles.container, style]}>
      <DecrementButton onPress={decrementCounted} disabled={disablePickButtons || counted <= 0} />
      <P style={styles.label} numberOfLines={1}>
        <Animated.View
          style={{
            paddingLeft: 2,
            transform: [{ scale: scaleAnim }],
          }}
        >
          {counted}
        </Animated.View>
        {` / ${total}`}
      </P>
      <IncrementButton onPress={incrementCounted} disabled={disablePickButtons || counted >= total} />
    </View>
  );
}

ItemCounter.propTypes = {
  counted: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  disablePickButtons: PropTypes.bool,
  decrementCounted: PropTypes.func.isRequired,
  incrementCounted: PropTypes.func.isRequired,
};

ItemCounter.defaultProps = {
  disablePickButtons: false,
};

export default ItemCounter;
