import { useMutation } from '@apollo/client';
import { UPDATE_USER_PASSWORD } from '../../graphql/UserQuery';

const useUpdateUserPassword = (fields, onCompleted, onError) => {
  const [updateUserPassword, { loading }] = useMutation(
    UPDATE_USER_PASSWORD,
    {
      variables: fields,
      onError(error) {
        if (typeof onError === 'function') onError(error);
      },
      onCompleted() {
        if (typeof onCompleted === 'function') onCompleted();
      },
    },
  );

  return { updateUserPassword, loading };
};

export default useUpdateUserPassword;
