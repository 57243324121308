import React from 'react';
import { View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { Small } from '../Text';
import { colors } from '../../constants/theme';
import styles from './PageHelperNote.styles';

function PageHelperNote({ note, style }) {
  return (
    <View style={StyleSheet.flatten(styles.container, style)}>
      <Icon name="information-circle" size={18} color={colors.label.tertiary} />
      <Small style={styles.note}>{note}</Small>
    </View>
  );
}

PageHelperNote.propTypes = {
  note: PropTypes.string.isRequired,
};

export default PageHelperNote;
