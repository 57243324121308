import React, { useRef, useMemo } from 'react';
import { View, ImageBackground } from 'react-native';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { P, Strong } from '../../../components/Text';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import ToggleButton from '../../../components/Fields/ToggleButton';
import ConnectSendcloudModal from '../../../features/ConnectSendcloudModal';
import { UPDATE_ORDER_FIELDS } from '../../../graphql/OrderQuery';
import {
  assets, colors, ShippingPluginName, ShippingPlugin, SendcloudStatus,
} from '../../../constants';
import styles from './SendCloud.styles';
import useToggleShippingLabelPlugin from '../../../hooks/shop/useToggleShippingPlugin';
import SendcloudCarriers from '../../../features/SendcloudCarriers';
import { useToastNotification } from '../../../services/toast';
import { useLocale } from '../../../services/localization';

const DISCONNECT_SENDCLOUD = gql`
mutation sendcloudCredentials($shopId: ID!) {
  sendcloudCredentials(shopId: $shopId, public_key: "", secret_key: "") {
    id
    sendcloud_status
  }
}
`;

function SendCloud({ shopId, status, active }) {
  const $t = useLocale();
  const connectSendcloudModalRef = useRef();
  const provider = ShippingPlugin.Sendcloud;
  const [disconnect, disconnectMutation] = useMutation(DISCONNECT_SENDCLOUD, {
    refetchQueries: [
      { query: UPDATE_ORDER_FIELDS },
    ],
  });
  const { toastInfo, toastWarning } = useToastNotification();

  const { toggleShippingPlugin, ...toggleSendcloud } = useToggleShippingLabelPlugin();

  const locale = useMemo(() => ({
    new: $t({ defaultMessage: 'New', id: 'Sendcloud.New.Label' }),
    sendcloudDisconnected: $t({ defaultMessage: 'Removed credentials and disconnected Sendcloud.', id: 'Sendcloud.SendcloudDisconnected.Toast.Text' }),
    disconnectFailed: $t({ defaultMessage: 'Something went wrong when disconnecting. Please try again', id: 'Sendcloud.DisconnectFailed.Toast.Text' }),
    saving: $t({ defaultMessage: 'Saving...', id: 'Sendcloud.StatusLabel.Saving' }),
    disabled: $t({ defaultMessage: 'Disabled', id: 'Sendcloud.StatusLabel.Disabled' }),
    enabled: $t({ defaultMessage: 'Enabled', id: 'Sendcloud.StatusLabel.Enabled' }),
    invalidCredentials: $t({ defaultMessage: 'Invalid credentials', id: 'Sendcloud.StatusLabel.InvalidCredentials' }),
    notConnected: $t({ defaultMessage: 'Not connected', id: 'Sendcloud.StatusLabel.NotConnected' }),
    accountConnected: $t({ defaultMessage: 'Sendcloud account connected.', id: 'Sendcloud.AccountConnected.Text' }),
    notSetup: $t({ defaultMessage: 'You need to connect with your SendCloud account before you can use SendCloud to declare orders.', id: 'Sendcloud.NotSetup.Text' }),
    disconnecting: $t({ defaultMessage: 'Disconnecting...', id: 'Sendcloud.Disconnect.Button.LoadingLabel' }),
    disconnectSendcloud: $t({ defaultMessage: 'Disconnect Sendcloud', id: 'Sendcloud.Disconnect.Button.Label' }),
    connectSendcloud: $t({ defaultMessage: 'Connect Sendcloud', id: 'Sendcloud.Connect.Button.Label' }),
    validatingConnection: $t({ defaultMessage: 'Validating connection...', id: 'Sendcloud.Connect.Button.LoadingLabel' }),
  }), [$t]);

  async function handleDisconnectRequest() {
    // @TODO: Add 'Are you sure you want to disconnect' confirmation
    try {
      await disconnect({ variables: { shopId } });
      toastInfo(locale.sendcloudDisconnected);
    } catch (e) {
      toastWarning(locale.disconnectFailed);
    }
  }

  async function toggleRequest() {
    // @TODO: [sendcloud]: Error handling
    toggleShippingPlugin(shopId, ShippingPlugin.Sendcloud, !active);
  }

  function renderToggleLabel() {
    if (toggleSendcloud.loading) {
      return <Strong style={styles.toggleLabel}>{locale.saving}</Strong>;
    }
    if (!active && status === SendcloudStatus.Active) {
      return <Strong style={styles.toggleLabel}>{locale.disabled}</Strong>;
    }
    if (status === SendcloudStatus.Active) {
      return <Strong style={[styles.toggleLabel, styles.toggleLabelGreen]}>{locale.enabled}</Strong>;
    }
    if (status === SendcloudStatus.InvalidCredentials) {
      return <Strong style={[styles.toggleLabel, styles.toggleLabelWarning]}>{locale.invalidCredentials}</Strong>;
    }
    return <Strong style={styles.toggleLabel}>{locale.notConnected}</Strong>;
  }

  function renderExplainer() {
    if (status === SendcloudStatus.Active) {
      return (
        <View style={styles.pluginInstalledContainer}>
          <Icon size={22} name="checkmark-circle" color={colors.ui.ok} />
          <P style={styles.pluginInstalledText}>{locale.accountConnected}</P>
        </View>
      );
    }
    return (
      <View style={styles.downloadPluginContainer}>
        <Icon size={22} name="information-circle" color={colors.brand.primary} />
        <View style={styles.downloadPluginTextWrapper}>
          <P style={styles.downloadPluginText}>
            {locale.notSetup}
          </P>
        </View>
      </View>
    );
  }

  function renderButtons() {
    if (status === SendcloudStatus.Active) {
      return <Button type="secondary" label={locale.disconnectSendcloud} onPress={() => handleDisconnectRequest()} loading={disconnectMutation.loading} loadingLabel={locale.disconnecting} />;
    }
    return (
      <Button
        label={locale.connectSendcloud}
        onPress={() => connectSendcloudModalRef.current.open()}
        loading={status === SendcloudStatus.CheckingConnection}
        loadingLabel={locale.validatingConnection}
      />
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleWrapper}>
        <View style={styles.providerWrapper}>
          <ImageBackground style={styles.providerLogo} resizeMode="contain" source={assets.images.sendcloud} />
          <Strong style={styles.providerName}>{ShippingPluginName[provider]}</Strong>
          {status !== SendcloudStatus.Active && <Strong style={styles.newLabel}>{locale.new}</Strong>}
        </View>
        <View style={styles.toggleWrapper}>
          {renderToggleLabel()}
          <ToggleButton
            style={styles.toggleButton}
            checked={status === null ? false : active}
            onChange={() => toggleRequest()}
            disabled={status !== SendcloudStatus.Active}
          />
        </View>
      </View>
      <View style={styles.seperator} />
      {renderExplainer()}

      {status === SendcloudStatus.Active && (
        <>
          <View style={styles.seperator} />
          <View style={styles.carriersWrapper}>
            <SendcloudCarriers shopId={shopId} />
          </View>
        </>
      )}
      <View style={styles.seperator} />
      <View style={styles.buttonContainer}>
        {renderButtons()}
      </View>
      <ConnectSendcloudModal ref={connectSendcloudModalRef} shopId={shopId} />
    </View>
  );
}

SendCloud.propTypes = {
  shopId: PropTypes.string.isRequired,
  status: PropTypes.string,
  active: PropTypes.bool,
};

SendCloud.defaultProps = {
  status: undefined,
  active: undefined,
};

export default SendCloud;
