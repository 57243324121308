import React from 'react';
import { View, useWindowDimensions } from 'react-native';
import PropTypes from 'prop-types';
import Screen from '../Layout';
import styles, { mobileStyles } from './ScreenWithSidebars.styles';

const ContentSize = {
  Normal: 'normal',
  Wide: 'wide',
};

function ScreenWithSidebars({
  LeftSidebarComponent, MainContentComponent, contentWidth, RightSidebarComponent,
}) {
  const { width } = useWindowDimensions();
  const isMobile = width <= 900;
  return (
    <Screen size="full" backgroundImage>
      <View style={[styles.container, isMobile && mobileStyles.container]}>
        <View style={styles.sidebar}>
          {LeftSidebarComponent}
        </View>
        <View style={styles.contentContainer}>
          <View style={[styles.content, contentWidth === ContentSize.Wide ? styles.wide : null]}>
            {MainContentComponent}
          </View>
        </View>
        {width >= 1200 && (
          <View style={styles.sidebar}>
            {RightSidebarComponent}
          </View>
        )}
      </View>
    </Screen>
  );
}

ScreenWithSidebars.propTypes = {
  LeftSidebarComponent: PropTypes.element.isRequired,
  MainContentComponent: PropTypes.element.isRequired,
  contentWidth: PropTypes.oneOf(Object.values(ContentSize)),
  RightSidebarComponent: PropTypes.element,
};

ScreenWithSidebars.defaultProps = {
  RightSidebarComponent: undefined,
  contentWidth: ContentSize.Normal,
};

export default ScreenWithSidebars;
