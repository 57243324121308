import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from 'react-native';
import { P } from './Text';
import Icon from './Icon';
import { useLocale } from '../services/localization';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    color: '#6FC62E',
    paddingVertical: 1,
    paddingHorizontal: 5,
    paddingRight: 7,
    borderRadius: 6,
  },
  icon: {
    marginBottom: 1,
    marginRight: 3,
    color: 'grey',
  },
  label: {
    color: 'grey',
  },
  active: {
    color: '#6FC62E',
  },
});

function ScannerStatus({ style, active, disabled }) {
  const $t = useLocale();
  if (disabled) {
    return null;
  }
  return (
    <View style={[styles.container, style]}>
      <Icon
        style={[styles.icon, active && styles.active]}
        name="barcode"
        size={21}
      />
      <P style={[styles.label, active && styles.active]}>
        {active
          ? $t({ id: 'ScannerStatus.ActiveText', defaultMessage: 'Barcode scanner active' })
          : $t({ id: 'ScannerStatus.NotActiveText', defaultMessage: 'Barcode scanner not active, click here' })}
      </P>
    </View>
  );
}

ScannerStatus.propTypes = {
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

ScannerStatus.defaultProps = {
  disabled: false,
};

export default ScannerStatus;
