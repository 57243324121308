import { useMutation } from '@apollo/client';
import { COMPLETE_ORDER } from '../../graphql/OrderQuery';
import { OrderStatus } from '../../constants';

const useCompleteOrder = (orderId, onCompleted, onError) => {
  const [runMutation, { loading }] = useMutation(
    COMPLETE_ORDER,
    {
      variables: { orderId },
      optimisticResponse: {
        orderCompleted: {
          id: orderId,
          status: OrderStatus.Completing,
          __typename: 'Order',
        },
      },
      refetchQueries: ['GetOrders'],
      onError(error) {
        if (onError) onError(error);
      },
      onCompleted() {
        if (onCompleted) onCompleted();
      },
    },
  );

  const completeOrder = () => {
    if (loading) return;
    runMutation();
  };

  return { completeOrder, loading };
};

export default useCompleteOrder;
