import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  heading: {
    marginBottom: 30,
  },
  input: {
    marginBottom: 15,
  },
  actionsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  actionsSecondaryActions: {
    flexDirection: 'row',
  },
  deleteButton: {
    color: '#D11212',
    textDecorationLine: 'underline',
    marginRight: 30,
  },
});

export default styles;
