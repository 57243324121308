import React from 'react';
import PropTypes from 'prop-types';
import { FlatList, View, StyleSheet } from 'react-native';
import TodoItem, { TodoItemType } from './TodoItem';

const styles = StyleSheet.create({
  container: {
    borderTopColor: '#f2f2f2',
    borderTopWidth: 1,
    padding: 30,
  },
  stepGuideLine: {
    position: 'absolute',
    width: 3,
    height: '100%',
    backgroundColor: '#f2f2f2',
    left: 44,
  },
});

function TodoItemSeparator() {
  return <View style={{ height: 40 }} />;
}

function TodoList({ style, data }) {
  return (
    <View>
      <View style={styles.stepGuideLine} />
      <FlatList
        style={[styles.container, style]}
        data={data}
        renderItem={TodoItem}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={TodoItemSeparator}
      />
    </View>
  );
}

TodoList.propTypes = {
  data: PropTypes.arrayOf(TodoItemType).isRequired,
};

export default TodoList;
