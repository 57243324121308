import React from 'react';
import { enableScreens } from 'react-native-screens';
import { useAuthContext } from '../services/auth';
import ProtectedNavigationStack from './ProtectedStackNavigation';
import PublicNavigationStack from './PublicStackNavigation';

enableScreens();

function Router() {
  const { auth, user } = useAuthContext();

  if (auth?.token && user) {
    return <ProtectedNavigationStack />;
  }

  return <PublicNavigationStack />;
}

export default Router;
