import { useMutation } from '@apollo/client';
import { UPDATE_PICKER } from '../graphql/PickerQuery';

const useUpdatePicker = (pickerFields, onCompleted, onError) => {
  const [runMutation, { loading }] = useMutation(UPDATE_PICKER, {
    variables: pickerFields,
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted() {
      if (typeof onCompleted === 'function') onCompleted();
    },
  });

  const updatePicker = () => {
    if (loading) return;
    runMutation();
  };

  return { updatePicker, loading };
};

export default useUpdatePicker;
