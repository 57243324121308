import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { ErrorNotification } from '../../../components/ErrorNotification';
import useShippingLabel from '../../../hooks/order/useShippingLabel';
import usePrint from '../../../hooks/usePrint';
import { ShippingPluginName, ShippingLabelStatus } from '../../../constants';
import { useLocale } from '../../../services/localization';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
});

const DeclaredState = {
  Declared: 'declared',
  DeclaredSucces: 'declaredSucces',
  Printing: 'printing',
};

function wait(milliseconds) {
  return new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });
}

// @TODO - move helper to service
function forceHttps(url) {
  const isHttps = url.includes('https');
  return !isHttps ? url.replace('http', 'https') : url;
}

function getProviderName(provider) {
  if (!provider) {
    return '';
  }
  const name = ShippingPluginName[provider];
  return name || provider;
}

function PrintingLabel({ orderId, onCompleted }) {
  const $t = useLocale();
  const [state, setState] = React.useState(DeclaredState.Declared);
  const { data } = useShippingLabel(orderId);
  const { print } = usePrint();

  const providerName = getProviderName(data?.order?.shipping_provider);

  const translations = React.useMemo(() => ({
    orderDeclared: $t({ id: 'ShippingModal.PrintingLabel.OrderDeclared', defaultMessage: 'Order declared!' }),
    orderDeclaredText: $t({ id: 'ShippingModal.PrintingLabel.OrderDeclaredWith', defaultMessage: 'Order is successfully declared with {provider}' }, { provider: providerName }),
    printingLabel: $t({ id: 'ShippingModal.PrintingLabel.PrintingLabel', defaultMessage: 'Printing label' }),
    printingLabelText: $t({ id: 'ShippingModal.PrintingLabel.OneMoment', defaultMessage: 'One moment, we are starting the shipping label print' }),
  }), [$t, providerName]);

  useEffect(() => {
    if (!data || data?.order?.shipping_label_status !== ShippingLabelStatus.DECLARED) {
      return;
    }
    async function procede() {
      await wait(2000);
      if (!data?.order?.shipping_label) {
        setState(DeclaredState.DeclaredSucces);
        await wait(1000);
        onCompleted();
        return;
      }
      setState(DeclaredState.Printing);
      await wait(2000);
      const options = {
        ref: 'shipping_label',
      };
      const url = forceHttps(data.order.shipping_label);
      print(url, options, onCompleted);
    }
    procede();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]); // @TODO - adding more deps would retrigger infinete loop

  if (state === DeclaredState.Declared) {
    return (
      <ErrorNotification
        style={styles.container}
        icon="loader"
        errorTitle={translations.orderDeclared}
        errorMessage={translations.orderDeclaredText}
      />
    );
  }

  if (state === DeclaredState.DeclaredSucces) {
    return (
      <ErrorNotification
        style={styles.container}
        icon="checkmark"
        errorTitle={translations.orderDeclared}
        errorMessage={translations.orderDeclaredText}
      />
    );
  }

  return (
    <ErrorNotification
      style={styles.container}
      icon="loader"
      errorTitle={translations.printingLabel}
      errorMessage={translations.printingLabelText}
    />
  );
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

PrintingLabel.propTypes = {
  orderId: IdType.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

export default PrintingLabel;
