import { gql } from '@apollo/client';

export const GET_SHOPS = gql`
  query GetShops {
    shops(first: 50) {
      data {
        id
        name
        url
        status
        wizard_status
      }
    }
  }
`;

export const HAS_SHOP = gql`
  query HasShop {
    shops(first: 1) {
      data {
        id
        wizard_status
        logo
        address
        city
        postcode
        country
        installed_plugin_version
      }
    }
  }
`;

export const GET_SHOP = gql`
  query GetShop($shopId: ID!) {
    shop(id: $shopId) {
      id
      name
      url
      key
      secret
      status
      import_order_status
      completed_order_status
      wizard_status
      woocommerce_order_statuses {
        slug
        name
      }
      logo
      barcode_postmeta_key
      address
      address_2
      city
      postcode
      country
      pdf_footer_text
      language
      shipping_fields {
        plugin
        active
      }
      installed_plugin_version
    }
  }
`;

export const CREATE_SHOP = gql`
  mutation CreateShop($name: String!, $url: String!, $key: String!, $secret: String!, $language: Language!) {
    createShop(input: { name: $name, url: $url, key: $key, secret: $secret, language: $language }) {
      id
      name
      url
      key
      secret
      status
      language
      installed_plugin_version
    }
  }
`;

export const UPDATE_SHOP = gql`
  mutation UpdateShop(
    $shopId: ID!
    $name: String
    $url: String
    $key: String
    $secret: String
    $import_order_status: String
    $completed_order_status: String
    $logo: String
    $barcode_postmeta_key: String
    $address: String
    $city: String
    $postcode: String
    $country: String
    $pdf_footer_text: String
    $language: Language
  ) {
    updateShop(
      input: {
        id: $shopId
        name: $name
        url: $url
        key: $key
        secret: $secret
        import_order_status: $import_order_status
        completed_order_status: $completed_order_status
        logo: $logo
        barcode_postmeta_key: $barcode_postmeta_key
        address: $address
        postcode: $postcode
        city: $city
        country: $country
        pdf_footer_text: $pdf_footer_text
        language: $language
      }
    ) {
      id
      name
      url
      key
      secret
      import_order_status
      completed_order_status
      woocommerce_order_statuses {
        slug
        name
      }
      status
      logo
      barcode_postmeta_key
      address
      city
      postcode
      country
      pdf_footer_text
      language
    }
  }
`;

export const DELETE_SHOP = gql`
  mutation DeleteShop($shopId: ID!) {
    deleteShop(id: $shopId) {
      id
    }
  }
`;

export const TEST_SHOP = gql`
  mutation TestShop($shopId: ID!) {
    testShop(id: $shopId) {
      id
      status
    }
  }
`;
