import { useMutation, gql } from '@apollo/client';
import { ShippingPlugin } from '../../constants';
import { UPDATE_ORDER_FIELDS } from '../../graphql/OrderQuery';

const TOGGLE_SHIPPING_PLUGIN = gql`
  mutation ($input: ShopUpdateInput!) {
    updateShop(input: $input) {
        id
        shipping_fields {
          plugin
          active
        }
    }
  }
`;

function isValidShippingPlugin(pluginName) {
  return Object.values(ShippingPlugin).includes(pluginName);
}

const useToggleShippingLabelPlugin = (onCompleted, onError) => {
  const [runMutation, { loading, data, error }] = useMutation(
    TOGGLE_SHIPPING_PLUGIN,
    {
      // @TODO: improve refetching order settings
      // force refetching shipping providers for orders to make sure the declare button is updated and displaying the correct state
      refetchQueries: [
        { query: UPDATE_ORDER_FIELDS },
      ],
      onError(e) {
        if (onError) onError(e);
      },
      onCompleted(response) {
        if (onCompleted) onCompleted(response);
      },
    },
  );

  const toggleShippingPlugin = (shopId, pluginName, enable) => {
    if (loading) return;
    if (!shopId || !pluginName || !isValidShippingPlugin(pluginName)) return;

    runMutation({
      variables: {
        input: {
          id: shopId,
          shipping_fields: {
            [pluginName]: enable,
          },
        },
      },
    });
  };

  return {
    toggleShippingPlugin, loading, data, error,
  };
};

export default useToggleShippingLabelPlugin;
