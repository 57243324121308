import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { gql, useMutation } from '@apollo/client';
import { P, Strong } from '../../../components/Text';
import Icon from '../../../components/Icon';
import ToggleButton from '../../../components/Fields/ToggleButton';
import { colors } from '../../../constants';
import { useLocale } from '../../../services/localization';
import { mutationError } from '../../../services/Error';
import { useToastNotification } from '../../../services/toast';
import styles from './ToggleNewsletter.styles';

export const TOGGLE_NEWSLETTER = gql`
  mutation ($userId: ID!, $newsletter: Boolean!) {
    updateUser(
      input: {
          id: $userId
          newsletter: $newsletter
        }
      ) {
        id
        newsletter
    }
  }
`;

function ToggleNewsletter({ userId, isSubscribed }) {
  const $t = useLocale();
  const { toastSucces, toastInfo, toastWarning } = useToastNotification();
  const prevToggledState = React.useRef(isSubscribed);
  const [toggled, setToggled] = React.useState(isSubscribed);

  const translations = React.useMemo(() => ({
    subscribed: $t({ id: 'SettingsScreen.Newsletter.Subscribed.Label', defaultMessage: 'Subscribed' }),
    notSubscribed: $t({ id: 'SettingsScreen.Newsletter.NotSubscribed.Label', defaultMessage: 'Not subscribed' }),
    subscribing: $t({ id: 'SettingsScreen.Newsletter.Subscribing.Label', defaultMessage: 'Subscribing...' }),
    unsubscribing: $t({ id: 'SettingsScreen.Newsletter.Unsubscribing.Label', defaultMessage: 'Unsubscribing...' }),
    heading: $t({ id: 'SettingsScreen.Newsletter.Heading', defaultMessage: 'Newsletter' }),
    explainer: $t({ id: 'SettingsScreen.Newsletter.Explainer', defaultMessage: 'Subscribe to the FastPicker newsletter to stay up-to-date on the latest features and functionalities.' }),
    subscribedToast: $t({ id: 'SettingsScreen.Newsletter.Subscribed.Toast', defaultMessage: 'Thanks for subscribing! We\'ll keep you up-to-date on the latest news.' }),
    notSubscribedToast: $t({ id: 'SettingsScreen.Newsletter.UnSubscribed.Toast', defaultMessage: 'We removed your from our mailing list. You no longer receive any newsletters from us.' }),
  }), [$t]);

  const [toggleNewsletter, { loading }] = useMutation(TOGGLE_NEWSLETTER, {
    onError(error) {
      setToggled(prevToggledState.current);
      toastWarning(mutationError(error));
    },
    onCompleted(data) {
      if (data?.updateUser?.newsletter) {
        toastSucces(translations.subscribedToast);
      } else {
        toastInfo(translations.notSubscribedToast);
      }
    },
  });

  const hanleTogglePress = async () => {
    const newToggleState = !toggled;
    prevToggledState.current = toggled;
    setToggled(newToggleState);
    toggleNewsletter({
      variables: {
        userId,
        newsletter: newToggleState,
      },
    });
  };

  function renderToggleLabel() {
    if (loading && !isSubscribed) {
      return <Strong style={styles.toggleLabel}>{translations.subscribing}</Strong>;
    }
    if (loading && isSubscribed) {
      return <Strong style={styles.toggleLabel}>{translations.unsubscribing}</Strong>;
    }
    if (isSubscribed) {
      return <Strong style={[styles.toggleLabel, styles.toggleLabelGreen]}>{translations.subscribed}</Strong>;
    }
    return <Strong style={styles.toggleLabel}>{translations.notSubscribed}</Strong>;
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleWrapper}>
        <View style={styles.providerWrapper}>
          <Strong style={styles.providerName}>{translations.heading}</Strong>
        </View>
        <View style={styles.toggleWrapper}>
          {renderToggleLabel()}
          <ToggleButton
            checked={toggled}
            onChange={hanleTogglePress}
            style={styles.toggleButton}
            disabled={loading}
          />
        </View>
      </View>
      <View style={styles.seperator} />
      <View style={styles.downloadPluginContainer}>
        <Icon size={22} name="mail-outline" color={colors.brand.primary} />
        <View style={styles.downloadPluginTextWrapper}>
          <P style={styles.downloadPluginText}>
            {translations.explainer}
          </P>
        </View>
      </View>
    </View>
  );
}

ToggleNewsletter.propTypes = {
  userId: PropTypes.string.isRequired,
  isSubscribed: PropTypes.bool.isRequired,
};

export default ToggleNewsletter;
