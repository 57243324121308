import React from 'react';
import PropTypes from 'prop-types';
import { useLocale } from '../../../services/localization';
import { colors } from '../../../constants';
import QuickActionButton from '../../../components/QuickActionButton';

function CompleteOrderButton({
  onPress, loading, disabled, focus, style, orderStatus,
}) {
  const $t = useLocale();

  if (orderStatus === 'completing') {
    return (
      <QuickActionButton
        style={style}
        label={$t({ id: 'CompleteOrderButton.CompletingButton.Label', defaultMessage: 'Completing order...' })}
        subLabel={$t({ id: 'CompleteOrderButton.CompletingButton.SubLabel', defaultMessage: 'and notifying customer' })}
        focus
        loading
      />
    );
  }

  if (orderStatus === 'completed') {
    return (
      <QuickActionButton
        style={style}
        label={$t({ id: 'CompleteOrderButton.CompletedButton.Label', defaultMessage: 'Order completed' })}
        subLabel={$t({ id: 'CompleteOrderButton.CompletedButton.SubLabel', defaultMessage: 'and customer is notified' })}
        leftIcon="checkmark-circle-outline"
        leftIconColor={colors.ui.ok}
        focus={false}
        disabled
      />
    );
  }

  return (
    <QuickActionButton
      style={style}
      label={$t({ id: 'PickOrderScreen.CompleteOrderButton.Label', defaultMessage: 'Complete order' })}
      subLabel={$t({ id: 'PickOrderScreen.CompleteOrderButton.SubLabel', defaultMessage: 'and notify customer' })}
      rightIcon="arrow-forward"
      focus={focus}
      disabled={disabled}
      onPress={onPress}
      loading={loading}
    />
  );
}

CompleteOrderButton.propTypes = {
  orderStatus: PropTypes.string,
  onPress: PropTypes.func,
  focus: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

CompleteOrderButton.defaultProps = {
  orderStatus: undefined,
  onPress: () => null,
  focus: false,
  loading: false,
  disabled: false,
};

export default CompleteOrderButton;
