import React from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { FadeIn } from '../../components/Animation';
import VerifyEmailForm from '../../components/forms/VerifyEmailForm';
import Logo from '../../components/Logo';

const smallDevice = Dimensions.get('window').width < 1024;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F6F6F6',
    alignItems: 'flex-end',
  },
  content: {
    flex: 1,
    backgroundColor: '#FFF',
    borderTopLeftRadius: smallDevice ? 0 : 20,
    borderBottomLeftRadius: smallDevice ? 0 : 20,
    justifyContent: 'center',
    alignItems: 'center',
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    width: smallDevice ? '100%' : '50%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  logoContainer: {
    width: '100%',
    maxWidth: 380,
    marginTop: 50,
  },
  formContainer: {
    flex: 1,
    maxWidth: 380,
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'center',
    paddingVertical: 50,
  },
});

function VerifyEmailScreen({ route }) {
  const { token } = route.params;

  return (
    <View style={styles.container}>
      <FadeIn style={styles.content}>
        <View style={styles.logoContainer}>
          <Logo />
        </View>
        <View style={styles.formContainer}>
          <VerifyEmailForm token={token} />
        </View>
      </FadeIn>
    </View>
  );
}

export default VerifyEmailScreen;
