import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import { Language, DefaultLanguage } from './types';
import { useAuthContext } from '../auth';

const getTranslationMessages = async (locale) => {
  switch (locale) {
    case Language.NL:
      return import('../../../translations/compiled/nl.json');
    case Language.EN:
    default:
      return import('../../../translations/compiled/en.json');
  }
};

function LocalizationProvider({ children }) {
  const [messages, setMessages] = useState(null);
  const { user } = useAuthContext();

  useEffect(() => {
    (async () => {
      const translations = await getTranslationMessages(user?.language || DefaultLanguage);
      setMessages(translations);
    })();
  }, [user]);

  return (
    <IntlProvider
      messages={messages}
      locale={user?.language || DefaultLanguage}
      defaultLocale={DefaultLanguage}
    >
      {messages && children}
    </IntlProvider>
  );
}

LocalizationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default LocalizationProvider;
