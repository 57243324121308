import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    marginBottom: 40,
  },
  heading: {
    marginTop: 20,
    marginBottom: 15,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#e1e1e1',
  },
  cell: {
    flex: 1,
    paddingHorizontal: 10,
  },
  firstCell: {
    flex: 0.6,
  },
  lastCell: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingHorizontal: 0,
  },
  messageIcon: {
    minWidth: 30,
  },
  message: {
    flex: 2,
  },
  logDetailsText: {
    marginTop: 3,
  },
  actions: {
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  loadMore: {
    borderWidth: 1,
    borderColor: '#D8D8D8',
    height: 40,
    paddingHorizontal: 20,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadMoreLabel: {
    textTransform: 'uppercase',
    color: '#B6B6B6',
    fontSize: 14,
  },
  noMoreLogs: {
    textTransform: 'uppercase',
    fontSize: 12,
    color: '#B6B6B6',
  },
});

export default styles;
