import React from 'react';
import {
  View, TouchableOpacity, StyleSheet, Image,
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { ENV } from '@env';
import { useAuthContext } from '../services/auth';
import { P } from './Text';

import { assets } from '../constants/theme';

const isDevelopment = ENV === 'development';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  logo: {
    width: 127,
    height: 32,
  },
  developmentContainer: {
    backgroundColor: '#ff6a00',
    borderRadius: 3,
    padding: 3,
    marginLeft: 10,
  },
  developmentLabel: {
    color: 'white',
    textAlign: 'center',
  },
  spacer: {
    flex: 1,
  },
});

function DevLabel() {
  return (
    <View style={styles.developmentContainer}>
      <P style={styles.developmentLabel}>
        {`DEV / ${window.location.hostname}`}
      </P>
    </View>
  );
}

function Logo({ style }) {
  const navigation = useNavigation();
  const { auth } = useAuthContext();
  const go = () => navigation.navigate(auth ? 'Dashboard' : 'Login');
  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity activeOpacity={0.8} onPress={go}>
        <Image style={styles.logo} source={assets.images.logo} />
      </TouchableOpacity>
      {isDevelopment && <DevLabel />}
      <View style={styles.spacer} />
    </View>
  );
}

export default Logo;
