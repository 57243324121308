import React from 'react';
import PropTypes from 'prop-types';
import {
  TouchableOpacity, View, StyleSheet, Dimensions,
} from 'react-native';
import { P, H2 } from './Text';
import Icon from './Icon';

const smallDevice = Dimensions.get('window').width < 1024;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignContent: 'space-between',
    backgroundColor: '#3258C5',
    padding: 10,
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
  },
  lightContainer: {
    backgroundColor: '#fff',
  },
  leftSide: {
    flex: 1,
    flexDirection: 'column',
    padding: smallDevice ? 5 : 10,
  },
  label: {
    color: '#fff',
    flex: 1,
    marginBottom: 10,
  },
  lightLabel: {
    color: '#000',
  },
  description: {
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: 16,
    fontFamily: 'Roboto bold',
  },
  lightDescription: {
    color: '#000',
  },
  subText: {
    color: '#d9dff0',
    textTransform: 'uppercase',
    fontSize: 14,
    marginTop: 1,
  },
  lightSubText: {
    color: '#727272',
  },
  iconContainer: {
    backgroundColor: '#3260E5',
    borderRadius: 8,
    width: 90,
    height: 90,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  lightIconContainer: {
    backgroundColor: '#F5F5F5',
  },
});

export const ButtonType = {
  Primary: 'primary',
  Secondary: 'secondary',
};

function LargeActionButton({
  type, label, description, subText, style, onPress,
}) {
  const isLight = type === ButtonType.Secondary;
  return (
    <TouchableOpacity
      activeOpacity={isLight ? 0.8 : 0.5}
      style={[styles.container, isLight && styles.lightContainer, style]}
      onPress={onPress}
    >
      <View style={styles.leftSide}>
        <H2 style={[styles.label, isLight && styles.lightLabel]}>
          {label}
        </H2>
        <P style={[styles.description, isLight && styles.lightDescription]}>
          {description}
        </P>
        <P style={[styles.subText, isLight && styles.lightSubText]}>
          {subText}
        </P>
      </View>
      <View
        style={[styles.iconContainer, isLight && styles.lightIconContainer]}
      >
        <Icon
          name="arrow-forward"
          size={80}
          color={isLight ? 'black' : 'white'}
        />
      </View>
    </TouchableOpacity>
  );
}

LargeActionButton.propTypes = {
  type: PropTypes.oneOf(Object.values(ButtonType)),
  label: PropTypes.string,
  description: PropTypes.string,
  subText: PropTypes.string,
  onPress: PropTypes.func,
};

LargeActionButton.defaultProps = {
  type: ButtonType.Primary,
  label: '',
  description: '',
  subText: '',
  onPress: () => null,
};

export default LargeActionButton;
