import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  badge: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: '#eecb0e',
    borderRadius: 3,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  god: {
    backgroundColor: '#eecb0e',
  },
  picker: {
    backgroundColor: '#cecece',
  },
  owner: {
    backgroundColor: '#3258C5',
  },
  icon: {
    marginRight: 3,
  },
  label: {
    textAlign: 'center',
    color: 'black',
    fontSize: 11,
    textTransform: 'uppercase',
  },
  ownerLabel: {
    color: '#e5ecff',
  },
  pickerLabel: {
    color: '#6a6a6a',
  },
});

export default styles;
