import { gql } from '@apollo/client';

export const GET_PICKERS = gql`
  query {
    pickers(first: 50) {
      data {
        id
        name
        email
      }
    }
  }
`;

export const GET_PICKER = gql`
  query($pickerId: ID!) {
    picker(id: $pickerId) {
      id
      name
      email
    }
  }
`;

export const CREATE_PICKER = gql`
  mutation($name: String!, $email: String!, $password: String!) {
    createPicker(input: { name: $name, email: $email, password: $password }) {
      id
      name
      email
    }
  }
`;

export const UPDATE_PICKER = gql`
  mutation($pickerId: ID!, $name: String) {
    updatePicker(
      input: { id: $pickerId, name: $name }
    ) {
      id
      name
      email
    }
  }
`;

export const DELETE_PICKER = gql`
  mutation($pickerId: ID!) {
    deletePicker(id: $pickerId) {
      id
    }
  }
`;
