import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import Screen from '../../components/Layout';
import FakeOrderList from './FakeOrderList';
import { H1, P, Strong } from '../../components/Text';
import { FadeIn } from '../../components/Animation';
import { pickProgress } from '../../services/Helpers';
import { mutationError } from '../../services/Error';
import LoadingIndicator from '../../components/LoadingIndicator';
import ToggleButton from '../../components/Fields/ToggleButton';
import PageHelperNote from '../../components/PageHelperNote';
import { ErrorNotification } from '../../components/ErrorNotification';
import GettingStartedWizard from '../../features/GettingStartedWizard';
import { useLocale } from '../../services/localization';
import { useAuthContext } from '../../services/auth';
import { colors, OrderStatus } from '../../constants';
import useHealthChecker from '../../hooks/useHealthChecker';
import useOrders from '../../hooks/useOrders';
import styles from './DashboardScreen.styles';
import { can, Actions } from '../../services/permissions';
import OrderListItem from './OrderListItem';
import NextOrderButton from './NextOrderButton';
import ContinueOrderButton from './ContinueOrderButton';
import NothingToPick from './NothingToPick';
import NothingCompleted from './NothingCompleted';
import FetchMoreButton from './FetchMoreButton';
import { hasAConnectedShop } from './helpers';
import { useOrderShortcutButtons, useLoadMore, useNewOrdersSubscription } from './hooks';

// Nice to have:
// @TODO: refactor orders list ui states. if/elses need to be flatter (separate more components)
// @TODO: add shop name to order list

const readyForPicking = [OrderStatus.New, OrderStatus.BeingPicked];
const completed = [OrderStatus.Completed, OrderStatus.Completing];
const itemsPerPage = 15;

function Bold(str) {
  return (<P style={styles.bold}>{str}</P>);
}

function DashboardScreen() {
  const $t = useLocale();
  const { user } = useAuthContext();
  const { issues } = useHealthChecker();

  const [showCompleted, setShowCompleted] = React.useState(false);
  const [refetching, setRefetching] = React.useState(false);

  const {
    loading, error, data, refetch, subscribeToMore, fetchMore,
  } = useOrders({ first: itemsPerPage, page: 1, status: readyForPicking });

  const { loadingShortcuts, shortcuts, hasShortcuts } = useOrderShortcutButtons();
  const { isFetchingMore, fetchMoreOrders } = useLoadMore(fetchMore);

  const canManageShop = React.useMemo(() => can(user.role, Actions.ManageShop), [user]);
  const canPickOrders = React.useMemo(() => hasAConnectedShop(issues), [issues]);

  const pageRef = React.useRef(1);
  const showCompletedRef = React.useRef(false);

  useNewOrdersSubscription(subscribeToMore, itemsPerPage);

  const orders = React.useMemo(() => {
    if (!data || !data?.orders?.data || !data?.orders?.data.length === 0) {
      return [];
    }
    return data?.orders?.data.reduce((agg, item) => {
      agg.push({ ...item, ...pickProgress(item.order_lines) });
      return agg;
    }, []);
  }, [data]);

  const toggleCompletedFilter = React.useCallback(async () => {
    showCompletedRef.current = !showCompletedRef.current;
    setShowCompleted(showCompletedRef.current);
    pageRef.current = 1;
    setRefetching(true);
    await refetch({
      first: itemsPerPage,
      page: pageRef.current,
      status: showCompletedRef.current ? completed : readyForPicking,
    });
    setRefetching(false);
  }, [refetch]);

  const fetchMoreOrdersNow = React.useCallback(() => {
    pageRef.current += 1;
    fetchMoreOrders({
      first: itemsPerPage,
      page: pageRef.current,
      status: showCompletedRef.current ? completed : readyForPicking,
      ...showCompletedRef.current ? { orderBy: { field: 'completed_at', order: 'DESC' } } : {},
    });
  }, [fetchMoreOrders]);

  const refreshOrderList = React.useCallback(async () => {
    setRefetching(true);
    await refetch({
      first: itemsPerPage,
      page: pageRef.current,
      status: showCompletedRef.current ? completed : readyForPicking,
      ...showCompletedRef.current ? { orderBy: { field: 'completed_at', order: 'DESC' } } : {},
    });
    setRefetching(false);
  }, [refetch]);

  if (loading || loadingShortcuts) {
    return (
      <Screen backgroundImage>
        <LoadingIndicator />
      </Screen>
    );
  }

  if (error) {
    return (
      <Screen backgroundImage>
        <ErrorNotification errorMessage={mutationError(error)} />
      </Screen>
    );
  }

  const allShortcutsAvailable = shortcuts?.inProgressOrder && shortcuts?.nextOrder;
  const hasInprogressOrder = shortcuts?.inProgressOrder !== undefined;

  const canLoadMoreOrders = data?.orders?.paginatorInfo.count < data?.orders?.paginatorInfo.total;

  function getSubTitle() {
    if (refetching) {
      return $t({ id: 'OrdersList.RetreivingOrders.Text', defaultMessage: 'Retrieving orders...' });
    }
    if (showCompleted) {
      return (
        $t(
          {
            id: 'OrdersList.AmountCompletedToday.Text',
            defaultMessage: '{orderCount, plural, =0 {No orders picked today} one {<strong>1 order</strong> completed today} other {<strong>{orderCount}</strong> orders completed today}}',
          },
          { orderCount: data?.orders?.paginatorInfo.total, strong: Bold },
        ));
    }
    return (
      $t(
        {
          id: 'OrdersList.AmountUncompletedOrders.Text',
          defaultMessage: '<strong>{orderCount, plural, =0 {No orders} one {1 order} other {{orderCount} orders}}</strong> ready for picking',
        },
        { orderCount: data?.orders?.paginatorInfo.total, strong: Bold },
      ));
  }

  return (
    <Screen backgroundImage>
      {canManageShop && <GettingStartedWizard style={styles.wizard} />}
      {hasShortcuts && (
        <FadeIn>
          <View style={styles.quickActionsContainer}>
            {hasInprogressOrder && <ContinueOrderButton order={shortcuts.inProgressOrder} highlight />}
            {allShortcutsAvailable && <View style={styles.actionSpacer} />}
            {shortcuts?.nextOrder && (
              <NextOrderButton
                wooId={shortcuts.nextOrder.woocommerce_order_id}
                orderId={shortcuts.nextOrder.id}
                highlight={!hasInprogressOrder}
              />
            )}
          </View>
        </FadeIn>
      )}
      {canPickOrders ? (
        <>
          <View style={styles.listHeader}>
            <View style={styles.titleWrapper}>
              {!showCompleted && <H1>{$t({ id: 'OrdersList.Orders.Title', defaultMessage: 'Orders' })}</H1>}
              {showCompleted && <H1>{$t({ id: 'OrdersList.CompletedOrders.Title', defaultMessage: 'Completed orders' })}</H1>}
              <P style={styles.subtitle}>{getSubTitle()}</P>
            </View>
            <View style={styles.listFilters}>
              <Strong style={styles.toggleCompletedLabel}>{!showCompleted ? $t({ id: 'OrdersList.Filter.Completed.Label', defaultMessage: 'Completed' }) : $t({ id: 'OrdersList.Filter.ReadyToPick.Label', defaultMessage: 'Ready to pick' })}</Strong>
              <ToggleButton onChange={toggleCompletedFilter} checked={showCompleted} loading={loading} />
            </View>
          </View>
          {!refetching ? (
            <>
              {orders.map((order, index) => (
                <FadeIn key={`${order.id}-${index}`}>
                  <OrderListItem
                    key={`${order.id}-${index}`}
                    id={order.id}
                    wooId={order.woocommerce_order_id}
                    status={order.status}
                    shippingStatus={order.shipping_label_status}
                    customerName={`${order.shipping_first_name[0]}. ${order.shipping_last_name}`}
                    address={order.shipping_address_1}
                    city={order.shipping_city}
                    totalItems={order.totalItems}
                    pickProgress={order.progressPercentage}
                    disabled={completed.includes(order.status)}
                  />
                </FadeIn>
              ))}
              {canLoadMoreOrders && <FetchMoreButton onPress={fetchMoreOrdersNow} loading={isFetchingMore} />}
              {orders.length === 0 && showCompleted && <NothingCompleted onRefreshPress={refreshOrderList} />}
              {orders.length === 0 && !showCompleted && <NothingToPick onRefreshPress={refreshOrderList} />}
              {showCompleted && <PageHelperNote style={styles.note} note={$t({ id: 'DashboardScreen.Note.CompletedOrdersWillBeRemoved', defaultMessage: 'Completed orders will be removed from FastPicker after 2 hours' })} />}
            </>
          ) : <ActivityIndicator size="large" style={styles.refetchSpinner} color={colors.brand.primary} />}
        </>
      ) : <FakeOrderList />}
    </Screen>
  );
}

export default DashboardScreen;
