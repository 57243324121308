import React, { useState, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { mutationError } from '../../../services/Error';
import { DefaultLanguage, Language, useLocale } from '../../../services/localization';
import { FadeIn } from '../../../components/Animation';
import { Small } from '../../../components/Text';
import { EmailField, TextField, SelectField } from '../../../components/Form';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import useUpdateUser from '../../../hooks/user/useUpdateUser';
import { useToastNotification } from '../../../services/toast';
import { useAuthContext } from '../../../services/auth';
import styles from './UserSettingsForm.styles';

function UserSettingsForm({ userId, initialFields, style }) {
  const { user, auth, signIn } = useAuthContext();
  const [fields, setFields] = useState(initialFields);
  const [variables, setVariables] = useState({});
  const [isTouched, setIsTouched] = useState(false);
  const [error, setError] = useState(false);
  const { toastSucces } = useToastNotification();
  const $t = useLocale();

  const locales = useMemo(() => ({
    toastSucces: $t({ id: 'UserSettingsForm.Toast.SubmitSucces', defaultMessage: 'Account settings updated!' }),
    missingFields: $t({ id: 'Form.Error.MissingFields', defaultMessage: 'Please fill in all required fields.' }),
    nameToShort: $t({ id: 'Form.Error.NameToShort', defaultMessage: 'Please enter a name with atleast 3 characters.' }),
  }), [$t]);

  const handleInputChange = (e, fieldName) => {
    setError(false);
    if (!e) return;
    const value = e.target?.value;
    if (!isTouched && fields[fieldName] !== value) setIsTouched(true);
    setFields({
      ...fields,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    const vars = {};
    Object.keys(initialFields).forEach((key) => {
      if (!fields[key]) return;
      vars[key] = fields[key];
    });
    setVariables(vars);
  }, [fields, initialFields]);

  const { updateUser, loading: isSaving } = useUpdateUser(
    {
      userId,
      ...variables,
    },
    (data) => {
      // Update localstorage after user setting change
      signIn({
        ...user,
        ...data.updateUser,
      }, auth);
      toastSucces(locales.toastSucces);
    },
    (err) => setError(mutationError(err)),
  );

  const submit = () => {
    if (isSaving) return;
    if (fields.email === '' || fields.name === '') {
      setError(locales.missingFields);
      return;
    }
    if (fields.name.length < 3) {
      setError(locales.nameToShort);
      return;
    }
    updateUser();
  };

  return (
    <View style={style}>
      <TextField
        style={styles.input}
        label={$t({ id: 'UserSettingsForm.Label.Name', defaultMessage: 'Name' })}
        placeholder={$t({ id: 'UserSettingsForm.Placeholder.Name', defaultMessage: 'Bill Person' })}
        value={fields.name}
        onChange={(e) => handleInputChange(e, 'name')}
        maxLength={100}
        autoFocus
      />
      <EmailField
        style={styles.input}
        label={$t({ id: 'UserSettingsForm.Label.Email', defaultMessage: 'Email address' })}
        placeholder={$t({ id: 'UserSettingsForm.Placeholder.Email', defaultMessage: 'your@email.com' })}
        value={fields.email}
        disabled
        description={(
          <Small style={styles.note}>
            {
              $t({ id: 'UserSettingsForm.EmailField.ChangeEmailExplanation', defaultMessage: 'Please contact our support if you would like to change your email address.' })
            }
          </Small>
        )}
      />
      <SelectField
        style={styles.input}
        label={$t({ id: 'UserSettingsForm.Label.AppLanguage', defaultMessage: 'App Language' })}
        items={[
          {
            value: Language.EN,
            label: $t({ id: 'UserSettingsForm.Language.Option.English', defaultMessage: 'English' }),
          },
          {
            value: Language.NL,
            label: $t({ id: 'UserSettingsForm.Language.Option.Dutch', defaultMessage: 'Dutch (Nederlands)' }),
          },
        ]}
        selectedValue={fields.language || DefaultLanguage}
        onValueChange={(e) => handleInputChange(e, 'language')}
        description={(
          <Small style={styles.note}>
            {
              $t({ id: 'UserSettingsForm.AppLanguage.ChangeAppLanguage', defaultMessage: 'Change here the language of your orderpicker. We currently support Dutch and English.' })
            }
          </Small>
        )}
      />
      <View style={styles.actions}>
        <Button
          onPress={submit}
          label={isTouched ? $t({ id: 'UserSettingsForm.Button.Save', defaultMessage: 'Save' }) : $t({ id: 'UserSettingsForm.Button.NothingToSave', defaultMessage: 'Nothing to save' })}
          size="small"
          loading={isSaving}
          disabled={!isTouched || isSaving}
        />
      </View>
      {error && (
        <FadeIn>
          <Alert style={styles.error} text={error} />
        </FadeIn>
      )}
    </View>
  );
}

UserSettingsForm.propTypes = {
  userId: PropTypes.string.isRequired,
  initialFields: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
};

UserSettingsForm.defaultProps = {
  initialFields: { name: '', email: '' },
};

export default UserSettingsForm;
