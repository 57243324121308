import { useMutation } from '@apollo/client';
import { GET_SHOPS, DELETE_SHOP } from '../graphql/ShopQuery';
import { useLocale } from '../services/localization';

const useDeleteShop = (shopId, onCompleted) => {
  const $t = useLocale();

  const [runMutation, { loading }] = useMutation(DELETE_SHOP, {
    variables: { shopId },
    update(cache) {
      const existingShops = cache.readQuery({ query: GET_SHOPS });
      if (!existingShops) {
        return;
      }
      const updatedShops = existingShops.shops.data.filter(
        (p) => p.id !== shopId,
      );
      cache.writeQuery({
        query: GET_SHOPS,
        data: {
          shops: { __typename: 'ShopPaginator', data: updatedShops },
        },
      });
    },
    optimisticResponse: {
      deleteShop: {
        id: shopId,
        __typename: 'Shop',
      },
    },
    refetchQueries: 'all',
    onCompleted() {
      onCompleted();
    },
  });

  const deleteShop = () => {
    if (loading) return;
    const msg = $t({
      id: 'DeleteShop.ConfirmPrompt.Text',
      defaultMessage: 'Are you sure you want to remove this shop? This action can not be reversed.',
    });
    if (window.confirm(msg)) {
      runMutation();
    }
  };

  return { deleteShop };
};

export default useDeleteShop;
