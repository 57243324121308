import React, { useMemo, useState } from 'react';
import { Image, View } from 'react-native';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import PropTypes from 'prop-types';
import { SmartSelect } from '../../../../components/Fields';
import { useDebounce } from '../../../../hooks/interface';
import { P } from '../../../../components/Text';
import { useLocale } from '../../../../services/localization';
import styles from './SendcloudProviderProduct.styles';
import { assets } from '../../../../constants';

const GET_SENDCLOUD_PROVIDERS_PRODUCTS = gql`
query GetSendcloudProviderProducts($orderId: ID!, $weight: Float) {
  sendcloudGetProviders(orderId: $orderId, weight: $weight) {
    products {
      id
      name
      provider
      max_weight
    }
    providers
  }
}
`;

const SendcloudCarrierName = { // @TODO: move to shipping constants
  sendcloud: 'Sendcloud',
  dhl: 'DHL',
  postnl: 'PostNL',
  dpd: 'DPD',
  ups: 'UPS',
  dhl_express: 'DHL Express',
  deutsche_post: 'Deutsche Post',
  mondial_relay: 'Mondial Relay',
  budbee: 'Budbee',
  trunkrs: 'Trunkrs',
  homerr: 'Homerr',
  viatim: 'Viatim',
  storeshippers: 'Storeshippers',
  fietskoeriersnl: 'Fietskoeriers.nl',
  quicargo: 'Quicargo',
  gls: 'GLS',
  fedex: 'FedEx',
};

function CarrierLogo(carrierName) {
  return <Image source={assets.images[carrierName]} resizeMode="contain" style={styles.carrierLogo} />;
}

function SendcloudProviderProduct({
  orderId, weight, onChange, selectedValue,
}) {
  const $t = useLocale();
  const weightValue = useDebounce(weight, 300);

  const translations = {
    enterWeightFirst: $t({ id: 'SendcloudProviderProduct.EnterWeightFirst', defaultMessage: 'Please enter the weight of the package first.' }),
    loadingProducts: $t({ id: 'SendcloudProviderProduct.LoadingProducts', defaultMessage: 'Loading products...' }),
    noProductsAvailable: $t({ id: 'SendcloudProviderProduct.NoProductsFound', defaultMessage: 'No products found that allow for a package of {weight} kg.' }, { weight }),
  };

  const productsQuery = useQuery(GET_SENDCLOUD_PROVIDERS_PRODUCTS, {
    variables: { orderId, weight: parseFloat(weightValue) },
    skip: weightValue === null,
  });
  const [selectedProvider, setSelectedProvider] = useState();

  const options = useMemo(() => {
    if (!productsQuery.data || productsQuery.data.sendcloudGetProviders?.products.length === 0) {
      return [];
    }
    const providers = productsQuery.data.sendcloudGetProviders.providers.map((provider) => (
      { value: provider, label: SendcloudCarrierName[provider] || [provider], iconComponent: CarrierLogo(provider) }
    ));
    const filteredProducts = productsQuery.data.sendcloudGetProviders.products.filter(({ provider }) => provider === selectedProvider);
    const products = filteredProducts.map((product) => ({ value: product.id, label: product.name }));
    return {
      providers,
      products,
    };
  }, [productsQuery.data, selectedProvider]);

  function handleSelectProvider(newProvider) {
    if (newProvider === selectedProvider) {
      return;
    }
    setSelectedProvider(newProvider);
    const providerProducts = productsQuery.data.sendcloudGetProviders.products.filter(({ provider }) => provider === newProvider);
    onChange(providerProducts[0].id); // Select first product after selecting a new provider
  }

  function renderContent() {
    if (weightValue === null) {
      return <View style={styles.placeholder}><P>{translations.enterWeightFirst}</P></View>;
    }
    if (productsQuery.loading && productsQuery.networkStatus !== NetworkStatus.refetch) {
      return <View style={styles.placeholder}><P>{translations.loadingProducts}</P></View>;
    }
    if (productsQuery.networkStatus === NetworkStatus.ready && options.length === 0) {
      return <View style={styles.placeholder}><P>{translations.noProductsAvailable}</P></View>;
    }
    return (
      <>
        <SmartSelect swapAt={6} name="sendcloudProvider" placeholder="Select provider" selectedValue={selectedProvider} options={options.providers} onChange={(provider) => handleSelectProvider(provider)} />
        <SmartSelect name="sendcloudProduct" placeholder="Select product" selectedValue={selectedValue} options={options.products} onChange={onChange} style={{ marginTop: 5 }} />
      </>
    );
  }

  return (
    <View style={styles.container}>
      {renderContent()}
    </View>
  );
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

SendcloudProviderProduct.propTypes = {
  orderId: IdType.isRequired,
  weight: IdType,
  // eslint-disable-next-line react/forbid-prop-types
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
};

SendcloudProviderProduct.defaultProps = {
  selectedValue: undefined,
  weight: undefined,
  onChange: () => null,
};

export default SendcloudProviderProduct;
