import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Dimensions } from 'react-native';
import { Strong } from './Text';
import Icon from './Icon';
import { ShippingLabelStatus, OrderStatus, colors } from '../constants';
import { useLocale } from '../services/localization';

const smallDevice = Dimensions.get('window').width < 1024;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F6F6F6',
    borderRadius: 6,
    width: smallDevice ? 'auto' : 190,
    justifyContent: 'center',
    alignSelf: smallDevice ? 'center' : 'stretch',
    padding: 15,
    flexDirection: 'row',
    alignItems: 'center',
    height: 45,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    color: '#2B54C9',
    textTransform: 'uppercase',
    textAlign: 'center',
    textAlignVertical: 'center',
    fontSize: 13,
  },
  continue: {
    backgroundColor: '#3258C5',
    borderRadius: 6,
    justifyContent: 'center',
  },
  continueLabel: {
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    textAlignVertical: 'center',
  },
  completedLabel: {
    color: '#6FC62E',
  },
  errorContainer: {
    backgroundColor: '#F6F6F6',
  },
  errorLabel: {
    color: '#0f0f0f',
  },
});

function OrderStatusLabel({
  status, pickProgress, shippingStatus,
}) {
  const $t = useLocale();

  if (status === OrderStatus.Completed || status === OrderStatus.Completing) {
    return (
      <View style={styles.container}>
        <Strong style={[styles.label, styles.completedLabel]} numberOfLines={1}>
          {$t({
            id: 'OrderStatusLabel.Ready',
            defaultMessage: 'Completed',
          })}
        </Strong>
      </View>
    );
  }

  if (shippingStatus === ShippingLabelStatus.FAILED_TO_DECLARE) {
    return (
      <View style={[styles.container, styles.errorContainer]}>
        <Icon name="alert-circle" size={20} color={colors.ui.attention} style={styles.icon} />
        <Strong style={[styles.label, styles.errorLabel]} numberOfLines={1}>
          {$t({
            id: 'OrderStatusLabel.FailedToDeclare',
            defaultMessage: 'Failed to declare ',
          })}
        </Strong>
      </View>
    );
  }

  if (shippingStatus === ShippingLabelStatus.DECLARING) {
    return (
      <View style={[styles.container, styles.continue]}>
        <Strong style={[styles.label, styles.continueLabel]} numberOfLines={1}>
          {$t({
            id: 'OrderStatusLabel.Declaring',
            defaultMessage: 'Declaring',
          })}
        </Strong>
      </View>
    );
  }

  if (shippingStatus === ShippingLabelStatus.DECLARED) {
    return (
      <View style={[styles.container, styles.continue]}>
        <Strong style={[styles.label, styles.continueLabel]} numberOfLines={1}>
          {$t({
            id: 'OrderStatusLabel.PickedAndDeclared',
            defaultMessage: 'Picked and declared',
          })}
        </Strong>
      </View>
    );
  }

  if (pickProgress > 0) {
    return (
      <View style={[styles.container, styles.continue]}>
        <Strong style={[styles.label, styles.continueLabel]} numberOfLines={1}>
          {$t({
            id: 'OrderStatusLabel.PercentagePicked',
            defaultMessage: '{percentage}% picked, continue',
          }, {
            percentage: pickProgress,
          })}
        </Strong>
      </View>
    );
  }

  if (pickProgress === 0) {
    return (
      <View style={styles.container}>
        <Strong style={styles.label} numberOfLines={1}>
          {$t({
            id: 'OrderStatusLabel.ReadyToPick',
            defaultMessage: 'Ready to pick',
          })}
        </Strong>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <Strong style={styles.label} numberOfLines={1}>
        {$t({
          id: 'OrderStatusLabel.Continue',
          defaultMessage: 'Continue',
        })}
      </Strong>
    </View>
  );
}

OrderStatusLabel.propTypes = {
  status: PropTypes.oneOf(Object.values(OrderStatus)).isRequired,
  shippingStatus: PropTypes.oneOf(Object.values(ShippingLabelStatus)),
  pickProgress: PropTypes.number,
};

OrderStatusLabel.defaultProps = {
  pickProgress: 0,
  shippingStatus: undefined,
};

export default OrderStatusLabel;
