import React from 'react';
import { mutationError } from '../../services/Error';
import { FadeIn } from '../../components/Animation';
import { H4 } from '../../components/Text';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ErrorNotification } from '../../components/ErrorNotification';
import UserSettingsForm from './UserSettingsForm';
import UserPasswordForm from './UserPasswordForm';
import { useLocale } from '../../services/localization';
import { useAuthContext } from '../../services/auth';
import useUser from '../../hooks/useUser';
import ScreenWithSidebars from '../../components/ScreenWithSidebars';
import styles from './SettingsScreen.styles';
import BrowserNotifications from './BrowserNotifications';
import ToggleNewsletter from './ToggleNewsletter';
import DataProcessingAgreement from './DataProcessingAgreement';
import LeftSidebar from './LeftSidebar';
import { Tab } from './shared';
import { can, Actions } from '../../services/permissions';

function SettingsScreen({ route }) {
  const { tab } = route.params;
  const { user } = useAuthContext();
  const { loading, error, data } = useUser(user.id);
  const canManageShop = React.useMemo(() => can(user.role, Actions.ManageShop), [user]);
  const $t = useLocale();

  function render() {
    if (!tab || !Object.values(Tab).includes(tab) || tab === Tab.Details) {
      return (
        <FadeIn>
          <H4 style={styles.heading}>
            {$t({ id: 'SettingsScreen.Heading.Account', defaultMessage: 'Account' })}
          </H4>
          <UserSettingsForm
            userId={data.user.id}
            initialFields={
              {
                name: data.user.name,
                email: data.user.email,
                language: data.user.language,
              }
            }
          />
        </FadeIn>
      );
    }
    if (tab === Tab.Security) {
      return (
        <FadeIn>
          <H4 style={styles.heading}>
            {$t({ id: 'SettingsScreen.Title.Password', defaultMessage: 'Password' })}
          </H4>
          <UserPasswordForm userId={data.user.id} />
        </FadeIn>
      );
    }
    if (tab === Tab.Privacy && canManageShop) {
      return (
        <FadeIn>
          <H4 style={styles.heading}>
            {$t({ id: 'SettingsScreen.Title.Privacy', defaultMessage: 'Privacy' })}
          </H4>
          <DataProcessingAgreement userId={data.user.id} acceptDate={data.user.data_processing_agreement} />
        </FadeIn>
      );
    }
    if (tab === Tab.Notifications) {
      return (
        <FadeIn>
          <H4 style={styles.heading}>
            {$t({ id: 'SettingsScreen.Title.Notifications', defaultMessage: 'Notifications' })}
          </H4>
          <BrowserNotifications />
          <H4 style={styles.heading}>
            {$t({ id: 'SettingsScreen.Emails.Title', defaultMessage: 'Emails' })}
          </H4>
          <ToggleNewsletter userId={data.user.id} isSubscribed={!!data.user.newsletter} />
        </FadeIn>
      );
    }
    return null;
  }

  if (loading) return <LoadingIndicator />;
  if (error || !data) {
    return (
      <FadeIn>
        <ErrorNotification errorMessage={mutationError(error)} />
      </FadeIn>
    );
  }
  return (
    <ScreenWithSidebars
      LeftSidebarComponent={<LeftSidebar username={data.user.name} canManageShop={canManageShop} role={data.user.role} />}
      MainContentComponent={render()}
    />
  );
}

export default SettingsScreen;
