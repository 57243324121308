import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { H1 } from '../../../components/Text';
import styles from './ModalHeader.styles';

function ModalHeader({ title }) {
  return (
    <View style={styles.header}>
      <H1>{title}</H1>
    </View>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ModalHeader;
