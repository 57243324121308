import React, { useMemo, useEffect } from 'react';
import { Linking, View } from 'react-native';
import {
  gql, useQuery, useMutation, useLazyQuery,
} from '@apollo/client';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { P, Small, Strong } from '../../../components/Text';
import styles from './ConnectForm.styles';
import useForm from '../../../hooks/form';
import { ValidationRule } from '../../../services/Validation';
import { TextField } from '../../../components/Form';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import Icon from '../../../components/Icon';
import { colors, links, SendcloudStatus } from '../../../constants';
import Alert from '../../../components/Alert';
import { useLocale } from '../../../services/localization';
import { UPDATE_ORDER_FIELDS } from '../../../graphql/OrderQuery';

const CONNECT_SENDCLOUD = gql`
mutation UpdateSendcloudCredentials($shopId: ID!, $publicKey: String!, $secretKey: String!) {
  sendcloudCredentials(shopId: $shopId, public_key: $publicKey, secret_key: $secretKey) {
    id
    sendcloud_status
  }
}
`;

export const GET_SENDCLOUD_STATUS = gql`
  query GetSendcloudStatus($shopId: ID!) {
    shop(id: $shopId) {
      id
      sendcloud_status
    }
  }
`;

const initialFields = {
  publicKey: '',
  secretKey: '',
};

const validation = {
  publicKey: [ValidationRule.notEmpty],
  secretKey: [ValidationRule.notEmpty],
};

function styledValitationError(errors) {
  // eslint-disable-next-line react/no-array-index-key
  return errors && errors.map((message, index) => <Small style={styles.validationError} key={`error-${index}`}>{message}</Small>);
}

function useRefetchOrdersOnSucces(query, refreshFunc) {
  useEffect(() => {
    const newStatus = query.data?.shop.sendcloud_status;
    if (newStatus === SendcloudStatus.Active) {
      refreshFunc();
    }
  }, [query, refreshFunc]);
}

function ConnectForm({ shopId, onRequestClose, onCompleted }) {
  const $t = useLocale();
  const form = useForm(initialFields, validation);
  const sendcloudStatusQuery = useQuery(GET_SENDCLOUD_STATUS, { variables: { shopId } });
  const [refreshOrderShippingFields] = useLazyQuery(UPDATE_ORDER_FIELDS);
  const [connectSendcloud, connectMutation] = useMutation(CONNECT_SENDCLOUD);

  useRefetchOrdersOnSucces(sendcloudStatusQuery, refreshOrderShippingFields);

  const locale = useMemo(() => ({
    connectSendcloudHeading: $t({ defaultMessage: 'Connect Sendcloud', id: 'SendcloudConnectForm.ConnectSendcloud.Heading' }),
    invalidCredentials: $t({ defaultMessage: 'Unable to connect with the given credentials. Please check if you entered the correct secret and private keys.', id: 'SendcloudConnectForm.InvalidCredentials.Error' }),
    accountIncomplete: $t({ defaultMessage: 'Unable to connect. Looks like Sendcloud isn\'t setup correctly. Please check you Sendcloud account.', id: 'SendcloudConnectForm.AccountIncomplete.Error' }),
    close: $t({ defaultMessage: 'Close', id: 'SendcloudConnectForm.Close.Button.Label' }),
    connectSendcloud: $t({ defaultMessage: 'Connect sendcloud', id: 'SendcloudConnectForm.Connect.Button.Label' }),
    validatingCredentials: $t({ defaultMessage: 'Validating credentials...', id: 'SendcloudConnectForm.Connect.Button.LoadingLabel' }),
    continue: $t({ defaultMessage: 'Continue', id: 'SendcloudConnectForm.Continue.Button.Label' }),
    enterYourKeys: $t({ defaultMessage: 'Please enter your public and private SendCloud API keys to connect to your SendCloud account 👇', id: 'SendcloudConnectForm.EnterYourKeys.Explainer' }),
    publicKeyLabel: $t({ defaultMessage: 'Public key', id: 'SendcloudConnectForm.PublicKey.Field.Label' }),
    publicKeyPlaceholder: $t({ defaultMessage: 'Enter your public key here', id: 'SendcloudConnectForm.PublicKey.Field.Placeholder' }),
    needHelp: $t({ defaultMessage: 'Where can I find my SendCloud API keys?', id: 'SendcloudConnectForm.NeedHelp.Link.Label' }),
    secretKeyLabel: $t({ defaultMessage: 'Secret key', id: 'SendcloudConnectForm.SecretKey.Field.Label' }),
    secretKeyPlaceholder: $t({ defaultMessage: 'Enter your secret key here', id: 'SendcloudConnectForm.SecretKey.Field.Placeholder' }),
    sendcloudConnected: $t({ defaultMessage: 'Sendcloud connected!', id: 'SendcloudConnectForm.SendcloudConnected.Title' }),
    sendcloudConnectedText: $t({ defaultMessage: 'Your orderpicker is now connected with Sendcloud. Please continue to check if you have setup you Sendcloud correctly.', id: 'SendcloudConnectForm.SendcloudConnected.Text' }),
  }), [$t]);

  async function submitConnectRequest() {
    if (connectMutation.loading || form.hasValidationErrors()) {
      return;
    }
    try {
      await connectSendcloud({
        variables: {
          shopId,
          publicKey: form.values.publicKey.value,
          secretKey: form.values.secretKey.value,
        },
      });
    } catch (e) {
      // @TODO: [sendcloud]: Error handling
      console.log(e);
    }
  }

  const status = sendcloudStatusQuery.data?.shop.sendcloud_status;
  const checkingConnection = status === SendcloudStatus.CheckingConnection || connectMutation.loading;

  function renderError() {
    if (connectMutation.error) {
      return <Alert text={connectMutation.error?.message} style={styles.alert} />;
    }
    if (status === SendcloudStatus.InvalidCredentials) {
      return <Alert text={locale.invalidCredentials} style={styles.alert} />;
    }
    if (status === SendcloudStatus.AccountIncomplete) {
      return <Alert text={locale.accountIncomplete} style={styles.alert} />;
    }
    return null;
  }

  function renderButtons() {
    if (status !== SendcloudStatus.Active) {
      return (
        <>
          <Button type="secondary" label={locale.close} onPress={onRequestClose} />
          <Button
            label={locale.connectSendcloud}
            onPress={() => submitConnectRequest()}
            disabled={!form.canSubmitForm}
            loading={checkingConnection}
            loadingLabel={locale.validatingCredentials}
          />
        </>
      );
    }
    return (
      <>
        <View style={{ flex: 1 }} />
        <Button label={locale.continue} onPress={onCompleted} />
      </>
    );
  }

  function renderForm() {
    if (status !== SendcloudStatus.Active) {
      return (
        <View style={styles.container}>
          <P style={styles.text}>{locale.enterYourKeys}</P>
          {renderError()}
          <TextField
            style={styles.input}
            label={locale.publicKeyLabel}
            placeholder={locale.publicKeyPlaceholder}
            value={form.values.publicKey.value}
            onChangeText={(val) => form.handleOnChange('publicKey', val)}
            onBlur={() => form.checkValidation('publicKey')}
            description={styledValitationError(form.validationErrors.publicKey)}
            moreButtonOnPress={() => Linking.openURL(links.SendcloudApiKeyHelp)}
            moreButtonLabel={locale.needHelp}
            required
            returnKeyType="next"
            autoFocus
            disabled={checkingConnection}
          />
          <TextField
            style={styles.input}
            label={locale.secretKeyLabel}
            placeholder={locale.secretKeyPlaceholder}
            value={form.values.secretKey.value}
            onChangeText={(val) => form.handleOnChange('secretKey', val)}
            onBlur={() => form.checkValidation('secretKey')}
            description={styledValitationError(form.validationErrors.secretKey)}
            required
            returnKeyType="next"
            disabled={checkingConnection}
          />
        </View>
      );
    }
    return (
      <View style={styles.connectedContainer}>
        <Icon name="checkmark-circle-outline" size={65} color={colors.ui.ok} />
        <Strong style={styles.textHeader}>{locale.sendcloudConnected}</Strong>
        <P style={[styles.text, styles.connectedText]}>
          {locale.sendcloudConnectedText}
        </P>
      </View>
    );
  }

  return (
    <>
      <ModalHeader title={locale.connectSendcloudHeading} />
      {renderForm()}
      <ModalFooter>
        {renderButtons()}
      </ModalFooter>
    </>
  );
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

ConnectForm.propTypes = {
  shopId: IdType.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

export default ConnectForm;
