import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  contentWrapper: {
    maxWidth: 800,
    width: 680,
    maxHeight: 1000,
    backgroundColor: '#fff',
    borderRadius: 10,
    alignSelf: 'center',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    overflow: 'hidden',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: 25,
  },
  content: {
    padding: 40,
  },
  solutions: {
    marginVertical: 20,
  },
  text: {
    fontSize: 16,
  },
  line: {
    fontSize: 16,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  handIcon: {
    marginRight: 10,
    fontSize: 18,
  },
});
