import { StyleSheet } from 'react-native';
import { colors } from '../../constants';

export default StyleSheet.create({
  header: {
    flexDirection: 'row',
    marginBottom: 15,
  },
  title: {
    flex: 1,
    fontSize: 16,
  },
  link: {
    marginLeft: 15,
    fontSize: 14,
  },
  refreshing: {
    color: colors.label.secondary,
  },
  carrierContainer: {
    minHeight: 37 * 2 + 8 * 2, // height + bottom margin for two rows
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    flexDirection: 'row',
    flex: 1,
    marginBottom: -8,
  },
  carrier: {
    borderWidth: 1,
    borderColor: colors.border.primary,
    backgroundColor: '#fbfbfb',
    borderRadius: 30,
    height: 37,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingVertical: 7,
    paddingHorizontal: 15,
    marginRight: 5,
    marginBottom: 8,
  },
  carrierLogo: {
    width: 19,
    height: 19,
    marginRight: 5,
  },
  carrierLabel: {
    flexWrap: 'nowrap',
  },
  alert: {
    marginTop: 0,
    flex: 1,
  },
});
