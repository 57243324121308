import { StyleSheet } from 'react-native';
import { fonts } from '../../constants/theme';

export default StyleSheet.create({
  modalContent: {
    maxWidth: 800,
    width: 680,
    maxHeight: '90%',
    backgroundColor: '#fff',
    borderRadius: 10,
    alignSelf: 'center',
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    overflow: 'hidden',
    justifyContent: 'space-between',
    flexDirection: 'column',
    margin: 25,
  },
  form: {
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  stateContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 40,
    borderTopWidth: 1,
    borderTopColor: '#F0F0F0',
    minHeight: 91,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 20,
    marginRight: 20,
  },
  label: {
    textAlign: 'right',
    fontSize: fonts.size.label,
    width: 160,
    marginRight: 30,
    paddingBottom: 2,
  },
  textArea: {
    flex: 1,
    maxWidth: 430,
  },
});
