import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { gql, useQuery } from '@apollo/client';
import { can, Actions } from '../../../services/permissions';
import { useAuthContext } from '../../../services/auth';
import { Strong, Small } from '../../../components/Text';
import Icon from '../../../components/Icon';
import styles from './MainMenu.styles';
import { useLocale } from '../../../services/localization';

const dashboardPages = ['Dashboard'];
const shopPages = ['Shops', 'EditShop', 'EditWoocommerce', 'AddShop', 'EditShippingProviders', 'EditPlugin', 'ShopActivity'];
const pickerPages = ['Pickers', 'EditPicker', 'AddPicker'];

const ORDER_COUNT = gql`query GetOrderCount { orders(status: ["new", "being_picked"]) { paginatorInfo { total } } }`;

function MainMenu() {
  const navigation = useNavigation();
  const route = useRoute();
  const orderQuery = useQuery(ORDER_COUNT);
  const { user } = useAuthContext();
  const canManageShop = React.useMemo(() => can(user.role, Actions.ManageShop), [user]);
  const $t = useLocale();

  const translations = React.useMemo(() => ({
    orders: $t({ id: 'MainMenu.Item.Orders', defaultMessage: 'Orders' }),
    pickers: $t({ id: 'UserMenu.Item.Pickers', defaultMessage: 'Pickers' }),
    shops: $t({ id: 'UserMenu.Item.Shops', defaultMessage: 'Shops' }),
  }), [$t]);

  function isActive(routeNames) {
    return routeNames.includes(route.name);
  }

  function renderOrderCount() {
    if (orderQuery.loading) {
      return null;
    }
    const orderCount = orderQuery?.data?.orders?.paginatorInfo?.total || 0;
    if (orderCount === 0) {
      return null;
    }
    return <Small style={styles.menuItemBadge}>{orderCount}</Small>;
  }

  return (
    <>
      <TouchableOpacity activeOpacity={0.8} style={styles.menuItem} onPress={() => navigation.navigate('Dashboard')}>
        <View style={[styles.menuItemWrapper, isActive(dashboardPages) && styles.menuItemActive]}>
          <Icon name="cube" size={18} />
          <Strong style={styles.menuItemLabel}>{translations.orders}</Strong>
          {renderOrderCount()}
        </View>
        { isActive(dashboardPages) && <View style={styles.menuItemActiveIndicator} />}
      </TouchableOpacity>
      {canManageShop && (
        <>
          <TouchableOpacity activeOpacity={0.8} style={styles.menuItem} onPress={() => navigation.navigate('Shops')}>
            <View style={[styles.menuItemWrapper, isActive(shopPages) && styles.menuItemActive]}>
              <Icon name="cart" size={18} />
              <Strong style={styles.menuItemLabel}>{translations.shops}</Strong>
            </View>
            { isActive(shopPages) && <View style={styles.menuItemActiveIndicator} />}
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.8} style={styles.menuItem} onPress={() => navigation.navigate('Pickers')}>
            <View style={[styles.menuItemWrapper, isActive(pickerPages) && styles.menuItemActive]}>
              <Icon name="people" size={18} />
              <Strong style={styles.menuItemLabel}>{translations.pickers}</Strong>
            </View>
            { isActive(pickerPages) && <View style={styles.menuItemActiveIndicator} />}
          </TouchableOpacity>
        </>
      )}
    </>
  );
}

export default MainMenu;
