import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  checkmark: {
    marginLeft: -10,
    marginTop: -30,
  },
  heading: {
    marginBottom: 30,
  },
  explanationText: {
    paddingBottom: 30,
  },
  input: {
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
  },
  registerText: {
    textAlign: 'center',
    marginTop: 30,
  },
  noteText: {
    marginTop: 3,
    marginLeft: 3,
    color: '#ff6a00',
  },
});

export default styles;
