import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { gql, useSubscription } from '@apollo/client';
import Logo from '../components/Logo';
import BackButton from './BackButton';
import UserControlBar from './UserControlBar';
import DashboardScreen from '../screens/DashboardScreen';
import PickOrderScreen from '../screens/PickOrderScreen';
import PickersScreen from '../screens/PickersScreen';
import EditPickerScreen from '../screens/EditPickerScreen';
import AddPickerScreen from '../screens/AddPickerScreen';
import ShopsScreen from '../screens/ShopsScreen';
import EditShopScreen from '../screens/EditShopScreen';
import EditWooCommerceScreen from '../screens/shops/EditWooCommerceScreen';
import EditShippingProvidersScreen from '../screens/EditShippingProvidersScreen';
import EditPluginScreen from '../screens/shops/EditPluginScreen';
import ShopActivityScreen from '../screens/ShopActivityScreen';
import AddShopScreen from '../screens/shops/AddShopScreen';
import SettingsScreen from '../screens/SettingsScreen';
import VerifyEmailChangeScreen from '../screens/VerifyEmailChangeScreen';
import { useAuthContext } from '../services/auth';
import { can, Actions } from '../services/permissions';

const NavigationStack = createStackNavigator();

const options = {
  headerTitle: () => <Logo />,
  headerRight: () => <UserControlBar />,
  headerLeft: () => <BackButton />,
  title: 'FastPicker',
};

const routes = {
  Dashboard: 'dashboard',
  PickOrder: 'pick/order/:orderId/:wooId?',
  ViewOrder: 'pick/order/:orderId/:wooId?/view',
  Settings: 'settings/:tab',
  VerifyEmailChange: '/verify/change/:token',
};

const manageShopRoutes = {
  Pickers: 'pickers',
  AddPicker: 'picker/add',
  EditPicker: 'picker/edit/:pickerId',
  Shops: 'shops',
  AddShop: 'shop/add/:shopId?',
  EditShop: 'shop/edit/:shopId',
  EditWoocommerce: 'shop/edit/:shopId/woocommerce',
  EditShippingProviders: 'shop/edit/:shopId/shipping-providers',
  EditPlugin: 'shop/edit/:shopId/plugin',
  ShopActivity: 'shop/edit/:shopId/activity',
};

const ORDER_SUBSCRIPTION = gql`
  subscription OnOrder {
    order {
      id
      woocommerce_order_id
      status
      shipping_label_status
      order_lines {
        id
        picked
        quantity
      }
      shipping_label
      shipping_provider
      shipping_failed_reason
    }
  }
`;

function ProtectedStackNavigation() {
  const { user } = useAuthContext();
  const canManageShop = React.useMemo(() => can(user.role, Actions.ManageShop), [user]);

  useSubscription(ORDER_SUBSCRIPTION);

  const linking = React.useMemo(() => ({
    config: {
      screens: {
        ...routes,
        ...canManageShop && manageShopRoutes,
      },
    },
  }), [canManageShop]);

  return (
    <NavigationContainer linking={linking}>
      <NavigationStack.Navigator initialRouteName="Dashboard">
        <NavigationStack.Screen
          name="Dashboard"
          component={DashboardScreen}
          options={{
            ...options,
            headerLeft: null,
            title: 'Dashboard · FastPicker',
          }}
        />
        <NavigationStack.Screen
          name="PickOrder"
          component={PickOrderScreen}
          options={({ route }) => ({
            ...options,
            title: `Order #${route.params.wooId} · FastPicker`,
          })}
        />
        <NavigationStack.Screen
          name="ViewOrder"
          component={PickOrderScreen}
          options={({ route }) => ({
            ...options,
            title: `Order #${route.params.wooId} · FastPicker`,
          })}
        />
        <NavigationStack.Screen
          name="Settings"
          component={SettingsScreen}
          options={{
            ...options,
            title: 'Settings · FastPicker',
          }}
        />
        <NavigationStack.Screen
          name="VerifyEmailChange"
          component={VerifyEmailChangeScreen}
          options={{
            ...options,
            title: 'Verifying Email · FastPicker',
          }}
        />
        {canManageShop && (
          <>
            <NavigationStack.Screen
              name="Shops"
              component={ShopsScreen}
              options={{
                ...options,
                title: 'Shops · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="EditShop"
              component={EditShopScreen}
              options={{
                ...options,
                title: 'Edit Shop · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="EditWoocommerce"
              component={EditWooCommerceScreen}
              options={{
                ...options,
                title: 'WooCommerce Shop Connection · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="EditShippingProviders"
              component={EditShippingProvidersScreen}
              options={{
                ...options,
                title: 'Shipping Providers · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="EditPlugin"
              component={EditPluginScreen}
              options={{
                ...options,
                title: 'Wordpress Plugin · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="ShopActivity"
              component={ShopActivityScreen}
              options={{
                ...options,
                title: 'Shop Activity · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="AddShop"
              component={AddShopScreen}
              options={{
                ...options,
                title: 'Add Shop · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="Pickers"
              component={PickersScreen}
              options={{
                ...options,
                title: 'Pickers · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="EditPicker"
              component={EditPickerScreen}
              options={{
                ...options,
                title: 'Edit Picker · FastPicker',
              }}
            />
            <NavigationStack.Screen
              name="AddPicker"
              component={AddPickerScreen}
              options={{
                ...options,
                title: 'Add Picker · FastPicker',
              }}
            />
          </>
        )}
      </NavigationStack.Navigator>
    </NavigationContainer>
  );
}

export default ProtectedStackNavigation;
