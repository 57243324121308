import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import { P } from '../../../../components/Text';
import { useLocale } from '../../../../services/localization';
import styles from './AddColliButton.styles';

function AddColliButton({ onPress }) {
  const $t = useLocale();
  return (
    <TouchableOpacity onPress={onPress} activeOpacity={0.5} style={styles.container}>
      <View style={styles.input} />
      <View style={styles.input} />
      <View style={styles.input} />
      <View style={[styles.input, styles.noMargin]} />
      <P style={styles.label}>
        {$t({ id: 'AddColliButton.Label', defaultMessage: '+ Add Colli' })}
      </P>
    </TouchableOpacity>
  );
}

AddColliButton.propTypes = {
  onPress: PropTypes.func.isRequired,
};

export default AddColliButton;
