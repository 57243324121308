import React, { useState, useRef } from 'react';
import {
  TouchableOpacity, View, Linking, Platform,
} from 'react-native';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Strong, Small } from '../../components/Text';
import Icon from '../../components/Icon';
import PulldownMenuItem from '../../components/PulldownMenuItem';
import styles from './GetSupportMenu.styles';
import { useLocale } from '../../services/localization';
import { useOutsideAlerter } from '../../hooks/interface';
import { links } from '../../constants/links';
import AppPackage from '../../../package.json';
import { useCanManageShop } from '../../services/permissions';

function GetSupportMenu() {
  const canManageShop = useCanManageShop();
  const [showMenu, SetShowMenu] = useState(false);
  const menuContainerRef = useRef(null);
  const $t = useLocale();

  const toggle = () => {
    SetShowMenu(!showMenu);
  };

  const close = () => {
    SetShowMenu(false);
  };

  function openLink(url) {
    Linking.openURL(url);
    close();
  }

  useOutsideAlerter(menuContainerRef, close);

  const translations = React.useMemo(() => ({
    heading: $t({ id: 'GetSupportMenu.Heading', defaultMessage: 'Need help?' }),
    question: $t({ id: 'GetSupportMenu.Item.AskQuestion', defaultMessage: 'Ask a question' }),
    documentation: $t({ id: 'GetSupportMenu.Item.CheckDocs', defaultMessage: 'Check documentation' }),
    report: $t({ id: 'GetSupportMenu.Item.ReportIssue', defaultMessage: 'Report an issue' }),
    submitIdea: $t({ id: 'GetSupportMenu.Item.SubmitIdea', defaultMessage: 'Submit an idea' }),
  }), [$t]);

  const copyright = `FastPicker · v${AppPackage.version}`;

  if (!canManageShop || Platform.OS !== 'web') {
    return null;
  }

  return (
    <View style={styles.container} ref={menuContainerRef}>
      <TouchableOpacity style={styles.openHelpMenuButton} activeOpacity={0.5} onPress={toggle}>
        <Icon name="help-circle-outline" size={30} />
      </TouchableOpacity>
      {showMenu && (
        <View style={styles.menuContainer}>
          <Strong style={styles.heading}>{translations.heading}</Strong>
          <PulldownMenuItem
            label={translations.question}
            icon="create"
            rightIcon="exit-outline"
            onPress={() => openLink(links.createTicket)}
          />
          <PulldownMenuItem
            label={translations.documentation}
            icon="information-circle"
            rightIcon="exit-outline"
            onPress={() => openLink(links.Documentation)}
          />
          <PulldownMenuItem
            label={translations.report}
            icon="bug"
            rightIcon="exit-outline"
            onPress={() => openLink(links.createTicket)}
          />
          <View style={styles.devider} />
          <PulldownMenuItem
            label={translations.submitIdea}
            icon="bulb"
            rightIcon="exit-outline"
            onPress={() => openLink(links.Upvoty)}
          />
          <View style={styles.devider} />
          <Small style={styles.copyright}>{copyright}</Small>
        </View>
      )}
      <TawkMessengerReact
        propertyId="63edf43ec2f1ac1e20339983"
        widgetId="1gpco456p"
      />
    </View>
  );
}

export default GetSupportMenu;
