import { StyleSheet } from 'react-native';
import { colors } from '../../constants';

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    bottom: 70,
    left: '50%',
    transform: [{ translateX: '-50%' }],
    maxWidth: 800,
    width: '100%',
    backgroundColor: colors.background.primary,
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.10,
    shadowRadius: 3,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
    padding: 20,
  },
  textWrapper: {
    flex: 1,
    marginLeft: 20,
  },
  heading: {
    fontSize: 16,
    lineHeight: 20,
  },
  text: {
    lineHeight: 20,
  },
  button: {
    marginLeft: 20,
  },
});

export default styles;
