import React from 'react';
import { View } from 'react-native';
import { FadeIn } from '../../components/Animation';
import { P, H1, A } from '../../components/Text';
import Logo from '../../components/Logo';
import LoginForm from './LoginForm';
import { useLocale } from '../../services/localization';
import styles from './LoginScreen.styles';

function LoginScreen() {
  const $t = useLocale();
  return (
    <View style={styles.container}>
      <FadeIn style={styles.content}>
        <View style={styles.logoContainer}>
          <Logo />
        </View>
        <View style={styles.formContainer}>
          <H1 style={styles.heading}>
            {$t({ id: 'LoginScreen.Text.FormTitle', defaultMessage: 'Sign in' })}
          </H1>
          <LoginForm />
          <P style={styles.registerText}>
            {$t({ id: 'LoginScreen.Text.NewHere', defaultMessage: 'New here?' })}
            <A style={styles.link} to="/register">
              {$t({ id: 'LoginScreen.Link.CreateAccountLabel', defaultMessage: 'Create an account' })}
            </A>
          </P>
        </View>
      </FadeIn>
    </View>
  );
}

export default LoginScreen;
