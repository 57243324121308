import { useState, useEffect } from 'react';
import { Image } from 'react-native';
import { useFonts } from 'expo-font';

import { assets } from '../constants/theme';

const useLoadImages = () => {
  const [loaded, setLoaded] = useState(false);

  async function loadImages() {
    await Image.prefetch(assets.images.logo);
    await Image.prefetch(assets.images.kdz);
    await Image.prefetch(assets.images.postnl);
    await Image.prefetch(assets.images.myparcel);
    setLoaded(true);
  }

  useEffect(() => {
    loadImages();
  }, []);

  return [loaded];
};

const useLoadResources = (onReady) => {
  const [fontsLoaded] = useFonts(assets.fonts);
  const [imagesLoaded] = useLoadImages();

  useEffect(() => {
    if (fontsLoaded && imagesLoaded) {
      onReady();
    }
  }, [fontsLoaded, imagesLoaded, onReady]);

  return fontsLoaded && imagesLoaded;
};

export default useLoadResources;
