import React from 'react';
import { Platform, View } from 'react-native';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { FadeIn } from '../../../../components/Animation';
import { wizardStatus as wizardStatusOrg } from '../../../../constants';
import Icon from '../../../../components/Icon';
import { P } from '../../../../components/Text';
import Button from '../../../../components/Button';
import ListItem from '../../../../components/ListItem';
import ShopStatusLabel from '../../../../components/ShopStatusLabel';
import { useLocale } from '../../../../services/localization';
import { isShopConnectionError } from '../../../../services/Helpers';
import styles from './ShopsListItem.styles';

function ShopsListItem({
  shopId, wizardStatus, name, domain, status, style,
}) {
  const navigation = useNavigation();
  const $t = useLocale();
  const wizardInProgress = wizardStatus !== wizardStatusOrg.FINISHED;

  const go = () => {
    if (!wizardInProgress && isShopConnectionError(status)) {
      navigation.navigate('EditWoocommerce', { shopId });
      return;
    }
    const pageName = wizardInProgress ? 'AddShop' : 'EditShop';
    navigation.push(pageName, { shopId });
  };

  return (
    <FadeIn>
      <ListItem style={[styles.item, style]} onPress={go}>
        <View style={styles.infoContainer}>
          <Icon style={styles.icon} name="cart" size={24} />
          <View style={styles.textContainer}>
            <P style={styles.shopName}>{name}</P>
            <P style={styles.shopUri}>{domain}</P>
            <ShopStatusLabel style={styles.status} status={status} />
          </View>
        </View>
        {Platform.OS === 'web' && (
          wizardInProgress ? (
            <Button
              label={$t({ id: 'ShopsListItem.ContinueSetupButton.Label', defaultMessage: 'Continue setup' })}
              onPress={go}
            />
          ) : (
            <Button
              label={$t({ id: 'ShopsListItem.EditShopButton.Label', defaultMessage: 'Edit' })}
              type="secondary"
              onPress={go}
            />
          )
        )}
      </ListItem>
    </FadeIn>
  );
}

ShopsListItem.propTypes = {
  shopId: PropTypes.string.isRequired,
  wizardStatus: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default ShopsListItem;
