import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useAuthContext } from '../services/auth';
import { HAS_SHOP } from '../graphql/ShopQuery';
import { GET_USER } from '../graphql/UserQuery';
import { wizardStatus } from '../constants/shop';
import { issueState } from '../constants/issues';

const hasAShop = (shops) => {
  if (shops.length === 0) return issueState.NO_SHOPS;
  return true;
};

const hasAConnectedShop = (shop) => {
  if (shop.wizard_status !== wizardStatus.FINISHED) return issueState.ADD_SHOP_INPROGRESS;
  return true;
};

const hasFastPickerWordpressPlugin = (shop) => {
  const hasPluginInstalled = shop.installed_plugin_version !== undefined;
  return hasPluginInstalled ? true : issueState.MISSING_FASTPICKER_PLUGIN;
};

const allShopInfoFilledIn = (shop) => {
  if (!shop) return issueState.INCOMPLETE_SHOP_INFO;

  const fields = ['address', 'city', 'country', 'logo', 'postcode'];
  const hasEmptyField = fields.find((field) => shop[field] === null);

  return hasEmptyField ? issueState.INCOMPLETE_SHOP_INFO : true;
};

// @TODO: Disabled step four because its not required and we currently have no way of knowing if user has seen the agreement without accepting
const hasAcceptedDataProcessingAgreement = () => true;
// if (user.data_processing_agreement === null) return issueState.ACCEPT_DATA_PROCESSING_AGREEMENT;
// return true;
// ;

const GENERAL_CHECKS = [hasAShop];
const SHOP_CHECKS = [hasAConnectedShop, hasFastPickerWordpressPlugin, allShopInfoFilledIn];
const USER_CHECKS = [hasAcceptedDataProcessingAgreement];

const useHealthChecker = () => {
  const [issues, setIssues] = useState({});
  const [hasIssues, setHasIssues] = useState(false);
  const [checking, setChecking] = useState(true);
  const { data } = useQuery(HAS_SHOP);
  const { user } = useAuthContext();
  const { data: userData } = useQuery(GET_USER, { variables: { userId: user.id } });

  useEffect(() => {
    if (!data || !userData) return;

    const shops = data.shops.data;
    const results = {
      general: new Set(),
      shops: {},
      timestamp: Date.now(),
    };

    GENERAL_CHECKS.forEach((check) => {
      const result = check(shops);
      if (result === true) return;
      results.general.add(result);
    });

    USER_CHECKS.forEach((check) => {
      const result = check(userData.user);
      if (result === true) return;
      results.general.add(result);
    });

    shops.forEach((shop) => {
      SHOP_CHECKS.forEach((check) => {
        const result = check(shop);
        if (result === true) return;
        if (!results.shops[shop.id]) results.shops[shop.id] = new Set();
        results.shops[shop.id].add(result);
      });
    });

    const generalIssuesDetected = results.general.size > 0;
    const shopIssuesDetected = Object.keys(results.shops).length > 0;

    setIssues({ ...results });
    setHasIssues(generalIssuesDetected || shopIssuesDetected);
    setChecking(false);
  }, [data, userData]);

  return { checking, hasIssues, issues };
};

export default useHealthChecker;
