import { StyleSheet } from 'react-native';
import { colors } from '../../../constants';

const styles = StyleSheet.create({
  container: {
    height: 300,
    backgroundColor: colors.background.secondary,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    marginBottom: 30,
    color: colors.label.primary,
  },
});

export default styles;
