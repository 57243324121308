import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: '#D8D8D8',
    alignItems: 'center',
    height: 53,
    borderRadius: 10,
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 20,
  },
  label: {
    textTransform: 'uppercase',
    color: '#B6B6B6',
    fontSize: 14,
  },
});

export default styles;
