import FastPickerLogo from '../assets/images/logo-full.svg';
import KdzLogo from '../assets/images/providers/kdz.png';
import PostnlLogo from '../assets/images/providers/postnl.svg';
import MyParcelLogo from '../assets/images/providers/myparcel.svg';
import SendCloudLogo from '../assets/images/providers/sendcloud.svg';
import UpsLogo from '../assets/images/providers/ups.svg';
import FedexLogo from '../assets/images/providers/fedex.svg';
import DhlLogo from '../assets/images/providers/dhl.svg';
import DhlExpressLogo from '../assets/images/providers/dhlexpress.svg';
import DeutschePostLogo from '../assets/images/providers/deutschepost.svg';
import BudbeeLogo from '../assets/images/providers/budbee.svg';
import ModialRelayLogo from '../assets/images/providers/modialrelay.svg';
import TrunkrsLogo from '../assets/images/providers/trunkrs.svg';
import HomerrLogo from '../assets/images/providers/homerr.svg';
import StoreShippersLogo from '../assets/images/providers/storeshippers.svg';
import FietsKoeriersnlLogo from '../assets/images/providers/fietskoeriersnl.svg';
import QuicargoLogo from '../assets/images/providers/quicargo.svg';
import ViatimLogo from '../assets/images/providers/viatim.svg';
import GlsLogo from '../assets/images/providers/gls.svg';
import DpdLogo from '../assets/images/providers/dpd.svg';
import Roboto from '../assets/fonts/Roboto-Regular.ttf';
import RobotoBlack from '../assets/fonts/Roboto-Black.ttf';
import RobotoBold from '../assets/fonts/Roboto-Bold.ttf';
import IonIcons from '../assets/fonts/Ionicons.ttf';

export const fonts = {
  family: {
    body: 'Roboto',
    heading: 'Roboto Black',
    bold: 'Roboto Bold',
  },
  size: {
    label: 16,
    body: 14,
    small: 12,
    h1: 32,
    h2: 28,
    h3: 22,
    h4: 22,
  },
};

export const colors = {
  brand: {
    primary: '#3258C5',
  },
  ui: {
    attention: '#ff6a00',
    ok: '#6FC62E',
  },
  background: {
    primary: '#ffffff',
    secondary: '#e7e7e7',
  },
  text: {
    body: '#000000',
    small: '#8e8e8e',
  },
  label: {
    primary: '#656565',
    secondary: '#949494',
    tertiary: '#B6B6B6',
  },
  warning: {
    background: '#FFF7DF',
    font: '#786b48',
    border: '#F0DB9A',
  },
  border: {
    primary: '#E1E1E1',
    secondary: '#F0F0F0',
  },
};

export const shadow = {
  normal: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
  },
};

export const assets = {
  fonts: {
    Roboto,
    'Roboto Black': RobotoBlack,
    'Roboto Bold': RobotoBold,
    Ionicons: IonIcons,
  },
  images: {
    logo: FastPickerLogo,
    kdz: KdzLogo,
    postnl: PostnlLogo,
    myparcel: MyParcelLogo,
    sendcloud: SendCloudLogo,
    ups: UpsLogo,
    fedex: FedexLogo,
    dhl: DhlLogo,
    dhl_express: DhlExpressLogo,
    dpd: DpdLogo,
    deutsche_post: DeutschePostLogo,
    budbee: BudbeeLogo,
    mondial_relay: ModialRelayLogo,
    trunkrs: TrunkrsLogo,
    homerr: HomerrLogo,
    storeshippers: StoreShippersLogo,
    fietskoeriersnl: FietsKoeriersnlLogo,
    quicargo: QuicargoLogo,
    gls: GlsLogo,
    viatim: ViatimLogo,
  },
};
