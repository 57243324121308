import { useMutation } from '@apollo/client';
import { REGISTER } from '../graphql/AuthQuery';

const useRegister = (registerFields, onCompleted, onError) => {
  const [register, { loading }] = useMutation(REGISTER, {
    variables: registerFields,
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted() {
      if (typeof onCompleted === 'function') onCompleted();
    },
  });

  return { register, loading };
};

export default useRegister;
