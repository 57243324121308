import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  sidebar: {
    width: 220,
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
  },
  content: {
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 20,
  },
  wide: {
    maxWidth: 800,
  },
});

export const mobileStyles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
});

export default styles;
