export const pickProgress = (orderLines) => {
  const line = orderLines || [];
  const r = {
    totalItems: 0,
    totalPicked: 0,
    progressPercentage: 0,
  };
  if (!line || line.length === 0 || !Array.isArray(line)) return r;
  line.forEach(({ quantity, picked }) => {
    r.totalItems += quantity;
    r.totalPicked += picked;
  });
  const percentage = (100 / r.totalItems) * r.totalPicked;
  r.progressPercentage = percentage < 1 ? Math.ceil(percentage) : Math.floor(percentage);
  return r;
};

export const ShopConnectionErrors = [
  'cant_reach_shop',
  'cant_read_order',
  'cant_delete_order',
  'cant_create_order',
  'cant_fetch_products',
  'cant_update_order',
  'invalid_credentials',
  'connection_error',
  'no_read_permissions',
  'no_write_permissions',
  'failed_retry',
];

export function isShopConnectionError(shopStatus) {
  return ShopConnectionErrors.includes(shopStatus);
}

export function forceHttps(url) {
  const isHttps = url.includes('https');
  return !isHttps ? url.replace('http', 'https') : url;
}

/**
 * Parse SKU string to make sure its a lowercased string for comparisons
 */
export function parseSku(skuString) {
  if (skuString === null || skuString === undefined || typeof skuString === 'object') {
    return '';
  }
  return String(skuString).toLowerCase();
}
