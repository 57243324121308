import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { P } from '../../Text';
import styles from './MenuItem.styles';
import { colors } from '../../../constants/theme';

function MenuItem({
  active, onPress, icon, activeIcon, label, subLabel,
}) {
  const iconName = active ? activeIcon : icon;
  return (
    <TouchableOpacity style={[styles.container, active && styles.activeContainer]} onPress={onPress}>
      <Icon size={24} color={colors.text.body} name={iconName} style={styles.icon} />
      <View>
        <P style={[styles.label, active && styles.activeLabel]}>{label}</P>
        {subLabel}
      </View>
    </TouchableOpacity>
  );
}

MenuItem.propTypes = {
  active: PropTypes.bool,
  onPress: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  activeIcon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subLabel: PropTypes.element,
};

MenuItem.defaultProps = {
  active: false,
  subLabel: null,
};

export default MenuItem;
