import { StyleSheet } from 'react-native';
import { colors } from '../../../constants';

const styles = StyleSheet.create({
  menuItem: {
    height: '100%',
    justifyContent: 'center',
  },
  menuItemWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 7,
  },
  menuItemActive: {
    backgroundColor: '#f2f2f2',
  },
  menuItemLabel: {
    marginLeft: 4,
    textTransform: 'uppercase',
  },
  menuItemActiveIndicator: {
    position: 'absolute',
    height: 5,
    bottom: 0,
    width: '100%',
    left: 0,
    backgroundColor: colors.brand.primary,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  menuItemBadge: {
    backgroundColor: colors.brand.primary,
    paddingHorizontal: 6,
    paddingVertical: 3,
    minWidth: 20,
    color: '#fff',
    fontWeight: 'bold',
    textAlign: 'center',
    marginLeft: 4,
    borderRadius: 10,
  },
});

export default styles;
