import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import PropTypes from 'prop-types';
import { Strong } from '../Text';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#E9E9E9',
    flexDirection: 'row',
    paddingHorizontal: 15,
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 70,
    minWidth: 150,
  },
  compact: {
    paddingHorizontal: 7,
    height: 48,
    minWidth: 1,
  },
  radio: {
    width: 17,
    height: 17,
    backgroundColor: '#fff',
    borderColor: '#E1E1E1',
    borderWidth: 1,
    borderRadius: 17,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginLeft: 5,
  },
  radioActive: {
    backgroundColor: '#2D54C4',
    borderColor: '#2D54C4',
  },
  radioDisabled: {
    backgroundColor: '#B2B2B2',
    borderColor: '#AAAAAA',
  },
  innerCircle: {
    backgroundColor: '#fff',
    width: 9,
    height: 9,
    borderRadius: 9,
  },
  label: {
    marginLeft: 7,
    marginRight: 4,
  },
});

function RadioButton({
  label, checked, disabled, compact, onPress, style, iconComponent,
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, compact && styles.compact, style]}
      activeOpacity={0.6}
      disabled={disabled}
    >
      <View style={[
        styles.radio,
        checked && styles.radioActive,
        (checked && disabled) && styles.radioDisabled,
      ]}
      >
        {checked && <View style={styles.innerCircle} />}
      </View>
      {iconComponent && <View style={styles.icon}>{iconComponent}</View>}
      <Strong style={styles.label}>{label}</Strong>
    </TouchableOpacity>
  );
}

RadioButton.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  compact: PropTypes.bool,
  onPress: PropTypes.func,
  iconComponent: PropTypes.element,
};

RadioButton.defaultProps = {
  checked: false,
  disabled: false,
  compact: false,
  onPress: () => null,
  iconComponent: undefined,
};

export default RadioButton;
