import { useQuery } from '@apollo/client';
import { GET_SHOPS } from '../graphql/ShopQuery';

const useShops = () => {
  const { loading, error, data } = useQuery(GET_SHOPS);

  return { loading, error, data };
};

export default useShops;
