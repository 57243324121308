import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { mutationError } from '../../services/Error';
import { DefaultLanguage, Language, useLocale } from '../../services/localization';
import { FadeIn } from '../../components/Animation';
import Icon from '../../components/Icon';
import ScreenHeading from '../../components/ScreenHeading';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ErrorNotification } from '../../components/ErrorNotification';
import ScreenWithSidebars from '../../components/ScreenWithSidebars';
import {
  P, H4, Strong, Small,
} from '../../components/Text';
import { TextField, SelectField } from '../../components/Form';
import Button from '../../components/Button';
import ShopSubMenu from '../../components/ShopSubMenu';
import Alert from '../../components/Alert';
import { isValidHttpUrl } from '../../services/Validation';
import useShop from '../../hooks/useShop';
import useUpdateShop from '../../hooks/useUpdateShop';
import useDeleteShop from '../../hooks/useDeleteShop';
import ShopLogo from './ShopLogo';
import { useToastNotification } from '../../services/toast';
import styles from './EditShopScreen.styles';

const initialFields = {
  name: '',
  url: '',
  key: '',
  barcode_postmeta_key: '',
  secret: '',
  logo: '',
  address: '',
  city: '',
  postcode: '',
  country: '',
  pdf_footer_text: '',
  language: '',
};

function EditShopScreen({ route, navigation }) {
  const { shopId } = route.params;
  const { loading, error, data: response } = useShop(shopId);
  const { toastSucces, toastInfo } = useToastNotification();
  const $t = useLocale();

  const [fields, setFields] = useState(initialFields);
  const [variables, setVariables] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState(false);

  const { deleteShop } = useDeleteShop(shopId, () => {
    navigation.navigate('Shops');
    const msg = $t({ id: 'EditShopScreen.ShopRemoved.Text', defaultMessage: 'Shop {name} removed' }, { name: fields.name });
    toastInfo(msg);
  });

  const { updateShop, loading: isSaving } = useUpdateShop(
    {
      shopId,
      ...variables,
    },
    () => {
      const msg = $t({ id: 'EditShopScreen.ChangesSaved.Text', defaultMessage: 'Changes to {name} saved' }, { name: fields.name });
      toastSucces(msg);
    },
    (err) => setErrors(mutationError(err)),
  );

  const submit = React.useCallback(() => {
    if (!isValidHttpUrl(variables.logo)) {
      setErrors($t({ id: 'EditShopScreen.EnterValidLogoUrl.Error', defaultMessage: 'Please enter use valid logo URL' }));
      return;
    }
    updateShop();
  }, [$t, updateShop, variables.logo]);

  const handleInputChange = (e, fieldName) => {
    if (!e) return;
    const value = e.target?.value;
    if (!hasChanges && fields[fieldName] !== value) setHasChanges(true);
    setFields({
      ...fields,
      [fieldName]: value,
    });
    setErrors();
  };

  // Only set variables for query that have content
  // to prevent sending things like empty password field
  useEffect(() => {
    const vars = {};
    Object.keys(initialFields).forEach((key) => {
      if (!fields[key]) return;
      vars[key] = fields[key];
    });
    setVariables(vars);
  }, [fields]);

  useEffect(() => {
    if (!response) return;

    setFields({
      ...initialFields,
      ...response.shop,
    });
  }, [response]);

  if (loading) return <LoadingIndicator />;
  if (!response) {
    return (
      <ErrorNotification
        errorTitle={$t({ id: 'EditShopScreen.ShopNotFound.Title', defaultMessage: 'Shop not found' })}
        errorMessage={$t({ id: 'EditShopScreen.ShopNotFound.Description', defaultMessage: "Sorry, seems that you're looking for a shop that does not exists." })}
        buttonLabel={$t({ id: 'EditShopScreen.BackToShopsButton.Label', defaultMessage: 'Back to shops' })}
        buttonAction={() => navigation.navigate('Shops')}
      />
    );
  }
  if (error) return <ErrorNotification errorMessage={mutationError(error)} />;

  return (
    <ScreenWithSidebars
      LeftSidebarComponent={(
        <>
          <Strong style={styles.shopName}>{response.shop.name}</Strong>
          <ShopSubMenu />
        </>
      )}
      MainContentComponent={(
        <FadeIn>
          <ScreenHeading title={$t({ id: 'EditShopScreen.Page.Title', defaultMessage: 'Edit shop {name}' }, { name: response.shop.name })} />
          <TextField
            style={styles.input}
            label={$t({ id: 'EditShopScreen.ShopNameField.Label', defaultMessage: 'Webshop name' })}
            placeholder={$t({ id: 'EditShopScreen.ShopNameField.Placeholder', defaultMessage: 'My Shop' })}
            value={fields.name}
            onChange={(e) => handleInputChange(e, 'name')}
          />
          <H4 style={styles.heading}>{$t({ id: 'EditShopScreen.PackageSlipSection.Title', defaultMessage: 'Package slip' })}</H4>
          <View style={styles.flexContainer}>
            <ShopLogo imageUrl={fields.logo} />
            <TextField
              style={styles.input}
              label={$t({ id: 'EditShopScreen.ShopLogoUrlField.Label', defaultMessage: 'Shop logo (url)' })}
              placeholder={$t({ id: 'EditShopScreen.ShopLogoUrlField.Placeholder', defaultMessage: 'http://yourwebsite.com/files/logo.png' })}
              value={fields.logo || ''}
              onChange={(e) => handleInputChange(e, 'logo')}
              description={!isValidHttpUrl(fields.logo) && (
              <View style={styles.note}>
                <Icon name="alert-circle-outline" size={15} color="#ff6a00" />
                <Small style={styles.noteText}>Add a logo for on your package slips</Small>
              </View>
              )}
            />
          </View>
          <TextField
            style={styles.input}
            label={$t({ id: 'EditShopScreen.FooterTextField.Label', defaultMessage: 'Footer text' })}
            placeholder={$t({ id: 'EditShopScreen.FooterTextField.Placeholder', defaultMessage: 'Add a custom line to the bottom of your package slip' })}
            value={fields.pdf_footer_text || ''}
            onChange={(e) => handleInputChange(e, 'pdf_footer_text')}
          />
          <SelectField
            style={styles.input}
            label={$t({ id: 'EditShopScreen.LanguageField.Label', defaultMessage: 'Package slip language' })}
            items={[
              {
                value: Language.EN,
                label: $t({ id: 'EditShopScreen.LanguageField.Option.English', defaultMessage: 'English' }),
              },
              {
                value: Language.NL,
                label: $t({ id: 'EditShopScreen.LanguageField.Option.Dutch', defaultMessage: 'Dutch' }),
              },
            ]}
            selectedValue={fields.language || DefaultLanguage}
            onValueChange={(e) => handleInputChange(e, 'language')}
          />
          <H4 style={styles.heading}>{$t({ id: 'EditShopScreen.AddressSection.Title', defaultMessage: 'Address' })}</H4>
          <TextField
            style={styles.input}
            label={$t({ id: 'EditShopScreen.AddressField.Label', defaultMessage: 'Address' })}
            placeholder={$t({ id: 'EditShopScreen.AddressField.Placeholder', defaultMessage: 'Streetname 1' })}
            value={fields.address}
            onChange={(e) => handleInputChange(e, 'address')}
          />
          <View style={styles.flexContainer}>
            <TextField
              style={styles.input}
              label={$t({ id: 'EditShopScreen.CityField.Label', defaultMessage: 'City' })}
              placeholder={$t({ id: 'EditShopScreen.CityField.Placeholder', defaultMessage: 'New York' })}
              value={fields.city}
              onChange={(e) => handleInputChange(e, 'city')}
            />
            <TextField
              style={[styles.input, styles.inputZipCode]}
              label={$t({ id: 'EditShopScreen.ZipCodeField.Label', defaultMessage: 'Zip code' })}
              placeholder={$t({ id: 'EditShopScreen.ZipCodeField.Placeholder', defaultMessage: '1234 AZ' })}
              value={fields.postcode}
              onChange={(e) => handleInputChange(e, 'postcode')}
            />
            <TextField
              style={styles.input}
              label={$t({ id: 'EditShopScreen.CountryField.Label', defaultMessage: 'Country' })}
              placeholder={$t({ id: 'EditShopScreen.CountryField.Placeholder', defaultMessage: 'United States' })}
              value={fields.country}
              onChange={(e) => handleInputChange(e, 'country')}
            />
          </View>
          <H4 style={styles.heading}>{$t({ id: 'EditShopScreen.ScannerSection.Title', defaultMessage: 'Barcode scanner' })}</H4>
          <TextField
            style={styles.input}
            label={$t({ id: 'EditShopScreen.BarcodePostMetaKeyField.Label', defaultMessage: 'Barcode postmeta key' })}
            placeholder={$t({ id: 'EditShopScreen.BarcodePostMetaKeyField.Placeholder', defaultMessage: 'Woocommerce post meta field' })}
            value={fields.barcode_postmeta_key || ''}
            onChange={(e) => handleInputChange(e, 'barcode_postmeta_key')}
          />
          <View style={styles.actions}>
            <P style={styles.delete} onPress={deleteShop}>
              {$t({ id: 'EditShopScreen.RemoveShopButton.Label', defaultMessage: 'Remove shop' })}
            </P>
            <Button
              label={
                  hasChanges
                    ? $t({ id: 'EditShopScreen.SaveButton.Label', defaultMessage: 'Save' })
                    : $t({ id: 'EditShopScreen.NothingToSave.Text', defaultMessage: 'Nothing to save' })
                }
              size="small"
              onPress={submit}
              loading={isSaving}
              disabled={!hasChanges || isSaving}
            />
          </View>
          {errors && <Alert text={errors} />}
        </FadeIn>
      )}
    />
  );
}

export default EditShopScreen;
