import { useQuery, gql } from '@apollo/client';

export const GET_SHIPPING_PROVIDERS = gql`
  query ($shopId: ID!) {
    shop(id: $shopId) {
      id
      shipping_fields {
        plugin
        active
      }
    }
  }
`;

const useShippingProviders = (shopId) => {
  const { loading, error, data } = useQuery(GET_SHIPPING_PROVIDERS, {
    variables: {
      shopId,
    },
  });

  return { loading, error, data };
};

export default useShippingProviders;
