import React, { useState, useRef } from 'react';
import {
  StyleSheet, TouchableOpacity, View, ScrollView,
} from 'react-native';
import PropTypes from 'prop-types';
import { Strong, P } from '../Text';
import Icon from '../Icon';
import { shadow, colors } from '../../constants/theme';
import { useOutsideAlerter } from '../../hooks/interface';

const OPTION_HEIGHT = 48;
const MIN_VISIBLE = 6;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    zIndex: 1,
  },
  selectButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 10,
    minHeight: OPTION_HEIGHT,
    minWidth: 150,
    backgroundColor: colors.background.primary,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: '#E9E9E9',
  },
  labelContainer: {
    flex: 1,
    flexGrow: 1,
    marginRight: 10,
  },
  placeholderLabel: {
    color: '#5E5E5E',
  },
  optionsWrapper: {
    borderRadius: 6,
    position: 'absolute',
    backgroundColor: colors.background.primary,
    top: OPTION_HEIGHT + 5,
    ...shadow.normal,
    left: 0,
    right: 0,
    maxHeight: (OPTION_HEIGHT * MIN_VISIBLE),
  },
  option: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    minHeight: OPTION_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedIndicator: {
    marginRight: 5,
  },
  devider: {
    height: 1,
    backgroundColor: '#E9E9E9',
  },
});

function Select({
  placeholder,
  selectedValue,
  onChange,
  options,
  name,
  disabled,
}) {
  const selectContainer = useRef(null);
  const [show, setShow] = useState(false);

  function toggle() {
    setShow((val) => !val);
  }
  useOutsideAlerter(selectContainer, () => setShow(false));

  function renderLabel() {
    const selectedOption = options.find(({ value }) => value === selectedValue);
    if (selectedOption === undefined) {
      return <P style={styles.placeholderLabel}>{placeholder}</P>;
    }
    return <Strong style={styles.selectedOptionLabel}>{selectedOption.label}</Strong>;
  }

  function renderOptionLabel(item) {
    if (selectedValue && selectedValue === item.value) {
      return (
        <>
          <Icon name="checkmark-circle" size={20} style={styles.selectedIndicator} color={colors.ui.ok} />
          <Strong>{item.label}</Strong>
        </>
      );
    }
    return <P>{item.label}</P>;
  }

  function itemPressed(value) {
    onChange(value);
    toggle();
  }

  const chevron = show ? 'chevron-up-outline' : 'chevron-down-outline';

  return (
    <View style={styles.container} ref={selectContainer}>
      <TouchableOpacity style={styles.selectButton} activeOpacity={1} onPress={() => toggle()} disabled={disabled}>
        <View style={styles.labelContainer}>
          {renderLabel()}
        </View>
        <Icon name={chevron} size={20} />
      </TouchableOpacity>
      {show && (
        <ScrollView style={styles.optionsWrapper}>
          {/* @TODO: Show warning when options list is empty */}
          {options.map((item, index) => (
            <View key={`select-${name}-$${index}`}>
              <TouchableOpacity activeOpacity={1} style={styles.option} onPress={() => itemPressed(item.value)}>
                {renderOptionLabel(item)}
              </TouchableOpacity>
              <View style={styles.devider} />
            </View>
          ))}
        </ScrollView>
      )}
    </View>
  );
}

const Option = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  label: PropTypes.string,
});

Select.propTypes = {
  /**
   * Key of component. Used for children
   */
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(Option),
  // eslint-disable-next-line react/forbid-prop-types
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  placeholder: '',
  options: [],
  selectedValue: undefined,
  disabled: false,
  onChange: () => null,
};

export default Select;
