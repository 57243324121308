import React from 'react';
import { StyleSheet } from 'react-native';
import { mutationError } from '../../services/Error';
import { useLocale } from '../../services/localization';
import ScreenWithSidebars from '../../components/ScreenWithSidebars';
import { FadeIn } from '../../components/Animation';
import ScreenHeading from '../../components/ScreenHeading';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ErrorNotification } from '../../components/ErrorNotification';
import { H4, Strong } from '../../components/Text';
import ShopSubMenu from '../../components/ShopSubMenu';
import FastPickerPlugin from '../../components/FastPickerPlugin';
import useShop from '../../hooks/useShop';

const styles = StyleSheet.create({
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  heading: {
    marginBottom: 15,
    alignItems: 'center',
    flexDirection: 'row',
  },
  shopStatus: {
    paddingLeft: 10,
  },
  input: {
    marginBottom: 15,
    flex: 1,
  },
  inputZipCode: {
    width: 100,
    marginHorizontal: 15,
  },
  logo: {
    width: 100,
    height: 100,
    marginRight: 15,
    marginBottom: 15,
    borderRadius: 10,
    backgroundColor: '#E1E1E1',
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  delete: {
    color: '#D11212',
    textDecorationLine: 'underline',
  },
  shopName: {
    textTransform: 'uppercase',
    fontSize: 12,
    marginBottom: 10,
    color: '#B6B6B6',
    marginLeft: 13,
  },
});

function EditPluginScreen({ route, navigation }) {
  const { shopId } = route.params;
  const { loading, error, data: response } = useShop(shopId);
  const $t = useLocale();

  if (loading) return <LoadingIndicator />;
  if (!response) {
    return (
      <ErrorNotification
        errorTitle={$t({
          id: 'EditShopScreen.ShopNotFound.Title',
          defaultMessage: 'Shop not found',
        })}
        errorMessage={$t({
          id: 'EditShopScreen.ShopNotFound.Description',
          defaultMessage:
            "Sorry, seems that you're looking for a shop that does not exists.",
        })}
        buttonLabel={$t({
          id: 'EditShopScreen.BackToShopsButton.Label',
          defaultMessage: 'Back to shops',
        })}
        buttonAction={() => navigation.navigate('Shops')}
      />
    );
  }
  if (error) return <ErrorNotification errorMessage={mutationError(error)} />;
  return (
    <ScreenWithSidebars
      LeftSidebarComponent={(
        <>
          <Strong style={styles.shopName}>{response.shop.name}</Strong>
          <ShopSubMenu />
        </>
      )}
      MainContentComponent={(
        <FadeIn>
          <ScreenHeading
            title={$t(
              {
                id: 'EditShopScreen.Page.Title',
                defaultMessage: 'Edit shop {name}',
              },
              { name: response.shop.name },
            )}
          />
          <H4 style={styles.heading}>
            {$t({
              id: 'EditShopScreen.PluginSection.Title',
              defaultMessage: 'Plugin',
            })}
          </H4>
          <FastPickerPlugin version={response.shop.installed_plugin_version} />
        </FadeIn>
  )}
    />
  );
}

export default EditPluginScreen;
