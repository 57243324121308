import { StyleSheet } from 'react-native';
import { colors } from '../../constants/theme';

const styles = StyleSheet.create({
  icon: {
    marginRight: 10,
  },
  subLabel: {
    fontSize: 11,
    textTransform: 'uppercase',
    marginTop: 3,
    color: '#B6B6B6',
  },
  activeSubLabel: {
    color: colors.ui.ok,
  },
  alertSubLabel: {
    color: colors.ui.attention,
  },
  spacer: {
    height: 20,
  },
});

export default styles;
