import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    height: 29,
    width: 29,
    borderColor: '#E1E1E1',
    borderWidth: 2,
    borderRadius: 12,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkmark: {
    marginLeft: 13,
    marginBottom: 10,
  },
});

export default styles;
