import React from 'react';
import { Animated, StyleSheet, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { colors } from '../../constants/theme';

const startPos = 4;
const endPos = 26;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.label.tertiary,
    borderRadius: 31,
    height: 31,
    width: 53,
  },
  containerActive: {
    backgroundColor: colors.ui.ok,
  },
  containerDisabled: {
    opacity: 0.3,
  },
  dot: {
    backgroundColor: '#fff',
    width: 23,
    height: 23,
    borderRadius: 22,
    position: 'absolute',
    left: startPos,
    top: 4,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.3,
    shadowRadius: 3,
  },
});

function getPosValue(checked) {
  return checked ? endPos : startPos;
}

function ToggleButton({
  checked, disabled, loading, onChange, style, ...props
}) {
  const leftPos = React.useRef(new Animated.Value(getPosValue(checked))).current;

  React.useEffect(() => {
    Animated.timing(leftPos, {
      toValue: getPosValue(checked),
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [checked, leftPos]);

  return (
    <TouchableOpacity
      onPress={onChange}
      style={[
        styles.container,
        checked && styles.containerActive,
        disabled && styles.containerDisabled,
        style,
      ]}
      activeOpacity={1}
      disabled={disabled || loading}
      {...props}
    >
      <Animated.View style={[styles.dot, { left: leftPos }]} />
    </TouchableOpacity>
  );
}

ToggleButton.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
};

ToggleButton.defaultProps = {
  checked: false,
  disabled: false,
  loading: false,
  onChange: () => null,
};

export default ToggleButton;
