import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, Dimensions } from 'react-native';
import Screen from './Layout';
import { P, H1 } from './Text';
import LoadingIndicator from './LoadingIndicator';
import Button from './Button';
import Icon from './Icon';

const smallDevice = Dimensions.get('window').width < 875;

const styles = StyleSheet.create({
  container: {
    marginVertical: 50,
  },
  checkmark: {
    alignSelf: 'center',
  },
  heading: {
    textAlign: 'center',
  },
  message: {
    fontSize: 16,
    marginTop: 10,
    marginBottom: 20,
    maxWidth: smallDevice ? 250 : 400,
    alignSelf: 'center',
    textAlign: 'center',
  },
});

export function ErrorNotification({
  icon,
  errorTitle,
  errorMessage,
  buttonLabel,
  buttonAction,
  style,
}) {
  return (
    <Screen style={[styles.container, style]}>
      {icon === 'loader' && <LoadingIndicator />}
      {icon === 'checkmark' && (
      <Icon
        name="checkmark"
        size={100}
        color="#6FC62E"
        style={styles.checkmark}
      />
      )}
      {icon === 'alert-circle' && (
      <Icon
        name="alert-circle"
        size={50}
        color="#ff6a00"
        style={styles.checkmark}
      />
      )}
      <H1 style={styles.heading}>{errorTitle || 'Something went wrong'}</H1>
      {errorMessage && <P style={styles.message}>{errorMessage}</P>}
      {buttonLabel && <Button label={buttonLabel} onPress={buttonAction} />}
    </Screen>
  );
}

ErrorNotification.propTypes = {
  icon: PropTypes.string,
  errorTitle: PropTypes.string,
  errorMessage: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonAction: PropTypes.func,
};

ErrorNotification.defaultProps = {
  icon: undefined,
  errorTitle: undefined,
  errorMessage: undefined,
  buttonLabel: undefined,
  buttonAction: () => null,
};

export default ErrorNotification;
