import React from 'react';
import { View, Linking } from 'react-native';
import AttentionSeeker from '../../../components/AttentionSeeker';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { Strong, P } from '../../../components/Text';
import { colors, links } from '../../../constants';
import styles from './PluginNotInstalledWarning.styles';

function PluginNotInstalledWarning() {
  return (
    <View style={styles.downloadPluginContainer}>
      <AttentionSeeker style={styles.downloadPluginAttention} />
      <Icon size={22} name="warning" color={colors.brand.primary} />
      <View style={styles.downloadPluginTextWrapper}>
        <Strong style={styles.downloadPluginTitle}>FastPicker plugin not installed</Strong>
        <P style={styles.downloadPluginText}>
          The FastPicker Wordpress plugin needs to be installed on you webshop before
          you can use one of the supported carriers.
        </P>
        <P style={styles.downloadPluginText}>
          Without the plugin you can not declare orders from the FastPicker app.
        </P>
        <Button
          style={styles.openLinkButton}
          label="Download Plugin here"
          type="secondary"
          rightIcon={(
            <Icon
              size={22}
              style={styles.openLinkIcon}
              name="exit-outline"
              color={colors.brand.primary}
            />
           )}
          onPress={() => Linking.openURL(links.FastPickerPluginStore)}
        />
      </View>
    </View>
  );
}

export default PluginNotInstalledWarning;
