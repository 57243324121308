import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { P } from './Text';
import Icon from './Icon';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: 2,
  },
  label: {
    textTransform: 'uppercase',
    color: '#B6B6B6',
    alignSelf: 'center',
    fontSize: 16,
    marginLeft: 7,
  },
});

function LoadMoreButton({ label, onPress, style }) {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.container, style]}>
      <Icon name="refresh" color="#B6B6B6" size={19} style={styles.icon} />
      <P style={styles.label}>{label}</P>
    </TouchableOpacity>
  );
}

LoadMoreButton.propTypes = {
  label: PropTypes.string.isRequired,
  onPress: PropTypes.func,
};

LoadMoreButton.defaultProps = {
  onPress: () => null,
};

export default LoadMoreButton;
