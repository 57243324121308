import React from 'react';
import { View } from 'react-native';
import UserMenu from './UserMenu';
import Notifications from './Notifications';
import styles from './UserControlbar.styles';
import MainMenu from './MainMenu/MainMenu';

function UserControlBar() {
  return (
    <View style={styles.container}>
      <Notifications />
      <MainMenu />
      <View style={styles.divider} />
      <UserMenu />
    </View>
  );
}

export default UserControlBar;
