import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from '../screens/LoginScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import UpdatePasswordScreen from '../screens/UpdatePasswordScreen';
import RegisterScreen from '../screens/RegisterScreen';
import VerifyEmailScreen from '../screens/register/VerifyEmailScreen';
import ResendVerifyEmailScreen from '../screens/register/ResendVerifyEmailScreen';
import VerifyEmailChangeScreen from '../screens/VerifyEmailChangeScreen';

const NavigationStack = createStackNavigator();

const routes = {
  config: {
    screens: {
      Login: 'login',
      Register: 'register',
      ForgotPassword: 'forgot-password',
      UpdatePassword: 'update-password/:token',
      VerifyEmail: 'verify/:token',
      VerifyEmailChange: '/verify/change/:token',
      ResendVerifyEmail: '/resend-verify-email',
    },
  },
};

const options = {
  headerShown: false,
  title: 'FastPicker',
};

function PublicStackNavigation() {
  return (
    <NavigationContainer linking={routes}>
      <NavigationStack.Navigator initialRouteName="Login">
        <NavigationStack.Screen
          name="Login"
          component={LoginScreen}
          options={options}
        />
        <NavigationStack.Screen
          name="Register"
          component={RegisterScreen}
          options={options}
        />
        <NavigationStack.Screen
          name="ForgotPassword"
          component={ForgotPasswordScreen}
          options={options}
        />
        <NavigationStack.Screen
          name="UpdatePassword"
          component={UpdatePasswordScreen}
          options={options}
        />
        <NavigationStack.Screen
          name="VerifyEmail"
          component={VerifyEmailScreen}
          options={options}
        />
        <NavigationStack.Screen
          name="ResendVerifyEmail"
          component={ResendVerifyEmailScreen}
          options={options}
        />
        <NavigationStack.Screen
          name="VerifyEmailChange"
          component={VerifyEmailChangeScreen}
          options={options}
        />
      </NavigationStack.Navigator>
    </NavigationContainer>
  );
}

export default PublicStackNavigation;
