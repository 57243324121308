import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { View, Linking } from 'react-native';
import { P, Strong, A } from '../../../components/Text';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import { colors, links } from '../../../constants';
import styles from './DataProcessingAgreement.styles';
import { useToastNotification } from '../../../services/toast';
import { useIntl, useLocale } from '../../../services/localization';
import { mutationError } from '../../../services/Error';

export const ACCEPT_AGREEMENT = gql`
  mutation ($userId: ID!) {
    updateUser(
      input: {
          id: $userId
          data_processing_agreement: true
        }
      ) {
        id
        data_processing_agreement
    }
  }
`;

function DataProcessingAgreement({ userId, acceptDate }) {
  const intl = useIntl();
  const $t = useLocale();
  const hasAcceptedAgreement = !!acceptDate;
  const { toastWarning, toastSucces } = useToastNotification();

  const translations = React.useMemo(() => ({
    title: $t({ id: 'DataProcessingAgreement.Title', defaultMessage: 'Data Processing Agreement' }),
    agreementAccepted: $t({ id: 'DataProcessingAgreement.AgreementAccepted.Toast', defaultMessage: 'You accepted our Data Processing Agreement, thanks!' }),
    agreementAcceptedLabel: $t({ id: 'DataProcessingAgreement.AgreementAccepted.label', defaultMessage: 'Accepted' }),
    pleaseReview: $t({ id: 'DataProcessingAgreement.PleaseReviewAgreement.Title', defaultMessage: 'Please review and accept our Data Processing Agreement' }),
    pleaseReviewDescription: $t({ id: 'DataProcessingAgreement.PleaseReviewAgreement.Description', defaultMessage: 'According to the General Data Processing Regulation (GDPR) you must make agreements with us about the processing of personal data for which you are responsible. Please review and accept our agreement.' }),
    viewReviewLabel: $t({ id: 'DataProcessingAgreement.ViewAgreementButton.Label', defaultMessage: 'View agreement (pdf)' }),
    acceptAgreementLabel: $t({ id: 'DataProcessingAgreement.AcceptAgreementButton.Label', defaultMessage: 'Accept agreement' }),
    acceptedDescription: $t({ id: 'DataProcessingAgreement.YouHaveAcceptedOn.Explainer', defaultMessage: 'You\'ve accepted our Data Processing Agreement on {data}.' }, {
      data: intl.formatDate(acceptDate, {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric',
      }),
    }),
  }), [$t, acceptDate, intl]);

  const [acceptAgreement, { loading: accepting }] = useMutation(ACCEPT_AGREEMENT, {
    variables: {
      userId,
    },
    onError(error) {
      toastWarning(mutationError(error));
    },
    onCompleted() {
      toastSucces(translations.agreementAccepted);
    },
  });

  function renderContent() {
    if (!hasAcceptedAgreement) {
      return (
        <View style={styles.contentContainer}>
          <Icon size={22} name="shield-outline" color={colors.brand.primary} />
          <View style={styles.downloadPluginTextWrapper}>
            <Strong style={styles.downloadPluginTitle}>
              {translations.pleaseReview}
            </Strong>
            <P style={styles.downloadPluginText}>
              {translations.pleaseReviewDescription}
            </P>
            <View style={styles.buttonContainer}>
              <Button
                onPress={() => Linking.openURL(links.DataProcessingAgreement)}
                style={styles.openLinkButton}
                label={translations.viewReviewLabel}
                type="secondary"
                rightIcon={(
                  <Icon
                    size={22}
                    name="exit-outline"
                    color={colors.brand.primary}
                    style={styles.openLinkIcon}
                  />
            )}
              />
              <Button
                style={styles.openLinkButton}
                label={translations.acceptAgreementLabel}
                onPress={acceptAgreement}
                loading={accepting}
              />
            </View>
          </View>
        </View>
      );
    }
    return (
      <>
        <View style={styles.contentContainer}>
          <View style={[styles.pluginInstalledTextWrapper, styles.noBottomMargin]}>
            <Icon size={22} name="shield-checkmark-outline" color={colors.ui.ok} />
            <P style={styles.pluginInstalledText}>
              {translations.acceptedDescription}
            </P>
          </View>
        </View>
        <View style={styles.seperator} />
        <View style={styles.visitStoreWrapper}>
          <A onPress={() => Linking.openURL(links.DataProcessingAgreement)}>
            {translations.viewReviewLabel}
          </A>
          <Icon
            size={19}
            name="exit-outline"
            color={colors.brand.primary}
            style={styles.visitStoreIcon}
          />
        </View>
      </>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleWrapper}>
        <Strong style={styles.title}>{translations.title}</Strong>
        {hasAcceptedAgreement && <Strong style={styles.statusLabel}>{translations.agreementAcceptedLabel}</Strong>}
      </View>
      <View style={styles.seperator} />
      {renderContent()}
    </View>
  );
}

DataProcessingAgreement.propTypes = {
  userId: PropTypes.string.isRequired,
  acceptDate: PropTypes.string,
};

DataProcessingAgreement.defaultProps = {
  acceptDate: undefined,
};

export default DataProcessingAgreement;
