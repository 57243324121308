import { StyleSheet } from 'react-native';
import { colors, fonts } from '../../../constants/theme';

export default StyleSheet.create({
  header: {
    backgroundColor: '#f9f9f9', // @TODO: alternative lighter background color variant. Add to theme
    padding: 40,
    paddingBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
  },
  providerLabel: {
    fontSize: fonts.size.label,
    marginBottom: 3,
    marginLeft: 1,
  },
  heading: {
    marginBottom: 20,
  },
  postnlLogo: {
    width: 30,
    height: 30,
  },
  myparcelLogo: {
    width: 27,
    height: 30,
  },
  kdzLogo: {
    width: 40,
    height: 28,
  },
  form: {
    padding: 20,
  },
  footer: {
    paddingHorizontal: 40,
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
    backgroundColor: '#f9f9f9', // @TODO: alternative lighter background color variant. Add to theme
  },
  actionsWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 91,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginVertical: 10,
    marginRight: 20,
  },
  noMargin: {
    marginBottom: 0,
  },
  labelWrapper: {
    minHeight: 48,
    paddingRight: 30,
    flex: 1,
    maxWidth: 200,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  label: {
    fontSize: fonts.size.label,
  },
  textArea: {
    flex: 1,
    maxWidth: 430,
  },
  numberField: {
    flex: 1,
    maxWidth: 200,
  },
  colliContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  colliRowWrapper: {
    flexDirection: 'row',
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  colliField: {
    flex: 1,
    marginRight: 5,
  },
  noRightMargin: {
    marginRight: 0,
  },
  removeColliRow: {
    position: 'absolute',
    right: -25,
    height: 40,
    width: 30,
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  checkbox: {
    marginRight: 10,
    marginVertical: 5,
  },
  optionsContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  inputNote: { // @TODO: move to Form component. Allow for normal string and components so all notes share same style
    marginTop: 3,
    marginLeft: 3,
  },
  infoBar: {
    margin: 20,
  },
});
