import React from 'react';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import ListItem from '../../../components/ListItem';
import { P } from '../../../components/Text';
import OrderStatusLabel from '../../../components/OrderStatusLabel';
import { OrderStatus, ShippingLabelStatus } from '../../../constants';
import { useLocale } from '../../../services/localization';
import styles from './OrderListItem.styles';

function OrderListItem({
  id,
  wooId,
  customerName,
  address,
  city,
  totalItems,
  pickProgress,
  disabled,
  status,
  shippingStatus,
  style,
}) {
  const $t = useLocale();
  const navigation = useNavigation();

  const routeName = disabled ? 'ViewOrder' : 'PickOrder';

  return (
    <ListItem style={[styles.container, style]} onPress={() => navigation.navigate(routeName, { orderId: id, wooId })}>
      <P style={[styles.cell, styles.firstCell]}>{`#${wooId}`}</P>
      <View style={styles.orderInfo}>
        <P style={styles.name}>{customerName}</P>
        <P style={styles.address}>{`${address}, ${city}`}</P>
      </View>
      <P numberOfLines={1} style={[styles.cell, styles.items]}>
        {$t(
          {
            id: 'OrderListItem.TotalItems.Text',
            defaultMessage: '{itemCount} {itemCount, plural, one {item} other {items}}',
          },
          { itemCount: totalItems },
        )}
      </P>
      <OrderStatusLabel status={status} pickProgress={pickProgress} shippingStatus={shippingStatus} />
    </ListItem>
  );
}

OrderListItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wooId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.oneOf(Object.values(OrderStatus)),
  shippingStatus: PropTypes.oneOf(Object.values(ShippingLabelStatus)),
  customerName: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  totalItems: PropTypes.number,
  pickProgress: PropTypes.number,
  disabled: PropTypes.bool,
};

OrderListItem.defaultProps = {
  id: Math.random() * 1000,
  wooId: Math.random() * 1000,
  status: OrderStatus.New,
  shippingStatus: undefined,
  customerName: '',
  address: '',
  city: '',
  totalItems: 0,
  pickProgress: 0,
  disabled: false,
};

export default OrderListItem;
