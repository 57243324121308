import React from 'react';
import { useNavigation, StackActions } from '@react-navigation/native';
import { mutationError } from '../services/Error';
import { FadeIn } from './Animation';
import { ErrorNotification } from './ErrorNotification';
import LoadingIndicator from './LoadingIndicator';
import PickersListItem from './PickersListItem';
import usePickers from '../hooks/usePickers';
import { useLocale } from '../services/localization';

function PickersList() {
  const { loading, error, data } = usePickers();
  const navigation = useNavigation();
  const $t = useLocale();

  const formattedPickers = React.useMemo(() => {
    if (!data) {
      return false;
    }
    return {
      items: data.pickers.data.map((picker) => (
        <PickersListItem
          key={picker.id}
          id={picker.id}
          name={picker.name}
          email={picker.email}
        />
      )),
      hasPickers: data.pickers.data?.length > 0,
    };
  }, [data]);

  if (loading) return <LoadingIndicator />;
  if (error) {
    return (
      <FadeIn>
        <ErrorNotification errorMessage={mutationError(error)} />
      </FadeIn>
    );
  }
  if (!formattedPickers.hasPickers) {
    return (
      <FadeIn>
        <ErrorNotification
          errorTitle={$t({ id: 'PickersList.AddFirstPicker.Title', defaultMessage: 'Add your first picker' })}
          errorMessage={$t({ id: 'PickersList.AddFirstPicker.Description', defaultMessage: 'You don\'t have any pickers yet.' })}
          buttonLabel={$t({ id: 'PickersList.AddPickerButton.Label', defaultMessage: '+ Add picker' })}
          buttonAction={() => navigation.dispatch(
            StackActions.replace('AddPicker'),
          )}
        />
      </FadeIn>
    );
  }
  return formattedPickers.items;
}

export default PickersList;
