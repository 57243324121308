import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  heading: {
    marginBottom: 30,
  },
  input: {
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
  },
  text: {
    paddingBottom: 30,
  },
  registerText: {
    textAlign: 'center',
    marginTop: 30,
  },
  noteText: {
    marginTop: 3,
    marginLeft: 3,
    color: '#ff6a00',
  },
  radioWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 5,
    marginLeft: 2,
  },
  radio: {
    width: 17,
    height: 17,
    backgroundColor: '#fff',
    borderColor: '#E1E1E1',
    borderWidth: 1,
    borderRadius: 3,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioActive: {
    backgroundColor: '#2D54C4',
    borderColor: '#2D54C4',
  },
  termsOfServiceLabel: {
    marginLeft: 5,
  },
  freeTrailText: {
    fontSize: 15,
    textAlign: 'center',
    marginTop: 5,
  },
});

export default styles;
