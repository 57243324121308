import { useQuery } from '@apollo/client';
import { GET_SHOP } from '../graphql/ShopQuery';

const useShop = (shopId) => {
  const { loading, error, data } = useQuery(GET_SHOP, {
    variables: {
      shopId,
    },
  });

  return { loading, error, data };
};

export default useShop;
