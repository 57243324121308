import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import Icon from '../Icon';
import { P, Strong } from '../Text';
import AttentionSeeker from '../AttentionSeeker';
import Button from '../Button';
import { colors, ShopStatus } from '../../constants';
import { useToastNotification } from '../../services/toast';
import { useLocale } from '../../services/localization';
import styles from './RetryConnectionWarning.styles';

const RETRY_CONNECTION = gql`
mutation ($id: ID!) {
  retryConnection(id: $id) {
    id
    status
  }
}
`;

function RetryConnectionAlert({ shopId }) {
  const $t = useLocale();
  const { toastSucces, toastWarning } = useToastNotification();

  const translations = React.useMemo(() => ({
    title: $t({ id: 'RetryConnectionAlert.Title', defaultMessage: 'Shop connection failed' }),
    explanation: $t({ id: 'RetryConnectionAlert.Explanation.Text', defaultMessage: 'We are unable to connect with your WooCommerce webshop. Please try the following:' }),
    fixOne: $t({ id: 'RetryConnectionAlert.FixOne.Text', defaultMessage: 'Check if your Woo API credentials have READ/WRITE permissions' }),
    fixTwo: $t({ id: 'RetryConnectionAlert.FixTwo.Text', defaultMessage: 'Try to reconnect' }),
    button: $t({ id: 'RetryConnectionAlert.RetryButton.Label', defaultMessage: 'Retry connection' }),
    retrying: $t({ id: 'RetryConnectionAlert.Retrying.Text', defaultMessage: 'Retrying connection...' }),
    retryFailed: $t({ id: 'RetryConnectionAlert.RetryFailed.Error', defaultMessage: 'Reconnecting failed. Please contact our support or try again.' }),
    retrySucces: $t({ id: 'RetryConnectionAlert.RetrySucces.Text', defaultMessage: 'Succes! Your shop is connected and active again.' }),
  }), [$t]);

  const [retryConnection, { loading }] = useMutation(RETRY_CONNECTION, {
    variables: { id: shopId },
    onCompleted(data) {
      if (!data || data.retryConnection.status !== ShopStatus.Active) {
        toastWarning(translations.retryFailed);
        return;
      }
      toastSucces(translations.retrySucces);
    },
    onError() {
      toastWarning(translations.retryFailed);
    },
  });

  return (
    <View style={styles.container}>
      <AttentionSeeker style={styles.attention} />
      <Icon size={22} name="warning" color={colors.brand.primary} />
      <View style={styles.textWrapper}>
        <Strong style={styles.title}>{translations.title}</Strong>
        <P style={styles.text}>{translations.explanation}</P>
        <P style={styles.text}>{`👉  ${translations.fixOne}`}</P>
        <P style={styles.text}>{`👉  ${translations.fixTwo}`}</P>
        <Button
          style={styles.button}
          label={translations.button}
          loading={loading}
          loadingLabel={translations.retrying}
          onPress={retryConnection}
          leftIcon={<Icon size={22} style={styles.buttonIcon} name="refresh" color={colors.background.primary} />}
        />
      </View>
    </View>
  );
}

RetryConnectionAlert.propTypes = {
  shopId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default RetryConnectionAlert;
