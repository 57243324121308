import React from 'react';
import { useNavigation, StackActions } from '@react-navigation/native';
import { mutationError } from '../../../services/Error';
import { FadeIn } from '../../../components/Animation';
import { ErrorNotification } from '../../../components/ErrorNotification';
import LoadingIndicator from '../../../components/LoadingIndicator';
import ShopsListItem from './ShopsListItem';
import useShops from '../../../hooks/useShops';
import { useLocale } from '../../../services/localization';

function ShopsList() {
  const { loading, error, data } = useShops();
  const navigation = useNavigation();
  const $t = useLocale();

  const formattedShops = React.useMemo(() => {
    if (!data) {
      return false;
    }
    return {
      items: data.shops.data.map((shop) => (
        <ShopsListItem
          key={shop.id}
          shopId={shop.id}
          domain={shop.url}
          name={shop.name}
          status={shop.status}
          wizardStatus={shop.wizard_status}
        />
      )),
      hasShops: data.shops.data?.length > 0,
    };
  }, [data]);

  if (loading) return <LoadingIndicator />;
  if (error) {
    return (
      <FadeIn>
        <ErrorNotification errorMessage={mutationError(error)} />
      </FadeIn>
    );
  }
  if (!formattedShops.hasShops) {
    return (
      <FadeIn>
        <ErrorNotification
          errorTitle={$t({ id: 'ShopsList.AddFirstShop.Title', defaultMessage: 'Add a shop' })}
          errorMessage={$t({ id: 'ShopsList.AddFirstShop.Description', defaultMessage: 'You don\'t have any shops connected yet.' })}
          buttonLabel={$t({ id: 'ShopsList.AddShopButton.Label', defaultMessage: '+ Add shop' })}
          buttonAction={() => navigation.dispatch(
            StackActions.replace('AddShop'),
          )}
        />
      </FadeIn>
    );
  }
  return formattedShops.items;
}

export default ShopsList;
