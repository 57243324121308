import { useState, useCallback, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { PICK_ITEM } from '../graphql/OrderQuery';
import useEventListener from './useEventListener';
import { useToastNotification } from '../services/toast';
import { parseSku } from '../services/Helpers';
import { useLocale } from '../services/localization';

const SCAN_TRIGGER = 'Enter';

const useBarcodeScanner = (orderLines = []) => {
  const $t = useLocale();
  const scannedString = useRef('');
  const [scannerActive, setScannerActive] = useState(true);
  const [scannedItem, setScannedItem] = useState({ orderLineId: undefined, scanTime: undefined });
  const [pickOrderItem] = useMutation(PICK_ITEM);
  const toast = useToastNotification();

  const isActiveHandler = useCallback(
    (e) => {
      if (e.type === 'blur') setScannerActive(false);
      else if (e.type === 'focus') setScannerActive(true);
    },
    [setScannerActive],
  );

  const scanProduct = useCallback(async (barcode) => {
    const pickedLine = orderLines.find(({ sku }) => parseSku(barcode).search(parseSku(sku)) !== -1);

    if (!pickedLine) {
      toast.toastInfo(`No item found for barcode [${barcode}]`);
      return;
    }

    if (pickedLine.picked >= pickedLine.quantity) {
      const message = $t({ defaultMessage: 'Picking {itemName} #{sku} completed!', id: 'BarcodeScanner.PickCompleted.Text' }, { itemName: pickedLine.title, sku: pickedLine.sku });
      toast.toastSucces(message);
      return;
    }

    const optimisticResponse = {
      picked: {
        id: pickedLine.id,
        quantity: pickedLine.quantity,
        picked: pickedLine.picked + 1,
        __typename: 'OrderLine',
      },
    };

    try {
      setScannedItem({ orderLineId: pickedLine.id, scanTime: Date.now() });
      await pickOrderItem({
        variables: { orderLineId: pickedLine.id },
        optimisticResponse,
      });
      const message = $t({ defaultMessage: 'Item scanned {itemName} #{sku}', id: 'BarcodeScanner.ItemScanned.Text' }, { itemName: pickedLine.title, sku: pickedLine.sku });
      toast.toastSucces(message);
    } catch {
      const message = $t({ defaultMessage: 'Something went wrong when picking {itemName} #{sku}', id: 'BarcodeScanner.ItemScannedError.Text' }, { itemName: pickedLine.title, sku: pickedLine.sku });
      toast.toastWarning(message);
    }
  }, [$t, orderLines, pickOrderItem, toast]);

  const handler = useCallback(
    (e) => {
      if (e.key === SCAN_TRIGGER && scannedString.current.length > 0) {
        scanProduct(scannedString.current);
        scannedString.current = '';
        return;
      }
      if (e.key.length === 1) {
        scannedString.current += e.key;
      }
    },
    [scanProduct],
  );

  useEventListener('keyup', handler);
  useEventListener('focus', isActiveHandler);
  useEventListener('blur', isActiveHandler);

  return { scannerActive, scannedItem };
};

export default useBarcodeScanner;
