import React from 'react';
import { StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { P } from '../Text';
import { colors } from '../../constants/theme';

const styles = StyleSheet.create({
  container: {
    marginTop: 15,
    backgroundColor: colors.warning.background,
    borderColor: colors.warning.border,
    color: colors.warning.font,
    borderWidth: 1,
    borderRadius: 5,
    paddingVertical: 10,
    paddingHorizontal: 15,
    textAlign: 'left',
    lineHeight: 22,
  },
});

function Alert({ text, style }) {
  return <P style={[styles.container, style]}>{text}</P>;
}

Alert.propTypes = {
  text: PropTypes.node,
};

Alert.defaultProps = {
  text: '',
};

export default Alert;
