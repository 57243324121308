import { Dimensions, StyleSheet } from 'react-native';
import { colors } from '../../../../constants';

const smallDevice = Dimensions.get('window').width < 875;

const styles = StyleSheet.create({
  item: {
    ...(smallDevice
      ? {
        flexDirection: 'column',
        alignItems: 'flex-start',
        paddingVertical: 10,
      }
      : {
        paddingVertical: 7,
      }),
  },
  icon: {
    color: colors.brand.primary,
    paddingRight: 15,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    paddingRight: 20,
  },
  textContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingVertical: 5,
    flexShrink: 1,
  },
  status: {
    paddingTop: 10,
    paddingBottom: 3,
  },
  shopName: {
    flex: 1,
    fontFamily: 'Roboto bold',
    textAlign: 'left',
    textTransform: 'uppercase',
    fontSize: 16,
  },
  shopUri: {
    color: '#717171', // @TODO: add color to theme
    textAlign: 'left',
    paddingTop: 3,
  },
});

export default styles;
