/* eslint-disable max-len */ // @TODO remove
import React, { useMemo, useRef } from 'react';
import { View, Platform } from 'react-native';
import { smallDevice, styles, mStyles } from './PickOrderScreen.styles';
import { pickProgress } from '../../services/Helpers';
import { mutationError } from '../../services/Error';
import { FadeIn } from '../../components/Animation';
import Screen from '../../components/Layout';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ErrorNotification } from '../../components/ErrorNotification';
import { H1, H3 } from '../../components/Text';
import ProgressBar from '../../components/ProgressBar';
import OrderLine from './OrderLine';
import ScannerStatus from '../../components/ScannerStatus';
import PrintPackingSlipButton from './PrintPackingSlipButton';
import DeclareForShippingButton from './DeclareForShippingButton';
import DeclareForShippingModal from '../../features/DeclareForShippingModal';
import { useLocale } from '../../services/localization';
import useOrder from '../../hooks/useOrder';
import useCompleteOrder from './useCompleteOrder';
import useBarcodeScanner from '../../hooks/useBarcodeScanner';
import { ShippingLabelStatus } from '../../constants';
import CompleteOrderButton from './CompleteOrderButton';

function PickOrderScreen({ route, navigation }) {
  const { orderId } = route.params;
  const { loading, error, data: response } = useOrder(orderId, { pollInterval: 10000 });
  const declareForShippingModalRef = useRef();
  const { scannerActive } = useBarcodeScanner(response?.order?.order_lines);
  const $t = useLocale();

  const content = useMemo(() => {
    if (!response) return false;

    const { progressPercentage } = pickProgress(
      response.order.order_lines,
    );

    const percentage = response.order.shipping_label ? 100 : progressPercentage - 10;

    return {
      progressPercentage: percentage,
      pickingCompleted: progressPercentage === 100,
      orderLines: response.order.order_lines,
      totalOrderLines: response.order.order_lines.length,
      wooOrderId: response.order.woocommerce_order_id,
      shippingLabelUrl: response.order.shipping_label,
      shopId: response.order.shop_id,
      shippingFields: response.order.shipping_fields,
      destinationCountry: {
        name: response.order.shipping_country,
        code: response.order.shipping_country_code,
      },
      shippingLabelStatus: response.order.shipping_label_status,
      shippingFailedReason: response.order.shipping_failed_reason,
      shippingProvider: response.order.shipping_provider,
      isCompleting: false,
    };
  }, [response]);

  const openShippingModal = () => {
    declareForShippingModalRef.current.open();
  };

  const onCompleteOrderError = () => {
    alert('Something went wrong, did you pick all items?');
  };

  const { completeOrder, loading: completing } = useCompleteOrder(
    orderId,
    null,
    onCompleteOrderError,
  );

  const orderPickedAndCompleted = route.name === 'PickOrder' && (response?.order?.status === 'completing' || response?.order?.status === 'completed');

  if (loading) return <LoadingIndicator />;
  if (!response) {
    return (
      <ErrorNotification
        errorTitle={$t({ id: 'PickOrderScreen.OrderNotFound.Title', defaultMessage: 'Order not found' })}
        errorMessage={$t({ id: 'PickOrderScreen.OrderNotFound.Description', defaultMessage: 'Sorry, you\'re looking for an order that does not exists.' })}
        buttonLabel={$t({ id: 'PickOrderScreen.BackToOrdersButton.Label', defaultMessage: 'Back to orders' })}
        buttonAction={() => navigation.navigate('Dashboard')}
      />
    );
  }
  if (error) return <ErrorNotification errorMessage={mutationError(error)} />;
  if (orderPickedAndCompleted) {
    return (
      <FadeIn>
        <ErrorNotification
          errorTitle={$t({ id: 'PickOrderScreen.OrderCompleted.Title', defaultMessage: 'Order completed!' })}
          errorMessage={$t({ id: 'PickOrderScreen.OrderCompleted.Description', defaultMessage: 'Order #{orderId} is packed and customer has been notified.' }, { orderId: content.wooOrderId })}
          icon="checkmark"
          buttonLabel={$t({ id: 'PickOrderScreen.NextOrderButton.Label', defaultMessage: 'Pick next order' })}
          buttonAction={() => navigation.navigate('Dashboard')}
        />
      </FadeIn>
    );
  }

  const hasEnabledShippingProviders = content?.shippingFields.length > 0;
  const isDeclaredForShipping = (
    content.shippingLabelStatus === ShippingLabelStatus.DECLARED || (content.pickingCompleted && !hasEnabledShippingProviders)
  );
  const focusCompleteOrderButton = content.pickingCompleted && isDeclaredForShipping;
  const disableCompleteOrderButton = hasEnabledShippingProviders
    ? (!content.pickingCompleted || content.shippingLabelStatus !== ShippingLabelStatus.DECLARED)
    : (!content.pickingCompleted);

  const disablePicking = response?.order?.status === 'completed' || response?.order?.status === 'completing';

  return (
    <Screen>
      {
        hasEnabledShippingProviders
        && (
        <DeclareForShippingModal
          ref={declareForShippingModalRef}
          shopId={content.shopId}
          orderId={orderId}
          shippingFields={content.shippingFields}
          shippingStatus={content.shippingLabelStatus}
          shippingProvider={content.shippingProvider}
          destinationCountry={content.destinationCountry}
        />
        )
      }
      <FadeIn>
        <View style={[styles.heading, smallDevice && mStyles.heading]}>
          <H1>
            {$t({ id: 'PickOrderScreen.Heading', defaultMessage: 'Picking order #{orderId}' }, {
              orderId: content.wooOrderId,
            })}
          </H1>
          <ProgressBar
            percentage={content.progressPercentage}
            style={[smallDevice && mStyles.progressBar]}
          />
        </View>
        <View style={[styles.actions, smallDevice && mStyles.actions]}>
          <PrintPackingSlipButton orderId={orderId} highlight={!content.progressPercentage > 0} />
          <DeclareForShippingButton
            shippingStatus={content.shippingLabelStatus}
            labelUrl={content.shippingLabelUrl}
            provider={content.shippingProvider}
            pickingCompleted={content.pickingCompleted}
            canDeclare={hasEnabledShippingProviders}
            onPress={openShippingModal}
          />
          <CompleteOrderButton
            style={styles.completeOrderAndNotify}
            focus={focusCompleteOrderButton}
            disabled={disableCompleteOrderButton}
            onPress={completeOrder}
            loading={completing}
            orderStatus={response?.order?.status}
          />
        </View>
        <View style={styles.flex}>
          <H3 style={styles.subHeading}>
            {content.pickingCompleted
              ? $t({ id: 'PickOrderScreen.AllItemsPicker.Text', defaultMessage: 'All items are picked' })
              : $t({ id: 'PickOrderScreen.PickAllItems.Text', defaultMessage: 'Pick and check all items' })}
          </H3>
          {['web'].includes(Platform.OS) && <ScannerStatus active={scannerActive} disabled={disablePicking} />}
        </View>
        {content.orderLines.map((line) => (
          <OrderLine
            style={styles.orderLine}
            key={line.id}
            id={line.id}
            sku={line.sku}
            title={line.title}
            picked={line.picked}
            quantity={line.quantity}
            image={line.image}
            disablePicking={disablePicking}
          />
        ))}
      </FadeIn>
    </Screen>
  );
}

export default PickOrderScreen;
