import React from 'react';
import { StyleSheet, View } from 'react-native';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { ShippingPluginName } from '../../../constants';
import { useLocale } from '../../../services/localization';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import { H1, P } from '../../../components/Text';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    marginVertical: 50,
    alignItems: 'center',
  },
  icon: {
    alignSelf: 'center',
    marginBottom: 25,
  },
  heading: {
    textAlign: 'center',
    marginBottom: 10,
  },
  message: {
    fontSize: 16,
    marginTop: 10,
    maxWidth: 450,
    textAlign: 'center',
  },
  button: {
    marginTop: 30,
  },
  errorCode: {
    marginTop: 40,
    fontSize: 12,
    color: '#808080',
    textTransform: 'lowercase',
  },
});

export const GET_SHIPPING_STATUS = gql`
  query order($orderId: ID!) {
    order(id: $orderId) {
      id
      shipping_label_status
      shipping_provider
      shipping_failed_reason
      notifications {
        id
        message
        type
        code
        subject {
          id
          typename
        }
        created_at
        updated_at
      }
    }
  }
`;

function Error({ orderId, onCompleted }) {
  const $t = useLocale();
  const { data, loading } = useQuery(
    GET_SHIPPING_STATUS,
    {
      variables: {
        orderId,
      },
    },
  );

  const latestErrorNotification = React.useMemo(() => {
    const sortedNotifications = [...data?.order?.notifications || []].sort(
      (objA, objB) => new Date(objA.created_at) - new Date(objB.created_at),
    );
    return sortedNotifications.find((item) => item.type === 'error');
  }, [data?.order?.notifications]);

  const providerId = data?.order?.shipping_provider;
  const providerName = ShippingPluginName[providerId] || '';

  const translations = React.useMemo(() => ({
    heading: $t({ id: 'ShippingModal.ErrorModal.FailedToDelcare.Heading', defaultMessage: 'Failed to declare' }),
    description: $t({ id: 'ShippingModal.ErrorModal.UnableToDeclareWith.Text', defaultMessage: 'We are unable to declare this order to {provider}.' }, { provider: providerName }),
    errorUnknownWoo: $t({ id: 'ShippingModal.ErrorModal.SomethingUnknownWentWrong.Error', defaultMessage: 'Something went wrong. Please check your Woo provider settings.' }),
    errorUnknownSendcloud: $t({ id: 'ShippingModal.ErrorModal.UnknownSendcloud.Error', defaultMessage: 'Please make sure you entered to correct form values or check your Sendcloud settings.' }),
    tryAgain: $t({ id: 'ShippingModal.ErrorModal.TryAgain.Button', defaultMessage: 'Try again' }),
    export_order_to_carrier_error: $t({ id: 'ShippingModal.ErrorModal.OrderCanNotBeExported.Error', defaultMessage: 'Order couldn\'t be exported to carrier.' }),
  }), [$t, providerName]);

  if (loading) {
    return null;
  }

  const errorNotification = translations[latestErrorNotification?.code] || (providerId === 'sendcloud' ? translations.errorUnknownSendcloud : translations.errorUnknownWoo);

  return (
    <View style={styles.container}>
      <Icon
        name="alert-circle"
        size={50}
        color="#ff6a00"
        style={styles.icon}
      />
      <H1 style={styles.heading}>{translations.heading}</H1>
      <P style={styles.message}>{translations.description}</P>
      <P style={styles.message}>{errorNotification}</P>
      <Button style={styles.button} label={translations.tryAgain} onPress={onCompleted} />
      <P style={styles.errorCode}>{`Error: ${data?.order?.shipping_failed_reason} | ${latestErrorNotification?.code}`}</P>
    </View>
  );
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

Error.propTypes = {
  orderId: IdType.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

export default Error;
