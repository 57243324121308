import React from 'react';
import PropTypes from 'prop-types';
import {
  View, Image, ActivityIndicator,
} from 'react-native';
import Icon from '../../../components/Icon';
import { isValidHttpUrl } from '../../../services/Validation';
import styles from './ShopLogo.styles';

function ShopLogo({ imageUrl }) {
  const [isContentLoading, toggleContentLoading] = React.useState(false);
  const [failedToLoad, setFailedToLoad] = React.useState(false);

  const onLoadStart = React.useCallback(() => {
    toggleContentLoading(true);
    setFailedToLoad(false);
  }, []);

  const onError = React.useCallback(() => {
    setFailedToLoad(true);
  }, []);

  const onLoad = React.useCallback(() => {
    setFailedToLoad(false);
    toggleContentLoading(false);
  }, []);

  const canLoadImage = isValidHttpUrl(imageUrl);

  return (
    <View style={styles.container}>
      {isContentLoading && (
        <View style={styles.state}>
          <ActivityIndicator />
        </View>
      )}
      {!canLoadImage && (
        <View style={styles.state}>
          <Icon name="image-outline" size={40} color="#bdbdbd" />
        </View>
      )}
      {failedToLoad && (
        <View style={styles.state}>
          <Icon name="close-circle-outline" size={40} color="#979797" />
        </View>
      )}
      {canLoadImage
        && (
        <Image
          style={styles.logo}
          source={{
            uri: imageUrl,
          }}
          onLoadStart={onLoadStart}
          onLoad={onLoad}
          onError={onError}
        />
        )}
    </View>
  );
}

ShopLogo.propTypes = {
  imageUrl: PropTypes.string,
};

ShopLogo.defaultProps = {
  imageUrl: '',
};

export default ShopLogo;
