import { StyleSheet } from 'react-native';
import { colors } from '../../../constants';

export default StyleSheet.create({
  footer: {
    padding: 20,
    paddingHorizontal: 40,
    borderTopWidth: 1,
    borderTopColor: colors.border.primary,
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#f9f9f9', // @TODO: alternative lighter background color variant. Add to theme
  },
});
