import { useQuery } from '@apollo/client';
import { GET_PICKER } from '../graphql/PickerQuery';

const usePicker = (pickerId) => {
  const { loading, error, data } = useQuery(GET_PICKER, {
    variables: {
      pickerId,
    },
  });

  return { loading, error, data };
};

export default usePicker;
