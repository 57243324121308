import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F6F6F6',
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  label: {
    textAlign: 'center',
    textAlignVertical: 'center',
    fontFamily: 'Roboto bold',
    fontSize: 18,
    paddingHorizontal: 10,
  },
  button: {
    backgroundColor: '#ececec',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 8,
  },
  disabledButton: {
    backgroundColor: undefined,
  },
  incrementButton: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
    borderLeftWidth: 1,
    borderLeftColor: '#fff',
  },
  decrementButton: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    borderRightWidth: 1,
    borderRightColor: '#fff',
  },
});

export default styles;
