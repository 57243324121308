import React from 'react';
import { useNavigation } from '@react-navigation/native';

export function useBrowserNotifications() {
  const navigation = useNavigation();
  const [notifcationPermission, setNotifcationPermission] = React.useState(Notification.permission);

  /**
   *
   */
  React.useEffect(() => {
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'notifications' }).then((notificationPerm) => {
        // eslint-disable-next-line no-param-reassign
        notificationPerm.onchange = () => {
          setNotifcationPermission(notificationPerm.state);
        };
      });
    }
  }, []);

  /**
   *
   */
  const sendNotification = React.useCallback((title, body) => {
    if (notifcationPermission !== 'granted') {
      return;
    }
    const notify = new Notification(title, {
      body,
      icon: 'https://fastpicker.io/wp-content/uploads/2021/10/cropped-icon-1024-270x270.png',
      image: 'https://fastpicker.io/wp-content/uploads/2021/10/cropped-icon-1024-270x270.png',
      silent: true,
      timestamp: Date.now(),
    });
    notify.onclick = () => { navigation.navigate('PickOrder', { orderId: 670, wooId: 313 }); }; // @TODO: Implement click on new order notification
  }, [navigation, notifcationPermission]);

  /**
   *
   */
  const requestNotificationPermissions = React.useCallback(async () => {
    if (!Notification) {
      setNotifcationPermission('unsupported');
      return;
    }
    setNotifcationPermission('asking');
    const permission = await Notification.requestPermission();
    setNotifcationPermission(permission);
    if (permission === 'granted') {
      sendNotification('Hi there! 👋', 'You enabled browser notifications. We\'ll notify you when any important activity happens.'); // @TODO: fix missing translation
    }
  }, [sendNotification]);

  /**
   *
   */
  return {
    notifcationPermission,
    requestNotificationPermissions,
    sendNotification,
  };
}
