export const mutationError = (errors) => {
  const unknownErrorResponse = 'Something went wrong. Please try again.';
  let errorResponse = unknownErrorResponse;

  // Return generic error if graphQLErrors is empty
  if (!errors || !errors.graphQLErrors) return errorResponse;

  // Reset error response
  errorResponse = '';

  // Loop through graphQLErrors
  const graphQLErrors = Object.values(errors.graphQLErrors);
  graphQLErrors.forEach((error) => {
    switch (error.extensions.category) {
      case 'validation':
        const fields = error.extensions.validation || error.extensions.errors;
        for (const field in fields) {
          if (Array.isArray(fields[field])) {
            errorResponse += fields[field][0];
          } else {
            errorResponse += fields[field];
          }
        }
        break;
      case 'notifications':
        errorResponse = error.extensions.reason;
        break;
      case 'internal':
      default:
        errorResponse = 'Something unexpected went wrong. Please try again later or contact our support.';
        break;
    }
  });

  // If no gql errors found, check for failed to fetch
  if (!errorResponse && errors.message === 'Failed to fetch') errorResponse = 'Connection failed. Please check if you are connected to the internet.';

  // Check if error reason is found, otherwise return genaric message
  if (!errorResponse) errorResponse = unknownErrorResponse;

  // Remove input. tag from error response
  return errorResponse.replace(/input./g, '');
};

export const ErrorType = {
  Unknown: 'unknown',
  EmailNotVerified: 'Email not verified',
};

/**
 * Returns an array with all errors found in a gql error response
 */
export const getErrors = (errors) => {
  const errorCollection = errors.graphQLErrors.reduce((agg, error) => {
    if (error.message === ErrorType.EmailNotVerified) {
      agg.push(ErrorType.EmailNotVerified);
    }
    return agg;
  }, []);

  if (errorCollection.lenght === 0) {
    errorCollection.push(ErrorType.Unknown);
  }

  return errorCollection;
};
