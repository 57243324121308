import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  input: {
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
  },
});

export default styles;
