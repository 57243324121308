import React from 'react';
import PropTypes from 'prop-types';
import { FadeIn } from '../../../components/Animation';
import { ErrorNotification } from '../../../components/ErrorNotification';
import LoadMoreButton from '../../../components/LoadMoreButton';
import { useLocale } from '../../../services/localization';

function NothingToPick({ onRefreshPress }) {
  const $t = useLocale();

  return (
    <FadeIn>
      <ErrorNotification
        errorTitle={$t({ id: 'OrdersList.NoOrdersToPick.Title', defaultMessage: 'No orders to pick' })}
        errorMessage={$t({ id: 'OrdersList.NoOrdersToPick.Description', defaultMessage: 'We\'ll notify you when there is something to do.' })}
      />
      <LoadMoreButton label={$t({ id: 'OrdersList.CheckForOrdersButton.Label', defaultMessage: 'Check for orders' })} onPress={onRefreshPress} />
    </FadeIn>
  );
}

NothingToPick.propTypes = {
  onRefreshPress: PropTypes.func.isRequired,
};

export default NothingToPick;
