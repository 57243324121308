import React from 'react';
import PropTypes from 'prop-types';
import AsyncStorage from '@react-native-async-storage/async-storage';

const NotificationsStorageKey = '@notifications';

const InitialState = { dontAskToSubscribe: null };

const NotificationsContext = React.createContext();

function notificationsReducer(state, action) {
  switch (action.type) {
    case 'RESTORE':
      return {
        ...state,
        ...action.payload,
      };
    case 'DONT_ASK_AGAIN':
      return {
        ...state,
        dontAskToSubscribe: action.payload.dontAskToSubscribe,
      };
    default:
      return state;
  }
}

function useNotificationsContext() {
  return React.useContext(NotificationsContext);
}

function NotificationsProvider({ onReady, ...props }) {
  const [state, commit] = React.useReducer(notificationsReducer, InitialState);

  const dispatch = React.useCallback(async (values) => {
    const parsed = JSON.stringify({ ...state, ...values.payload });
    await AsyncStorage.setItem(NotificationsStorageKey, parsed);
    commit(values);
  }, [state]);

  const values = React.useMemo(() => ({
    ...state,
    dispatch,
  }), [dispatch, state]);

  React.useEffect(() => {
    async function restore() {
      try {
        const storedState = await AsyncStorage.getItem(NotificationsStorageKey);
        const parsedState = JSON.parse(storedState) || InitialState;
        commit({
          type: 'RESTORE',
          payload: parsedState,
        });
      } catch (e) {
        // something during restore went wrong...
      }
      onReady();
    }
    restore();
  }, [onReady]);

  return <NotificationsContext.Provider value={values} {...props} />;
}

NotificationsProvider.propTypes = {
  onReady: PropTypes.func.isRequired,
};

export { NotificationsContext, useNotificationsContext, NotificationsStorageKey };
export default NotificationsProvider;
