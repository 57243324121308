import { useEffect, useState } from 'react';

/**
 * Debounce helper function from https://usehooks-ts.com/react-hook/use-debounce
 *
 * @param {string|number} value changing value
 * @param {number} delay is seconds before new value is returned
 * @returns value
 */
function useDebounce(value, delayInMs) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delayInMs || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delayInMs, setDebouncedValue]);

  return debouncedValue;
}

export default useDebounce;
