import React, { useState } from 'react';
import { View } from 'react-native';
import { mutationError } from '../../services/Error';
import { A } from '../../components/Text';
import Screen from '../../components/Layout';
import { FadeIn } from '../../components/Animation';
import ScreenHeading from '../../components/ScreenHeading';
import { TextField, EmailField, PasswordField } from '../../components/Form';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import useCreatePicker from '../../hooks/useCreatePicker';
import { useToastNotification } from '../../services/toast';
import { useLocale } from '../../services/localization';
import styles from './AddPickerScreen.styles';

const initialFields = {
  name: '',
  email: '',
  password: '',
};

function AddPickerScreen({ navigation }) {
  const [fields, setFields] = useState(initialFields);
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState(false);
  const { toastSucces } = useToastNotification();
  const $t = useLocale();

  const handleInputChange = (e, fieldName) => {
    if (!hasChanges && fields[fieldName] !== e.target.value) setHasChanges(true);
    setFields({
      ...fields,
      [fieldName]: e.target.value,
    });
  };

  const { createPicker, loading } = useCreatePicker(
    fields,
    () => {
      navigation.navigate('Pickers');
      toastSucces($t({ id: 'AddPickerScreen.PickerAddedSucces.Text', defaultMessage: 'Picker added!' }));
    },
    (error) => setErrors(mutationError(error)),
  );

  return (
    <Screen size="medium">
      <FadeIn>
        <ScreenHeading title={$t({ id: 'AddPickerScreen.Page.Title', defaultMessage: 'Add a picker' })} />
        <TextField
          style={styles.input}
          label={$t({ id: 'AddPickerScreen.NameField.Label', defaultMessage: 'Picker name' })}
          placeholder={$t({ id: 'AddPickerScreen.NameField.Placeholder', defaultMessage: 'John Picker' })}
          value={fields.name}
          onChange={(e) => handleInputChange(e, 'name')}
        />
        <EmailField
          style={styles.input}
          label={$t({ id: 'AddPickerScreen.EmailField.Label', defaultMessage: 'Email address' })}
          placeholder={$t({ id: 'AddPickerScreen.EmailField.Placeholder', defaultMessage: 'name@youremail.com' })}
          value={fields.email}
          onChange={(e) => handleInputChange(e, 'email')}
        />
        <PasswordField
          style={styles.input}
          label={$t({ id: 'AddPickerScreen.PasswordField.Label', defaultMessage: 'Password' })}
          value={fields.password}
          onChange={(e) => handleInputChange(e, 'password')}
        />
        <View style={styles.actions}>
          <A to="/pickers">{$t({ id: 'AddPickerScreen.CancelButton.Label', defaultMessage: 'Cancel' })}</A>
          <Button
            label={$t({ id: 'AddPickerScreen.AddPickerButton.Label', defaultMessage: 'Add Picker' })}
            size="small"
            onPress={createPicker}
            loading={loading}
            disabled={!hasChanges || loading}
          />
        </View>
        {errors && <Alert text={errors} />}
      </FadeIn>
    </Screen>
  );
}

export default AddPickerScreen;
