import React from 'react';
import { Platform } from 'react-native';
import {
  ApolloProvider, InMemoryCache, ApolloClient, createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setContext } from '@apollo/client/link/context';
import Pusher from 'pusher-js/react-native';
import {
  GRAPHQL_API_URL,
  PUSHER_URL,
  PUSHER_AUTH_ENDPOINT,
  PUSHER_KEY,
  PUSHER_CLUSTER,
  PUSHER_HOST,
  PUSHER_WSS_PORT,
} from '@env';
import PusherLink from '../pusher';
import { useAuthContext } from '../auth';

function getPusherLink(token) {
  return new PusherLink({
    pusher: new Pusher('wooorders', {
      broadcaster: 'pusher',
      host: PUSHER_URL,
      authEndpoint: PUSHER_AUTH_ENDPOINT,
      key: PUSHER_KEY,
      cluster: PUSHER_CLUSTER,
      wsHost: PUSHER_HOST,
      wssPort: PUSHER_WSS_PORT,
      encrypted: true,
      auth: { headers: { Authorization: token ? `Bearer ${token}` : '' } },
      disableStats: true,
    }),
  });
}

function getHttpLink() {
  const httpLink = createHttpLink({
    uri: GRAPHQL_API_URL,
  });
  return httpLink;
}

function setAsyncAuthLink() {
  const asyncAuthLink = setContext(
    (_, { headers }) => new Promise((success) => {
      AsyncStorage.getItem('@new-auth').then((data) => {
        const localState = JSON.parse(data);
        success({
          headers: {
            ...headers,
            authorization: localState?.auth.token ? `Bearer ${localState?.auth.token}` : '',
          },
        });
      });
    }),
  );
  return asyncAuthLink;
}

function getOnErrorLink() {
  const errorLink = onError(
    ({
      graphQLErrors, networkError, operation, forward,
    }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ debugMessage }) => {
          if (debugMessage === 'Unauthenticated.') {
            AsyncStorage.removeItem('@new-auth');
            if (Platform.OS === 'web') window.location = location;
          }
        });
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);

      forward(operation);
    },
  );
  return errorLink;
}

const inMemoryCacheOptions = {
  typePolicies: {
    OrderShipping: {
      keyFields: ['plugin'],
    },
  },
};

function NewApolloProvider(props) {
  const state = useAuthContext();

  const links = React.useMemo(() => {
    const errorLink = getOnErrorLink();
    const pusherLink = state?.auth?.token ? getPusherLink(state?.auth?.token) : false;
    const authLink = setAsyncAuthLink(state?.auth?.token);
    const httpLink = getHttpLink();
    if (!pusherLink) {
      return errorLink.concat(authLink.concat(httpLink));
    }
    return errorLink.concat(pusherLink.concat(authLink.concat(httpLink)));
  }, [state?.auth?.token]);

  const client = React.useMemo(() => new ApolloClient({
    link: links,
    cache: new InMemoryCache(inMemoryCacheOptions),
  }), [links]);

  return <ApolloProvider client={client} {...props} />;
}

export default NewApolloProvider;
