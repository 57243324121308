import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import RadioGroup from './RadioGroup';

function SmartSelect({ swapAt, ...props }) {
  if (props.options.length <= swapAt) {
    return <RadioGroup {...props} compact />;
  }
  return (<Select {...props} />);
}

const SelectOption = PropTypes.shape({
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  label: PropTypes.string,
});

SmartSelect.propTypes = {
  /**
   * Key of component. Used for children
   */
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(SelectOption),
  // eslint-disable-next-line react/forbid-prop-types
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
  /**
   * Value on which the radio group changes to a select
   */
  swapAt: PropTypes.number,
};

SmartSelect.defaultProps = {
  placeholder: '',
  options: [],
  selectedValue: undefined,
  onChange: () => null,
  swapAt: 3,
};

export default SmartSelect;
