import { useMutation } from '@apollo/client';
import { VERIFY_EMAIL } from '../graphql/AuthQuery';
import { useAuthContext } from '../services/auth';

const useVerifyEmail = (token, onCompleted, onError) => {
  const { signIn } = useAuthContext();
  const [verifyEmail, { loading }] = useMutation(VERIFY_EMAIL, {
    variables: { token },
    update(_, { data: { verifyEmail: data } }) {
      const user = {
        email: data.user.email,
        id: data.user.id,
        name: data.user.name,
        role: data.user.role,
      };
      const auth = {
        token: data.access_token,
        expires_in: data.expires_in,
        refresh_token: data.refresh_token,
      };
      signIn(user, auth);
    },
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted() {
      if (typeof onCompleted === 'function') onCompleted();
    },
  });

  return { verifyEmail, loading };
};

export default useVerifyEmail;
