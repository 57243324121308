import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { FadeIn } from '../../components/Animation';
import { H1, H3 } from '../../components/Text';
import TodoList from './components/TodoList';
import { colors, shadow, links } from '../../constants';
import { issueState } from '../../constants/issues';
import { useAuthContext } from '../../services/auth';
import useHealthChecker from '../../hooks/useHealthChecker';
import { useLocale, FormattedMessage } from '../../services/localization';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 10,
    minHeight: 55,
    ...shadow.normal,
  },
  headingWrapper: {
    paddingVertical: 25,
    paddingHorizontal: 30,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    opacity: 0.1,
  },
  heading: {
    marginBottom: 5,
    color: colors.brand.primary,
  },
});

// @todo - This file and its logic is quite ugly tbh... improve in the future!

const STEP_1_IDLE = {
  id: 'stepOneIdle',
  step: 1,
  title: <FormattedMessage id="GettingStartedWizard.StepOne.Idle.Title" defaultMessage="Connect your WooCommerce shop" />,
  description: <FormattedMessage id="GettingStartedWizard.StepOne.Idle.Description" defaultMessage="Before you can start picking you need to add a shop." />,
  buttonLabel: <FormattedMessage id="GettingStartedWizard.StepOne.Idle.ButtonLabel" defaultMessage="Connect your shop" />,
  state: 'IN_PROGRESS',
};
const STEP_1_IN_PROGRESS = {
  id: 'stepOneInProgress',
  ...STEP_1_IDLE,
  buttonLabel: <FormattedMessage id="GettingStartedWizard.StepOne.InProgress.ButtonLabel" defaultMessage="Complete your setup" />,
  state: 'IN_PROGRESS',
};
const STEP_1_COMPLETED = {
  id: 'stepOneCompleted',
  ...STEP_1_IDLE,
  completedDescription: <FormattedMessage id="GettingStartedWizard.StepOne.Completed.Text" defaultMessage="Shop connected" />,
  state: 'COMPLETED',
};

const STEP_2_IDLE = {
  id: 'stepTwoIdle',
  step: 3,
  title: <FormattedMessage id="GettingStartedWizard.StepTwo.Idle.Title" defaultMessage="Update shop info" />,
  description: <FormattedMessage id="GettingStartedWizard.StepTwo.Idle.Description" defaultMessage="Validate your shop address and add a logo as this will be shown on your package slips." />,
  buttonLabel: <FormattedMessage id="GettingStartedWizard.StepTwo.Idle.ButtonLabel" defaultMessage="Update shop info" />,
  state: 'IDLE',
};
const STEP_2_IN_PROGRESS = {
  id: 'stepTwoInProgress',
  ...STEP_2_IDLE,
  completedDescription: 'Info complete',
  state: 'IN_PROGRESS',
};
// const STEP_2_COMPLETED = {
//   id: 'stepTwoCompleted',
//   ...STEP_2_IDLE,
//   completedDescription: <FormattedMessage id="GettingStartedWizard.StepTwo.Completed.Text" defaultMessage="Info updated" />,
//   state: 'COMPLETED',
// };

const STEP_3_IDLE = {
  id: 'stepThreeIdle',
  step: 2,
  title: <FormattedMessage id="GettingStartedWizard.StepThree.Idle.Title" defaultMessage="Install FastPicker Wordpress plugin" />,
  description: <FormattedMessage id="GettingStartedWizard.StepThree.Idle.Description" defaultMessage="This plugin is necessary if you would like declare your orders with one of our supported shipping providers." />,
  buttonLabel: <FormattedMessage id="GettingStartedWizard.StepThree.Idle.ButtonLabel" defaultMessage="Download plugin" />,
  state: 'IDLE',
};
const STEP_3_IN_PROGRESS = {
  id: 'stepThreeInProgress',
  ...STEP_3_IDLE,
  state: 'IN_PROGRESS',
};
const STEP_3_COMPLETED = {
  id: 'stepThreeCompleted',
  ...STEP_3_IDLE,
  completedDescription: <FormattedMessage id="GettingStartedWizard.StepThree.Completed.Text" defaultMessage="Plugin installed" />,
  state: 'COMPLETED',
};

const STEP_4_IDLE = {
  id: 'stepFourIdle',
  step: 4,
  title: <FormattedMessage id="GettingStartedWizard.stepFour.Idle.Title" defaultMessage="Accept our Data Processing Agreement" />,
  description: <FormattedMessage id="GettingStartedWizard.stepFour.Idle.Description" defaultMessage="Please check and agree with our Data Processing Agreement." />,
  buttonLabel: <FormattedMessage id="GettingStartedWizard.stepFour.Idle.ButtonLabel" defaultMessage="View Agreement" />,
  state: 'IDLE',
};
// const STEP_4_IN_PROGRESS = {
//   id: 'stepFourInProgress',
//   ...STEP_4_IDLE,
//   state: 'IN_PROGRESS',
// };

function GettingStartedWizard({ style = {} }) {
  const navigation = useNavigation();
  const { user } = useAuthContext();
  const [todos, setTodos] = useState([]);
  const firstName = user.name.split(' ')[0] || '';
  const { checking, hasIssues, issues } = useHealthChecker();
  const $t = useLocale();

  useEffect(() => {
    if (!hasIssues || !issues) return;

    const list = [];

    if (issues.general.has(issueState.NO_SHOPS)) {
      list.push({
        ...STEP_1_IDLE,
        onPress: () => navigation.navigate('AddShop'),
      });
      list.push(STEP_3_IDLE);
      list.push(STEP_2_IDLE);
      list.push(STEP_4_IDLE);
    } else {
      const shopId = Object.keys(issues.shops)[0] || false;
      const shopIssues = issues.shops[shopId] || [];
      const issueString = [...shopIssues].join();

      if (issueString === 'ADD_SHOP_INPROGRESS,MISSING_FASTPICKER_PLUGIN,INCOMPLETE_SHOP_INFO') {
        list.push({
          ...STEP_1_IN_PROGRESS,
          onPress: () => navigation.navigate('AddShop', { shopId }),
        });
        list.push(STEP_3_IDLE);
        list.push(STEP_2_IDLE);
        list.push(STEP_4_IDLE);
      } else if (issueString === 'ADD_SHOP_INPROGRESS,INCOMPLETE_SHOP_INFO') {
        list.push({
          ...STEP_1_IN_PROGRESS,
          onPress: () => navigation.navigate('AddShop', { shopId }),
        });
        list.push(STEP_3_IDLE);
        list.push(STEP_2_IDLE);
        list.push(STEP_4_IDLE);
      } else if (issueString === 'MISSING_FASTPICKER_PLUGIN,INCOMPLETE_SHOP_INFO') {
        list.push(STEP_1_COMPLETED);
        list.push({
          ...STEP_3_IN_PROGRESS,
          onPress: () => Linking.openURL(links.FastPickerPluginStore),
        });
        list.push(STEP_2_IDLE);
        list.push(STEP_4_IDLE);
      } else if (issueString === 'INCOMPLETE_SHOP_INFO') {
        list.push(STEP_1_COMPLETED);
        list.push(STEP_3_COMPLETED);
        list.push({
          ...STEP_2_IN_PROGRESS,
          onPress: () => navigation.navigate('EditShop', { shopId }),
        });
        // list.push(STEP_4_IDLE);
      }
      // @TODO: Disabled step four because its not required and we currently have no way of knowing if user has seen the agreement without accepting
      // else if (issues.general.has(issueState.ACCEPT_DATA_PROCESSING_AGREEMENT)) {
      //   list.push(STEP_1_COMPLETED);
      //   list.push(STEP_3_COMPLETED);
      //   list.push(STEP_2_COMPLETED);
      //   list.push({
      //     ...STEP_4_IN_PROGRESS,
      //     onPress: () => navigation.navigate('Settings', { tab: 'privacy' }),
      //   });
      // }
    }

    setTodos(list);
  }, [hasIssues, issues, navigation]);

  if (checking || !hasIssues) return null;

  return (
    <FadeIn style={style}>
      <View style={styles.container}>
        <View style={styles.headingWrapper}>
          <H1 style={styles.heading}>
            {$t({ id: 'GettingStartedWizard.Hello.Text', defaultMessage: '👋  Hi {name},' }, { name: firstName })}
          </H1>
          <H3>
            {$t({
              id: 'GettingStartedWizard.LetsGetStarted.Text',
              defaultMessage: 'Let\'s get your orderpicker ready for success!',
            })}
          </H3>
        </View>
        <TodoList data={todos} />
      </View>
    </FadeIn>
  );
}

export default GettingStartedWizard;
