import { useQuery } from '@apollo/client';
import { GET_ORDER } from '../graphql/OrderQuery';

const useOrder = (orderId, options) => {
  const { loading, error, data } = useQuery(GET_ORDER, {
    variables: {
      orderId,
    },
    ...options,
  });

  return { loading, error, data };
};

export default useOrder;
