import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { FadeIn } from '../../../components/Animation';
import { P } from '../../../components/Text';
import LoadMoreButton from '../../../components/LoadMoreButton';
import { useLocale } from '../../../services/localization';
import styles from './NothingCompleted.styles';

function NothingToPick({ onRefreshPress }) {
  const $t = useLocale();
  return (
    <FadeIn>
      <View style={styles.container}>
        <P style={styles.text}>{$t({ id: 'NothingToPick.Explainer', defaultMessage: 'There are no orders picked and completed today.' })}</P>
        <LoadMoreButton label={$t({ id: 'NothingToPick.RefreshButton.Label', defaultMessage: 'Refresh' })} onPress={onRefreshPress} />
      </View>
    </FadeIn>
  );
}

NothingToPick.propTypes = {
  onRefreshPress: PropTypes.func.isRequired,
};

export default NothingToPick;
