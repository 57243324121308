import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { SmartSelect } from '../../../../components/Fields';

const GET_SENDCLOUD_ADDRESSES = gql`
query SendcloudGetSenderAddresses($shopId: ID! ) {
  sendcloudGetSenderAddresses(shopId: $shopId) {
    id
    contact_name
    email
    street
    house_number
    postal_code
    city
    country
    company_name
  }
}
`;

function formatAddress(address) {
  return `${address.company_name}, ${address.street} ${address.house_number}, ${address.city}`;
}

function SendcloudSenderAddress({ shopId, onChange, selectedValue }) {
  const { data } = useQuery(GET_SENDCLOUD_ADDRESSES, {
    variables: { shopId },
    onCompleted: (newData) => onChange(newData.sendcloudGetSenderAddresses[0].id, newData), // @TODO: Use first element or previous selected item
  });

  const options = useMemo(() => {
    if (!data || data.sendcloudGetSenderAddresses.length === 0) {
      return [];
    }
    return data.sendcloudGetSenderAddresses.map((address) => ({ value: address.id, label: formatAddress(address) }));
  }, [data]);

  const handleOnChange = (newValue) => {
    const selected = data.sendcloudGetSenderAddresses.find(({ id }) => id === newValue);
    onChange(newValue, selected); // second param is used to also communicate original/full address object to parent
  };

  return <SmartSelect name="sendcloudSender" selectedValue={selectedValue} options={options} onChange={handleOnChange} />;
}

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

SendcloudSenderAddress.propTypes = {
  shopId: IdType.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedValue: PropTypes.any,
  onChange: PropTypes.func,
};

SendcloudSenderAddress.defaultProps = {
  selectedValue: undefined,
  onChange: () => null,
};

export default SendcloudSenderAddress;
