import { useQuery, gql } from '@apollo/client';

export const GET_SHIPPING_LABEL = gql`
  query GetShippingLabel($orderId: ID!) {
    order(id: $orderId) {
      id
      shipping_label
      shipping_label_status
      shipping_provider
    }
  }
`;

const useShippingLabel = (orderId) => {
  const { loading, error, data } = useQuery(
    GET_SHIPPING_LABEL,
    {
      variables: {
        orderId,
      },
    },
  );

  return { loading, error, data };
};

export default useShippingLabel;
