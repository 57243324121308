import { useQuery, gql } from '@apollo/client';

export const GET_SHOP_STATUS = gql`
  query ($shopId: ID!) {
    shop(id: $shopId) {
      id
      status
    }
  }
`;

const useShopStatus = (shopId) => {
  const { loading, error, data } = useQuery(GET_SHOP_STATUS, {
    variables: {
      shopId,
    },
  });

  return { loading, error, data };
};

export default useShopStatus;
