import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import {
  A, P, Strong, Small,
} from '../../../components/Text';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import LoadingIndicator from '../../../components/LoadingIndicator';
import styles from './ActivityList.styles';
import useGetActivityLogs from './useGetActivityLogs';
import { useLocale, useIntl } from '../../../services/localization';
import { colors } from '../../../constants';

const logItemsPerPage = 15;

const Icons = {
  COMPLETED_ORDER: 'checkmark-circle-outline',
  COMPLETE_ORDER: 'checkmark',
  SYNC_FASTPICKER_WORDPRESS_PLUGIN: 'code-working-outline',
  SYNC_FASTPICKER_WORDPRESS_PLUGIN_BARCODE_META: 'code-working-outline',
  SYNC_WOOCOMMERCE_SHIPPING_SETTINGS: 'code-working-outline',
  FETCHING_ORDERS: 'list-outline',
  REGISTER_SHIPPING: 'boat-outline',
  CREATE_ORDER_NOTE: 'cube-outline',
};

function ActivityList({ shopId }) {
  const logs = useGetActivityLogs(shopId, logItemsPerPage);
  const [openLogs, setOpenLogs] = React.useState([]);
  const $t = useLocale();
  const intl = useIntl();

  const translations = React.useMemo(() => ({
    SYNC_WOOCOMMERCE_SHIPPING_SETTINGS: $t({ id: 'ActivityList.Log.SyncWooCommerceShippingSettings', defaultMessage: 'Syncing Woo shipping details' }),
    SYNC_FASTPICKER_WORDPRESS_PLUGIN: $t({ id: 'ActivityList.Log.SyncFastPickerWordpressPlugin', defaultMessage: 'Syncing FastPicker plugin details' }),
    SYNC_FASTPICKER_WORDPRESS_PLUGIN_BARCODE_META: $t({ id: 'ActivityList.Log.SyncFastPickerWordpressPluginBarcodeMeta', defaultMessage: 'Syncing FastPicker barcode meta data' }),
    COMPLETE_ORDER: $t({ id: 'ActivityList.Log.CompleteOrder', defaultMessage: 'Completing order' }),
    COMPLETED_ORDER: $t({ id: 'ActivityList.Log.CompletedOrder', defaultMessage: 'Completed order and customer notified' }),
    TESTING_SHOP: $t({ id: 'ActivityList.Log.TestingShop', defaultMessage: 'Testing shop connection' }),
    FETCHING_ORDERS: $t({ id: 'ActivityList.Log.FetchingShopOrders', defaultMessage: 'Fetching orders' }),
    REGISTER_SHIPPING: $t({ id: 'ActivityList.Log.RegisterShipping', defaultMessage: 'Declaring order for shipping' }),
    CREATE_ORDER_NOTE: $t({ id: 'ActivityList.Log.CreateOrderNote', defaultMessage: 'Order available for picking' }),
    showMoreLabel: $t({ id: 'ActivityList.ShowMoreButton.Label', defaultMessage: 'Show more' }),
    noMoreLogs: $t({ id: 'ActivityList.NoMoreLogs.Text', defaultMessage: 'No more logs available' }),
    viewOrder: $t({ id: 'ActivityList.ViewOrder.Link', defaultMessage: 'View order' }),
  }), [$t]);

  function formatDate(timestamp) {
    const date = intl.formatDate(timestamp, { day: 'numeric', month: 'short' });
    const time = intl.formatTime(timestamp);
    return `${date} · ${time}`;
  }

  function renderLink(item) {
    if (item.order_id !== null) {
      const url = `/pick/order/${item.order_id}/0/view`;
      return <A to={url}>{translations.viewOrder}</A>;
    }
    return null;
  }

  function renderIcon(message) {
    const iconName = Icons[message];
    if (!iconName) {
      return null;
    }
    return <Icon size={18} name={iconName} color={colors.brand.primary} />;
  }

  function renderExtendedLog(item) {
    return (
      <Small style={styles.logDetailsText}>
        {`${item.message} · shop: #${item.shop_id} · order: #${item.order_id}`}
      </Small>
    );
  }

  function toggleExtendedLog(itemId) {
    const isOpen = openLogs.some((id) => id === itemId);
    if (!isOpen) {
      setOpenLogs([...openLogs, itemId]);
    } else {
      const updatedLogs = openLogs.filter((id) => id !== itemId);
      setOpenLogs(updatedLogs);
    }
  }

  function renderList() {
    if (logs.loading) {
      return <LoadingIndicator />;
    }
    return logs.items.map((item) => {
      const isExtended = openLogs.some((id) => id === item.id);
      return (
        <View style={styles.row} key={`log-item-${item.id}`}>
          <View style={styles.firstCell}>
            <Small>{formatDate(item.created_at)}</Small>
          </View>
          <View style={styles.messageIcon}>
            {renderIcon(item.message)}
          </View>
          <View style={[styles.cell, styles.message]}>
            <TouchableOpacity onPress={() => toggleExtendedLog(item.id)} activeOpacity={0.8}>
              <P>{translations[item.message] || item.message}</P>
            </TouchableOpacity>
            {isExtended && renderExtendedLog(item)}
          </View>
          <View style={styles.lastCell}>
            {renderLink(item)}
          </View>
        </View>
      );
    });
  }

  function renderButton() {
    if (logs.loading || logs.items?.length === 0 || logs.error) {
      return null;
    }
    if (logs.items?.length < logs.pagination?.total) {
      return (
        <Button label={translations.showMoreLabel} loading={fetch.isFetchingMore} onPress={logs.fetchMore} />
      );
    }
    return <Strong style={styles.noMoreLogs}>{translations.noMoreLogs}</Strong>;
  }

  return (
    <View style={styles.container}>
      {renderList()}
      <View style={styles.actions}>
        {renderButton()}
      </View>
    </View>
  );
}

ActivityList.propTypes = {
  shopId: PropTypes.string.isRequired,
};

export default ActivityList;
