import React from 'react';
import PropTypes from 'prop-types';
import {
  ActivityIndicator, TouchableOpacity, StyleSheet, View,
} from 'react-native';
import { P } from './Text';
import { colors } from '../constants';
import Icon from './Icon';

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background.primary,
    height: 100,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    borderRadius: 10,
    padding: 20,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinnerWrapper: {
    width: 30,
    height: 50,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelWrapper: {
    flexShrink: 1,
  },
  label: {
    fontFamily: 'Roboto bold',
    lineHeight: 20,
    fontSize: 16,
    flexShrink: 1,
  },
  subLabel: {
    fontFamily: 'Roboto',
    color: '#717171', // @TODO: add color to theme
    fontSize: 14,
    paddingTop: 2,
  },
  active: {
    color: colors.brand.primary,
  },
  focus: {
    backgroundColor: colors.brand.primary,
    color: '#fff',
  },
  highlight: {
    color: colors.brand.primary,
  },
  disabled: {
    backgroundColor: `${colors.background.primary}bf`,
    shadowOpacity: 0,
  },
  icon: {
    borderRadius: 7,
  },
  leftIcon: {
    marginRight: 10,
  },
  rightIcon: {
    marginLeft: 10,
  },
});

function Spinner({ color }) {
  return (
    <View style={styles.spinnerWrapper}>
      <ActivityIndicator color={color} size={30} />
    </View>
  );
}

Spinner.propTypes = {
  color: PropTypes.string,
};

Spinner.defaultProps = {
  color: '#000',
};

function LeftIcon({ name, color, size }) {
  return (
    <Icon
      style={[styles.icon, styles.leftIcon]}
      name={name}
      size={size}
      color={color}
    />
  );
}

LeftIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

LeftIcon.defaultProps = {
  color: '#000',
  size: 50,
};

function RightIcon({ name, color, size }) {
  return (
    <Icon
      style={[styles.icon, styles.rightIcon]}
      name={name}
      size={size}
      color={color}
    />
  );
}

RightIcon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

RightIcon.defaultProps = {
  color: '#000',
  size: 50,
};

function QuickActionButton({
  label,
  subLabel,
  focus,
  disabled,
  loading,
  highlight,
  leftIcon,
  leftIconSize,
  leftIconColor,
  rightIcon,
  style = {},
  ...props
}) {
  const containerStyling = [
    styles.container,
    style,
    focus && styles.focus,
    disabled && styles.disabled,
  ];

  const labelStyling = [
    styles.label,
    focus && styles.focus,
    (highlight && !focus) && styles.highlight,
  ];

  const subLabelStyling = [
    styles.subLabel,
    focus && styles.focus,
    (highlight && !focus) && styles.highlight,
  ];

  const color = focus ? '#fff' : leftIconColor;
  const spinnerColor = focus ? '#fff' : colors.brand.primary;

  return (
    <TouchableOpacity
      activeOpacity={focus ? 0.8 : 0.5}
      style={containerStyling}
      disabled={disabled}
      {...props}
    >
      {loading && <Spinner color={spinnerColor} />}
      {!!leftIcon && <LeftIcon name={leftIcon} color={color} size={leftIconSize} />}
      <View style={styles.labelWrapper}>
        <P style={labelStyling}>{label}</P>
        <P style={[labelStyling, subLabelStyling]}>{subLabel}</P>
      </View>
      {!!rightIcon && !loading && <RightIcon name={rightIcon} color={color} />}
    </TouchableOpacity>
  );
}

QuickActionButton.propTypes = {
  label: PropTypes.string,
  subLabel: PropTypes.string,
  focus: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  highlight: PropTypes.bool,
  leftIcon: PropTypes.string,
  leftIconSize: PropTypes.number,
  leftIconColor: PropTypes.string,
  rightIcon: PropTypes.string,
};

QuickActionButton.defaultProps = {
  label: '',
  subLabel: '',
  focus: false,
  disabled: false,
  loading: false,
  highlight: false,
  leftIcon: '',
  leftIconSize: undefined,
  leftIconColor: '#000',
  rightIcon: '',
};

export default QuickActionButton;
