import { StyleSheet } from 'react-native';
import { colors } from '../../../constants';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 15,
  },
  text: {
    color: colors.ui.attention,
    marginLeft: 7,
  },
});

export default styles;
