import React from 'react';
import { View } from 'react-native';
import { FadeIn } from '../../components/Animation';
import UpdatePasswordForm from './UpdatePasswordForm';
import Logo from '../../components/Logo';
import styles from './UpdatePasswordScreen.styles';

function UpdatePasswordScreen({ route }) {
  const { token } = route.params;
  return (
    <View style={styles.container}>
      <FadeIn style={styles.content}>
        <View style={styles.logoContainer}>
          <Logo />
        </View>
        <View style={styles.formContainer}>
          <UpdatePasswordForm token={token} />
        </View>
      </FadeIn>
    </View>
  );
}

export default UpdatePasswordScreen;
