import { RequiredShippingFields } from '../constants';

export const validateEmail = (emailString) => {
  if (!emailString || emailString.length < 7) return false;
  // eslint-disable-next-line max-len
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(emailString);
};

export const validatePassword = (passwordString) => {
  if (!passwordString || passwordString.length < 8) return false;
  return true;
};

export const isEmpty = (value) => value === undefined || value === null || value === '';

export const ValidationRule = {
  minThreeChar: 'minThreeChar',
  notEmpty: 'notEmpty',
  notShort: 'notShort',
  hasLetter: 'hasLetter',
  hasCapital: 'hasCapital',
  hasSpecialChar: 'hasSpecialChar',
  hasDigit: 'hasDigit',
  validEmail: 'validEmail',
  validName: 'validName',
  noSpecialChar: 'noSpecialChar',
};

export const ValidationRegex = {
  [ValidationRule.minThreeChar]: /^.{3,}$/,
  [ValidationRule.notEmpty]: /(.|\s)*\S(.|\s)*/,
  [ValidationRule.hasLetter]: /^.*(?=.*[a-z]).*$/,
  [ValidationRule.hasCapital]: /^.*(?=.*[A-Z]).*$/,
  [ValidationRule.hasSpecialChar]: /^.*(?=.*[!#$@%&? "]).*$/,
  [ValidationRule.hasDigit]: /^.*(?=.*\d).*$/,
  // eslint-disable-next-line max-len
  [ValidationRule.validEmail]: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  [ValidationRule.validString]: /^([a-zA-Z]+\s)*[a-zA-Z]+$/,
  [ValidationRule.noSpecialChar]: /^[a-zA-Z0-9]+$/,
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

/**
 * Function to check if shipping field form has any missing required fields
 *
 * Note: Does not validate the field on contents. Only check if its set or not
 *
 * @param {ShippingPlugin} shippingPlugin shipping plugin name as defined by api
 * @param {object} formValues object with key:value pair for each form field. Key = ShippingFields and value is string, object or number
 * @returns {boolean} true or false based on if any of the required fields are NULL|undefined
 */
export function hasMissingFields(shippingPlugin, formValues) {
  const requiredShippingFields = RequiredShippingFields[shippingPlugin];
  if (!requiredShippingFields) {
    return false;
  }
  const emptyFields = Object.entries(formValues).reduce((agg, [key, value]) => {
    if (requiredShippingFields.includes(key) && (value === null || value === undefined)) {
      agg.push(key);
    }
    return agg;
  }, []);
  return emptyFields.length > 0;
}
