import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import { P } from '../Text';
import Icon from '../Icon';
import styles from './TabButton.styles';
import { colors } from '../../constants/theme';

function TabButton({
  label, iconName, isActive, onPress,
}) {
  const icon = isActive ? iconName : `${iconName}-outline`;
  return (
    <TouchableOpacity style={[styles.button, isActive && styles.activeButton]} onPress={onPress}>
      <Icon size={24} color={colors.text.body} name={icon} style={styles.icon} />
      <View>
        <P style={[styles.label, isActive && styles.activeLabel]}>{label}</P>
      </View>
    </TouchableOpacity>
  );
}

TabButton.propTypes = {
  label: PropTypes.string,
  iconName: PropTypes.string,
  isActive: PropTypes.bool,
  onPress: PropTypes.func,
};

TabButton.defaultProps = {
  label: '',
  iconName: '',
  isActive: false,
  onPress: () => null,
};

export default TabButton;
