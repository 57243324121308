import React from 'react';
import { StackActions } from '@react-navigation/native';
import Screen from '../../components/Layout';
import { FadeIn } from '../../components/Animation';
import ScreenHeading from '../../components/ScreenHeading';
import ShopsList from './ShopsList/ShopsList';
import { useLocale } from '../../services/localization';

function ShopsScreen({ navigation }) {
  const $t = useLocale();
  return (
    <Screen backgroundImage>
      <FadeIn>
        <ScreenHeading
          title={$t({ id: 'ShopsScreen.Heading', defaultMessage: 'Shops' })}
          buttonLabel={$t({ id: 'ShopsScreen.AddShopButton.Label', defaultMessage: '+ Add a shop' })}
          buttonAction={() => navigation.dispatch(
            StackActions.replace('AddShop'),
          )}
        />
        <ShopsList />
      </FadeIn>
    </Screen>
  );
}

export default ShopsScreen;
