import { useMutation } from '@apollo/client';
import { TEST_SHOP } from '../graphql/ShopQuery';
import { GET_ORDERS } from '../graphql/OrderQuery';

const useTestShop = (shopId, onCompleted, onError) => {
  const [runMutation, { loading }] = useMutation(TEST_SHOP, {
    variables: { shopId },
    optimisticResponse: {
      testShop: {
        id: shopId,
        status: 'waiting_for_test',
        __typename: 'Shop',
      },
    },
    refetchQueries: [{ query: GET_ORDERS }],
    onCompleted() {
      onCompleted();
    },
    onError(error) {
      onError(error);
    },
  });

  const testShop = () => {
    if (loading) return;
    runMutation();
  };

  return { testShop };
};

export default useTestShop;
