import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, TouchableOpacity, StyleSheet } from 'react-native';
import { P } from './Text';
import { colors } from '../constants';

const styles = StyleSheet.create({
  button: {
    borderRadius: 7,
    backgroundColor: colors.brand.primary,
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    paddingHorizontal: 15,
    alignSelf: 'center',
    flexDirection: 'row',
  },
  secondaryButton: {
    backgroundColor: undefined,
    paddingHorizontal: 0,
  },
  loadingPrimary: {
    backgroundColor: '#6B81C1',
  },
  disabled: {
    opacity: 0.5,
    backgroundColor: '#ACACAC',
  },
  disabledSecondary: {
    backgroundColor: 'transparent',
  },
  label: {
    textTransform: 'uppercase',
    color: '#ffffff',
    alignSelf: 'center',
    fontFamily: 'Roboto bold',
  },
  loadingLabel: {
    paddingLeft: 10,
  },
  secondaryLabel: {
    color: colors.brand.primary,
  },
  large: {
    height: 48,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    alignSelf: 'auto',
    shadowRadius: 10,
  },
  largeLabel: {
    fontSize: 16,
  },
});

const ButtonType = {
  Primary: 'primary',
  Secondary: 'secondary',
};

const ButtonSize = {
  Small: 'small',
  Large: 'large',
};

const iconColor = {
  [ButtonType.Primary]: '#ffffff',
  [ButtonType.Secondary]: colors.brand.primary,
};

function Button({
  label,
  loadingLabel,
  type,
  size,
  disabled,
  loading,
  leftIcon,
  rightIcon,
  onPress,
  style,
  ...props
}) {
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={StyleSheet.flatten([
        styles.button,
        type === ButtonType.Secondary && styles.secondaryButton,
        size === ButtonSize.Large && styles.large,
        disabled && styles.disabled,
        (disabled && type === ButtonType.Secondary) && styles.disabledSecondary,
        (loading && type === ButtonType.Primary) && styles.loadingPrimary,
        style,
      ])}
      onPress={onPress}
      disabled={loading || disabled}
      {...props}
    >
      {loading && <ActivityIndicator color={iconColor[type]} />}
      {!loading && leftIcon}
      <P
        style={StyleSheet.flatten([
          styles.label,
          (loading && loadingLabel) && styles.loadingLabel,
          type === ButtonType.Secondary && styles.secondaryLabel,
          size === ButtonSize.Large && styles.largeLabel,
        ])}
      >
        {loading ? loadingLabel : label}
      </P>
      {!loading && rightIcon}
    </TouchableOpacity>
  );
}

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  loadingLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(Object.values(ButtonType)),
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  onPress: PropTypes.func,
};

Button.defaultProps = {
  label: '',
  loadingLabel: '',
  type: ButtonType.Primary,
  size: ButtonSize.Small,
  disabled: false,
  loading: false,
  leftIcon: undefined,
  rightIcon: undefined,
  onPress: () => null,
};

export default Button;
