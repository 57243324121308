import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Strong } from '../Text';
import {
  useIsShopInfoComplete, useIsActiveShop, useHasEnabledShippingProviders, useHasEnabledWordpressPlugin,
} from './hooks';
import styles from './ShopSubMenu.styles';
import MenuItem from './MenuItem';
import { useLocale } from '../../services/localization';

const shopSpinnerStatuses = [
  'testing',
  'fetching_order_statuses',
  'ready_to_test',
];

function ShopSubMenu({ style }) {
  const navigation = useNavigation();
  const route = useRoute();
  const $t = useLocale();

  const { incompleteField: isShopInfoComplete, shopStatus } = useIsShopInfoComplete(route.params.shopId);
  const isActiveShop = useIsActiveShop(route.params.shopId);
  const hasEnabledShippingProviders = useHasEnabledShippingProviders(route.params.shopId);
  const hasEnabledWordpressPlugin = useHasEnabledWordpressPlugin(route.params.shopId);

  const translations = useMemo(() => ({
    validatingConnection: $t({ id: 'ShopSubMenu.ValidatingConnection.SubLabel', defaultMessage: 'Validating connection...' }),
    checkCredentials: $t({ id: 'ShopSubMenu.CheckCredentials.SubLabel', defaultMessage: 'Check credentials' }),
    connected: $t({ id: 'ShopSubMenu.Connected.SubLabel', defaultMessage: 'Connected' }),
    incompleteInfo: $t({ id: 'ShopSubMenu.IncompleteInfo.SubLabel', defaultMessage: 'Incomplete info' }),
    enabled: $t({ id: 'ShopSubMenu.Enabled.SubLabel', defaultMessage: 'Enabled' }),
    notSetup: $t({ id: 'ShopSubMenu.NotSetup.SubLabel', defaultMessage: 'Not setup' }),
    installed: $t({ id: 'ShopSubMenu.Installed.SubLabel', defaultMessage: 'Installed' }),
    notInstalled: $t({ id: 'ShopSubMenu.NotInstalled.SubLabel', defaultMessage: 'Not installed' }),
    shop: $t({ id: 'ShopSubMenu.Shop.Label', defaultMessage: 'Shop' }),
    woocommerce: $t({ id: 'ShopSubMenu.WooCommerce.Label', defaultMessage: 'WooCommerce' }),
    shippingProviders: $t({ id: 'ShopSubMenu.ShippingProviders.Label', defaultMessage: 'Shipping Providers' }),
    plugin: $t({ id: 'ShopSubMenu.Plugin.Label', defaultMessage: 'Plugin' }),
    activityLog: $t({ id: 'ShopSubMenu.ActivityLog.Label', defaultMessage: 'Activity Log' }),
  }), [$t]);

  const go = useCallback((routeName) => {
    navigation.navigate(routeName, { shopId: route.params.shopId });
  }, [navigation, route.params.shopId]);

  const isActive = (routeName) => routeName === route.name;

  const renderShopStatusLabel = useCallback(() => {
    if (shopSpinnerStatuses.includes(shopStatus)) {
      return <Strong style={styles.subLabel}>{translations.validatingConnection}</Strong>;
    }
    if (!isActiveShop) {
      return <Strong style={[styles.subLabel, styles.alertSubLabel]}>{translations.checkCredentials}</Strong>;
    }
    return <Strong style={[styles.subLabel, styles.activeSubLabel]}>{translations.connected}</Strong>;
  }, [isActiveShop, shopStatus, translations.checkCredentials, translations.connected, translations.validatingConnection]);

  const items = useMemo(() => [
    {
      label: translations.shop,
      icon: 'cart-outline',
      activeIcon: 'cart',
      onPress: () => go('EditShop'),
      active: route.name === 'EditShop',
      subLabel: !isShopInfoComplete ? <Strong style={styles.subLabel}>{translations.incompleteInfo}</Strong> : null,
    },
    {
      label: translations.woocommerce,
      icon: 'swap-horizontal-outline',
      activeIcon: 'swap-horizontal',
      onPress: () => go('EditWoocommerce'),
      active: route.name === 'EditWoocommerce',
      subLabel: renderShopStatusLabel(),
    },
    {
      label: translations.shippingProviders,
      icon: 'boat-outline',
      activeIcon: 'boat',
      onPress: () => go('EditShippingProviders'),
      active: route.name === 'EditShippingProviders',
      subLabel: hasEnabledShippingProviders
        ? <Strong style={[styles.subLabel, styles.activeSubLabel]}>{translations.enabled}</Strong>
        : <Strong style={styles.subLabel}>{translations.notSetup}</Strong>,
    },
    {
      label: translations.plugin,
      icon: 'code-slash-outline',
      activeIcon: 'code-slash',
      onPress: () => go('EditPlugin'),
      active: route.name === 'EditPlugin',
      subLabel: hasEnabledWordpressPlugin
        ? <Strong style={[styles.subLabel, styles.activeSubLabel]}>{translations.installed}</Strong>
        : <Strong style={styles.subLabel}>{translations.notInstalled}</Strong>,
    },
  ], [go, hasEnabledShippingProviders, hasEnabledWordpressPlugin, isShopInfoComplete, renderShopStatusLabel, route.name, translations]);
  return (
    <View style={style}>
      {items.map((item) => <MenuItem key={`submenu-item-${item.label}`} {...item} />)}
      <View style={styles.spacer} />
      <MenuItem label={translations.activityLog} activeIcon="list" icon="list-outline" onPress={() => go('ShopActivity')} active={isActive('ShopActivity')} />
    </View>
  );
}

export default ShopSubMenu;
