import { gql, useMutation } from '@apollo/client';
import { useAuthContext } from '../../../services/auth';

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
      access_token
      refresh_token
      expires_in
      user {
        id
        email
        name
        role
        language
      }
    }
  }
`;

const useLogin = (loginFields, onCompleted, onError) => {
  const { signIn } = useAuthContext();
  const [runMutation, { loading }] = useMutation(LOGIN, {
    variables: loginFields,
    update(_, { data: { login: data } }) {
      const user = {
        email: data.user.email,
        id: data.user.id,
        name: data.user.name,
        role: data.user.role,
        language: data.user.language,
      };
      const auth = {
        token: data.access_token,
        expires_in: data.expires_in,
        refresh_token: data.refresh_token,
      };
      signIn(user, auth);
    },
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted() {
      if (typeof onCompleted === 'function') onCompleted();
    },
  });

  const login = () => {
    if (loading) return;
    runMutation();
  };

  return { login, loading };
};

export default useLogin;
