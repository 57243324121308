import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    marginRight: 5,
    height: 40,
    borderRadius: 10,
    backgroundColor: '#f9f9f9',
  },
  noMargin: {
    marginRight: 0,
  },
  label: {
    position: 'absolute',
    color: '#3258C5',
    textDecorationLine: 'underline',
    textAlign: 'center',
    width: '100%',
  },
});

export default styles;
