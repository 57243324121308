import { useQuery } from '@apollo/client';
import { GET_ORDERS } from '../graphql/OrderQuery';

const useOrders = (variables) => {
  const {
    loading, error, data, refetch, subscribeToMore, fetchMore,
  } = useQuery(GET_ORDERS, { variables });

  return {
    loading, error, data, refetch, subscribeToMore, fetchMore,
  };
};

export default useOrders;
