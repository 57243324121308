import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { mutationError } from '../../services/Error';
import { validateEmail } from '../../services/Validation';
import { FadeIn } from '../Animation';
import {
  H1, P, A, Strong,
} from '../Text';
import { EmailField } from '../Form';
import Button from '../Button';
import Alert from '../Alert';
import Icon from '../Icon';
import { colors } from '../../constants/theme';
import useResendVerifyEmail from '../../hooks/auth/useResendVerifyEmail';
import { useLocale } from '../../services/localization';

const styles = StyleSheet.create({
  checkmark: {
    marginRight: 10,
    alignSelf: 'center',
  },
  heading: {
    marginBottom: 30,
  },
  explanationText: {
    paddingBottom: 30,
  },
  input: {
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
  },
  link: {
    textDecorationLine: 'underline',
    color: colors.brand.primary,
  },
  registerText: {
    textAlign: 'center',
    marginTop: 30,
  },
});

function Bold(str) {
  return <Strong>{str}</Strong>;
}

function ResendVerifyEmailForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const $t = useLocale();

  const resetForm = () => {
    setEmail('');
    setCompleted(false);
  };

  const { resendVerifyEmail, loading } = useResendVerifyEmail(
    email,
    () => {
      setEmail('');
      setCompleted(true);
    },
    (e) => setError(mutationError(e)),
  );

  const submit = () => {
    if (loading) return;
    if (!validateEmail(email)) {
      setError($t({ id: 'Form.Error.InvalidEmail', defaultMessage: 'Please enter a valid email address.' }));
      return;
    }
    resendVerifyEmail();
  };

  if (completed) {
    return (
      <FadeIn>
        <H1 style={styles.heading}>
          <Icon
            name="mail"
            color={colors.ui.ok}
            size={25}
            style={styles.checkmark}
          />
          {$t({ id: 'ResendVerifyEmailForm.VerifyEmailSend.Title', defaultMessage: 'Verify email resend' })}
        </H1>
        <P style={styles.explanationText}>
          {$t({
            id: 'ResendVerifyEmailForm.EmailHasBeenSend.Text',
            defaultMessage: 'An email has been send to <strong>{email}</strong> with a link to verify your account. If you have not received the email after a few minutes, please check your spam folder.',
          }, { email, strong: Bold })}
        </P>
        <P style={styles.explanationText}>
          {$t({
            id: 'ResendVerifyEmailForm.StillNotReceived.Text',
            defaultMessage: 'Still not received the verify email? Please <link>retry</link> or contact our support.',
          }, { link: (str) => <P style={styles.link} onPress={resetForm}>{str}</P> })}
        </P>
      </FadeIn>
    );
  }

  return (
    <>
      <H1 style={styles.heading}>{$t({ id: 'ResendVerifyEmailForm.Heading', defaultMessage: 'Resend verify email' })}</H1>
      <P style={styles.explanationText}>
        {$t({ id: 'ResendVerifyEmailForm.EnterEmailInstruction.Description', defaultMessage: 'Enter the email you used during registration to receive the verify email instructions.' })}
      </P>
      <EmailField
        style={styles.input}
        label={$t({ id: 'ResendVerifyEmailForm.EmailField.Label', defaultMessage: 'Enter your email address' })}
        placeholder={$t({ id: 'ResendVerifyEmailForm.EmailField.Placeholder', defaultMessage: 'your@email.com' })}
        value={email}
        onChangeText={setEmail}
        onFocus={() => setError(false)}
        autoFocus
        returnKeyType="next"
        onSubmitEditing={submit}
      />
      <Button
        style={styles.button}
        onPress={submit}
        loading={loading}
        size="large"
        label={$t({ id: 'ResendVerifyEmailForm.ResendVerifyEmailButton.Label', defaultMessage: 'Resend verify email' })}
      />
      {error && (
        <FadeIn>
          <Alert text={error} />
        </FadeIn>
      )}
      <P style={styles.registerText}>
        {$t({
          id: 'ResendVerifyEmailForm.AlreadyHaveAccount.Text',
          defaultMessage: 'Already have an account? <link>Login here</link>',
        }, { link: (str) => <A style={styles.link} to="/login">{str}</A> })}
      </P>
    </>
  );
}

export default ResendVerifyEmailForm;
