import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { P } from '../../components/Text';
import { colors } from '../../constants/theme';

const styles = StyleSheet.create({
  container: {
    minWidth: 100,
    backgroundColor: '#dbf8e8',
    borderColor: '#bdf2d4',
    borderWidth: 1,
    padding: 10,
    borderRadius: 5,
    paddingHorizontal: 20,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  text1: {
    color: '#149e4e',
    fontSize: 16,
  },
  info: {
    color: '#616e94',
    backgroundColor: '#e6effa',
    borderColor: '#c3d7ee',
  },
  warning: {
    backgroundColor: colors.warning.background,
    borderColor: colors.warning.border,
    color: colors.warning.font,
  },
});

const ToastType = {
  Success: 'success',
  Info: 'info',
  Warning: 'warning',
};

function ToastNotification({ style, type, text1 }) {
  return (
    <View style={[styles.container, styles[type], style]}>
      <P style={[styles.text1, styles[type]]} numberOfLines={1}>{text1}</P>
    </View>
  );
}

ToastNotification.propTypes = {
  type: PropTypes.oneOf([ToastType.Success, ToastType.Info, ToastType.Warning]),
  text1: PropTypes.string,
};

ToastNotification.defaultProps = {
  type: ToastType.Info,
  text1: '',
};

export default ToastNotification;
