import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { P, Strong } from '../Text';
import Icon from '../Icon';
import styles from './InfoBar.styles';

function InfoBar({
  title, iconName, iconColor, description, style,
}) {
  return (
    <View style={[styles.container, styles.toggleViewButton, style]}>
      <Icon
        name={iconName}
        size={22}
        color={iconColor}
        style={styles.helpIcon}
      />
      <View style={styles.textWrapper}>
        {title && <Strong style={styles.title}>{title}</Strong>}
        {description && <P style={styles.description}>{description}</P>}
      </View>
    </View>
  );
}

InfoBar.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
};

InfoBar.defaultProps = {
  title: undefined,
  description: undefined,
  iconName: undefined,
  iconColor: undefined,
};

export default InfoBar;
