import { StyleSheet } from 'react-native';
import { colors } from '../../../constants';

export default StyleSheet.create({
  header: {
    padding: 40,
    borderBottomWidth: 1,
    borderBottomColor: colors.border.primary,
    backgroundColor: '#f9f9f9', // @TODO: alternative lighter background color variant. Add to theme
  },
});
