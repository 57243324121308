import { StyleSheet, Dimensions } from 'react-native';

const smallDevice = Dimensions.get('window').width < 1024;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F6F6F6',
  },
  leftContainer: {
    flex: 1,
    backgroundColor: '#FFF',
    borderTopRightRadius: smallDevice ? 0 : 20,
    borderBottomRightRadius: smallDevice ? 0 : 20,
    justifyContent: 'center',
    alignItems: 'center',
    shadowOffset: {
      width: 5,
      height: 5,
    },
    shadowOpacity: 0.1,
    shadowRadius: 10,
    width: smallDevice ? '100%' : '50%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  logoContainer: {
    width: '100%',
    maxWidth: 380,
    marginTop: 50,
  },
  formContainer: {
    flex: 1,
    maxWidth: 380,
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'center',
    paddingVertical: 50,
  },
});

export default styles;
