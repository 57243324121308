import { StyleSheet } from 'react-native';
import { colors, fonts } from '../../../constants';

export default StyleSheet.create({
  container: {
    padding: 40,
  },
  connectedContainer: {
    padding: 40,
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 322, // height of form state
  },
  textHeader: {
    fontSize: 18,
    marginBottom: 20,
    marginTop: 10,
  },
  text: {
    fontSize: fonts.size.label,
    lineHeight: 26,
    marginBottom: 20,
  },
  connectedText: {
    maxWidth: 480,
  },
  alert: {
    marginTop: 0,
    marginBottom: 15,
  },
  input: {
    marginTop: 15,
  },
  validationError: {
    marginTop: 3,
    marginLeft: 3,
    color: colors.ui.attention,
    fontSize: 14,
  },
});
