import React, { useState, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { mutationError } from '../../../services/Error';
import { validatePassword } from '../../../services/Validation';
import { FadeIn } from '../../../components/Animation';
import { PasswordField } from '../../../components/Form';
import Button from '../../../components/Button';
import Alert from '../../../components/Alert';
import useUpdateUserPassword from '../../../hooks/user/useUpdateUserPassword';
import { useToastNotification } from '../../../services/toast';
import { useLocale } from '../../../services/localization';
import styles from './UserPasswordForm.styles';

function UserPasswordForm({ userId, initialFields, style }) {
  const [fields, setFields] = useState(initialFields);
  const [variables, setVariables] = useState({});
  const [isTouched, setIsTouched] = useState(false);
  const [error, setError] = useState(false);
  const { toastSucces } = useToastNotification();
  const $t = useLocale();

  const locales = useMemo(() => ({
    toastSucces: $t({ id: 'UserPasswordForm.Toast.SubmitSucces', defaultMessage: 'Password updated and saved!' }),
    missingFields: $t({ id: 'Form.Error.MissingFields', defaultMessage: 'Please fill in all required fields.' }),
    passwordNotTheSame: $t({ id: 'Form.Error.PasswordNotTheSame', defaultMessage: 'Your new password and confirm password aren\'t the same. Please make sure they are.' }),
    passwordToShort: $t({ id: 'Form.Error.PasswordToShort', defaultMessage: 'Password must be at least 8 characters long.' }),
  }), [$t]);

  const handleInputChange = (e, fieldName) => {
    setError(false);
    if (!isTouched && fields[fieldName] !== e.target.value) setIsTouched(true);
    setFields({
      ...fields,
      [fieldName]: e.target.value,
    });
  };

  useEffect(() => {
    const vars = {};
    Object.keys(initialFields).forEach((key) => {
      if (!fields[key]) return;
      vars[key] = fields[key];
    });
    setVariables(vars);
  }, [fields, initialFields]);

  const { updateUserPassword, loading: isSaving } = useUpdateUserPassword(
    {
      userId,
      ...variables,
    },
    () => toastSucces(locales.toastSucces),
    (err) => setError(mutationError(err)),
  );

  const submit = () => {
    if (isSaving) return;
    if (fields.old_password === '' || fields.password === '' || fields.password_confirmation === '') {
      setError(locales.missingFields);
      return;
    }
    if (fields.password !== fields.password_confirmation) {
      setError(locales.passwordNotTheSame);
      return;
    }
    if (!validatePassword(fields.password)) {
      setError(locales.passwordToShort);
    }
    updateUserPassword();
  };

  return (
    <View style={[styles.container, style]}>
      <PasswordField
        style={styles.input}
        label={$t({ id: 'UserPasswordForm.Label.OldPassword', defaultMessage: 'Old password' })}
        placeholder={$t({ id: 'UserPasswordForm.Placeholder.OldPassword', defaultMessage: 'Enter your current password' })}
        value={fields.old_password}
        onChange={(e) => handleInputChange(e, 'old_password')}
        maxLength={100}
      />
      <PasswordField
        style={styles.input}
        label={$t({ id: 'UserPasswordForm.Label.NewPassword', defaultMessage: 'New password' })}
        placeholder={$t({ id: 'UserPasswordForm.Placeholder.NewPassword', defaultMessage: 'Enter a new password' })}
        value={fields.password}
        onChange={(e) => handleInputChange(e, 'password')}
        maxLength={100}
      />
      <PasswordField
        style={styles.input}
        label={$t({ id: 'UserPasswordForm.Label.ConfirmPassword', defaultMessage: 'Confirm new password' })}
        placeholder={$t({ id: 'UserPasswordForm.Placeholder.ConfirmPassword', defaultMessage: 'Please enter your new password again' })}
        value={fields.password_confirmation}
        onChange={(e) => handleInputChange(e, 'password_confirmation')}
        maxLength={100}
      />
      <View style={styles.actions}>
        <Button
          onPress={submit}
          label={isTouched ? $t({ id: 'UserPasswordForm.Button.ChangePassword', defaultMessage: 'Change password' }) : $t({ id: 'UserPasswordForm.Button.NothingToSave', defaultMessage: 'Nothing to save' })}
          size="small"
          loading={isSaving}
          disabled={!isTouched || isSaving}
        />
      </View>
      {error && (
        <FadeIn>
          <Alert text={error} />
        </FadeIn>
      )}
    </View>
  );
}

UserPasswordForm.propTypes = {
  userId: PropTypes.string.isRequired,
  initialFields: PropTypes.shape({
    old_password: PropTypes.string,
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }),
};

UserPasswordForm.defaultProps = {
  initialFields: { old_password: '', password: '', password_confirmation: '' },
};

export default UserPasswordForm;
