import { useMutation } from '@apollo/client';
import { PICK_ITEM, UNPICK_ITEM } from '../graphql/OrderQuery';

const usePickOrderLine = (orderLine) => {
  const [runMutation] = useMutation(PICK_ITEM);

  const [unpickOrderLine] = useMutation(UNPICK_ITEM);

  const pickOrderLine = () => {
    if (orderLine.picked === orderLine.quantity) return;
    runMutation({
      variables: { 
        orderLineId: orderLine.id,
      },
      optimisticResponse: {
        picked: {
          id: orderLine.id,
          quantity: orderLine.quantity,
          picked: orderLine.picked + 1,
          __typename: 'OrderLine',
        },
      },
    });
  };

  const revertPick = () => {
    if (orderLine.picked === 0) return;
    const quantity = orderLine.picked -1;
    unpickOrderLine({
      variables: {
        orderLineId: orderLine.id,
        quantity,
      },
      optimisticResponse: {
        picked: {
          id: orderLine.id,
          quantity: orderLine.quantity,
          picked: quantity,
          __typename: 'OrderLine',
        },
      },
    });
  };

  return { pickOrderLine, revertPick };
};

export default usePickOrderLine;
