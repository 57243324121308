import React from 'react';
import { View } from 'react-native';
import { P, Strong } from '../../../components/Text';
import Button from '../../../components/Button';
import Icon from '../../../components/Icon';
import { colors } from '../../../constants';
import { useBrowserNotifications } from '../../../services/notifications';
import styles from './BrowserNotifications.styles';

const greeting = ['Greetings', 'Hallo', 'Hello', 'Bonjour', 'Gutentag', 'Bzzzz', 'Ping', 'Booooom', 'Evaaaaaaah'];
const emoji = ['🚀', '🌳', '🐛', '👋', '🐰', '🎁', '📞', '💡', '🧸'];

function greet() {
  return `${greeting[Math.floor(Math.random() * greeting.length)]}! ${emoji[Math.floor(Math.random() * emoji.length)]}`;
}

function BrowserNotifications() {
  const { notifcationPermission, requestNotificationPermissions, sendNotification } = useBrowserNotifications();

  function renderToggleLabel() {
    if (notifcationPermission === 'granted') {
      return <Strong style={[styles.toggleLabel, styles.toggleLabelGreen]}>Enabled</Strong>;
    }
    if (notifcationPermission === 'denied') {
      return <Strong style={[styles.toggleLabel, styles.toggleLabelAttention]}>Disallowed</Strong>;
    }
    return <Strong style={styles.toggleLabel}>Disabled</Strong>;
  }

  return (
    <View style={styles.container}>
      <View style={styles.titleWrapper}>
        <View style={styles.providerWrapper}>
          <Strong style={styles.providerName}>Desktop Notifications</Strong>
        </View>
        <View style={styles.toggleWrapper}>
          {renderToggleLabel()}
        </View>
      </View>
      <View style={styles.seperator} />
      {['denied'].includes(notifcationPermission) && (
        <View style={styles.downloadPluginContainer}>
          <Icon size={22} name="notifications-off" color={colors.ui.attention} />
          <View style={styles.downloadPluginTextWrapper}>
            <P style={styles.downloadPluginText}>
              You've disallowed notifications in your browser. You'll need to open your browser preferences to change that.
            </P>
          </View>
        </View>
      )}
      {['granted'].includes(notifcationPermission) && (
      <View style={styles.downloadPluginContainer}>
        <Icon size={22} name="notifications-outline" color={colors.ui.ok} />
        <View style={styles.downloadPluginTextWrapper}>
          <P style={styles.downloadPluginText}>
            Notification are enabled. We'll let you know when important activity happens in your orderpicker workspace.
          </P>
          <Button style={styles.openLinkButton} onPress={() => sendNotification(greet(), 'This is a test notification. Have a nice day and keep on picking!')} label="Send test notification" type="secondary" rightIcon={<Icon size={22} style={styles.rightIcon} name="exit-outline" color={colors.brand.primary} />} />
        </View>
      </View>
      )}
      {['asking', 'prompt', 'default'].includes(notifcationPermission) && (
        <View style={styles.downloadPluginContainer}>
          <Icon size={22} name="information-circle" color={colors.brand.primary} />
          <View style={styles.downloadPluginTextWrapper}>
            <P style={styles.downloadPluginText}>
              We strongly recommend enabling notifications so that you will know when important activity happens in your orderpicker workspace.
            </P>
            {['prompt', 'default'].includes(notifcationPermission) && (
              <Button onPress={requestNotificationPermissions} style={styles.openLinkButton} label="Allow Dekstop Notifications" leftIcon={<Icon size={22} style={styles.openLinkIcon} name="notifications" color={colors.background.primary} />} />
            )}
            {['asking'].includes(notifcationPermission) && (
              <Strong style={styles.downloadPluginText}>👆 Click Allow in your browser's pop-up to enable notifications.</Strong>
            )}
          </View>
        </View>
      )}
    </View>
  );
}

export default BrowserNotifications;
