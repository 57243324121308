import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { gql, useSubscription } from '@apollo/client';
import { mutationError } from '../../services/Error';
import { useLocale } from '../../services/localization';
import { FadeIn } from '../../components/Animation';
import ScreenHeading from '../../components/ScreenHeading';
import ScreenWithSidebars from '../../components/ScreenWithSidebars';
import LoadingIndicator from '../../components/LoadingIndicator';
import Icon from '../../components/Icon';
import ImportStatusForm from '../../components/forms/ImportStatusForm';
import { ErrorNotification } from '../../components/ErrorNotification';
import { Strong, H4 } from '../../components/Text';
import { TextField, PasswordField } from '../../components/Form';
import ShopSubMenu from '../../components/ShopSubMenu';
import Alert from '../../components/Alert';
import Button from '../../components/Button';
import RetryConnectionWarning from '../../components/RetryConnectionWarning';
import ShopStatusLabel from '../../components/ShopStatusLabel';
import useShop from '../../hooks/useShop';
import { colors, ShopStatus } from '../../constants';
import useUpdateShop from '../../hooks/useUpdateShop';
import { isShopConnectionError } from '../../services/Helpers';
import { useToastNotification } from '../../services/toast';

const styles = StyleSheet.create({
  flexContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  heading: {
    marginBottom: 15,
    alignItems: 'center',
    flexDirection: 'row',
  },
  warningIcon: {
    marginLeft: 5,
  },
  input: {
    marginBottom: 15,
    flex: 1,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  leftActionsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    paddingRight: 10,
  },
  statusSpinner: {
    marginRight: 10,
  },
  shopName: {
    textTransform: 'uppercase',
    fontSize: 12,
    marginBottom: 10,
    color: '#B6B6B6',
    marginLeft: 13,
  },
  importSectionWrapper: {
    marginTop: 30,
  },
});

const initialFields = {
  url: '',
  key: '',
  secret: '',
};

const GET_SHOP_UPDATE = gql`
  subscription OnShopChanged($id: ID!) {
    shopUpdated(id: $id) {
      id
      status
    }
  }
`;

const shopSpinnerStatuses = [
  'testing',
  'fetching_order_statuses',
  'ready_to_test',
];

function EditWooCommerceScreen({ route, navigation }) {
  const { shopId } = route.params;
  const { loading, error, data: response } = useShop(shopId);
  const { toastInfo } = useToastNotification();
  const $t = useLocale();

  useSubscription(GET_SHOP_UPDATE, { variables: { id: shopId } });

  const [fields, setFields] = useState(initialFields);
  const [variables, setVariables] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [errors, setErrors] = useState(false);

  const { updateShop, loading: isSaving } = useUpdateShop(
    {
      shopId,
      ...variables,
    },
    () => {
      const msg = $t({ id: 'EditShopScreen.ChangesSaved.Text', defaultMessage: 'Changes to {name} saved' }, { name: fields.name });
      toastInfo(msg);
    },
    (err) => setErrors(mutationError(err)),
  );

  const save = () => {
    updateShop();
  };

  const handleInputChange = (e, fieldName) => {
    if (!e) { return; }
    const value = e.target?.value;
    if (!hasChanges && fields[fieldName] !== value) { setHasChanges(true); }
    setFields({
      ...fields,
      [fieldName]: value,
    });
  };

  // Only set variables for query that have content
  // to prevent sending things like empty password field
  useEffect(() => {
    const vars = {};
    Object.keys(initialFields).forEach((key) => {
      if (!fields[key]) { return; }
      vars[key] = fields[key];
    });
    setVariables(vars);
  }, [fields]);

  useEffect(() => {
    if (!response) { return; }

    setFields({
      ...initialFields,
      ...response.shop,
    });
  }, [response]);

  if (loading) { return <LoadingIndicator />; }
  if (!response) {
    return (
      <ErrorNotification
        errorTitle={$t({ id: 'EditShopScreen.ShopNotFound.Title', defaultMessage: 'Shop not found' })}
        errorMessage={$t({ id: 'EditShopScreen.ShopNotFound.Description', defaultMessage: "Sorry, seems that you're looking for a shop that does not exists." })}
        buttonLabel={$t({ id: 'EditShopScreen.BackToShopsButton.Label', defaultMessage: 'Back to shops' })}
        buttonAction={() => navigation.navigate('Shops')}
      />
    );
  }
  if (error) { return <ErrorNotification errorMessage={mutationError(error)} />; }

  return (
    <ScreenWithSidebars
      LeftSidebarComponent={(
        <>
          <Strong style={styles.shopName}>
            {response.shop.name}
          </Strong>
          <ShopSubMenu />
        </>
      )}
      MainContentComponent={(
        <FadeIn>
          <ScreenHeading title={$t({ id: 'EditShopScreen.Page.Title', defaultMessage: 'Edit shop {name}' }, { name: response.shop.name })} />
          {isShopConnectionError(response.shop.status) && <FadeIn><RetryConnectionWarning shopId={shopId} /></FadeIn>}
          <H4 style={styles.heading}>
            {$t({ id: 'EditShopScreen.ConnectionSection.Title', defaultMessage: 'Connection' })}
            {isShopConnectionError(response.shop.status) && <Icon name="ios-warning" size={20} color={colors.ui.attention} style={styles.warningIcon} />}
            {response.shop.status === ShopStatus.Active && <Icon name="checkmark-circle" size={20} color={colors.ui.ok} style={styles.warningIcon} />}
          </H4>
          <TextField
            style={styles.input}
            label={$t({ id: 'EditShopScreen.WebshopDomainField.Label', defaultMessage: 'Webshop domain' })}
            placeholder={$t({ id: 'EditShopScreen.WebshopDomainField.Placeholder', defaultMessage: 'https://www.yourwebshop.com' })}
            value={fields.url}
            onChange={(e) => handleInputChange(e, 'url')}
          />
          <TextField
            style={styles.input}
            label={$t({ id: 'EditShopScreen.WooConsumerKeyField.Label', defaultMessage: 'WooCommerce consumer key' })}
            placeholder={$t({ id: 'EditShopScreen.WooConsumerKeyField.Placeholder', defaultMessage: 'ck_326c14ee636...' })}
            value={fields.key}
            onChange={(e) => handleInputChange(e, 'key')}
          />
          <PasswordField
            style={styles.input}
            label={$t({ id: 'EditShopScreen.WooConsumerSecretField.Label', defaultMessage: 'WooCommerce consumer secret' })}
            placeholder={$t({ id: 'EditShopScreen.WooConsumerSecretField.Placeholder', defaultMessage: 'cs_123a64b8009c14c2b4...' })}
            value={fields.secret}
            onChange={(e) => handleInputChange(e, 'secret')}
            textContentType="none"
          />
          <View style={styles.actions}>
            <View style={styles.leftActionsWrapper}>
              {shopSpinnerStatuses.includes(response.shop.status) ? (
                <>
                  <ActivityIndicator size="small" style={styles.statusSpinner} />
                  <Strong>Validating connection...</Strong>
                </>
              ) : <ShopStatusLabel style={styles.shopStatus} status={response.shop.status} />}
            </View>
            <Button
              label={hasChanges
                ? $t({ id: 'EditShopScreen.SaveButton.Label', defaultMessage: 'Save' })
                : $t({ id: 'EditShopScreen.NothingToSave.Text', defaultMessage: 'Nothing to save' })}
              size="small"
              onPress={save}
              loading={isSaving}
              disabled={!hasChanges || isSaving || shopSpinnerStatuses.includes(response.shop.status)}
            />
          </View>
          {errors && <Alert text={errors} />}
          <View style={styles.importSectionWrapper}>
            <H4 style={styles.heading}>
              {$t({ id: 'EditShopScreen.ImportSection.Title', defaultMessage: 'Import' })}
            </H4>
            <ImportStatusForm
              shopId={shopId}
              shopStatus={response.shop.status}
              statusOptions={response.shop.woocommerce_order_statuses}
              importOrderStatus={response.shop.import_order_status}
              completedOrderStatus={response.shop.completed_order_status}
            />
          </View>
        </FadeIn>
      )}
    />
  );
}

export default EditWooCommerceScreen;
