import { useMutation } from '@apollo/client';
import { FORGOT_PASSWORD } from '../graphql/AuthQuery';

const useForgotPassword = (email, onCompleted, onError) => {
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    variables: { email },
    onError(error) {
      if (typeof onError === 'function') onError(error);
    },
    onCompleted() {
      if (typeof onCompleted === 'function') onCompleted();
    },
  });

  return { forgotPassword, loading };
};

export default useForgotPassword;
