import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet, Image } from 'react-native';
import BackgroundImage from '../assets/images/blocks.png';

const styles = StyleSheet.create({
  screen: {
    width: '100%',
    alignSelf: 'center',
    padding: 15,
    paddingTop: 30,
    paddingBottom: 50,
  },
  image: {
    width: 200,
    height: 200,
    position: 'absolute',
    right: '15%',
    top: 46,
  },
  small: {
    maxWidth: 500,
  },
  medium: {
    maxWidth: 600,
  },
  large: {
    maxWidth: 850,
  },
  full: {
    maxWidth: 1400,
  },
});

const ScreenSize = {
  Small: 'small',
  Medium: 'medium',
  Large: 'large',
  Full: 'full',
};

const ScreenStyle = {
  [ScreenSize.Small]: styles.small,
  [ScreenSize.Medium]: styles.medium,
  [ScreenSize.Large]: styles.large,
  [ScreenSize.Full]: styles.full,
};

function Screen({
  style, size, backgroundImage, ...props
}) {
  const styling = [styles.screen, ScreenStyle[size], style];
  return (
    <View>
      {backgroundImage && <Image style={styles.image} source={BackgroundImage} />}
      <View style={styling} {...props} />
    </View>
  );
}

Screen.propTypes = {
  size: PropTypes.oneOf(Object.values(ScreenSize)),
  backgroundImage: PropTypes.bool,
};

Screen.defaultProps = {
  size: ScreenSize.Large,
  backgroundImage: false,
};

export default Screen;
