import { StyleSheet, Dimensions } from 'react-native';

export const smallDevice = Dimensions.get('window').width < 875;

const styles = StyleSheet.create({
  button: {
    marginHorizontal: smallDevice ? 0 : 5,
    marginBottom: smallDevice ? 10 : 0,
  },
});

export default styles;
