import React, { useState } from 'react';
import { View, Modal } from 'react-native';
import PropTypes from 'prop-types';
import Backdrop from '../../components/Backdrop';
import CloseModalButton from '../../components/CloseModalButton';
import styles from './ConnectSendcloudModal.styles';
import ConnectForm from './ConnectForm';
import ValidateCarriers from './ValidateCarriers';
import { useToastNotification } from '../../services/toast';
import { useLocale } from '../../services/localization';

const ModalState = {
  ConnectForm: 'connect_form',
  ValidateCarriers: 'validate_carriers',
};

const ConnectSendcloudModal = React.forwardRef(({ shopId }, ref) => {
  const $t = useLocale();
  const [isVisible, setIsVisible] = useState(false);
  const [state, setState] = useState(ModalState.ConnectForm);
  const { toastSucces } = useToastNotification();

  const onRequestClose = () => {
    setIsVisible(false);
  };

  React.useImperativeHandle(ref, () => ({
    open: () => {
      setState(ModalState.ConnectForm); // reset modal state
      setIsVisible(true);
    },
  }));

  const nextState = () => {
    switch (state) {
      case ModalState.ConnectForm:
        setState(ModalState.ValidateCarriers);
        break;
      case ModalState.ValidateCarriers:
        setIsVisible(false);
        toastSucces($t({ defaultMessage: '☁️ Sendcloud setup completed! 🥳', id: 'SendcloudConnectModal.ConnectSuccess.Toast' }));
        break;
      default:
        setState(ModalState.ConnectForm);
        break;
    }
  };

  function renderState() {
    if (state === ModalState.ConnectForm) {
      return <ConnectForm shopId={shopId} onRequestClose={onRequestClose} onCompleted={nextState} />;
    }
    if (state === ModalState.ValidateCarriers) {
      return <ValidateCarriers shopId={shopId} onRequestClose={onRequestClose} onCompleted={nextState} />;
    }
    return null;
  }

  return (
    <Modal onRequestClose={onRequestClose} animationType="fade" visible={isVisible} transparent>
      <Backdrop />
      <View style={styles.contentWrapper}>
        {renderState()}
        <CloseModalButton onPress={onRequestClose} />
      </View>
    </Modal>
  );
});

const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

ConnectSendcloudModal.propTypes = {
  shopId: IdType.isRequired,
};

export default ConnectSendcloudModal;
