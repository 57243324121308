import React from 'react';
import { StackActions } from '@react-navigation/native';
import Screen from '../../components/Layout';
import { FadeIn } from '../../components/Animation';
import ScreenHeading from '../../components/ScreenHeading';
import PickersList from '../../components/PickersList';
import { useLocale } from '../../services/localization';

function PickersScreen({ navigation }) {
  const $t = useLocale();
  return (
    <Screen backgroundImage>
      <FadeIn>
        <ScreenHeading
          title={$t({ id: 'PickersScreen.Heading', defaultMessage: 'Pickers' })}
          buttonLabel={$t({ id: 'PickersScreen.AddPickerButton.Label', defaultMessage: '+ Add a picker' })}
          buttonAction={() => navigation.dispatch(
            StackActions.replace('AddPicker'),
          )}
        />
        <PickersList />
      </FadeIn>
    </Screen>
  );
}

export default PickersScreen;
