import { StyleSheet } from 'react-native';
import { colors } from '../../constants/theme';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.10,
    shadowRadius: 3,
    marginBottom: 15,
  },
  titleWrapper: {
    paddingHorizontal: 20,
    paddingVertical: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  providerName: {
    fontSize: 18,
  },
  toggleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  toggleLabel: {
    textTransform: 'uppercase',
    color: colors.label.tertiary,
    fontSize: 11,
  },
  toggleLabelGreen: {
    color: colors.ui.ok,
  },
  toggleLabelBlue: {
    color: colors.brand.primary,
  },
  seperator: {
    height: 1,
    backgroundColor: '#F0F0F0',
  },
  pluginInstalledContainer: {
    padding: 20,
  },
  pluginInstalledTextWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  noBottomMargin: {
    marginBottom: 0,
  },
  pluginInstalledText: {
    marginLeft: 10,
    lineHeight: 20,
    color: colors.label.secondary,
    flexShrink: 1,
  },
  downloadPluginContainer: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexShrink: 1,
  },
  downloadPluginTextWrapper: {
    flexShrink: 1,
    marginLeft: 10,
    marginTop: 3,
  },
  downloadPluginTitle: {
    lineHeight: 20,
    color: colors.label.primary,
    fontSize: 14,
    marginBottom: 10,
  },
  downloadPluginText: {
    lineHeight: 20,
    color: colors.label.primary,
  },
  openLinkButton: {
    alignSelf: 'flex-start',
    marginTop: 10,
  },
  openLinkIcon: {
    marginLeft: 7,
  },
  visitStoreWrapper: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  visitStoreIcon: {
    marginLeft: 3,
    marginTop: 3,
  },
});

export default styles;
